import React from "react";
import Button from "../Button";
import {
  StyledRegistrationBtn,
  StyledRegistrationLink,
} from "../styles/RegistrationBtn.styled";
import { StyledRoomCard, StyledRoomCardName } from "../styles/roomDetails/RoomCard.styled";
import RoomCardInfo from "./RoomCardInfo";
import { StyledRoomCardLogo } from "../styles/roomDetails/RoomCardLogo.styled";
import { StyledRoomCardFooter } from "../styles/roomDetails/RoomCardFooter.styled";
import { FaStar } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { CgCardHearts } from "react-icons/cg";
import * as DOMPurify from "dompurify";


function RoomCard({ room }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledRoomCard style={{ position: "relative" }}>
      <div
        style={{
          fontSize: isMobile ? "2rem" : "3rem",
          fontWeight: "700",
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaStar color="rgb(255, 188, 11)" />
        <span>{room?.rating}</span>
      </div>
      <StyledRoomCardLogo src={room?.logo} alt={room?.name} />
      <StyledRoomCardName>{room?.name}</StyledRoomCardName>
      <div className="room-card-info__first">
        <div className="key" style={{ display: "flex" }}>
          <span className="key">🌐 Мережа:</span>
          <span style={{ color: "black" }}>{room?.network}</span>
        </div>
        <div className="key" style={{ display: "flex" }}>
          <span className="key">💰 Рейкбек:</span>
          <span style={{ color: "black" }}>{room?.rakeback}</span>
        </div>
        <div className="key" style={{ display: "flex" }}>
          <span className="key">🎁 Бонус:</span>
          <span style={{ color: "black" }}>{room?.first_bonus}</span>
        </div>
      </div>
      <StyledRegistrationLink to={`/rooms/${room?.slug}/aff/`} target="_blank">
        Зареєструватися
      </StyledRegistrationLink>
      <p
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(room?.description),
        }}
      ></p>

      <StyledRoomCardFooter>
        <RoomCardInfo keyy="🎂 Заснований" value={room?.start_work} />
        <RoomCardInfo keyy="😎 Власник" value={room?.owner} />
        <RoomCardInfo keyy="📝 Ліцензія" value={room?.license} />
        <RoomCardInfo keyy="📱 Пристрої" value={room?.os_txt} />
        <RoomCardInfo keyy="💳 Депозит/Кешаут" value={room?.cashier_methods_txt} />
        <RoomCardInfo keyy="🚀 Швидкість виводу" value={room?.cashout_speed} />
        <RoomCardInfo keyy="🚧 Ліміт на вивід" value={room?.cashout_limit} />
        <RoomCardInfo keyy="💲 Валюта" value={room?.currency} />
        <RoomCardInfo keyy="👨‍👦‍👦 Гравців онлайн" value={room?.players_peak} />
        <RoomCardInfo keyy="🌎 Мови" value={room?.language} />
        <RoomCardInfo keyy="🛂 Документи" value={room?.documents} />
        <RoomCardInfo keyy="💬 Онлайн-чат" value={room?.support_chat} />
        <RoomCardInfo keyy="📧 Email" value={room?.support_email} />
        <RoomCardInfo keyy="📞 Телефон" value={room?.support_phone} />
        <RoomCardInfo keyy="📊 HUD" value={room?.huds} />
        <RoomCardInfo keyy="📊 Трекери" value={room?.trackers} />
        <RoomCardInfo keyy="🌐 VPN" value={room?.vpn} />
        <RoomCardInfo keyy="⛏️ Майнінг" value={room?.mining} />
      </StyledRoomCardFooter>
    </StyledRoomCard>
  );
}

export default RoomCard;
