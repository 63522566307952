import React from "react";
import { BiTime, BiCommentDetail, BiLike } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import {
  StyledColorBlock,
  StyledInfoContainer,
  StyledInfo,
  StyledColorBlockLink,
  StyledColorBlockLinkContainer,
  StyledColorBlockSimpleLink,
} from "../../componets/styles/pages/main/MainPage.styled";
import formateDate from "../../utils/DateFormater";

function PublicationsBlock({ publicationList }) {

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return (
    <StyledColorBlock>
      <div style={{ display: "flex" }}>
        <h3>Публікації</h3>
        {isNotMobile && (
          <StyledColorBlockLinkContainer>
            <StyledColorBlockLink
              to="/publications/"
              colorStyles={{
                color: "#184f6f",
                bgColor: "rgba(40, 96, 129, 0.15)",
                bgHover: "rgba(40, 96, 129, 0.2)",
                bgActive: "rgba(40, 96, 129, 0.25)",
              }}
            >
              Усі публікації
            </StyledColorBlockLink>

            {/* <StyledColorBlockLink
              to="/publications/create/"
              colorStyles={{
                color: "#fff",
                bgColor: "#286081",
                bgHover: "rgba(40, 96, 129, 0.9)",
                bgActive: "rgba(40, 96, 129, 0.8)",
              }}
            >
              Додати публікацію
            </StyledColorBlockLink> */}
          </StyledColorBlockLinkContainer>
        )}
      </div>
      <p>
        Ми публікуємо цікаві статті, переклади зарубіжних статей, останні
        новини, актуальні акції покер румів та онлайн казино.
      </p>
      {isMobile && (
        <StyledColorBlockLink
          to="/publications/"
          style={{ margin: "1.5rem 0" }}
          colorStyles={{
            color: "#184f6f",
            bgColor: "rgba(40, 96, 129, 0.15)",
            bgHover: "rgba(40, 96, 129, 0.2)",
            bgActive: "rgba(40, 96, 129, 0.25)",
          }}
        >
          Усі публікації
        </StyledColorBlockLink>
      )}
      <p>
        Також ти можеш додати новину, акцію чи написати статтю на гемблінг
        тематику та отримати до <b> 200 betraisers points</b> ($2 USD)
      </p>
      {isMobile && (
        <StyledColorBlockLink
          to="/publications/create/"
          style={{ margin: "1.5rem 0" }}
          colorStyles={{
            color: "#fff",
            bgColor: "#286081",
            bgHover: "rgba(40, 96, 129, 0.9)",
            bgActive: "rgba(40, 96, 129, 0.8)",
          }}
        >
          Додати публікацію
        </StyledColorBlockLink>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(30rem,1fr))",
          gridGap: "1.5rem",
        }}
      >
        <StyledColorBlock
          bgColor="none"
          color="#000"
          style={{ border: "none" }}
        >
          <h3>Акції</h3>
          <ul>
            {publicationList?.promotions?.map((promotion) => {
              return (
                <li>
                  <Link
                    to={`/publications/${promotion?.category?.slug}/${promotion?.slug}/`}
                  >
                    {promotion?.title}
                  </Link>

                  <StyledInfoContainer>
                    <StyledInfo>
                      <BiTime />
                      {formateDate(promotion?.date_published)}
                    </StyledInfo>

                    <StyledInfo>
                      <BiCommentDetail /> {promotion?.comments_count}
                    </StyledInfo>

                    <StyledInfo>
                      <BiLike />
                      {promotion?.likes_count}
                    </StyledInfo>
                  </StyledInfoContainer>
                </li>
              );
            })}
          </ul>
          <StyledColorBlockSimpleLink to={`/publications/promotions/`}>
            {">"} Усі акції
          </StyledColorBlockSimpleLink>
        </StyledColorBlock>

        <StyledColorBlock
          bgColor="none"
          color="#000"
          style={{ border: "none" }}
        >
          <h3>Новини</h3>

          <ul>
            {publicationList?.news?.map((news) => {
              return (
                <li>
                  <Link
                    to={`/publications/${news?.category?.slug}/${news?.slug}/`}
                  >
                    {news?.title}
                  </Link>

                  <StyledInfoContainer>
                    <StyledInfo>
                      <BiTime />
                      {formateDate(news?.date_published)}
                    </StyledInfo>

                    <StyledInfo>
                      <BiCommentDetail />
                      {news?.comments_count}
                    </StyledInfo>

                    <StyledInfo>
                      <BiLike />
                      {news?.likes_count}
                    </StyledInfo>
                  </StyledInfoContainer>
                </li>
              );
            })}
          </ul>
          <StyledColorBlockSimpleLink to={`/publications/news/`}>
            {">"}Усі новини
          </StyledColorBlockSimpleLink>
        </StyledColorBlock>

        <StyledColorBlock
          bgColor="none"
          color="#000"
          style={{ border: "none" }}
        >
          <h3>Статті</h3>
          <ul>
            {publicationList?.articles?.map((article) => {
              return (
                <li>
                  <Link
                    to={`/publications/${article?.category?.slug}/${article?.slug}/`}
                  >
                    {article?.title}
                  </Link>

                  <StyledInfoContainer>
                    <StyledInfo>
                      <BiTime />
                      {formateDate(article?.date_published)}
                    </StyledInfo>

                    <StyledInfo>
                      <BiCommentDetail />
                      {article?.comments_count}
                    </StyledInfo>

                    <StyledInfo>
                      <BiLike />
                      {article?.likes_count}
                    </StyledInfo>
                  </StyledInfoContainer>
                </li>
              );
            })}
          </ul>
          <StyledColorBlockSimpleLink to="/publications/articles/">
            {">"}Усі статті
          </StyledColorBlockSimpleLink>
        </StyledColorBlock>
      </div>
    </StyledColorBlock>
  );
}

export default PublicationsBlock;
