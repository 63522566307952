import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledTheme = styled.div`
  display: flex;
  border-bottom: 1px solid silver;
  padding: 1rem 0;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StyledThemeTitleContainer = styled.div`
  width: 50%;
  margin-right: 2rem;
`;

export const StyledThemeTitle = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledThemeAuthor = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: grey;
`;

export const StyledThemeStatContainer = styled.div`
  width: 25%;
  font-size: 1.3rem;
  margin-right: 2rem;
`;

export const StyledThemeLastPostContainer = styled.div`
  width: 25%;
`;

export const StyledThemeLastPost = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledThemeLastPostDate = styled.div`
  margin-right: 2rem;
`;

export const StyledThemeLastPostAuthor = styled.div``;

// ========== MOBILE ==========

export const StyledTheme_Mob = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid silver;
  padding: 1rem 0;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StyledThemeTitleContainer_Mob = styled.div`
  width: 100%;
  margin-right: 2rem;
`;

export const StyledThemeTitle_Mob = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledThemeAuthor_Mob = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: grey;
`;

export const StyledThemeStatContainer_Mob = styled.div`
  width: 100%;
  font-size: 1.3rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
`;

export const StyledThemeLastPostContainer_Mob = styled.div`
  width: 100%;
`;

export const StyledThemeLastPost_Mob = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledThemeLastPostDate_Mob = styled.div`
  margin-right: 2rem;
`;

export const StyledThemeLastPostAuthor_Mob = styled.div``;
