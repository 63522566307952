import React from "react";
import { useEffect, useContext } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import useApi from "../../services/useApi";
import { getCurrentUser } from "../../services/users";
import AuthenticationContext from "../contexts/AuthenticationContext";

function CurrentUserChecker({ children }) {
  const { state, dispatch } = useContext(AuthenticationContext);
  const { data, loading, error, request } = useApi(getCurrentUser);
  const [token, setToken] = useLocalStorage("token");

  useEffect(() => {
    if (!token) {
      dispatch({ type: "CLEAR_CURRENT_USER" });
      return;
    }
    dispatch({ type: "SET_LOADING" });
    // request("/dj-rest-auth/user/");
    request('pa/editprofile/')
  }, [token]);

  useEffect(() => {
    if (!data) {
      dispatch({ type: "UNSET_LOADING" });
      return;
    }
    dispatch({ type: "CURRENT_USER", payload: {...data?.user, photo: data?.photo} });
  }, [data]);

  if (error) {
    setToken("");
  }
  return children;
}

export default CurrentUserChecker;
