import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { StyledAccordion, StyledAccordionSubMenu } from "../../styles/layout/mobile/Accordion.styled";

function Accordion({ title, subMenu, closeSidebar }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <StyledAccordion isActive={isActive}>
      <div onClick={() => setIsActive(!isActive)}>{title}</div>
      {isActive && (
        <StyledAccordionSubMenu isActive={isActive}>
          {subMenu.map((item) => {
            return <NavLink onClick={() => closeSidebar()} to={item?.slug}>{item?.name}</NavLink>;
          })}
        </StyledAccordionSubMenu>
      )}
    </StyledAccordion>
  );
}

export default Accordion;
