import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledEntranceModal = styled.div`
  position: relative;
  outline: 0px;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  max-width: 43rem;
  width: 100%;
  min-height: 55rem;
  text-align: left;
  border-radius: 0.8rem;
  background-color: white;
  padding: 4.8rem 5rem 2rem;

  @media (max-width: 767px) {
    padding: 2.8rem 2rem 2rem;
  }
`;

export const StyledEntranceTab = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const StyledEntranceTabItem = styled(NavLink)`
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  font-size: 3rem;
  font-weight: 700;
  margin-right: 2.5rem;
  padding-bottom: 0.8rem;
  transition: 0.25s color;

  &:last-of-type {
    margin-right: 0rem;
  }

  &:hover {
    color: rgba(0, 0, 0, 1);
  }

  &.active {
    color: rgba(0, 0, 0, 1);
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    margin-bottom: -2px;
  }
`;

export const StyledEntranceTitle = styled.div`
  padding-bottom: 2rem;
  white-space: pre-line;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: normal;
  overflow-wrap: break-word;
`;
