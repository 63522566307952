import styled from "styled-components";

export const StyledCasinoInformation = styled.div`
  width: 100%;
  border-radius: 1rem;
  background-color: #f2f5f7;
  border: 1px solid rgba(196, 205, 212, 0.5);
  padding: 3rem;

  text-align: left;
  display: block;
  column-count: 2;
  column-gap: 10rem;
  padding: 2.5rem 3rem;
  border-top: 1px solid rgba(196, 205, 212, 0.5);

  @media (max-width: 767px) {
    padding: 2rem 1rem 1rem 1rem;
    column-count: 1;
    column-gap: 1rem;
  }

`;

export const StyledCasinoInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const StyledCasinoInformationKey = styled.div`
    width: 50%;
    margin-right: 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #797a7b;
`;

export const StyledCasinoInformationValue = styled.div`
    width: 50%;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #000;
`;