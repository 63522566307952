import React from "react";
import SubsectionList from "./SubsectionList";
import {
  StyledSection,
  StyledSectionName,
  StyledSectionContainerSubsection,
} from "../styles/forum/Section.styled";

function Section({data}) {
  return (
    <StyledSection>
      <StyledSectionName>{data?.name}</StyledSectionName>
      <StyledSectionContainerSubsection>
        <SubsectionList data={data?.subsections} />
      </StyledSectionContainerSubsection>
    </StyledSection>
  );
}

export default Section;
