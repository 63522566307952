import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledWebsitePropositionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-gap: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    grid-gap: 0.3rem;
    margin-bottom: 1rem;
  }
`;

export const StyledWebsiteProposition = styled(Link)`
  color: #FFFFFF;
  border-radius: 1rem;
  background-color: rgb(51, 51, 51);
  border: 1px solid #f1f7ff;
  padding: 2rem;

  &:hover {
    border: 1px solid #3b93ff;
    cursor: pointer;
  }
`;

export const StyledWebsitePropositionIcon = styled.div`
  font-size: 2.5rem;
  margin-right: 1rem;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;

export const StyledWebsitePropositionTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 1.7rem;
  }
`;

export const StyledWebsitePropositionDesc = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;
