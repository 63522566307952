import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledColorBlock = styled.div`
  border-radius: 1rem;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 4rem;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid silver;

  &:first-of-type {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  p {
    font-size: 1.7rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  h3 {
    font-size: 3.8rem;
    line-height: 1.25;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  ul {
    font-size: 1.6rem;
  }

  li {
    margin-bottom: 2rem;
  }

  li > a {
    text-decoration: none;
    color: inherit;
    font-size: 1.6rem;
    font-weight: 500;
  }

  li > a:hover {
    opacity: 0.5;
  }

  li:hover {
    /* color: rgba(24, 79, 111, 1); */
    /* opacity: 0.5 */
  }

  @media (max-width: 767px) {
    padding: 1.5rem 1.5rem 3rem;
    border-radius: 0.5rem;
    margin-bottom: 0.2rem;

    &:first-of-type {
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
    }

    h3 {
      font-size: 2.2rem;
      line-height: 1.27;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.42;
    }
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props?.flexDirection};
  margin-top: ${(props) => props?.mt || 0};
  margin-right: ${(props) => props?.mr || 0};
  margin-bottom: ${(props) => props?.mb || 0};
  margin-left: ${(props) => props?.ml || 0};
  padding-top: ${(props) => props?.pt || 0};
  padding-right: ${(props) => props?.pr || 0};
  padding-bottom: ${(props) => props?.pb || 0};
  padding-left: ${(props) => props?.pl || 0};

  @media (max-width: 767px) {
    margin-top: ${(props) => props?.mobMt};
    margin-right: ${(props) => props?.mobMr};
    margin-bottom: ${(props) => props?.mobMb};
    margin-left: ${(props) => props?.mobMl};
    padding-top: ${(props) => props?.mobPt};
    padding-right: ${(props) => props?.mobPr};
    padding-bottom: ${(props) => props?.mobPb};
    padding-left: ${(props) => props?.mobPl};
  }
`;

export const StyledBlockContainer = styled.div`
  width: ${(props) => props?.width || "100%"};
  margin-top: ${(props) => props?.mt || 0};
  margin-right: ${(props) => props?.mr || 0};
  margin-bottom: ${(props) => props?.mb || 0};
  margin-left: ${(props) => props?.ml || 0};
  padding-top: ${(props) => props?.pt || 0};
  padding-right: ${(props) => props?.pr || 0};
  padding-bottom: ${(props) => props?.pb || 0};
  padding-left: ${(props) => props?.pl || 0};
`;

export const StyledColorBlockSimpleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: 1.6rem;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledColorBlockLinkContainer = styled.div`
  margin-left: auto;
`;

export const StyledColorBlockLink = styled(Link)`
  width: fit-content;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: 0.25s;
  overflow: visible;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10rem;
  height: 5rem;
  padding: 0 3rem;
  font-size: 1.7rem;
  line-height: 1.41;
  margin-left: 1rem;
  color: ${(props) => props?.colorStyles?.color || "#000"};
  background-color: ${(props) => props?.colorStyles?.bgColor || "none"};
  white-space: nowrap;

  &:hover {
    background-color: ${(props) => props?.colorStyles?.bgHover || "none"};
  }

  &:active {
    background-color: ${(props) => props?.colorStyles?.bgActive || "none"};
  }

  @media (max-width: 767px) {
    border-radius: 10rem;
    height: 4rem;
    padding: 0 2.5rem;
    font-size: 1.4rem;
    line-height: 1.28;
    margin-left: 0;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  opacity: 0.5;
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  font-size: 1.4rem;

  &:first-of-type {
    min-width: 14rem;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const StyledListRoom = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 1.5rem; */
  display: flex;
  justify-content: center;
`;

export const StyledRoomCard = styled.div`
  color: #000;
  background-color: white;
  border-radius: 1rem;
  padding: 3rem;
`;

export const StyledRoomCardHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const StyledRoomCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledRoomCardTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
`;

export const StyledRoomCardLogo = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 2rem;
  background-color: #000;
  transform: translateZ(0);
  padding: 1rem;
  object-fit: contain;

  &:not(:first-of-type) {
    margin-left: calc((100% - 10 * 9rem) / 10);
  }

  @media (max-width: 767px) {
    width: 4.3rem;
    height: 4.3rem;
    border-radius: 1rem;
    padding: 0.3rem;

    &:not(:first-of-type) {
      margin-left: calc((100% - 10 * 4.3rem) / 10);
    }
  }
`;

export const StyledRoomCardRating = styled.div``;

export const StyledRoomCardBody = styled.div`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const StyledRoomCardFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  grid-gap: 1rem;
`;
