import React from "react";
import {
  StyledChangeInput,
  StyledInput,
  StyledChangeLabel,
} from "../styles/form/ChangeInput.styled";

function ChangeInput({ id, name, label, value, onChange }) {
  return (
    <StyledChangeInput>
      <StyledChangeLabel htmlFor={id}>{label}</StyledChangeLabel>
      <StyledInput
        name={name}
        id={id}
        placeholder="0"
        value={value}
        onChange={onChange}
      />
    </StyledChangeInput>
  );
}

export default ChangeInput;
