import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledTheme = styled.div`
  margin-bottom: 1rem;
  background-color: rgb(242, 245, 247);
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const StyledThemeSubsection = styled.div`
  margin-bottom: 0.5rem;
  color: grey;
  font-weight: 700;
`;

export const StyledThemeName = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(49, 135, 238);
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledThemeAuthor = styled.div`
  font-weight: 700;
`;
