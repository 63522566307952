import React, { useState, useEffect, useRef, useContext } from "react";
import { StyledCommentButton } from "../styles/comments/Comments.styled";
import { StyledRestorePasswordBtn } from "../styles/Button.styled";
import {
  StyledComment,
  StyledCommentImg,
  StyledCommentContainer,
  StyledCommentBodyContainer,
  StyledCommentBody,
  StyledCommentBottomContainer,
  StyledCommentTime,
  StyledCommentButtons,
  StyledCommentAuthor,
} from "../styles/comments/Comments.styled";
import Button from "../Button";
import { BiCommentAdd, BiTrash, BiLike, BiDislike } from "react-icons/bi";
import { Rating } from "react-simple-star-rating";
import ReviewAnswerList from "./ReviewAnswerList";
import JustRichTxtEditor from "../richTxtEditor/JustRichTxtEditor";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import useApi from "../../services/useApi";
import { addReviewAnswer, gradeReview } from "../../services/rooms";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import formateDate from "../../utils/DateFormater";
import { toast } from "react-toastify";
import AuthenticationContext from "../contexts/AuthenticationContext";
import * as DOMPurify from "dompurify";


function Review({ data, refreshList, pathToRequest }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();
  const [isShowList, setIsShowList] = useState(false);
  const [isScroll, setIsScrool] = useState(false);
  const [isShowEditor, setIsShowEditor] = useState(false);
  const [dataReviewAnswer, setDataReviewAnswer] = useState(null);
  const [allData, setAllData] = useState({});
  const [dataToRequest, setDataToRequest] = useState({});
  const [isFetch, setIsFetch] = useState(false);
  const [isGrade, setIsGrade] = useState(false);
  const [grade, setGrade] = useState(null);

  const {state} = useContext(AuthenticationContext)

  const {
    data: dataGrade,
    loading: loadingGrade,
    error: errorGrade,
    request: requestGrade,
  } = useApi(gradeReview);

  const richEditorRef = useRef(null);

  const scroll = (ref) => {
    ref?.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  const {
    data: dataResponse,
    loading,
    error,
    request,
  } = useApi(addReviewAnswer);

  const handleAnswer = (e, dataA) => {
    setIsShowEditor(true);
    setIsScrool(true);
    setDataReviewAnswer(null);
    setAllData({ dataReview: dataA, dataReviewAnswer: null });
  };

  const answerData = (dataA) => {
    setIsShowEditor(true);
    setDataReviewAnswer(dataA);
    setAllData({ dataReview: data, dataReviewAnswer: dataA });
  };

  const handleSendAnswer = () => {
    setIsFetch(true);
  };

  const handleGradeClick = (e, id, vote, type) => {
    setGrade({ id, vote, type });
  };

  const dataBody = (data) => {
    setDataToRequest((prevState) => ({ ...prevState, data }));
  };

  useEffect(() => {
    if (!isFetch) {
      return;
    }
    request((pathToRequest && `${pathToRequest}answer/add/`) || `${location.pathname}answer/add/`, {
      feedback: allData.dataReview.id,
      text: dataToRequest.data,
    });
    setIsFetch(false);
  }, [isFetch]);

  useEffect(() => {
    if (!dataResponse) {
      return;
    }
    toast.success("Відповідь додано!");
    refreshList(true);
    setIsShowEditor(false)
  }, [dataResponse, refreshList]);

  useEffect(() => {
    if (!isScroll) {
      return;
    }
    setTimeout(() => {
      scroll(richEditorRef);
    }, 50);
    setIsScrool(false);
  }, [isScroll]);

  useEffect(() => {
    if (!grade) {
      return;
    }
    requestGrade((pathToRequest && `${pathToRequest}grade/`) || `${location.pathname}grade/`, grade);
    setGrade(null);
  }, [grade]);

  return (
    <div style={{ scrollMarginBottom: "2rem" }} ref={richEditorRef}>
      <StyledComment>
        {!isMobile && (
          <StyledCommentImg src={data.user.photo} alt={data.user.user.username} />
        )}
        <StyledCommentContainer>
          <div
            style={{
              display: "flex",
              width: "100%",
              // justifyContent: "space-between",
            }}
          >
            {isMobile && (
              <StyledCommentImg src={data.user.photo} alt={data.user.user.username} />
            )}
            <StyledCommentAuthor>{data.user.user.username}</StyledCommentAuthor>
          </div>

          <StyledCommentBodyContainer>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                Оцінка користувача
              </div>
              <Rating initialValue={data.rating} size={20} readonly />
            </div>

            <StyledCommentBody
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.text) }}
            ></StyledCommentBody>
            {data.disadvantages && (
              <>
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    color: "green",
                  }}
                >
                  Плюси
                </div>
                <div
                  style={{ fontSize: "1.5rem", marginBottom: "1rem" }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.disadvantages) }}
                ></div>
              </>
            )}
            {data.advantages && (
              <>
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    color: "red",
                  }}
                >
                  Мінуси
                </div>
                <div
                  style={{ fontSize: "1.5rem" }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.advantages) }}
                ></div>
              </>
            )}

            <StyledCommentBottomContainer>
              <StyledCommentTime>
                {formateDate(data.date_published, true)}
              </StyledCommentTime>
              <StyledCommentButtons>
                {state?.currentUser && state?.isLoggedIn && (
                  <Button
                    icon={<BiCommentAdd />}
                    styled={StyledCommentButton}
                    text="Відповісти"
                    onClick={(e) => handleAnswer(e, data)}
                  />
                )}

                {/* {state?.currentUser?.username === user?.user?.username && (
                  <Button
                    icon={<BiTrash />}
                    styled={StyledCommentButton}
                    text="Удалить"
                    onClick={() => handleDelete(id)}
                  />
                )} */}
                <Button
                  icon={<BiLike />}
                  styled={StyledCommentButton}
                  text={dataGrade?.likes || data.likes || 0} // need to fix
                  // style={gradeStatus?.user_vote ? { color: "green" } : {}}
                  onClick={(e) =>
                    handleGradeClick(e, data?.id, "like", "review")
                  }
                  // onClick={(e) => handleLikeClick(e, id)}
                />
                <Button
                  icon={<BiDislike />}
                  styled={StyledCommentButton}
                  text={dataGrade?.dislikes || data.dislikes || 0} // need to fix
                  // style={
                  //   gradeStatus?.user_vote === false ? { color: "red" } : {}
                  // }
                  // onClick={(e) => handleDislikeClick(e, id)}
                  onClick={(e) =>
                    handleGradeClick(e, data?.id, "dislike", "review")
                  }
                />
              </StyledCommentButtons>
            </StyledCommentBottomContainer>
          </StyledCommentBodyContainer>
        </StyledCommentContainer>
      </StyledComment>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {data?.answers.length > 0 && !isShowList && (
          <Button
            style={{
              fontSize: "1.3rem",
              color: "rgba(0,0,0,0.5)",
              marginTop: "-1.5rem",
            }}
            styled={StyledRestorePasswordBtn}
            text="Показати відповіді"
            onClick={(e) => setIsShowList(true)}
          />
        )}
      </div>
      {isShowList && (
        <div>
          <ReviewAnswerList
            list={data?.answers}
            answerData={answerData}
            refToScroll={richEditorRef}
          />
        </div>
      )}
      {isShowEditor && (
        <div
          style={{
            marginBottom: "2rem",
            marginLeft: !isMobile ? (isShowList ? "9rem" : "4.5rem") : "0rem",
            scrollMargin: "8rem",
          }}
        >
          <JustRichTxtEditor
            dataBody={dataBody}
            dataReviewAnswer={dataReviewAnswer}
          />
          <Button
            style={{ marginTop: "1rem" }}
            styled={StyledRedBtn}
            text="Відправити"
            onClick={handleSendAnswer}
          />
        </div>
      )}
    </div>
  );
}

export default Review;
