import React, { useEffect } from "react";
import SectionList from "../componets/forum/SectionList";
import { Left } from "../componets/styles/shared/Left";
import { Right } from "../componets/styles/shared/Right";
import { StyledPageTitle } from "../componets/styles/shared/PageTitle.styled";
import useApi from "../services/useApi";
import { getForumPageData, getSide } from "../services/forum";
import Spinner from "../componets/shared/Spinner";
import SideBlock from "../componets/forum/SideBlock";
import List from "../componets/forum/side/List";
import Post from "../componets/forum/side/Post";
import Theme from "../componets/forum/side/Theme";
import SeoFields from "../componets/shared/SeoFields";
import {
  StyledForum__StatsBlock,
  StyledForum__StatsBlockContainer,
  StyledForum__StatsBlockIcon,
  StyledForum__StatsBlockValue,
  StyledForum__StatsBlockValueText,
  StyledForum__StatsContainer,
} from "../componets/styles/pages/main/Block.styled";

function Forum() {
  const { data, loading, error, request } = useApi(getForumPageData);
  const {
    data: dataSide,
    loading: loadingSide,
    error: errorSide,
    request: requestSide,
  } = useApi(getSide);

  useEffect(() => {
    request("/forum/");
    requestSide("/forum/side/");
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <SeoFields
        title={"Forum"}
        description={"Forum"}
        keywords={null}
        robots="noindex, nofollow"
        canonical={null}
      />
      <Left>
        <StyledPageTitle style={{ marginBottom: "1rem" }}>
          Форум betraisers
        </StyledPageTitle>
        <StyledForum__StatsContainer>
          <StyledForum__StatsBlock>
            <StyledForum__StatsBlockContainer>
              <StyledForum__StatsBlockIcon>🤠</StyledForum__StatsBlockIcon>
              <div>
                <StyledForum__StatsBlockValue>
                  {dataSide?.forum?.forum_stats?.forum_users_count}
                </StyledForum__StatsBlockValue>
                <StyledForum__StatsBlockValueText>
                  форумчан
                </StyledForum__StatsBlockValueText>
              </div>
            </StyledForum__StatsBlockContainer>
          </StyledForum__StatsBlock>

          <StyledForum__StatsBlock>
            <StyledForum__StatsBlockContainer>
              <StyledForum__StatsBlockIcon>✍️</StyledForum__StatsBlockIcon>
              <div>
                <StyledForum__StatsBlockValue>
                  {dataSide?.forum?.forum_stats?.forum_themes_count}
                </StyledForum__StatsBlockValue>
                <StyledForum__StatsBlockValueText>
                  тем
                </StyledForum__StatsBlockValueText>
              </div>
            </StyledForum__StatsBlockContainer>
          </StyledForum__StatsBlock>

          <StyledForum__StatsBlock>
            <StyledForum__StatsBlockContainer>
              <StyledForum__StatsBlockIcon>✉️</StyledForum__StatsBlockIcon>
              <div>
                <StyledForum__StatsBlockValue>
                  {dataSide?.forum?.forum_stats?.forum_posts_count}
                </StyledForum__StatsBlockValue>
                <StyledForum__StatsBlockValueText>
                  постів
                </StyledForum__StatsBlockValueText>
              </div>
            </StyledForum__StatsBlockContainer>
          </StyledForum__StatsBlock>

          <StyledForum__StatsBlock>
            <StyledForum__StatsBlockContainer>
              <StyledForum__StatsBlockIcon>👁️</StyledForum__StatsBlockIcon>
              <div>
                <StyledForum__StatsBlockValue>
                  {dataSide?.forum?.forum_stats?.forum_views_count}
                </StyledForum__StatsBlockValue>
                <StyledForum__StatsBlockValueText>
                  переглядів
                </StyledForum__StatsBlockValueText>
              </div>
            </StyledForum__StatsBlockContainer>
          </StyledForum__StatsBlock>
        </StyledForum__StatsContainer>
        {data && <SectionList data={data?.results} />}
      </Left>
      <Right>
        {loadingSide && <Spinner />}
        {dataSide && (
          <>
            <SideBlock title="Останні пости">
              <List item={Post} data={dataSide?.last_posts} />
            </SideBlock>
            <SideBlock title="Нові теми">
              <List item={Theme} data={dataSide?.last_themes} />
            </SideBlock>
          </>
        )}
      </Right>
    </>
  );
}

export default Forum;
