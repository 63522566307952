import React from "react";
import Button from "../Button";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import { LoginBtn } from "../styles/LoginBtn.styled";

function ConfirmLogoutModal({yesClick, noClick}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>Ви точно хочете вийти?</h2>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        <Button
          style={{
            margin: "0",
            minWidth: "8rem",
          }}
          text="Так"
          styled={StyledRedBtn}
          onClick={yesClick}
        />
        <Button
          style={{
            borderRadius: "0.8rem",
            minWidth: "8rem",
          }}
          text="Ні"
          styled={LoginBtn}
          onClick={noClick}
        />
      </div>
    </div>
  );
}

export default ConfirmLogoutModal;
