import React, { createContext, useState } from "react";

const ForumContext = createContext();

export const ForumProvider = ({ children }) => {
  const [dataPost, setDataPost] = useState("");
  const [dataEditPost, setDataEditPost] = useState(null);
  const [editPost, setEditPost] = useState(() => dataEditPost?.text || null);
  const [isSaveEditPost, setIsSaveEditPost] = useState(false);

  return (
    <ForumContext.Provider
      value={{
        dataPost,
        setDataPost,
        dataEditPost,
        setDataEditPost,
        editPost,
        setEditPost,
        isSaveEditPost,
        setIsSaveEditPost,
      }}
    >
      {children}
    </ForumContext.Provider>
  );
};

export default ForumContext;
