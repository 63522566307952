import React, { useEffect } from "react";
import { OneBlockCenter } from "../componets/styles/shared/OneBlockCenter";
import { StyledPageTitle } from "../componets/styles/shared/PageTitle.styled";
import CasinoList from "../componets/casino/CasinoList";
import useApi from "../services/useApi";
import { getCasinos } from "../services/casinos";
import SeoFields from "../componets/shared/SeoFields";

function Casinos() {
  const { data, loading, error, request } = useApi(getCasinos);

  useEffect(() => {
    request("casinos/");
  }, []);

  return (
    <>
      <SeoFields
        title="Кращі ліцензійні онлайн казино в Україні на гроші"
        description="Топ ліцензійних онлайн казино України. Рейтинг легальних онлайн казино: відгуки, бонуси, акції"
        keywords={null}
        robots="index, follow"
        canonical="https://betraisers.com/casinos/"
      />
      <OneBlockCenter>
        <StyledPageTitle>Список кращих онлайн казино</StyledPageTitle>
        <CasinoList casinoList={data?.results} loading={loading} />
      </OneBlockCenter>
    </>
  );
}

export default Casinos;
