import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { postFreeroll } from "../../services/freerolls";
import useApi from "../../services/useApi";
import Button from "../Button";
import Input from "../form/Input";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import {
  StyledSelect,
  StyledContainer,
  StyledLabel,
} from "../styles/form/Form.styled";
import {
  StyledFieldsContainer,
  StyledFieldsInnerContainer,
} from "../styles/modals/CreateFreerollModal.styled";
import BackendErrors from "./BackendErrors";
import { BsCheck2Circle } from "react-icons/bs";
import FreerollList from "../freerolls/FreerollList";

function CreateFreerollModal({ rooms }) {
  const room = useRef();
  const name = useRef();
  const date = useRef();
  const time = useRef();
  const timezone = useRef();
  const prize = useRef();
  const buyin = useRef();
  const id_freeroll = useRef();
  const password_freeroll = useRef();
  const isUserAgree = useRef();

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isCreateFreeroll, setIsCreateFreeroll] = useState(false);
  const [freerollToCreate, setFreerollToCreate] = useState();

  const { data, loading, error, request } = useApi(postFreeroll);

  useEffect(() => {
    if (!isCreateFreeroll) {
      return;
    }
    request("freerolls/create/", freerollToCreate);
    setIsCreateFreeroll(false);
    //need to reload page to see added freeroll
  }, [isCreateFreeroll]);

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  const handlePostFreeroll = (e) => {
    e.preventDefault();
    if (!isUserAgree.current.checked) {
      return;
    }
    const data = {
      name: name.current.value,
      date_freeroll: date.current.value,
      time_freeroll: time.current.value,
      timezone: timezone.current.value,
      prizepool: prize.current.value,
      buyin: buyin.current.value,
      id_freeroll: id_freeroll.current.value,
      pass_freeroll: password_freeroll.current.value,
      room: room.current.value,
    };
    setFreerollToCreate(data);
    setIsCreateFreeroll(true);
  };

  if (data) {
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "10rem", color: "#02980e" }}>
          <BsCheck2Circle />
        </div>
        <p
          style={{ fontSize: "2rem", fontWeight: "500", marginBottom: "3rem" }}
        >
          Фрірол додано успішно!
        </p>
      </div>
    );
  }

  console.log(error);

  return (
    <div>
      <h1 style={{ marginBottom: "2rem" }}>Додавання фріролу</h1>
      <StyledFieldsContainer>
        <StyledFieldsInnerContainer>
          <StyledContainer>
            <StyledLabel>Покеррум</StyledLabel>
            <StyledSelect defaultValue={0} ref={room}>
              <option value="0" disabled>
                Оберіть зі списку
              </option>
              {rooms &&
                rooms.map((room, i) => {
                  return (
                    <option key={i} value={room?.id}>
                      {room?.name}
                    </option>
                  );
                })}
            </StyledSelect>
          </StyledContainer>
          <Input label="Назва" required refc={name} />
          <Input type="date" label="Дата фріролу" required refc={date} />
          <Input type="time" label="Час фріролу" required refc={time} />
          <Input label="Часовий пояс" required refc={timezone} />
        </StyledFieldsInnerContainer>
        <StyledFieldsInnerContainer
          style={{ marginLeft: isMobile ? "0" : "3rem" }}
        >
          <Input label="Призи" required refc={prize} />
          <Input label="Байін" refc={buyin} />
          <Input label="ID" refc={id_freeroll} />
          <Input label="Пароль" refc={password_freeroll} />
        </StyledFieldsInnerContainer>
      </StyledFieldsContainer>
      {error && <BackendErrors errors={error} />}
      {error && error?.response?.data?.queryset_freerolls && (
        <FreerollList
          freerollList={error?.response?.data?.queryset_freerolls}
        />
      )}
      <fieldset style={{ marginTop: "2rem", border: "0" }}>
        <label htmlFor="asd">
          <input
            id="asd"
            type="checkbox"
            style={{ margin: "1rem" }}
            ref={isUserAgree}
            required
          />
          Я ознайомлений(а) та погоджуюсь з правилами та умовами додавання
          фріролів
        </label>
      </fieldset>
      <Button
        styled={StyledRedBtn}
        text="Додати фрірол"
        style={{ marginTop: "3rem" }}
        onClick={handlePostFreeroll}
      />
    </div>
  );
}

export default CreateFreerollModal;
