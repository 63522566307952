import React, { useEffect, useRef, useState } from "react";
import ChangeInput from "../../componets/form/ChangeInput";
import { StyledChangeInputContainer } from "../../componets/styles/form/ChangeInput.styled";
import Button from "../../componets/Button";
import { useContext } from "react";
import PublicationContext from "../../componets/contexts/PublicationContext";
import { StyledRedBtn } from "../../componets/styles/buttons/RedBtn.styled";
import {
  StyledAmountsContainer,
  StyledAmounts,
  StyledAmountsTitle,
  StyledAmountsBlock,
  StyledAmountsValue,
} from "../../componets/styles/balance/Amounts.styled";
import Input from "../../componets/form/Input";
import {
  StyledStatistics,
  StyledStatisticsTitle,
  StyledStatisticsTable,
  StyledStatisticsTr,
  StyledStatisticsTd,
  StyledStatisticsTh,
} from "../../componets/styles/balance/Statistics.styled";
import useApi from "../../services/useApi";
import {
  getUserBalance,
  getUserOperationList,
  doChange,
  getCashoutMethods,
  doCashout,
} from "../../services/users";
import Spinner from "../../componets/shared/Spinner";
import Select from "../../componets/form/Select";
import { useMediaQuery } from "react-responsive";
import formateDate from "../../utils/DateFormater";
import { toast } from "react-toastify";
import Pagination from "../../componets/pagination/Pagination";
import setStatusColor from "../../utils/StatusColor";
import { Link } from "react-router-dom";

function BalanceTab() {
  const { pointValue, usdValue, setPointValue, setUsdValue } =
    useContext(PublicationContext);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isChange, setIsChange] = useState(false);
  const [isCashout, setIsCashout] = useState(false);
  const [walletData, setWalletData] = useState({
    walletType: null,
    wallet: null,
  });

  const walletRef = useRef();
  const walletTypeRef = useRef();

  const { data, loading, error, request } = useApi(getUserOperationList);
  const {
    data: dataBal,
    loading: loadingBal,
    error: errorBal,
    request: requestBal,
  } = useApi(getUserBalance);
  const {
    data: dataMethods,
    loading: loadingMethods,
    error: errorMethods,
    request: requestMethods,
  } = useApi(getCashoutMethods);

  const {
    data: dataChange,
    loading: loadingChange,
    error: errorChange,
    request: requestChange,
  } = useApi(doChange);

  const {
    data: dataCashout,
    loading: loadingCashout,
    error: errorCashout,
    request: requestCashout,
  } = useApi(doCashout);

  function maxValue(e, setValue) {
    let v = +e.target.value.replace(/\D/g, "");
    if (e.target.id === "points" && v <= dataBal?.points) {
      setValue(v);
    }
    if (e.target.id === "usd" && v <= dataBal?.usd) {
      setValue(v);
    }
  }

  const handleChange = (e) => {
    // e.preventDefault();
    if (pointValue < 100) {
      toast.warn(
        <div>
          Обмін від <b>100</b> поінтів
        </div>
      );
      return;
    }
    setIsChange(true);
  };

  const handleCashout = (e) => {
    e.preventDefault();
    if (usdValue < 20) {
      toast.warn(
        <div>
          Зняття від <b>20</b> долларів
        </div>
      );
      return;
    }
    if (walletRef.current.value === null || walletRef.current.value === "") {
      toast.warn(<div>Введіть номер гаманця або інші реквезити</div>);
      return;
    }
    setWalletData({ ...walletData, wallet: walletRef.current.value });
    setIsCashout(true);
  };

  useEffect(() => {
    request("pa/operations/");
    requestBal("pa/balance/");
    // requestMethods("pa/cashoutmethods/");
  }, []);

  useEffect(() => {
    if (!isChange) {
      return;
    }
    requestChange("pa/operations/change/", { points: pointValue });
    setIsChange(false);
    setPointValue(0);
    setUsdValue(0);
  }, [isChange]);

  useEffect(() => {
    if (!dataChange) {
      return;
    }
    toast.success(<div>Обмін оформлено</div>);
    request("pa/operations/");
    requestBal("pa/balance/");
  }, [dataChange]);

  useEffect(() => {
    if (!dataCashout) {
      return;
    }
    toast.success(<div>Зняття оформлено</div>);
    request("pa/operations/");
    requestBal("pa/balance/");
  }, [dataCashout]);

  useEffect(() => {
    if (!isCashout) {
      return;
    }
    requestCashout("pa/operations/cashout/", { usd: usdValue, walletData });
    setIsCashout(false);
    setPointValue(0);
    setUsdValue(0);
  }, [isCashout]);

  return (
    <div style={{ width: "100%" }}>
      <StyledAmountsTitle>Винагороди за активність на сайті</StyledAmountsTitle>
      <div
        style={{
          backgroundColor: "rgb(242, 245, 247)",
          borderRadius: "1rem",
          padding: "2rem 1.5rem",
          marginBottom: "2rem",
          fontSize: "1.5rem",
          fontWeight: "500",
        }}
      >
        <p>📝 Реєстрація - 200 br поінтів</p>
        <p>📰 Додавання публікації - до 200 br поінтів (UPD 25.01.24 <Link to="/publications/articles/pryzupynka-dodavannia-publikatsii-ta-narakhuvannia-pointiv-za-nykh-g60zndva/">Призупинення нарахування поінтів</Link>)</p>
        <p>🍱 Додавання пароля на фрірол - 10 br поінтів</p>
        <p>🤝 Прив'язка покер руму чи казино - від 500 br поінтів</p>
        <p>👫 Реферальна система - від 200 br поінтів</p>
        <p>🗣️ Додавання відгуку про покер рум чи казино - до 100 поінтів</p>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "3rem" }}>
        <StyledAmountsContainer>
          <StyledAmounts>
            <StyledAmountsTitle>betraisers points</StyledAmountsTitle>

            <StyledAmountsBlock>
              <StyledAmountsValue>{dataBal?.points || 0}</StyledAmountsValue>
            </StyledAmountsBlock>
          </StyledAmounts>
          <StyledAmountsTitle>Обмін betraisers points</StyledAmountsTitle>

          <StyledChangeInputContainer>
            <ChangeInput
              label="Поінти"
              id="points"
              name="points"
              value={pointValue}
              onChange={(e) => maxValue(e, setPointValue)}
            />
            <Button
              text="Обміняти на"
              styled={StyledRedBtn}
              style={{
                display: "inline-flex",
                flexDirection: "column",
                padding: "1rem 3rem",
                margin: "0",
                whiteSpace: "nowrap",
                maxWidth: isMobile && "none",
                width: isMobile && "100%",
              }}
              onClick={(e) => handleChange(e)}
            >
              <p>${(pointValue || 0) * 0.01}</p>
            </Button>
          </StyledChangeInputContainer>
        </StyledAmountsContainer>
        <StyledAmountsContainer>
          <StyledAmounts>
            <StyledAmountsTitle>USD</StyledAmountsTitle>

            <StyledAmountsBlock>
              <StyledAmountsValue>{dataBal?.usd || 0}</StyledAmountsValue>
            </StyledAmountsBlock>
          </StyledAmounts>
          <StyledAmountsTitle>Вивід USD</StyledAmountsTitle>

          <StyledChangeInputContainer>
            <ChangeInput
              label="$"
              id="usd"
              name="usd"
              value={usdValue}
              onChange={(e) => maxValue(e, setUsdValue)}
            />
            <Button
              text="Вывести"
              styled={StyledRedBtn}
              style={{
                display: "inline-flex",
                flexDirection: "column",
                padding: "1rem 3rem",
                margin: "0",
                whiteSpace: "nowrap",
                maxWidth: isMobile ? "none" : "21rem",
                width: isMobile && "100%",
              }}
              onClick={(e) => handleCashout(e)}
            >
              <p>${usdValue || 0}</p>
            </Button>
          </StyledChangeInputContainer>
          <div style={{ display: "flex", width: "100%" }}>
            <Select
              placeholder="Cashout method"
              options={[
                { slug: "advcash", name: "AdvCash USD" },
                { slug: "usdt", name: "Tether (TRC20)" },
                { slug: "skrill", name: "Skrill USD" },
                { slug: "mono", name: "monobank UAH" },
              ]}
              setSelectValue={(value) =>
                setWalletData({
                  ...walletData,
                  walletType: value.toUpperCase(),
                })
              }
              width="50%"
              style={{ borderRadius: "0", color: "#999" }}
              ref={walletTypeRef}
            />

            <Input
              type="text"
              id="wallet"
              name="wallet"
              // label="Кошелек AdvCash"
              styleForFieldset={{ borderRadius: "0rem 1rem 1rem 0rem" }}
              refc={walletRef}
            />
          </div>
        </StyledAmountsContainer>
      </div>
      <StyledStatistics>
        <StyledStatisticsTitle>Історія операцій</StyledStatisticsTitle>
        {loading && <Spinner />}
        {data && !loading && (
          <div style={{ overflow: "scroll" }}>
            <StyledStatisticsTable cellspacing="0" cellpadding="0">
              <StyledStatisticsTr>
                <StyledStatisticsTh>Дата</StyledStatisticsTh>
                <StyledStatisticsTh>Опис операції</StyledStatisticsTh>
                <StyledStatisticsTh>Коментар</StyledStatisticsTh>
                <StyledStatisticsTh>Points</StyledStatisticsTh>
                <StyledStatisticsTh>USD</StyledStatisticsTh>
                <StyledStatisticsTh>Статус</StyledStatisticsTh>
              </StyledStatisticsTr>
              {data?.results?.map((o) => {
                return (
                  <StyledStatisticsTr operationType="income">
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {formateDate(o.date, true)}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          width: "fit-content",
                          margin: "0 auto",
                          padding: "0.5rem 1rem",
                          border: `1px solid ${o?.type?.color}`,
                          // border: `1px solid ${setTypeColor(o.type.name)}`,
                          borderRadius: "1rem",
                          color: o?.type?.color,
                          // color: setTypeColor(o.type.name),
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          // textTransform: "uppercase"
                        }}
                      >
                        {o.type.description}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        {o.comment_for_user}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd
                      operationType={
                        o.status === "Успішно" &&
                        (+o.points >= 0 ? "income" : "cashout")
                      }
                    >
                      <div style={{ fontWeight: "500" }}>
                        {+o.points >= 0 ? `+${o.points}` : `${o.points}`}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd
                      operationType={
                        o.status === "Успішно" &&
                        (+o.usd >= 0 ? "income" : "cashout")
                      }
                    >
                      <div style={{ fontWeight: "500" }}>
                        {+o.usd >= 0 ? `+${o.usd}` : `${o.usd}`}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          padding: "0.5rem",
                          borderRadius: "0.8rem",
                          color: "white",
                          backgroundColor: setStatusColor(o?.status),
                          fontWeight: "500",
                          fontSize: "1.4rem",
                          textAlign: "center",
                        }}
                      >
                        {o.status}
                      </div>
                    </StyledStatisticsTd>
                  </StyledStatisticsTr>
                );
              })}
            </StyledStatisticsTable>
          </div>
        )}
        {data && <Pagination data={data} request={request} />}
      </StyledStatistics>
    </div>
  );
}

export default BalanceTab;
