import React from "react";
import {
  StyledFreerollBlock,
  StyledFreerollBlockDate,
  StyledFreerollBlockImg,
  StyledFreerollBlockItem,
  StyledFreerollBlockList,
  StyledFreerollBlockName,
  StyledFreerollBlockPassword,
  StyledFreerollBlockPrize,
  StyledFreerollBlockRoom,
  StyledFreerollBlockTitle,
} from "../styles/sideblocks/FreerollsBlock.styled";
import { BiTime } from "react-icons/bi";

function FreerollsBlock({ data }) {

  return (
    <StyledFreerollBlock>
      <StyledFreerollBlockTitle>🏆 Найближчі фріроли</StyledFreerollBlockTitle>
      <StyledFreerollBlockList>
        {data?.results.map((freeroll) => {
          return (
            <StyledFreerollBlockItem>
              <div style={{ display: "flex" }}>
                <StyledFreerollBlockImg
                  src={freeroll?.room?.logo_small}
                  alt={freeroll?.room?.name}
                />
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledFreerollBlockRoom>
                      {freeroll?.room?.name}
                    </StyledFreerollBlockRoom>
                    <StyledFreerollBlockPrize>
                      {/* <BsCashCoin /> */}
                      {freeroll?.prizepool}
                    </StyledFreerollBlockPrize>
                  </div>
                  <StyledFreerollBlockDate>
                    <BiTime />
                    {freeroll?.date_freeroll} {freeroll?.time_freeroll}{" "}
                    {freeroll?.timezone}
                  </StyledFreerollBlockDate>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <StyledFreerollBlockName>
                  {freeroll?.name}
                </StyledFreerollBlockName>
              </div>
              {freeroll?.pass_freeroll && (
                <StyledFreerollBlockPassword>
                  <div
                    style={{ color: "rgba(0,0,0,0.5)", marginRight: "1rem" }}
                  >
                    Пароль:
                  </div>
                  <div style={{ color: "rgb(51, 179, 39)", fontWeight: "500" }}>
                    {freeroll?.pass_freeroll}
                  </div>
                </StyledFreerollBlockPassword>
              )}
            </StyledFreerollBlockItem>
          );
        })}
      </StyledFreerollBlockList>
    </StyledFreerollBlock>
  );
}

export default FreerollsBlock;
