import React from "react";
import styled from "styled-components";

export const RoomDetailsMenu = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    cursor: pointer;
  }

  a:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  a.active {
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
  }

  @media (max-width: 767px) {
    /* flex-wrap: wrap; */
    flex-direction: column;
    margin-top: 2rem;

    a {
      padding: 1rem;
    }

    a:hover {
      border-bottom: none;
      border-left: 2px solid rgba(0, 0, 0, 0.1);
    }

    a.active {
      border-bottom: none;
      border-left: 2px solid rgba(0, 0, 0, 1);
      color: rgba(0, 0, 0, 1);
    }
  }
`;

export const RoomDetailsContent = styled.div`
  padding: 2rem;
  font-size: 1.5rem;
  column-count: 3;
  border-radius: 1rem;
  background-color: #f2f5f7;

  a {
    display: block;
    text-decoration: none;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 500;
  }

  a::before {
    content: "▪️";
    display: inline-block;
    margin-right: 0.5rem;
  }

  a:hover {
    color: rgb(49, 135, 238);
  }

  @media (max-width: 767px) {
    column-count: inherit;
  }
`;
