import React, { useEffect, useState } from "react";
import { StyledRoomInstruction } from "../styles/roomDetails/RoomInstruction.styled";
import { useLocation } from "react-router-dom";
import SeoFields from "../shared/SeoFields";
import * as DOMPurify from "dompurify";



function RoomInstruction({ roomInstruction, roomData }) {
  const { state } = useLocation();
  const { targetId } = state || {};
  const [t, setT] = useState(targetId);

  useEffect(() => {
    if (!t) {
      return;
    }
    const el = document.getElementById(t);
    if (el) {
      el.scrollIntoView();
    }
    setT(null);
  }, [t]);

  return (
    <>
      <SeoFields
        title={roomData?.seo_title_instruction}
        description={roomData?.seo_description_instruction}
        keywords={roomData?.seo_keywords_instruction}
        robots={roomData?.seo_robots_instruction}
        canonical={roomData?.seo_canonical_instruction}
      />
      <StyledRoomInstruction
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(roomInstruction),
        }}
      ></StyledRoomInstruction>
    </>
  );
}

export default RoomInstruction;
