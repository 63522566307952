import React from "react";
import Button from "../Button";
import { StyledSuggestion } from "../styles/suggestions/Suggestion.styled";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import { StyledRestorePasswordBtn } from "../styles/Button.styled";
import { useNavigate } from "react-router-dom";

function Suggestion({ title, text, buttons }) {
  let navigate = useNavigate();
  return (
    <StyledSuggestion>
      <h3>{title || "100 BR Points за публікацію"}</h3>
      <div style={{ marginBottom: "1rem" }}>
        {text ||
          "Додай новину, акцію чи напиши статтю на гемблінг тематику та отримай до 200 BR Points ($2 USD)"}
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {buttons || (
          <>
            <Button
              style={{ margin: "1rem 2rem 1rem 0" }}
              styled={StyledRestorePasswordBtn}
              text="Детальніше"
            />
            <Button
              style={{ margin: "1rem 0" }}
              styled={StyledRedBtn}
              text="Додати"
              onClick={(e) => {
                e.preventDefault();
                navigate("/publications/create/");
              }}
            />
          </>
        )}
      </div>
    </StyledSuggestion>
  );
}

export default Suggestion;
