import React, { useState, useEffect } from "react";
import { OneBlockCenter } from "../componets/styles/shared/OneBlockCenter";
import CasinoHeader from "../componets/casino/CasinoHeader";
import CasinoInformation from "../componets/casino/CasinoInformation";
import { RoomDetailsMenu } from "../componets/styles/roomDetails/RoomDetails.styled";
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useParams,
} from "react-router-dom";
import RoomInstruction from "../componets/roomDetails/RoomInstruction";
import RoomReviews from "../componets/roomDetails/RoomReviews";
import RoomReview from "../componets/roomDetails/RoomReview";
import RoomBinding from "../componets/roomDetails/RoomBinding";
import useApi from "../services/useApi";
import { getCasino } from "../services/casinos";
import Spinner from "../componets/shared/Spinner";
import Error404 from "../componets/shared/Error404";
import SeoFields from "../componets/shared/SeoFields";

function CasinoDetails() {
  const [scrollY, setScrollY] = useState(0);
  const location = useLocation();
  const params = useParams();

  const { data, loading, error, request } = useApi(getCasino);

  useEffect(() => {
    request(location.pathname);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error404 />;
  }

  const googleMicroData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        name: data?.name,
        url: data?.logo,
        applicationCategory: "GameApplication",
        // datePublished:
        //   "Укажите допустимую дату и время по стандарту ISO 8601. Например: 2015-07-27 или 2015-07-27T15:30",
        downloadUrl: `https://betraisers.com/casinos/${data?.slug}/aff/`,
        operatingSystem: data?.os_txt,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: data?.rating,
          ratingCount: data?.feedback_count,
        },
        offers: {
          "@type": "Offer",
          price: "0.00",
          priceCurrency: "USD",
        },
      })}
    </script>
  );

  return (
    <>
      <SeoFields
        title={data?.seo_title}
        description={data?.seo_description}
        keywords={data?.seo_keywords}
        robots={data?.seo_robots}
        canonical={data?.seo_canonical}
        googleMicroData={googleMicroData}
      />
      <OneBlockCenter>
        <CasinoHeader data={data} />
        {params["*"] === "" && <CasinoInformation data={data} />}
        <RoomDetailsMenu>
          <NavLink to="" end state={{ scrollto: scrollY }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "3rem", marginRight: "1rem" }}>📊</div>
              <div>Огляд {data?.name}</div>
            </div>
          </NavLink>
          <NavLink to="reviews/" state={{ scrollto: scrollY }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "3rem", marginRight: "1rem" }}>😍</div>
              <div>Відгуки про {data?.name}</div>
            </div>
          </NavLink>

          <NavLink
            id={"instruction"}
            to="how-start-to-play/"
            state={{ scrollto: scrollY }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "3rem", marginRight: "1rem" }}>💸</div>
              <div>Як почати грати на {data?.name}</div>
            </div>
          </NavLink>

          <NavLink to="binding/" state={{ scrollto: scrollY }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "3rem", marginRight: "1rem" }}>🪢</div>
              <div>Привязка {data?.name}</div>
            </div>
          </NavLink>
        </RoomDetailsMenu>

        <Routes>
          <Route
            path="/"
            element={
              <RoomReview
                roomContentList={data?.content_casino}
                roomReview={data?.review_casino}
                seoH1={data?.seo_h1}
              />
            }
          />
          <Route
            path="reviews/"
            element={<RoomReviews rating={data?.rating} roomData={data} />}
          />
          <Route
            path="how-start-to-play/"
            element={
              <RoomInstruction
                roomInstruction={data?.reg_instruction}
                roomData={data}
              />
            }
          />
          <Route
            path="binding/"
            element={<RoomBinding binding={data?.binding} />}
          />
        </Routes>
      </OneBlockCenter>
    </>
  );
}

export default CasinoDetails;
