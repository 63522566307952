import styled from "styled-components";

export const StyledArticlesBlock = styled.div`
  background-color: #f2f5f7;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;

  li {
    margin-bottom: 1.5rem;
  }

  li > a {
    text-decoration: none;
    color: inherit;
    font-size: 1.5rem;
    font-weight: 500;
  }

  li > a:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  li > a:active {
    color: rgba(0, 0, 0, 0.8);
  }
`;
