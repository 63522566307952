import React from "react";
import PokerRoomsBlock from "./main/PokerRoomsBlock";
import PublicationsBlock from "./main/PublicationsBlock";
import FreerollsBlock from "./main/FreerollsBlock";
import OnlineCasinosBlock from "./main/OnlineCasinosBlock";
import useApi from "../services/useApi";
import { getMainPageData } from "../services/pages";
import { useEffect } from "react";
import Spinner from "../componets/shared/Spinner";
import SeoFields from "../componets/shared/SeoFields";
import WebsiteProposition from "../componets/suggestions/WebsiteProposition";
import { StyledWebsitePropositionContainer } from "../componets/styles/suggestions/WebsiteProposition.styled";
import Publications from "./main/Publications";
import Rooms from "./main/Rooms";
import Feedbacks from "./main/Feedbacks";
import Forum from "./main/Forum";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";

function Main() {
  const { data, loading, error, request } = useApi(getMainPageData);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (data) {
      return;
    }
    request("/main/");
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  return (
    data && (
      <div style={{ width: "100%" }}>
        <SeoFields
          title="Покер онлайн, казино онлайн: відгуки, новини, акції, паролі на фріроли"
          description="betraisers - український інформаційний гемблінг портал. Свіжі новини, актуальні акції, цікаві статті українською, відгуки онлайн казино і покер онлайн, форум гравців."
          keywords={null}
          robots="index, follow"
          canonical="https://betraisers.com"
        />

        {isMobile ? (
          <div style={{margin: "0 -0.5rem 1rem"}}>
            <Slider {...settings}>
              <WebsiteProposition
                to="/publications/articles/vinagorodi-za-aktivnist-vid-betraiserscom/"
                icon="💸"
                title="+200 br points за реєстрацію"
                desc={
                  !isMobile &&
                  "Отримай 200 br points (2.00 USD) за легку реєстрацію на сайті!"
                }
              />
              <WebsiteProposition
                to="/publications/articles/vinagorodi-za-aktivnist-vid-betraiserscom/"
                icon="🤑"
                title="Від 200 br points за друга!"
                desc={
                  !isMobile &&
                  "Приводь друга через нашу реферальну систему і отримуй від 200 br points за кожну його прив'язку!"
                }
                style={{ backgroundColor: "rgb(51, 51, 51)" }}
              />
              <WebsiteProposition
                to="/publications/articles/vinagorodi-za-aktivnist-vid-betraiserscom/"
                icon="👍"
                title="До 100 br points за відгук!"
                desc={
                  !isMobile &&
                  "Залишай відгуки про покер-руми та онлайн казино та отримуй від 25 до 100 brp!"
                }
                style={{ backgroundColor: "rgb(51, 51, 51)" }}
              />
            </Slider>
          </div>
        ) : (
          <StyledWebsitePropositionContainer>
            <WebsiteProposition
              to="/publications/articles/vinagorodi-za-aktivnist-vid-betraiserscom/"
              icon="💸"
              title="+200 br points за реєстрацію"
              desc={
                !isMobile &&
                "Отримай 200 br points (2.00 USD) за легку реєстрацію на сайті!"
              }
            />
            <WebsiteProposition
              to="/publications/articles/vinagorodi-za-aktivnist-vid-betraiserscom/"
              icon="🤑"
              title="Від 200 br points за друга!"
              desc={
                !isMobile &&
                "Приводь друга через нашу реферальну систему і отримуй від 200 br points за кожну його прив'язку!"
              }
              style={{ backgroundColor: "rgb(51, 51, 51)" }}
            />
            <WebsiteProposition
              to="/publications/articles/vinagorodi-za-aktivnist-vid-betraiserscom/"
              icon="👍"
              title="До 100 br points за відгук!"
              desc={
                !isMobile &&
                "Залишай відгуки про покер-руми та онлайн казино та отримуй від 25 до 100 brp!"
              }
              style={{ backgroundColor: "rgb(51, 51, 51)" }}
            />
          </StyledWebsitePropositionContainer>
        )}

        <Publications publications={data?.publications} />
        <FreerollsBlock freerollList={data?.freerolls} />
        <Rooms rooms={data?.rooms} casinos={data?.online_casinos} />
        <Feedbacks feedbacks={data?.feedbacks} />
        <Forum forum={data?.forum} />
      </div>
    )
  );
}

export default Main;
