import React from 'react'

function Error404() {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
    <div style={{ width: "100%", textAlign: "center", fontSize: "10rem" }}>
      🧭
    </div>
    <div style={{ width: "100%", textAlign: "center", fontSize: "7rem" }}>
      404
    </div>
    <div style={{ width: "100%", textAlign: "center", fontSize: "4rem" }}>
      Сторінка не знайдена
    </div>
  </div>
  )
}

export default Error404