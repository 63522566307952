import React from "react";
import styled from "styled-components";

export const StyledEditArea = styled.div`
  font-size: 1.5rem;

  h2 {
    margin: 4rem 0 2rem;
    padding: 0;
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: 700;
    scroll-margin-top: 7rem;
  }

  h3 {
    margin: 2rem 0 1rem;
    padding: 0;
    font-size: 2.2rem;
    line-height: 1.27;
    font-weight: 700;
  }

  h4 {
    margin: 2rem 0 1rem;
    padding: 0;
    font-size: 1.8rem;
    line-height: 1.27rem;
    font-weight: 700;
  }

  h5 {
    margin: 2rem 0 1rem;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.27rem;
    font-weight: 700;
  }

  h6 {
    margin: 2rem 0 1rem;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1.27rem;
    font-weight: 700;
  }

  & p {
    margin-top: 2.5rem;
    white-space: pre-line;
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: normal;
    overflow-wrap: break-word;
  }

  div {
    scroll-margin-top: 7rem;
  }

  span {
    display: block;
    margin-top: -2rem;
    margin-bottom: 2rem;
    white-space: pre-line;
    font-size: 1.4rem;
    line-height: 1.42;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.5);
    overflow-wrap: break-word;
  }

  img {
    max-width: 100%;
    /* max-height: 50vh; */
  }

  ul {
    margin: 1.5rem 0px 1.5rem 2rem;
    padding: 0px;
    list-style-type: none;
  }

  ul li {
    font-size: inherit;
    white-space: pre-line;
    line-height: 1.41;
    letter-spacing: normal;
    overflow-wrap: break-word;
    display: flex;
    margin-top: 0.5rem;
  }

  ul li::before {
    display: inline-block;
    flex-shrink: 0;
    width: 1.8rem;
    margin-right: 0.5rem;
    content: "—";
  }

  ol {
    margin: 1.5rem 0px 1.5rem 2rem;
    padding: 0px;
    list-style-type: none;
    counter-reset: numberedCounter 0;
  }

  ol li {
    white-space: pre-line;
    font-size: inherit;
    line-height: 1.41;
    letter-spacing: normal;
    overflow-wrap: break-word;
    display: flex;
    margin-top: 0.5rem;
  }

  ol li::before {
    display: inline-block;
    flex-shrink: 0;
    width: 1.8rem;
    margin-right: 0.5rem;
    counter-increment: numberedCounter 1;
    content: counter(numberedCounter) ". ";
    white-space: nowrap;
  }

  table {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1.5rem;
    line-height: 1.3;
    text-align: center;

    & caption {
      color: rgba(0, 0, 0, 0.4);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 1.5rem;
    }
  }

  th,
  td {
    border: 1px solid rgba(196, 205, 212, 0.5);
    /* background-color: rgba(0, 0, 0, 0.06); */
    font-size: 1.5rem;
    line-height: 1.3;
    padding: 1rem;
  }

  th {
    background: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }

  tr {
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.025);
      /* background-color: #f6f6f6; */
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      /* background-color: #ddd; */
    }
  }

  a {
    color: #3187ee;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #52a0ff;
    }
  }

  // TODO div, list, links, table and eth
`;
