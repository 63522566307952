import React from "react";

function EstablishmentNoResults() {
  return (
    <div style={{ fontSize: "2rem", textAlign: "center", marginTop: "5rem" }}>
      🤷‍♀️ Не знайдено закладів
    </div>
  );
}

export default EstablishmentNoResults;
