import React from "react";
import styled from "styled-components";

export const StyledRoomCard = styled.div`
  position: relative;
  padding: 4rem 3rem;
  overflow: hidden;
  background-color: #f2f5f7;
  border-radius: 1rem;
  border: 1px solid rgba(196, 205, 212, 0.5);
  text-align: center;

  h1 {
    margin-top: 1.2rem;
    font-size: 4rem;
    line-height: 6rem;
    text-align: center;
    text-transform: capitalize;
  }

  p p {
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgb(121, 122, 123);
    margin-top: 1.5rem;
  }

  .room-card-info__first {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .key {
    display: flex;
    margin-right: 0.5rem;
    align-items: center;
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgb(121, 122, 123);
  }

  .key::before {
    content: "";
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    background-color: #0073ff;
    margin: 0 1.2rem;
    border-radius: 50%;
  }

  .key:first-of-type::before {
    display: none;
  }

  @media (max-width: 767px) {
    padding: 4rem 1rem;

    h1 {
      font-size: 3.2rem;
      line-height: 4rem;
      text-align: center;
      font-weight: 700;
      color: #000;
      margin: 0 0 0.8rem;
      word-break: break-word;
    }

    .room-card-info__first {
      display: block;
    }

    .key {
      justify-content: space-between;
    }

    .key::before {
      display: none;
    }
  }
`;

export const StyledRoomCardName = styled.div`
  margin-top: 1.2rem;
  font-size: 4rem;
  font-weight: 700;
  line-height: 6rem;
  text-align: center;
  text-transform: capitalize;
`;
