import React from "react";
import Comment from "./Comment";
import { StyledCommentList } from "../styles/comments/Comments.styled";

function CommentsList({ comments, page, refToScrollTo }) {
  const sortedComments = (comments, reverse = false) => {
    if (reverse) {
      return comments?.sort((a, b) => {
        return new Date(b.date_published) - new Date(a.date_published);
      });
    }
    return comments?.sort((a, b) => {
      return new Date(a.date_published) - new Date(b.date_published);
    });
  };

  const filteredComments = sortedComments(
    comments?.filter((c) => c.parent === null),
    true
  );

  const filteredAndMapedCommentAnswers = (comment) => {
    return sortedComments(comments?.filter((c) => c.parent === comment.id)).map(
      (c) => (
        <Comment
          key={c.id}
          data={c}
          page={page}
          refToScrollTo={refToScrollTo}
        />
      )
    );
  };

  return (
    <>
      <StyledCommentList>
        {filteredComments?.map((comment) => {
          return (
            <div key={comment.id}>
              <Comment
                key={comment.id}
                data={comment}
                page={page}
                refToScrollTo={refToScrollTo}
              />
              {filteredAndMapedCommentAnswers(comment)}
            </div>
          );
        })}
      </StyledCommentList>
    </>
  );
}

export default CommentsList;
