import styled from "styled-components";

export const StyledForumBreadcrumbs = styled.div`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  color: #909090;

  a {
    color: #909090;
  }

  ul {
    display: flex;
    align-items: center;

    svg {
      &:last-child {
        display: none;
      }
    }
  }

  li {
    overflow: hidden;
  }

  @media (max-width: 767px) {
    white-space: nowrap;

    ul {
      flex-wrap: wrap;
    }
  }
`;
