import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import Input from "../form/Input";
import { StyledBinding } from "../styles/binding/Binding.styled";
import {
  StyledBlueBtn,
  StyledSuccessBtn,
  StyledCheckingBtn,
  StyledErrorBtn,
  StyledUnknownBtn,
} from "../styles/Button.styled";
import { MdDone } from "react-icons/md";
import useApi from "../../services/useApi";
import { checkBinding } from "../../services/rooms";
import { useMediaQuery } from "react-responsive";

function Binding({ type, status, data }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isCheckRequest, setIsCheckRequest] = useState(false);
  const [bindingStatus, setBindingStatus] = useState({
    status: status,
    email: null,
    nickname: null,
  });
  const emailRef = useRef();
  const nicknameRef = useRef();
  const {
    data: dataBinding,
    loading: loadingBinding,
    error: errorBinding,
    request: requestBinding,
  } = useApi(checkBinding);

  const handleBind = (e) => {
    e.preventDefault();
    setIsCheckRequest(true);
  };

  useEffect(() => {
    if (!isCheckRequest) {
      return;
    }
    requestBinding(`${type}/${data?.slug}/binding/check/`, {
      email: emailRef.current.value,
      nickname: nicknameRef.current.value,
    });
    setIsCheckRequest(false);
  }, [isCheckRequest]);

  useEffect(() => {
    if (!dataBinding) {
      return;
    }
    setBindingStatus((prevState) => ({
      ...prevState,
      status: dataBinding?.status,
      email: dataBinding?.email,
      nickname: dataBinding?.nickname,
    }));
  }, [dataBinding]);

  return (
    <StyledBinding>
      <img
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.5rem",
          backgroundColor: "#000",
          borderRadius: "1rem",
          objectFit: "contain"
        }}
        src={data?.logo_small}
        alt={data?.name}
      />
      <div style={{ width: "100%" }}>
        <div>{data?.name}</div>
        <div
          style={{ display: "flex", flexWrap: isMobile ? "wrap" : "nowrap" }}
        >
          {bindingStatus?.status === "success" && (
            <>
              <div>
                <p>Email: {data?.binding[0]?.email}</p>
                <p>Nickname: {data?.binding[0]?.nickname}</p>
              </div>

              <Button
                text="Привязано!"
                icon={<MdDone />}
                styled={StyledSuccessBtn}
                style={{ marginLeft: isMobile ? "0" : "auto" }}
              />
            </>
          )}
          {bindingStatus?.status === undefined && (
            <>
              <div>
                <Input
                  refc={emailRef}
                  type="email"
                  name="email"
                  id="email"
                  label="Email"
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>
              <div>
                <Input
                  refc={nicknameRef}
                  type="text"
                  name="nickname"
                  id="nickname"
                  label="Nickname"
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>
              <Button
                onClick={handleBind}
                text="Прив'язати"
                styled={StyledBlueBtn}
                style={{ marginLeft: isMobile ? "0" : "auto" }}
              />
            </>
          )}
          {bindingStatus?.status === "checking" && (
            <>
              <div>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  label="Email"
                  value={data?.binding[0]?.email || dataBinding?.email}
                  readOnly={true}
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="nickname"
                  id="nickname"
                  label="Nickname"
                  value={data?.binding[0]?.nickname || dataBinding?.nickname}
                  readOnly={true}
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>

              <Button
                text="Перевіряється"
                styled={StyledCheckingBtn}
                style={{ marginLeft: isMobile ? "0" : "auto" }}
              />
            </>
          )}
          {bindingStatus?.status === "error" && (
            <>
              <div>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  label="Email"
                  value={data?.binding[0]?.email || dataBinding?.email}
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="nickname"
                  id="nickname"
                  label="Nickname"
                  value={data?.binding[0]?.nickname || dataBinding?.nickname}
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>

              <Button
                text="Помилка!"
                styled={StyledErrorBtn}
                style={{ marginLeft: isMobile ? "0" : "auto" }}
              />
            </>
          )}
          {bindingStatus?.status === "unknown" && (
            <>
              <div>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  label="Email"
                  value={data?.binding[0]?.email || dataBinding?.email}
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="nickname"
                  id="nickname"
                  label="Nickname"
                  value={data?.binding[0]?.nickname || dataBinding?.nickname}
                  styleForFieldset={{ height: "4rem" }}
                />
              </div>

              <Button
                text="Статус невідомий"
                styled={StyledErrorBtn}
                style={{ marginLeft: isMobile ? "0" : "auto" }}
              />
            </>
          )}
        </div>
      </div>
    </StyledBinding>
  );
}

export default Binding;
