import styled from "styled-components";

export const StyledSocialConnections = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const StyledSocialConnectionList = styled.div`
  margin-top: 3rem;
`;
export const StyledSocialConnection = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid lightgrey;
  background-color: rgb(242, 245, 247);
  font-size: 3rem;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;
export const StyledSocialConnectionInnerContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 2rem;
  }

  div {
    min-width: 20rem;
    margin-right: 2rem;
  }

  @media (max-width: 767px) {
    svg {
      margin-right: 1rem;
    }
    div {
      min-width: 0;
      flex-grow: 1;
    }
  }
`;
