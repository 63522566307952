import styled from "styled-components";

export const StyledPublicationDetailImg = styled.img`
  width: 100%;
  border-radius: 1rem;
  max-height: 40rem;
  object-fit: cover;
`;

export const StyledPublicationDetailTitle = styled.h1`
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 2.5rem;
  }
`;

export const StyledPublicationDetailStat = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 2rem;
  }
`;

export const StyledPublicationDetailInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;

  & svg {
    margin-right: 0.5rem;
  }
`;

export const StyledPublicationDetailBody = styled.div`
  margin-top: 2.5rem;

  h2 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2.8rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h5 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 1.25;
    font-weight: 700;
  }

  p {
    margin-bottom: 2.5rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  img {
    max-width: 70%;
    border-radius: 1rem;
    margin-bottom: 1rem;
    object-fit: cover;
  }

  img.card-icon {
    width: auto;
    border-radius: 0;
    margin-bottom: 0;
  }

  blockquote {
    margin: 3rem;
  }

  blockquote p {
    position: relative;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    font-weight: bold;
    letter-spacing: normal;
    overflow-wrap: break-word;
    margin-top: 0px;
    margin-bottom: 1rem;
    padding-left: 1.3rem;
  }

  blockquote p::before {
    content: "";
    position: absolute;
    background-color: var(--color-ut-alert, #ed4040);
    width: 0.3rem;
    height: 100%;
    left: 0px;
    top: 0.3rem;
    bottom: 0.3rem;
  }

  table {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1.6rem;
    line-height: 1.3;
    text-align: center;

    & caption {
      color: rgba(0, 0, 0, 0.4);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 1.5rem;
    }
  }

  th,
  td {
    border: 1px solid rgba(196, 205, 212, 0.5);
    /* background-color: rgba(0, 0, 0, 0.06); */
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 1rem;
  }

  th {
    background: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }

  tr {
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.025);
      /* background-color: #f6f6f6; */
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      /* background-color: #ddd; */
    }
  }

  ul {
    padding-left: 4rem;
    list-style: disc;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  ol {
    padding-left: 4rem;
    list-style: auto;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  a {
    color: #3187ee;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #52a0ff;
    }
  }
`;

export const StyledPublicationLikeShareContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 4rem;
  }
`;

export const StyledPublicationLike = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-right: 2rem;

  @media (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const StyledPublicationLikeBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem 1.8rem;
  font-size: 2rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #000;
  letter-spacing: 0.1rem;
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 1rem;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
    outline-width: 0px;
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }

  svg {
    margin-right: 1rem;
  }
`;

export const StyledSocialAuthBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #000;
  letter-spacing: 0.1rem;
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 1rem;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
    outline-width: 0px;
    /* color: #ed4040; */
  }

  & svg {
    transition: all 0.3s ease;
    font-size: 2rem;
    margin-right: 0.5rem;
  }

  &:hover svg {
    color: #ed4040;
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }
`;

export const StyledPublicationShare = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
    /* gap: 1rem; */
  }
`;

export const StyledPublicationShareBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background: transparent;
  font-size: 1.3rem;
  line-height: 0;
  color: #fff;
  transition: 0.25s all;
  margin-left: 1rem;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.telegram {
    background-color: #5682a3;
  }

  /* &.telegram:hover::after {
    content: "Telegram";
    margin-left: 0.3rem;
  } */

  &.whatsup {
    background-color: #27d366;
  }

  /* &.whatsup:hover::after {
    content: "WhatsUp";
    margin-left: 0.3rem;
  } */

  &.twitter {
    background-color: #1da1f2;
  }

  /* &.twitter:hover::after {
    content: "Twitter";
    margin-left: 0.3rem;
  } */

  &.facebook {
    background-color: #3b5998;
  }

  /* &.facebook:hover::after {
    content: "Facebook";
    margin-left: 0.3rem;
  } */
`;
