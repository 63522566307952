import styled from "styled-components";

export const StyledAmountsContainer = styled.div`
  width: calc(50% - 4rem);
  margin-right: 4rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const StyledAmounts = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`;
export const StyledAmountsTitle = styled.div`
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;
export const StyledAmountsBlock = styled.div`
  width: 100%;
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
  padding: 2rem;
  height: max-content;
  margin-bottom: 0.5rem;
`;
export const StyledAmountsValue = styled.div`
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;

  @media(max-width: 767px) {
    font-size: 2rem;
  }
`;
