import styled from "styled-components";
import { FaQuoteLeft } from "react-icons/fa6";

export const StyledPost = styled.div`
  /* display: flex; */
  width: 100%;
  margin: 1rem 0;
  padding: 1.5rem;
  background-color: rgb(242, 245, 247);
  border-radius: 0.5rem;
`;

export const StyledPostInnerContainer = styled.div`
  display: flex;
`;

export const StyledPostControl = styled.div`
  width: 100%;
  margin-top: 3rem;
  border-top: 1px solid silver;
`;

export const StyledPostAuthor = styled.div`
  margin-right: 6rem;
`;

export const StyledPostIsAuthor = styled.div`
  width: fit-content;
  margin-bottom: 0.5rem;
  border: 1px solid rgb(121, 122, 123);
  border-radius: 0.3rem;
  padding: 0 0.5rem;
  color: rgb(121, 122, 123);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
`;

export const StyledPostAuthorUsername = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const StyledPostAuthorImg = styled.img`
  width: 8rem;
  height: 8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  border: 1px solid silver;
  background-color: rgb(255, 255, 255);
  object-fit: cover;
`;

export const StyledPostBody = styled.div`
  width: 100%;
  font-size: 1.5rem;

  h2 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2.8rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h5 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 1.25;
    font-weight: 700;
  }

  p {
    margin-bottom: 2.5rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  img {
    max-width: 70%;
    border-radius: 1rem;
    margin-bottom: 1rem;
    object-fit: cover;
  }

  img.card-icon {
    width: auto;
    border-radius: 0;
    margin-bottom: 0;
  }

  blockquote {
    display: block;
    margin: 0 0 1rem;
    padding: 2rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid silver;
    border-radius: 1rem;
  }

  blockquote > * {
    position: relative;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    overflow-wrap: break-word;
    margin-top: 0px;
    margin-bottom: 1rem;
    margin-left: 4.3rem;
  }

  blockquote::before {
    content: "";
    display: block;
    position: absolute;
    width: 2.875rem;
    height: 2rem;
    min-width: 2.875rem;
    min-height: 2rem;
    background: url(https://pokertribe.club/static/icons/other/quote-blue.svg);
    background-size: 2.875rem 2rem;
    margin-bottom: 0.75rem;
  }

  table {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1.6rem;
    line-height: 1.3;
    text-align: center;

    & caption {
      color: rgba(0, 0, 0, 0.4);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 1.5rem;
    }
  }

  th,
  td {
    border: 1px solid rgba(196, 205, 212, 0.5);
    /* background-color: rgba(0, 0, 0, 0.06); */
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 1rem;
  }

  th {
    background: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }

  tr {
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.025);
      /* background-color: #f6f6f6; */
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      /* background-color: #ddd; */
    }
  }

  ul {
    padding-left: 4rem;
    list-style: disc;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  ol {
    padding-left: 4rem;
    list-style: auto;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  a {
    color: #3187ee;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #52a0ff;
    }
  }
 
  details {
    width: 100%;
    border: 1px solid silver;
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 0 2rem;
    margin-bottom: 2rem;
  }

  summary {
    /* background-color: green; */

    /* width: 100%; */
    /* margin: 0 -2rem; */
    padding: 2rem 0;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    /* margin-bottom: 2rem; */
  }
`;

// ========== MOBILE ============

export const StyledPost_Mob = styled.div`
  /* display: flex; */
  width: 100%;
  margin: 1rem 0;
  padding: 1.5rem;
  background-color: rgb(242, 245, 247);
  border-radius: 0.5rem;
`;

export const StyledPostInnerContainer_Mob = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPostControl_Mob = styled.div`
  width: 100%;
  margin-top: 3rem;
  border-top: 1px solid silver;
`;

export const StyledPostAuthor_Mob = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const StyledPostIsAuthor_Mob = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  /* margin-bottom: 0.5rem; */
  border: 1px solid rgb(121, 122, 123);
  border-radius: 0.3rem;
  padding: 0 0.5rem;
  color: rgb(121, 122, 123);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
`;

export const StyledPostAuthorUsername_Mob = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 1rem;
`;

export const StyledPostAuthorImg_Mob = styled.img`
  width: 6rem;
  height: 6rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  border: 1px solid silver;
  background-color: rgb(255, 255, 255);
  object-fit: cover;
`;

export const StyledPostBody_Mob = styled.div`
  font-size: 1.5rem;

  h2 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2.8rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 700;
  }

  h5 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 1.25;
    font-weight: 700;
  }

  p {
    margin-bottom: 2.5rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  img {
    max-width: 70%;
    border-radius: 1rem;
    margin-bottom: 1rem;
    object-fit: cover;
  }

  img.card-icon {
    width: auto;
    border-radius: 0;
    margin-bottom: 0;
  }

  blockquote {
    margin: 0 0 1rem;
    padding: 2rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid silver;
    border-radius: 1rem;
  }

  blockquote > * {
    position: relative;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    overflow-wrap: break-word;
    margin-top: 0px;
    margin-bottom: 1rem;
    padding-left: 4.3rem;
  }

  blockquote > figure {
    position: relative;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    overflow-wrap: break-word;
    margin-top: 0px;
    margin-bottom: 1rem;
    padding-left: 4.3rem;
  }

  blockquote::before {
    content: "";
    display: block;
    position: absolute;
    width: 2.875rem;
    height: 2rem;
    min-width: 2.875rem;
    min-height: 2rem;
    background: url(https://pokertribe.club/static/icons/other/quote-blue.svg);
    background-size: 2.875rem 2rem;
    margin-bottom: 0.75rem;
  }

  table {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1.6rem;
    line-height: 1.3;
    text-align: center;

    & caption {
      color: rgba(0, 0, 0, 0.4);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 1.5rem;
    }
  }

  th,
  td {
    border: 1px solid rgba(196, 205, 212, 0.5);
    /* background-color: rgba(0, 0, 0, 0.06); */
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 1rem;
  }

  th {
    background: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }

  tr {
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.025);
      /* background-color: #f6f6f6; */
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      /* background-color: #ddd; */
    }
  }

  ul {
    padding-left: 4rem;
    list-style: disc;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  ol {
    padding-left: 4rem;
    list-style: auto;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  a {
    color: #3187ee;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #52a0ff;
    }
  }
`;
