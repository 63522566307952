import React, { useState } from "react";
import Button from "../Button";
import { StyledRegistrationBtn } from "../styles/RegistrationBtn.styled";
import { StyledRestorePasswordBtn } from "../styles/Button.styled";
import {
  StyledForm,
  StyledNotice,
  StyledBottomContainer,
} from "../styles/form/Form.styled";
import Input from "../form/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import PublicationContext from "../contexts/PublicationContext";
import { useRef } from "react";
import { useEffect } from "react";
import useApi from "../../services/useApi";
import { login as loginService } from "../../services/authentication";
import { getCurrentUser } from "../../services/users";
import AuthenticationContext from "../contexts/AuthenticationContext";
import BackendErrors from "./BackendErrors";
import useLocalStorage from "../../hooks/useLocalStorage";
import Spinner from "../shared/Spinner";
import SocialAuth from "../SocialAuth";
import SeoFields from "../shared/SeoFields";

function LoginForm() {
  const { setEntranceForm, setEntranceModalOpen, setEmailResend } =
    useContext(PublicationContext);
  const { state, dispatch } = useContext(AuthenticationContext);

  const [token, setToken] = useLocalStorage("token");

  let navigate = useNavigate();
  let location = useLocation();
  const emailRef = useRef();
  const passwordRef = useRef();

  const { data, loading, error, request } = useApi(loginService);
  const {
    data: currentUser,
    loading: currentUserLoading,
    error: currentUserError,
    request: getCurrentUserRequest,
  } = useApi(getCurrentUser);

  function restorePswd(e) {
    e.preventDefault();
    navigate("/restore/", { state: location.state });
    setEntranceForm("restore");
  }

  function handleLogin(e) {
    e.preventDefault();
    dispatch({ type: "SET_LOADING" });
  }

  // request get token
  useEffect(() => {
    if (!state?.isLoading) {
      return;
    }

    request("/dj-rest-auth/login/", {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    });
  }, [state?.isLoading]);

  //set token
  useEffect(() => {
    if (!data) {
      return;
    }
    setToken(data?.key);
  }, [data]);

  //request get user
  useEffect(() => {
    if (!token) {
      return;
    }
    getCurrentUserRequest("/dj-rest-auth/user/");
  }, [token]);

  //set user in authcontext
  useEffect(() => {
    if (!currentUser) {
      return;
    }

    dispatch({ type: "CURRENT_USER", payload: currentUser });
    setEntranceModalOpen(false);
  }, [currentUser]);

  useEffect(() => {
    if (!error || !currentUserError) {
      dispatch({ type: "CLEAR_CURRENT_USER" });
    }
    let isArr = Array.isArray(error?.response?.data?.non_field_errors);
    if (
      isArr &&
      error?.response?.data?.non_field_errors[0] === "E-mail is not verified."
    ) {
      setEntranceForm("resend");
      setEmailResend(emailRef.current.value);
    }
  }, [error, currentUserError]);

  return (
    <>
      <SeoFields
        title="Авторизація"
        description="Авторизація у betraisers.com"
        keywords={null}
        robots="noindex, nofollow"
        canonical="https://betraisers.com/login/"
      />
      <StyledForm
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // e.preventDefault();
            handleLogin(e);
          }
        }}
        onSubmit={handleLogin}
      >
        <Input
          type="email"
          name="email"
          id="email"
          label="Email"
          refc={emailRef}
        />
        <Input
          type="password"
          name="password"
          id="password"
          label="Пароль"
          refc={passwordRef}
        />
        {error && <BackendErrors errors={error} />}

        <SocialAuth />

        <StyledBottomContainer>
          <StyledNotice>
            Входячи, Ви приймаєте <a href="">умови користування сайтом</a>
          </StyledNotice>
          <Button
            text={
              loading || currentUserLoading ? (
                <Spinner
                  style={{ margin: "0", width: "2rem", height: "2rem" }}
                />
              ) : (
                "Увійти"
              )
            }
            styled={StyledRegistrationBtn}
            style={{
              marginTop: "1rem",
              marginRight: "1.5rem",
              minWidth: "10rem",
            }}
            type="submit"
            // onClick={handleLogin}
          />
          <Button
            text="Відновити пароль"
            styled={StyledRestorePasswordBtn}
            onClick={restorePswd}
          />
        </StyledBottomContainer>
      </StyledForm>
    </>
  );
}

export default LoginForm;
