import React from "react";
import {
  StyledSubMenu,
  StyledSubMenuContainer,
} from "../styles/layout/SubMenu.styled";
import SubMenuItem from "./SubMenuItem";

function SubMenu({ items, isShow }) {
  return (
    <StyledSubMenuContainer>
      <StyledSubMenu isShow={isShow}>
        {items.map((i) => {
          return <SubMenuItem item={i} />;
        })}
      </StyledSubMenu>
    </StyledSubMenuContainer>
  );
}

export default SubMenu;
