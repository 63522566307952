import styled from "styled-components";

export const SignUpBtn = styled.button`
  letter-spacing: 0.1rem;
  margin-left: 0.1rem;
  cursor: pointer;
  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: 0.25s;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 4rem;
  font-size: 1.4rem;
  line-height: 1.28;
  color: #0b5acd;
  background-color: rgba(49, 135, 238, 0.1);
  padding-left: 2rem;
  padding-right: 2rem;
  border-width: 0px;
  text-decoration: none;
  overflow: visible;
  border-radius: 0 0.8rem 0.8rem 0;

  &:hover {
    background-color: rgba(49, 135, 238, 0.2);
  }

  &:active {
    background-color: rgba(49, 135, 238, 0.3);
  }
`;
