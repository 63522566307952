import React, { useEffect, useRef, useState } from "react";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import Spinner from "../shared/Spinner";
import Input from "../form/Input";
import Button from "../Button";
import useApi from "../../services/useApi";
import { useParams } from "react-router-dom";
import { resetPswdConfirm } from "../../services/authentication";
import { BsCheck2Circle } from "react-icons/bs";

function RestorePswdConfirm() {
  const newPassword1 = useRef();
  const newPassword2 = useRef();
  const [isRequest, setIsRequest] = useState(false);
  const { uid, token } = useParams();
  const { data, loading, error, request } = useApi(resetPswdConfirm);

  useEffect(() => {
    if (!isRequest) {
      return;
    }
    request("dj-rest-auth/password/reset/confirm/", {
      uid: uid,
      token: token,
      new_password1: newPassword1.current.value,
      new_password2: newPassword2.current.value,
    });
    setIsRequest(false);
  }, [isRequest]);

  if (data) {
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ color: "#02980e", fontSize: "10rem" }}>
          <BsCheck2Circle />
        </div>
        <h2>{data?.detail && "Пароль був успішно змінений."}</h2>
      </div>
    );
  }

  return (
    <div>
      <div style={{ marginTop: "3rem" }}>
        <Input
          type="password"
          name="new-password1"
          id="new-password1"
          label="Новий пароль"
          refc={newPassword1}
        />
        <Input
          type="password"
          name="new-password2"
          id="new-password2"
          label="Новий пароль ще раз"
          refc={newPassword2}
        />
      </div>

      {error &&
        Object.entries(error?.response?.data).map((i) => {
          return <div>{i.join(" ")}</div>;
        })}

      <Button
        text={
          loading ? (
            <Spinner style={{ margin: "0", width: "2rem", height: "2rem" }} />
          ) : (
            "Зберегти"
          )
        }
        styled={StyledRedBtn}
        style={{ marginLeft: "0", marginTop: "3rem", minWidth: "13rem" }}
        onClick={() => {
          setIsRequest(true);
        }}
      />
    </div>
  );
}

export default RestorePswdConfirm;
