import React from "react";
import Section from "./Section";

function SectionList({ data }) {
  return (
    <div>
      {data?.map((item) => {
        return <Section data={item} />;
      })}
    </div>
  );
}

export default SectionList;
