import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import { createDropdown } from "@ckeditor/ckeditor5-ui";
import xsIcon from "./cards/spades/xs.svg";
import xcIcon from "./cards/clubs/xc.svg";
import xdIcon from "./cards/diamonds/xd.svg";
import xhIcon from "./cards/hearts/xh.svg";
import xoIcon from "./cards/offsuit/xo.svg";
import { Cards as cards } from "./cards/Cards";

import DropdownView from "./DropdownView";

export default class SpadeCardPlugin extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.ui.componentFactory.add("SpadeCardPlugin", (locale) => {
      const dropdownView = createDropdown(locale);
      let dropdownPanelContent;

      dropdownView.buttonView.set({
        label: t("Spade"),
        icon: xsIcon,
        tooltip: true,
        class: ["dropdown-icon"],
      });

      const gridView = new DropdownView(locale, editor, cards.spades);
      dropdownView.panelView.children.add(gridView);

      return dropdownView;
    });

    editor.ui.componentFactory.add("ClubCardPlugin", (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: t("Club"),
        icon: xcIcon,
        tooltip: true,
        class: ["dropdown-icon"],
      });

      const gridView = new DropdownView(locale, editor, cards.clubs);
      dropdownView.panelView.children.add(gridView);

      return dropdownView;
    });

    editor.ui.componentFactory.add("DiamondCardPlugin", (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: t("Diamond"),
        icon: xdIcon,
        tooltip: true,
        class: ["dropdown-icon"],
      });

      const gridView = new DropdownView(locale, editor, cards.diamonds);
      dropdownView.panelView.children.add(gridView);

      return dropdownView;
    });

    editor.ui.componentFactory.add("HeartCardPlugin", (locale) => {
        const dropdownView = createDropdown(locale);
  
        dropdownView.buttonView.set({
          label: t("Heart"),
          icon: xhIcon,
          tooltip: true,
          class: ["dropdown-icon"],
        });
  
        const gridView = new DropdownView(locale, editor, cards.hearts);
        dropdownView.panelView.children.add(gridView);
  
        return dropdownView;
      });

      editor.ui.componentFactory.add("OffsuitCardPlugin", (locale) => {
        const dropdownView = createDropdown(locale);
  
        dropdownView.buttonView.set({
          label: t("Offsuit"),
          icon: xoIcon,
          tooltip: true,
          class: ["dropdown-icon"],
        });
  
        const gridView = new DropdownView(locale, editor, cards.offsuit);
        dropdownView.panelView.children.add(gridView);
  
        return dropdownView;
      });
  }
}
