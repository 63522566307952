import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledPublicationList = styled.div`
  margin-bottom: 4rem;
`;

export const StyledPublicationCard = styled.div`
  display: flex;
  width: 100%;
  height: 18rem;
  border-radius: 1rem;
  background-color: #f2f5f7;
  margin-bottom: 1.5rem;

  @media (max-width: 767px) {
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const StyledPublicationImgContainer = styled(Link)`
  display: block;
  width: 40%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledPublicationImg = styled.img`
  width: 100%;
  height: 100%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  object-fit: cover;

  @media (max-width: 767px) {
    width: 100%;
    height: 15rem;
    max-height: 20rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0rem;
  }
`;

export const StyledPublicationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 1.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledPublicationLabel = styled.div`
  &::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 50%;

    ${(props) => {
      switch (props.labelname) {
        case "news":
          return css`
            background-color: rgb(88, 82, 246);
          `;
        case "promotions":
          return css`
            background-color: rgb(213, 75, 70);
          `;
        case "articles":
          return css`
            background-color: rgb(124, 183, 92);
          `;
      }
    }}
  }
`;

export const StyledPublicationTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2rem;
  text-decoration: none;
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.2rem;

  @media (max-width: 767px) {
    display: -webkit-box;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: vertical;
  }
`;

export const StyledPublicationDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1.5rem;
  line-height: 2.2rem;
  margin-bottom: 2rem;
`;

export const StyledPublicationInfoContainer = styled.div`
  display: flex;
  margin-top: auto;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    line-height: 3rem;
  }
`;

export const StyledPublicationInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;

  & svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 767px) {
    margin-right: 2rem;
    font-size: 1.3rem;
  }
`;
