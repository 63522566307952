import React from "react";

function List({ item: Item, data }) {
  return (
    <div>
      {data?.map((item, i) => {
        return <Item key={i} item={item} />;
      })}
    </div>
  );
}

export default List;
