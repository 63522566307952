import styled from "styled-components";

export const StyledMobNavbar = styled.div`
  height: 100%;
`;

export const StyledMobNavbarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  /* backdrop-filter: saturate(180%) blur(20px); */
`;
