import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getRoomReviews } from "../../services/rooms";
import useApi from "../../services/useApi";
import Button from "../Button";
import AddReview from "../modals/AddReview";
import ReviewList from "../reviews/ReviewList";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import Modal from "react-modal";
import { StyledCloseModalBtn } from "../styles/Button.styled";
import { GrClose } from "react-icons/gr";
import { Rating } from "react-simple-star-rating";
import Spinner from "../shared/Spinner";
import { StyledLoadMoreBtn } from "../styles/shared/LoadMoreBtn.styled";
import {
  StyledRoomReviews,
  StyledRoomReviewsHeader,
  StyledRoomReviewsHeaderTitle,
} from "../styles/roomDetails/RoomReviews.styled";
import AuthenticationContext from "../contexts/AuthenticationContext";
import PleaseLogin from "../shared/PleaseLogin";
import { BsCheck2Circle } from "react-icons/bs";
import SeoFields from "../shared/SeoFields";
import * as DOMPurify from "dompurify";


function RoomReviews({ rating, reviewCount, roomData }) {
  const { state } = useContext(AuthenticationContext);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [doRequestRefresjList, setDoRequestRefresjList] = useState(false);
  const { data, loading, error, request } = useApi(getRoomReviews);

  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);
  const [limit, setLimit] = useState(0);
  const [limitParams, setLimitParams] = useState(0);

  useEffect(() => {
    request(`${location.pathname}list/`);
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isFirstLoadPage) {
      let params = null;
      try {
        params = new URLSearchParams(new URL(data?.next).search);
      } catch (e) {
        params = null;
      }
      setLimit((prevState) => prevState + +params?.get("limit"));
      setLimitParams((prevState) => prevState + +params?.get("limit"));
      setIsFirstLoadPage(false);
    }
    setDoRequestRefresjList(false);
  }, [data]);

  useEffect(() => {
    if (!doRequestRefresjList) {
      return;
    }
    request(`${location.pathname}list/`, { params: { limit: limitParams } });
    setDoRequestRefresjList(false);
  }, [doRequestRefresjList]);

  const handleAddReview = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setDoRequestRefresjList(false);
  };

  const handleLoadMoreReviews = () => {
    setLimitParams((prevState) => prevState + limit);
    setDoRequestRefresjList(true);
  };

  const closeModal = () => {
    document.querySelector("body").className = "";
    setIsModalOpen(false);
  };

  const closeSuccessModal = () => {
    document.querySelector("body").className = "";
    setIsSuccessModalOpen(false);
    setDoRequestRefresjList(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <StyledRoomReviews>
      <SeoFields
        title={roomData?.seo_title_feedbacks}
        description={roomData?.seo_description_feedbacks}
        keywords={roomData?.seo_keywords_feedbacks}
        robots={roomData?.seo_robots_feedbacks}
        canonical={roomData?.seo_canonical_feedbacks}
      />
      <StyledRoomReviewsHeader>
        <Rating allowFraction={true} initialValue={rating} size={30} readonly />
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <div style={{ fontSize: "3rem", marginRight: "1rem " }}>{rating}</div>
          <div style={{ fontSize: "2rem" }}>({data?.count} відгуків)</div>
        </div>
        <StyledRoomReviewsHeaderTitle>
          {roomData?.seo_h1_feedbacks}
        </StyledRoomReviewsHeaderTitle>
        <div
          style={{
            fontSize: "1.5rem",
            textAlign: "center",
            marginBottom: "3rem",
            color: "rgb(121, 122, 123)",
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(roomData?.main_text_feedbacks),
          }}
        >
        </div>
        {state?.currentUser && state?.isLoggedIn ? (
          <Button
            text="Додати відгук"
            styled={StyledRedBtn}
            onClick={handleAddReview}
          />
        ) : (
          <PleaseLogin />
        )}
      </StyledRoomReviewsHeader>

      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={closeSuccessModal}
        contentLabel="Add Review"
        className="modal-add-review"
        overlayClassName="overlay"
        onAfterClose={closeSuccessModal}
      >
        <Button
          icon={<GrClose />}
          styled={StyledCloseModalBtn}
          onClick={closeSuccessModal}
        />
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: "10rem", color: "#02980e" }}>
            <BsCheck2Circle />
          </div>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              marginBottom: "3rem",
            }}
          >
            Відгук додано успішно!
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Review"
        className="modal-add-review"
        overlayClassName="overlay"
        onAfterClose={closeModal}
      >
        <Button
          icon={<GrClose />}
          styled={StyledCloseModalBtn}
          onClick={closeModal}
        />
        <AddReview
          setSuccessAddedModal={setIsSuccessModalOpen}
          setAddReviewModal={setIsModalOpen}
          // refreshList={request}
        />
      </Modal>

      {data && (
        <ReviewList
          reviewList={data?.results}
          refreshList={setDoRequestRefresjList}
        />
      )}
      {data?.next && (
        <Button
          styled={StyledLoadMoreBtn}
          onClick={handleLoadMoreReviews}
          style={{ marginTop: "2rem" }}
          disabled={loading}
          text={!loading && `Показати ще ${limit} з ${data?.count}`}
          icon={
            loading && <Spinner style={{ width: "2rem", height: "2rem" }} />
          }
        />
      )}
    </StyledRoomReviews>
  );
}

export default RoomReviews;
