import React, { useContext, useEffect } from "react";
import Button from "../../componets/Button";
import {
  StyledAmounts,
  StyledAmountsBlock,
  StyledAmountsContainer,
  StyledAmountsTitle,
  StyledAmountsValue,
} from "../../componets/styles/balance/Amounts.styled";
import { StyledRedBtn } from "../../componets/styles/buttons/RedBtn.styled";
import { FaRegCopy } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import {
  StyledStatistics,
  StyledStatisticsTitle,
  StyledStatisticsTable,
  StyledStatisticsTr,
  StyledStatisticsTd,
  StyledStatisticsTh,
} from "../../componets/styles/balance/Statistics.styled";
import { useState } from "react";
import AuthenticationContext from "../../componets/contexts/AuthenticationContext";
import useApi from "../../services/useApi";
import { getUserReferals } from "../../services/users";
import formateDate from "../../utils/DateFormater";
import Pagination from "../../componets/pagination/Pagination";
import { useMediaQuery } from "react-responsive";
import Spinner from "../../componets/shared/Spinner";

function Referals() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { state } = useContext(AuthenticationContext);
  const refLink = `https://betraisers.com/?ref=${state?.currentUser?.pk}`;
  const [isCopied, setIsCopied] = useState(false);
  const { data, loading, error, request } = useApi(getUserReferals);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(refLink)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    request("pa/referals/");
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div style={{ width: "100%" }}>
      <StyledAmounts>
        <StyledAmountsTitle>Реферальне посилання</StyledAmountsTitle>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          <StyledAmountsBlock
            style={{ marginBottom: "0", marginRight: "2rem" }}
          >
            <StyledAmountsValue>{refLink}</StyledAmountsValue>
          </StyledAmountsBlock>
          <Button
            styled={StyledRedBtn}
            icon={isCopied ? <MdDone /> : <FaRegCopy />}
            text={isCopied ? "Скопійовано!" : "Скопіювати"}
            onClick={handleCopyClick}
          />
        </div>
      </StyledAmounts>
      <StyledAmountsTitle>Реферальна система</StyledAmountsTitle>
      <div
        style={{
          backgroundColor: "rgb(242, 245, 247)",
          borderRadius: "1rem",
          padding: "2rem 1.5rem",
          marginBottom: "2rem",
          fontSize: "1.5rem",
          fontWeight: "500",
          lineHeight: "1.3",
        }}
      >
        <p style={{ marginBottom: "1rem" }}>
          Отримуй від 200 br поінтів за кожну прив'язку свого реферала!
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Для деяких покер-румів та онлайн казино також діє система Rev. Share,
          тому Ви будете отримувати відсоток від рейку вашого реферала кожного
          місяця.
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Для того, щоб Ваш реферал був закріплений саме за Вами, Вам треба, щоб
          він перейшов і зареєструвався на сайті по Вашому реферальному
          посиланню. Для цього в кінці будь-якої сторінки сайту додайте Ваш
          ідентифікатор -{" "}
          <strong
            style={{
              fontSize: "2rem",
              marginTop: "0.5rem",
              padding: "0.3rem",
              backgroundColor: "#ffffff",
              border: "1px solid silver",
              borderRadius: "0.5rem",
            }}
          >{`?ref=${state?.currentUser?.pk}`}</strong>
        </p>
        <p style={{ fontWeight: "700", marginBottom: "1rem" }}>
          Приклад посилань:
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Сторінка "Паролі на фріроли":{" "}
          <span
            style={{
              marginTop: "0.5rem",
              padding: "0.3rem",
              backgroundColor: "#ffffff",
              border: "1px solid silver",
              borderRadius: "0.5rem",
            }}
          >
            https://betraisers.com/freerolls/
            <strong>{`?ref=${state?.currentUser?.pk}`}</strong>
          </span>
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Сторінка "Огляд ACR Poker":{" "}
          <span
            style={{
              marginTop: "0.5rem",
              padding: "0.3rem",
              backgroundColor: "#ffffff",
              border: "1px solid silver",
              borderRadius: "0.5rem",
            }}
          >
            https://betraisers.com/rooms/acrpoker/
            <strong>{`?ref=${state?.currentUser?.pk}`}</strong>
          </span>
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Сторінка публікації:{" "}
          <span
            style={{
              marginTop: "0.5rem",
              padding: "0.3rem",
              backgroundColor: "#ffffff",
              border: "1px solid silver",
              borderRadius: "0.5rem",
            }}
          >
            https://betraisers.com/publications/news/pokerstars-vdnovlyu-napt-pslya-12-rokv/
            <strong>{`?ref=${state?.currentUser?.pk}`}</strong>
          </span>
        </p>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: isMobile ? "wrap" : "nowrap",
          gap: "1rem",
        }}
      >
        <StyledAmountsContainer>
          <StyledAmountsTitle>Притягнуто рефералів</StyledAmountsTitle>
          <StyledAmountsBlock>
            <StyledAmountsValue>{data?.referal_count || 0}</StyledAmountsValue>
          </StyledAmountsBlock>
        </StyledAmountsContainer>

        <StyledAmountsContainer>
          <StyledAmountsTitle>
            Зароблено за реферальною системою
          </StyledAmountsTitle>
          <StyledAmountsBlock>
            <StyledAmountsValue>{data?.points || 0} points</StyledAmountsValue>
          </StyledAmountsBlock>
        </StyledAmountsContainer>
      </div>
      <StyledStatistics>
        <StyledStatisticsTitle>Статистика по рефералам</StyledStatisticsTitle>
        <div style={{ overflow: "scroll" }}>
          <StyledStatisticsTable cellspacing="0" cellpadding="0">
            <StyledStatisticsTr>
              <StyledStatisticsTh>Дата/Час</StyledStatisticsTh>
              <StyledStatisticsTh>Користувач</StyledStatisticsTh>
              <StyledStatisticsTh>Коментар</StyledStatisticsTh>
              <StyledStatisticsTh>Points</StyledStatisticsTh>
            </StyledStatisticsTr>

            {data &&
              data?.operations.map((i) => {
                return (
                  <StyledStatisticsTr>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {formateDate(i?.date, true)}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "1.2rem",
                        }}
                      >
                        {i?.referal_email}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.4rem",
                          color: "#000",
                        }}
                      >
                        {i?.comment_for_user}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "1.2rem",
                        }}
                      >
                        {i?.points}
                      </div>
                    </StyledStatisticsTd>
                  </StyledStatisticsTr>
                );
              })}
          </StyledStatisticsTable>
        </div>
      </StyledStatistics>
    </div>
  );
}

export default Referals;
