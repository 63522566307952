import client from "./client";

export const getCurrentUser = (pathname, params = {}, headers) => {
  return client.get(pathname, params, headers);
};

export const getUserArticleList = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getUserCommentList = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getUserFreerollList = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getUserReferals = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getUserBindings = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getUserOperationList = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getUserBalance = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const doChange = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const doCashout = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const getCashoutMethods = (pathname) => {
  return client.get(pathname);
};

export const getProfile = (pathname = 'pa/editprofile/', params = {}) => {
  return client.get(pathname, params);
}

export const editProfile = (pathname = 'pa/editprofile/', options, params = {}) => {
  return client.put(pathname, options, params);
}

export const getUserReviews = (pathname = 'pa/reviews/', params = {}) => {
  return client.get(pathname, params);
}