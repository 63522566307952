import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledBlockContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledBlock = styled.section`
  background-color: rgb(242, 245, 247);
  border-radius: 0.8rem;
  padding: 1.5rem 2rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
  }
`;

export const StyledBlock__TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledBlock__Title = styled.div`
  font-size: 2rem;
  font-weight: 700;
  width: fit-content;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #ed4040;
`;

export const StyledBlock__TitleLink = styled(Link)`
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgb(127, 127, 127);
`;

export const StyledArticleContainer = styled.article`
  display: flex;
  margin-bottom: 2rem;
`;

export const StyledArticle__Image = styled.img`
  width: 15rem;
  min-width: 15rem;
  max-width: 15rem;
  height: 7rem;
  max-height: 7rem;
  min-height: 7rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  object-fit: cover;

  @media (max-width: 767px) {
  width: 10rem;
  min-width: 10rem;
  max-width: 10rem;
  height: 8rem;
  max-height: 8rem;
  min-height: 8rem;
  }
`;

export const StyledArticle__Label = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #f9fdff;
  border: 1px solid #b6daf0;
  border-radius: 0.4rem;
  padding: 0.2rem 0.6rem;
  margin-right: 1rem;
`;

export const StyledArticle__Date = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(127, 127, 127);
`;

export const StyledArticle__InnerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledArticle__Title = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledArticle__RoomLabel = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  background-color: #000000;
  border: 1px solid silver;
  padding: 0.3rem;
  border-radius: 0.5rem;

  img {
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  div {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

export const StyledRoomCard = styled(Link)`
  text-decoration: none;
  color: #000000;
  display: flex;
  background-color: rgb(242, 245, 247);
  border: 1px solid rgb(242, 245, 247);
  border-radius: 0.8rem;
  padding: 1.5rem 2rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    border: 1px solid silver;
  }
`;

export const StyledRoomCard__Logo = styled.img`
  width: 12rem;
  min-width: 12rem;
  height: 7rem;
  margin-right: 2rem;
  background-color: #000000;
  border-radius: 1rem;
  padding: 2rem;
  object-fit: contain;
`;

export const StyledRoomCard__Name = styled.div`
  font-size: 2rem;
  font-weight: 700;
  white-space: nowrap;
`;

export const StyledRoomCard__RatingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 1rem;
`;

export const StyledRoomCard__RatingValue = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  margin-right: 0.5rem;
`;

export const StyledRoomCard__BonusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const StyledRoomCard__BonusKey = styled.div`
  font-size: 2rem;
  font-weight: 500;
`;

export const StyledRoomCard__BonusValue = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: rgb(127, 127, 127);
`;

export const StyledFeedbackCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  background-color: rgb(242, 245, 247);
  border-radius: 0.8rem;
  padding: 1.5rem 2rem;
  margin-right: 1rem;

  &:last-of-type {
    margin-right: 0rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const StyledFeedbackCard__UserContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

export const StyledFeedbackCard__UserImage = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const StyledFeedbackCard__UserName = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledFeedbackCard__Feedback = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

export const StyledFeedbackCard__BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export const StyledForum__StatsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledForum__StatsBlock = styled.div`
  width: 25%;
  padding: 2rem;
  margin-right: 1rem;
  background-color: rgb(51, 51, 51);
  border-radius: 1rem;
  color: #ffffff;
  text-align: center;

  &:last-of-type {
    margin-right: 0rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 0.2rem;
    margin-right: 0;
  }
`;

export const StyledForum__StatsBlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StyledForum__StatsBlockIcon = styled.div`
  font-size: 5rem;
  margin-right: 1rem;
`;

export const StyledForum__StatsBlockValue = styled.div`
  font-size: 3rem;
  font-weight: 700;
`;

export const StyledForum__StatsBlockValueText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const StyledForum__Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  padding: 2rem;
  margin-right: 1rem;
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
  color: #000000;

  &:last-of-type {
    margin-right: 0rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const StyledForum__BlockTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const StyledForum__BlockTheme = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledForum__BlockThemeLink = styled(Link)`
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  color: rgb(49, 135, 238);
  display: block;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledForum__BlockThemeInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledForum__BlockThemeSection = styled.div`
  color: grey;
  margin-right: 1rem;
  font-weight: 700;
`;

export const StyledForum__BlockThemeTime = styled.div`
  color: rgb(127, 127, 127);
  font-weight: 500;
`;

export const StyledForum__BlockLink = styled(Link)`
  font-size: 1.2rem;
  color: rgb(127, 127, 127);
  margin-top: auto;
  width: 100%;
  text-align: center;
`;

export const StyledForum__BlockPost = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledForum__BlockPostTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const StyledForum__BlockPostUserContainer = styled.div`
  display: flex;
`;

export const StyledForum__BlockPostUserImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const StyledForum__BlockPostUserName = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
`;

export const StyledForum__BlockPostText = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledForum__BlockPostBottomContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledForum__BlockPostTheme = styled.div`
  margin-right: 0.5rem;
  color: grey;
  font-weight: 700;
  white-space: nowrap;
`;

export const StyledForum__BlockPostThemeLink = styled(Link)`
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 700;
  color: rgb(49, 135, 238);
  display: block;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

// ============== MOBILE ================

export const StyledRoomCardMob = styled(Link)`
  text-decoration: none;
  color: #000000;
  display: flex;
  background-color: rgb(242, 245, 247);
  border: 1px solid rgb(242, 245, 247);
  border-radius: 0.8rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    border: 1px solid silver;
  }
`;

export const StyledRoomCard__LogoMob = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 1.5rem;
  background-color: #000;
  padding: 1rem;
  margin-right: 1rem;
  object-fit: contain;
`;

export const StyledRoomCard__NameMob = styled.div`
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.41;
  white-space: normal;
  overflow: hidden;
  letter-spacing: normal;
  /* overflow-wrap: break-word; */
  white-space: nowrap;
  margin-right: 1rem;
`;

export const StyledRoomCard__RatingContainerMob = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRoomCard__RatingValueMob = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  margin-right: 0.5rem;
`;

export const StyledRoomCard__BonusContainerMob = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledRoomCard__BonusKeyMob = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const StyledRoomCard__BonusValueMob = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  color: rgb(127, 127, 127);
`;
