import { Command } from '@ckeditor/ckeditor5-core';

export default class DetailsSummaryCommand extends Command {
    execute() {
        const editor = this.editor;
        const selection = editor.model.document.selection;

        editor.model.change(writer => {
            const details = writer.createElement('details');
            const summary = writer.createElement('summary');
            const paragraph = writer.createElement('paragraph');

            writer.insertText('Спойлер', summary);
            writer.insertText('test', paragraph);
            writer.append(summary, details);
            writer.append(paragraph, details);

            if (selection && !selection.isCollapsed) {
                // Wrap selected content in details
                const range = selection.getFirstRange();
                const content = editor.model.createRange(range.start, range.end);

                writer.move(content, paragraph);

                editor.model.insertContent(details);

                // Remove old content to avoid duplication
                writer.remove(range);

                // Move cursor to the new paragraph inside details
                const position = writer.createPositionAt(paragraph, 'end');
                writer.setSelection(position);
            } else {
                // Insert details at the current editor position
                editor.model.insertContent(details);

                // Move cursor to the new paragraph inside details
                const position = writer.createPositionAt(paragraph, 0);
                writer.setSelection(position);
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'details');

        this.isEnabled = allowedIn !== null;
    }
}
