import React, { useRef, useState } from "react";
import { StyledCheckBox } from "../styles/form/CheckBox.styled";

function CheckBox({ label, style, icon, isChecked }) {
  const inputRef = useRef(null);
  const [chechboxState, setCheckboxState] = useState(false)

  const handleChange = (e) => {
    setCheckboxState(inputRef.current.checked)
    isChecked(inputRef.current.checked)
  }

  return (
    <StyledCheckBox style={style}>
      <input ref={inputRef} type="checkbox" onChange={(e) => handleChange(e)} />
      {label}
      {chechboxState ? <span>{icon}</span> : <span></span>}
    </StyledCheckBox>
  );
}

export default CheckBox;
