import styled from "styled-components";

export const StyledGamblerNotification = styled.div`
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid red;
  padding: 0.5rem;
  z-index: 999;

  p {
    background: #fff;
    width: 100%;
    font-size: 1rem;
    letter-spacing: 3px;
    text-align: center;
  }

  @media (max-width: 1200px) {
    p {
      letter-spacing: 2px;
    }
  }

  @media (max-width: 1000px) {
    p {
      letter-spacing: 1px;
    }
  }

  @media (max-width: 900px) {
    p {
      letter-spacing: 0px;
    }
  }

  @media (max-width: 767px) {
    p {
      letter-spacing: -1px;
    }
  }
`;

export const StyledNotAdvertisingNotification = styled.div`
  display: flex;
  align-items: flex-start;

  background-color: #f5faff;
  border: 1px solid #277ff0;
  padding: 1.5rem;
  border-radius: 0.8rem;
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  svg {
    color: #277ff0;
    width: 2rem;
    min-width: 2rem;
    font-size: 2rem;
    margin-right: 1rem;
  }
`;
