import styled from "styled-components";

export const StyledLoadMoreBtn = styled.button`
  text-decoration: none;
  border: none;
  height: 4.4rem;
  min-width: 25.8rem;
  border-radius: 1.4rem;
  text-align: center;
  margin: 0 auto;
  padding: unset;
  color: #176cd4;
  background-color: rgba(23, 108, 212, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    background-color: rgba(23, 108, 212, 0.24);
  }

  &:active {
    background-color: rgba(23, 108, 212, 0.36);
  }
`;
