import React from "react";
import { Left } from "../componets/styles/shared/Left";
import { Right } from "../componets/styles/shared/Right";

import {
  StyledBonuses,
  StyledCardContainer,
  StyledCard,
  StyledLogo as StyledLogoSide,
  StyledName as StyledNameSide,
  StyledContainer,
  StyledInnerContainer,
  StyledTitle,
  StyledStickyContainer,
} from "../../src/componets/styles/roomRecomend/RoomRecomend.styled";

import {
  StyledRoomsFilterList,
  StyledRoomsFilter,
  StyledSearchContainer,
  StyledSearchButton,
  StyledSearchInput,
} from "../componets/styles/shared/Filters.styled";
import { BiTime, BiCommentDetail, BiLike, BiSearch } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import Suggestion from "../componets/suggestions/Suggestion";
import { useState } from "react";
import { StyledTransparentBtn } from "../componets/styles/Button.styled";
import Button from "../componets/Button";
import { useRef } from "react";
import { useEffect } from "react";
import { StyledLoadMoreBtn } from "../componets/styles/shared/LoadMoreBtn.styled";
import PublicationList from "../componets/publications/PublicationList";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Spinner from "../componets/shared/Spinner";
import useApi from "../services/useApi";
import { getPublications } from "../services/publications";
import { useMediaQuery } from "react-responsive";
import { getRooms } from "../services/rooms";
import SeoFields from "../componets/shared/SeoFields";
import AdsSide from "../componets/shared/AdsSide";

function Publications({ publications }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isSearch, setIsSearch] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  let location = useLocation();
  const { data, loading, error, request } = useApi(getPublications);
  const {
    data: dataRooms,
    loading: loadingRooms,
    error: errorRooms,
    request: requestRooms,
  } = useApi(getRooms);
  const [limit, setLimit] = useState({ pub_limit: null, LIMIT_CONST: null });
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  useEffect(() => {
    if (dataRooms) {
      return;
    }
    requestRooms("/rooms/");
  }, [dataRooms]);

  useEffect(() => {
    request(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (isSearch) {
      searchRef.current.focus();
    }
  }, [isSearch]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!data.next && !data.previous) {
      return;
    }
    if (isFirstRequest) {
      const params = new URLSearchParams(new URL(data?.next).search);
      const lim = +params.get("limit");
      setLimit({ pub_limit: lim, LIMIT_CONST: lim });
    }
    setIsFirstRequest(false);
  }, [data]);

  useEffect(() => {
    request(location.pathname, { params: { limit: limit.pub_limit } });
  }, [limit]);

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearch((prev) => !prev);
    navigate("/publications/");
    request(location.pathname);
  };

  const handleInput = (e) => {
    e.preventDefault();
    request(location.pathname + "", {
      params: { title__icontains: searchRef.current.value },
    });
  };

  const loadMorePublications = () => {
    setLimit((prev) => ({
      ...prev,
      pub_limit: prev.pub_limit + prev.LIMIT_CONST,
    }));
  };


  const seoFields =
    publications === "" ? (
      <SeoFields
        title="Цікаві статті, свіжі новини, актуальні акції у світі гемблінгу українською"
        description="Публікації на бетрейзерс - це покерні статті, акції покер румів та онлайн казино, останні новини зі світу гемблінгу"
        keywords={null}
        robots="index, follow"
        canonical="https://betraisers.com/publications/"
      />
    ) : null;

  return (
    <>
      {seoFields || (
        <SeoFields
          title={data?.results[0]?.category?.seo_title}
          description={data?.results[0]?.category?.seo_descriptions}
          keywords={data?.results[0]?.category?.seo_keywords}
          robots={data?.results[0]?.category?.seo_robots}
          canonical={data?.results[0]?.category?.seo_canonical}
        />
      )}

      <Left>
        {!isSearch ? (
          <StyledRoomsFilterList style={{ margin: "0 0 2rem 0" }}>
            {!isMobile && (
              <StyledRoomsFilter
                style={{ padding: "2rem 0" }}
                end
                to="/publications/"
              >
                Усі публікації
              </StyledRoomsFilter>
            )}
            {isMobile && (
              <StyledRoomsFilter
                style={{ padding: "" }}
                end
                to="/publications/"
              >
                Усі
              </StyledRoomsFilter>
            )}
            <StyledRoomsFilter to="/publications/news/">
              Новини
            </StyledRoomsFilter>
            <StyledRoomsFilter to="/publications/promotions/">
              Акції
            </StyledRoomsFilter>
            <StyledRoomsFilter to="/publications/articles/">
              Статті
            </StyledRoomsFilter>
            <StyledSearchButton onClick={handleSearch}>
              <BiSearch />
            </StyledSearchButton>
          </StyledRoomsFilterList>
        ) : (
          <StyledSearchContainer>
            <StyledSearchInput
              ref={searchRef}
              placeholder="Пошук за ключовими словами"
              onInput={handleInput}
            />
            <Button
              styled={StyledTransparentBtn}
              icon={<IoCloseOutline />}
              onClick={handleSearch}
            />
          </StyledSearchContainer>
        )}
        {isFirstRequest && loading && <Spinner />}
        {data && (
          <PublicationList
            publications={{ data: data?.results, loading, error }}
          />
        )}
        {data?.next && (
          <Button
            styled={StyledLoadMoreBtn}
            onClick={loadMorePublications}
            disabled={loading}
            text={!loading && "Показати більше"}
            icon={
              loading && <Spinner style={{ width: "2rem", height: "2rem" }} />
            }
          />
        )}
      </Left>

      <Right>
        {/* <Suggestion /> */}
        {dataRooms && (
          <StyledContainer>
            <StyledTitle>⭐️ Рекомендовані покер-руми</StyledTitle>

            {dataRooms?.results.map((room, i) => {
              if (i >= 10) {
                return;
              }
              return (
                <Link to={`/rooms/${room.slug}/`}>
                  <StyledCardContainer>
                    <StyledCard>
                      <StyledLogoSide src={room?.logo_small} alt={room?.name} />
                      <StyledInnerContainer>
                        <StyledNameSide>{room?.name}</StyledNameSide>
                        <StyledBonuses>{room?.first_bonus}</StyledBonuses>
                      </StyledInnerContainer>
                    </StyledCard>
                  </StyledCardContainer>
                </Link>
              );
            })}
          </StyledContainer>
        )}
        <StyledStickyContainer>
          <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
          <AdsSide />
        </StyledStickyContainer>
      </Right>
    </>
  );
}

export default Publications;
