import React from "react";
import Review from "./Review";

function ReviewList({ reviewList, refreshList, pathToRequest }) {
  return (
    <div>
      {reviewList?.map((i) => {
        return <Review key={i?.id} data={i} refreshList={refreshList} pathToRequest={pathToRequest} />;
      })}
    </div>
  );
}

export default ReviewList;
