import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

export const StyledStickyContainer = styled.div`
  margin-bottom: 2rem;
  position: sticky;
  top: 7rem;
`;

export const StyledTitle = styled.div`
  color: #7f7f7f;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin-bottom: 1rem;
`;

export const StyledCard = styled.div`
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const StyledCardContainer = styled.div`
  padding: 0 1rem;

  &:hover {
    background-color: #f2f5f7;
    transition: all 0.1s ease-in;
    border-radius: 1.4rem;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const StyledLogo = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  object-fit: contain;
  background-color: #000;
  border-radius: 1rem;
  padding: 0.5rem;
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  overflow: hidden;

  @media (max-width: 767px) {
    margin-left: 1.5rem;
  }
`;

export const StyledName = styled.div`
  font-size: 2rem;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledDevider = styled.div``;

export const StyledBonuses = styled.div`
  font-size: 1.5rem;
  color: #797a7b;
  line-height: 2rem;
  margin-top: 0.2rem;
`;
