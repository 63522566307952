import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import Main from "../pages/Main";

function Referer() {
  const params = useParams();
  const navigate = useNavigate();
  const [referer, setReferer] = useLocalStorage("r");

  useEffect(() => {
    setReferer(params.referer);
  }, []);

  useEffect(() => {
    navigate("/register");
  }, [referer]);

  return <Main />;
}

export default Referer;
