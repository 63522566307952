import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import { StyledRegistrationBtn } from "../styles/RegistrationBtn.styled";
import { StyledForm, StyledBottomContainer } from "../styles/form/Form.styled";
import Input from "../form/Input";
import useApi from "../../services/useApi";
import { resetPswd } from "../../services/authentication";
import { BsEnvelope } from "react-icons/bs";
import SeoFields from "../shared/SeoFields";

function RestorePswdForm() {
  const { data, loading, error, request } = useApi(resetPswd);
  const [isRestore, setIsRestore] = useState(false);
  const emailRef = useRef(null);

  const handleRestorePswd = (e) => {
    e.preventDefault();
    setIsRestore(true);
  };

  useEffect(() => {
    if (!isRestore) {
      return;
    }
    request("dj-rest-auth/password/reset/", { email: emailRef.current.value });
  }, [isRestore]);

  if (data) {
    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "10rem", color: "#02980e" }}>
          <BsEnvelope />
        </div>
        <h2>
          Вам відправлено листа на вказану поштову адресу. Дотримуйтесь вказивок
          в отриманому листі. Якщо Ви не отримали листа - перевірте папку СПАМ.
        </h2>
      </div>
    );
  }

  return (
    <>
      <SeoFields
        title="Відновлення паролю"
        description="Форма відновлення паролю"
        keywords={null}
        robots="noindex, nofollow"
        canonical="https://betraisers.com/restore/"
      />
      <StyledForm>
        <Input
          type="email"
          name="email"
          id="email"
          label="Email"
          refc={emailRef}
        />
        <StyledBottomContainer>
          <Button
            text="Отримати лист відновлення"
            styled={StyledRegistrationBtn}
            style={{ marginTop: "1rem", marginRight: "1.5rem" }}
            onClick={(e) => handleRestorePswd(e)}
          />
        </StyledBottomContainer>
      </StyledForm>
    </>
  );
}

export default RestorePswdForm;
