import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledSideNav = styled.div`
  white-space: nowrap;
  margin-right: 5rem;
`;

export const StyledSideNavTitle = styled.h2`
  font-size: 1.3rem;
  font-weight: 400;
  color: #999;
  margin: 2rem 0 1rem;
  padding: 1rem 0;
  letter-spacing: 1.63px;
  text-transform: uppercase;
  border-bottom: 1px solid #e6e6e6;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const StyledSideNavLink = styled(NavLink)`
  font-size: 1.5rem;
  display: block;
  margin-left: 2rem;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.8);
  transition-property: color, background-color;
  transition-duration: 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 1;
  }

  &.active {
    font-weight: 700;
    opacity: 1;
  }
`;
