import React, { createContext, useState } from "react";
import { RiNurseFill } from "react-icons/ri";

const PublicationContext = createContext();

export const PublicationProvider = ({ children }) => {
  const [inputData, setInputData] = useState("");
  const [editor, setEditor] = useState(null);
  const [entranceForm, setEntranceForm] = useState("login");
  const [isEntranceModalOpen, setEntranceModalOpen] = useState(false);
  const [pointValue, setPointValue] = useState(null);
  const [usdValue, setUsdValue] = useState(null);
  const [dataEditor, setDataEditor] = useState("");
  const [titleArticle, setTitleArticle] = useState("");
  const [descArticle, setDescArticle] = useState(null);
  const [imageArticle, setImageArticle] = useState(null);
  const [stats, setStats] = useState(null);
  const [listComments, setListComments] = useState(null);
  const [commentAnswer, setCommentAnswer] = useState(null);
  const [ed, setEd] = useState(null);
  const [isAnswerClick, setIsAnswerClick] = useState(false);
  const [emailResend, setEmailResend] = useState("");
  const [image, setImage] = useState(null);

  const [sidebar, setSidebar] = useState(false);

  //Freeroll
  const [params, setParams] = useState({ limit: 0 });

  return (
    <PublicationContext.Provider
      value={{
        inputData,
        setInputData,
        editor,
        setEditor,
        entranceForm,
        setEntranceForm,
        isEntranceModalOpen,
        setEntranceModalOpen,
        pointValue,
        setPointValue,
        usdValue,
        setUsdValue,
        dataEditor,
        setDataEditor,
        titleArticle,
        setTitleArticle,
        descArticle,
        setDescArticle,
        imageArticle,
        setImageArticle,
        stats,
        setStats,
        listComments,
        setListComments,
        commentAnswer,
        setCommentAnswer,
        ed,
        setEd,
        isAnswerClick,
        setIsAnswerClick,
        emailResend,
        setEmailResend,
        image,
        setImage,
        params,
        setParams,
        sidebar,
        setSidebar,
      }}
    >
      {children}
    </PublicationContext.Provider>
  );
};

export default PublicationContext;
