import React, { useEffect } from "react";
import Binding from "../../componets/binding/Binding";
import { StyledBindingTab } from "../../componets/styles/personalArea/BindingTab.style";
import useApi from "../../services/useApi";
import { getUserBindings } from "../../services/users";
import Spinner from "../../componets/shared/Spinner";

function BindingTab() {
  const { data, loading, error, request } = useApi(getUserBindings);

  useEffect(() => {
    request("pa/bindings/");
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <StyledBindingTab>
      <div
        style={{
          color: "#db4b37",
          fontSize: "2rem",
          fontWeight: "700",
          textAlign: "center",
          borderRadius: "1rem",
          padding: "2rem  1rem",
          border: "1px solid #db4b37",
          marginBottom: "2rem",
        }}
      >
        <div style={{ fontSize: "2rem" }}>⚠️ Увага!</div>
        <div style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
          НЕ ТРЕБА перевіряти привязку, якщо Ви реєструвалися НЕ через наш сайт!
        </div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "400",
            color: "#000",
          }}
        >
          Якщо Ви зареєструвалися у покер румі або казино не через наш сайт і хочете переприв'язати
          аккаунт до нас - спочатку напишіть нам в телеграм
          <span style={{fontWeight: "700"}}> @betraisers_support </span>
          або на пошту <span style={{fontWeight: "700"}}> help@betraisers.com</span>
        </div>
      </div>
      <h2 style={{ margin: " 0 0 2rem 0" }}>Покер руми</h2>
      {data &&
        data?.room?.map((room) => {
          return (
            <Binding type='rooms' data={room} status={room.binding[0]?.status}></Binding>
          );
        })}
      <h2 style={{ margin: " 3rem 0 2rem 0" }}>Онлайн казино</h2>
      {data &&
        data?.casino?.map((casino) => {
          return (
            <Binding type='casinos' data={casino} status={casino.binding[0]?.status}></Binding>
          );
        })}
    </StyledBindingTab>
  );
}

export default BindingTab;
