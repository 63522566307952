import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  StyledSideNavTitle,
  StyledSideNavLink,
  StyledSideNav,
} from "../styles/layout/SideNav.styled";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";

function SideNav() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const location = useLocation();
  const [navTo, setNavTo] = useState();

  useEffect(() => {
    navigate(navTo);
  }, [navTo]);

  const options = [
    { value: "/p/edit-profile/", label: "Редагувати профіль" },
    { value: "/p/change-password/", label: "Змінити пароль" },
    { value: "/p/social-connections/", label: "Прикріплені акаунти" },
    { value: "/p/balance/", label: "Баланс та історія операцій" },
    { value: "/p/added-freerolls/", label: "Додані фріроли" },
    { value: "/p/added-articles/", label: "Додані статті" },
    { value: "/p/my-reviews/", label: "Додані відгуки" },
    { value: "/p/my-comments/", label: "Додані коментарі" },
    { value: "/p/my-bindings/", label: "Мої прив'язки" },
    { value: "/p/referals/", label: "Мої реферали" },
  ];

  if (isMobile) {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Select
          isClearable={false}
          isSearchable={false}
          defaultValue={() => {
            return {
              value: "/p/balance/",
              label: "Баланс та історія операцій",
            };
          }}
          placeholder="Виберіть розділ..."
          options={options}
          value={options.filter((option) => {
            return option.value === location.pathname;
          })}
          onChange={(option) => {
            setNavTo(option.value);
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              position: "sticky",
              bottom: "30",
              height: "5rem",
              border: "0",
              borderRadius: "0.8rem",
              backgroundColor: "rgba(0,41,91,0.06)",
              boxShadow: "none",
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              fontSize: "1.5rem",
              fontWeight: "700",
            }),
            indicatorSeparator: (baseStyles, state) => ({
              ...baseStyles,
              display: "none",
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "white",
              borderRadius: "0.8rem",
              fontSize: "1.5rem",
              zIndex: "33",
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              maxHeight: "none",
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              padding: "1.5rem 1.5rem",
              color: "#000000",
              backgroundColor: "none",
            }),
          }}
        />
      </div>
    );
  }
  return (
    <StyledSideNav>
      <StyledSideNavTitle>🙂 Профіль</StyledSideNavTitle>
      <ul>
        <StyledSideNavLink to="/p/edit-profile">
          Редагувати профіль
        </StyledSideNavLink>
        <StyledSideNavLink to="/p/change-password">
          Змінити пароль
        </StyledSideNavLink>
        <StyledSideNavLink to="/p/social-connections">
          Прикріплені акаунти
        </StyledSideNavLink>
      </ul>
      <StyledSideNavTitle>💰 Баланс</StyledSideNavTitle>
      <ul>
        <StyledSideNavLink to="balance">
          Баланс та історія операцій
        </StyledSideNavLink>
      </ul>
      <StyledSideNavTitle>🚀 Моя активність</StyledSideNavTitle>
      <ul>
        <StyledSideNavLink to="added-freerolls">
          Додані фріроли
        </StyledSideNavLink>
        <StyledSideNavLink to="added-articles">Додані статті</StyledSideNavLink>
        <StyledSideNavLink to="my-reviews">Додані відгуки</StyledSideNavLink>
        <StyledSideNavLink to="my-comments">Додані коментарі</StyledSideNavLink>
      </ul>
      <StyledSideNavTitle>🪢 Прив'язки</StyledSideNavTitle>
      <ul>
        <StyledSideNavLink to="my-bindings">Мої прив'язки</StyledSideNavLink>
      </ul>
      <StyledSideNavTitle>👫 Реферальна система</StyledSideNavTitle>
      <ul>
        <StyledSideNavLink to="referals">Мої реферали</StyledSideNavLink>
      </ul>
    </StyledSideNav>
  );
}

export default SideNav;
