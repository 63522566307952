import React from "react";
import {
  StyledPost,
  StyledPostAvatar,
  StyledPostDate,
  StyledPostText,
  StyledPostTheme,
  StyledPostUsername,
} from "../../styles/forum/side/Post.styled";
import * as DOMPurify from "dompurify";
import formateDate from "../../../utils/DateFormater";
import ReactTimeAgo from "react-time-ago";

function Post({ item }) {
  return (
    <StyledPost>
      <div style={{ display: "flex" }}>
        <StyledPostAvatar
          src={item?.author?.photo}
          alt={item?.author?.user?.username}
        />
        <div style={{ marginBottom: "0.5rem" }}>
          <StyledPostUsername>
            {item?.author?.user?.username}
          </StyledPostUsername>
          <StyledPostDate>
            <ReactTimeAgo
              date={item?.date_creation}
              locale="uk-UK"
              timeStyle={{
                labels: "short",
              }}
            />
          </StyledPostDate>
        </div>
      </div>
      <StyledPostText
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            item?.text.replaceAll("<p>&nbsp;</p>", ""),
            { FORBID_TAGS: ["figure", "img"] }
          ),
        }}
      ></StyledPostText>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div
          style={{
            marginRight: "0.5rem",
            color: "grey",
            fontWeight: "700",
            whiteSpace: "nowrap",
          }}
        >
          у темі
        </div>
        <StyledPostTheme to={`/forum/theme/${item?.theme?.id}/?page=last`}>
          {item?.theme?.name}
        </StyledPostTheme>
      </div>
    </StyledPost>
  );
}

export default Post;
