import React, { useEffect, useState } from "react";
import useApi from "../services/useApi";
import {
  getEstablishments,
  getEstablishmentsFilters,
} from "../services/establishments";
import EstablishmentListBlock from "../componets/establishment/EstablishmentListBlock";
import { Left } from "../componets/styles/shared/Left";
import {
  StyledRoomsFilter,
  StyledRoomsFilterList,
} from "../componets/styles/shared/Filters.styled";
import Select from "react-select";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../componets/Button";
import { StyledRestorePasswordBtn } from "../componets/styles/Button.styled";
import { AiOutlineDelete } from "react-icons/ai";
import { StyledPageTitle } from "../componets/styles/shared/PageTitle.styled";
import EstablishmentNoResults from "../componets/establishment/EstablishmentNoResults";
import { Right } from "../componets/styles/shared/Right";
import {
  StyledStickyContainer,
  StyledTitle,
} from "../componets/styles/roomRecomend/RoomRecomend.styled";
import AdsSide from "../componets/shared/AdsSide";
import { getCasinos } from "../services/casinos";
import {
  StyledBonuses,
  StyledCardContainer,
  StyledCard,
  StyledLogo as StyledLogoSide,
  StyledName as StyledNameSide,
  StyledContainer,
  StyledInnerContainer,
  // StyledTitle,
} from "../componets/styles/roomRecomend/RoomRecomend.styled";
import * as DOMPurify from "dompurify";
import { useMediaQuery } from "react-responsive";
import SeoFields from "../componets/shared/SeoFields";
import Spinner from "../componets/shared/Spinner";
import { StyledLoadMoreBtn } from "../componets/styles/shared/LoadMoreBtn.styled";

function Establishments(prop) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loadMoreParams, setLoadMoreParams] = useState(null);
  const [dataToRender, setDataToRender] = useState([]);
  const { data, loading, error, request } = useApi(getEstablishments);
  const {
    data: dataFilters,
    loading: loadingFilters,
    error: errorFilters,
    request: requestFilters,
  } = useApi(getEstablishmentsFilters);

  const {
    data: dataCasinos,
    loading: loadingCasinos,
    error: errorCasinos,
    request: requestCasinos,
  } = useApi(getCasinos);

  const navigate = useNavigate();
  const params = useParams();
  const [options, setOptions] = useState(null);

  const [path, setPath] = useState({
    type: prop?.type,
    city: params?.city ? params?.city + "/" : "",
  });

  useEffect(() => {
    let c = path.city.slice(0, -1);
    let t = path.type.slice(1, -1);
    if (t === "hralni-zaklady-ukrainy") {
      t = "";
    }
    request(`/establishments/?city__slug=${c}&type__slug=${t}`);
  }, [path]);

  useEffect(() => {
    requestFilters("/establishments/filters/");
  }, []);

  useEffect(() => {
    if (dataCasinos) {
      return;
    }
    requestCasinos("/casinos/");
  }, [dataCasinos]);

  useEffect(() => {
    if (!dataFilters) {
      return;
    }
    setOptions(setFilterOptions(dataFilters.cities));
  }, [dataFilters]);

  useEffect(() => {
    navigate(path.type + path.city);
  }, [path]);

  const setFilterOptions = (cities) => {
    let arr = [{ value: "", label: "Вся країна" }];

    cities.forEach((city) => {
      arr.push({ value: city.slug + "/", label: city.name });
    });
    return arr;
  };

  useEffect(() => {
    if (!loadMoreParams) {
      return;
    }
    request(loadMoreParams);
  }, [loadMoreParams]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!loadMoreParams) {
      setDataToRender(data?.results);
    } else {
      setDataToRender((prev) => [...prev, ...data?.results]);
      setLoadMoreParams(null);
    }
  }, [data]);

  const handleClearFilters = () => {
    setPath({
      type: "/hralni-zaklady-ukrainy/",
      city: "",
    });
  };

  const findNameBySlug = (array, slugToFind) => {
    const foundObject = array.find((obj) => obj.slug + "/" === slugToFind);
    return foundObject ? foundObject.title : null;
  };

  const loadMoreEstab = (data) => {
    setLoadMoreParams(data?.next?.split("https://betraisers.com/api")[1]);
  };

  return (
    <>
      <SeoFields
        title={
          (path?.type === "/hralni-zaklady-ukrainy/" &&
            `Гральні заклади, покер клуби, казино ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }`) ||
          (path?.type === "/pokerkluby/" &&
            `Покер клуби ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }`) ||
          (path?.type === "/kazyno/" &&
            `Казино ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }`) ||
          (path?.type === "/inshe/" &&
            `Інші гральні заклади ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }`)
        }
        description={
          (path?.type === "/hralni-zaklady-ukrainy/" &&
            `Список гральних закладів ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }, номера телефонів, адреси, контакти, відгуки та оцінки відвідувачів`) ||
          (path?.type === "/pokerkluby/" &&
            `Список покер клубів ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }, номера телефонів, адреси, контакти, відгуки та оцінки відвідувачів`) ||
          (path?.type === "/kazyno/" &&
            `Список казино ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }, номера телефонів, адреси, контакти, відгуки та оцінки відвідувачів`) ||
          (path?.type === "/inshe/" &&
            `Інші гральні заклади ${
              (dataFilters?.cities &&
                findNameBySlug(dataFilters?.cities, path.city)) ||
              "України"
            }, номера телефонів, адреси, контакти, відгуки та оцінки відвідувачів`)
        }
        keywords={null}
        robots="index, follow"
        canonical={`https://betraisers.com${path?.type}${path?.city}`}
      />
      <Left>
        <StyledPageTitle>
          Кращі{" "}
          {(dataFilters?.types &&
            findNameBySlug(dataFilters?.types, path.type.slice(1))) ||
            "гральні заклади"}{" "}
          {(dataFilters?.cities &&
            findNameBySlug(dataFilters?.cities, path.city)) ||
            "України"}
        </StyledPageTitle>
        <div
          style={{
            display: "flex",
            marginBottom: "2rem",
            marginTop: "2rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <StyledRoomsFilterList
            style={{
              width: isMobile ? "100%" : "max-content",
              justifyContent: "flex-start",
              marginRight: isMobile ? "0rem" : "2rem",
              marginTop: "0",
            }}
          >
            <StyledRoomsFilter
              style={{
                padding: "1rem 2rem",
                whiteSpace: "nowrap",
                width: isMobile ? "100%" : "max-content",
              }}
              end
              to={`/hralni-zaklady-ukrainy/${path.city}`}
              onClick={() =>
                setPath((prev) => ({
                  ...prev,
                  type: "/hralni-zaklady-ukrainy/",
                }))
              }
            >
              Усі {!isMobile && "заклади"}
            </StyledRoomsFilter>

            <StyledRoomsFilter
              to={`/pokerkluby/${path.city}`}
              style={{
                padding: "1rem 2rem",
                whiteSpace: "nowrap",
                width: "max-content",
              }}
              onClick={() =>
                setPath((prev) => ({ ...prev, type: "/pokerkluby/" }))
              }
            >
              Покер
            </StyledRoomsFilter>
            <StyledRoomsFilter
              to={`/kazyno/${path.city}`}
              style={{
                padding: "1rem 2rem",
                whiteSpace: "nowrap",
                width: "max-content",
              }}
              onClick={() => setPath((prev) => ({ ...prev, type: "/kazyno/" }))}
            >
              Казино
            </StyledRoomsFilter>
            <StyledRoomsFilter
              to={`/inshe/${path.city}`}
              style={{
                padding: "1rem 2rem",
                whiteSpace: "nowrap",
                width: "max-content",
              }}
              onClick={() => setPath((prev) => ({ ...prev, type: "/inshe/" }))}
            >
              Інше
            </StyledRoomsFilter>
          </StyledRoomsFilterList>
          <Select
            isClearable={false}
            isSearchable={false}
            defaultValue={() => {
              return {
                value: "/establishment/",
                label: "Місто",
              };
            }}
            placeholder="Місто"
            options={options}
            value={
              options &&
              options?.filter((option) => {
                return option.value === path.city;
              })
            }
            onChange={(option) => {
              setPath((prev) => ({ ...prev, city: option.value }));
            }}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  fontSize: "1.4rem",
                  lineHeight: "1.2",
                  color: "#000000",
                  padding: "0 2rem",
                };
              },
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  bottom: "0",
                  border: "0",
                  borderRadius: "0.8rem",
                  boxShadow: "none",
                  color: "#0b5acd",
                  backgroundColor: "rgba(49, 135, 238, 0.1)",
                  fontSize: "1.4rem",
                  lineHeight: "1.2",
                };
              },
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                color: "#0b5acd",
                fontSize: "1.4rem",
                lineHeight: "1.2",
                padding: "1rem 2rem",
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                display: "none",
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "white",
                borderRadius: "0.8rem",
                fontSize: "1.5rem",
                zIndex: "33",
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                maxHeight: "none",
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                padding: "1.5rem 1.5rem",
                color: "#000000",
                backgroundColor: "none",
                "&:hover": {
                  backgroundColor: "rgba(0, 41, 91, 0.09);",
                },
              }),
            }}
          />
          <Button
            icon={<AiOutlineDelete />}
            style={{
              margin: "0",
              marginLeft: "auto",
              marginTop: isMobile ? "1rem" : "0rem",
              fontSize: "1.4rem",
              lineHeight: "1.2",
              color: "#000000",
              letterSpacing: "0",
              borderRadius: "0.8rem",
            }}
            styled={StyledRestorePasswordBtn}
            text="Очистити"
            onClick={(e) => handleClearFilters(e)}
          />
        </div>
        <div style={{ width: "100%" }}>
          {dataToRender &&
            dataToRender.map((estab) => {
              return <EstablishmentListBlock estab={estab} />;
            })}
          {data?.next && (
            <Button
              styled={StyledLoadMoreBtn}
              onClick={() => loadMoreEstab(data)}
              disabled={loading}
              text={!loading && "Показати більше"}
              icon={
                loading && <Spinner style={{ width: "2rem", height: "2rem" }} />
              }
            />
          )}
          {data && data.count == 0 && <EstablishmentNoResults />}
        </div>
      </Left>
      <Right>
        {dataCasinos && (
          <StyledContainer>
            <StyledTitle>🎰 Казино онлайн</StyledTitle>

            {dataCasinos?.results.map((room, i) => {
              if (i >= 5) {
                return;
              }
              return (
                <Link key={i} to={`/casinos/${room.slug}/`}>
                  <StyledCardContainer>
                    <StyledCard>
                      <StyledLogoSide src={room?.logo_small} alt={room?.name} />
                      <StyledInnerContainer>
                        <StyledNameSide>{room?.name}</StyledNameSide>
                        <StyledBonuses
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(room?.bonus),
                          }}
                        ></StyledBonuses>
                      </StyledInnerContainer>
                    </StyledCard>
                  </StyledCardContainer>
                </Link>
              );
            })}
          </StyledContainer>
        )}
        <StyledStickyContainer>
          <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
          <AdsSide />
        </StyledStickyContainer>
      </Right>
    </>
  );
}

export default Establishments;
