import React from "react";
import Subsection from "./Subsection";
import { StyledSubsectionList } from "../styles/forum/SubsectionList.styled";

function SubsectionList({data}) {
  return (
    <StyledSubsectionList>
        {data?.map((item) => {
            return <Subsection data={item} />
        })}
    </StyledSubsectionList>
  );
}

export default SubsectionList;
