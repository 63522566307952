import styled from "styled-components";

export const StyledFreerollFilter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #f2f5f7;
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 1.3rem;
  margin-top: 2rem;
  gap: 1rem;

  @media (max-width: 767px) {
    align-items: inherit;
    flex-direction: column;
  }

  label {
    color: rgba(0, 0, 0, 0.5);
  }

  input,
  select {
    font-family: "Montserrat";
    color: rgba(0, 0, 0, 0.5);
    background-color: #f2f5f7;
    border: 1px solid silver;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }

  input:focus {
    background-color: #ffffff;
    outline: none;
    border: none;
    border: 1px solid silver;
    color: #000000;
  }

  /* Basic styling */

  [type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
