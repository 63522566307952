import React from "react";
import InnerSubsection from "./InnerSubsection";
import { StyledInnerSubsectionList, StyledInnerSubsectionListName } from "../styles/forum/InnerSubsectionList.styled";

function InnerSubsectionList({ data }) {
  return (
    <div style={{marginBottom: "1rem"}}>
      <StyledInnerSubsectionListName>
        {/* {data?.name} */}
        Категорії
      </StyledInnerSubsectionListName>
      <StyledInnerSubsectionList>
        {data?.inner_subsections?.map((item) => {
          return <InnerSubsection data={item} />;
        })}
      </StyledInnerSubsectionList>
    </div>
  );
}

export default InnerSubsectionList;
