import React, { useState } from "react";
import {
  StyledForumPagination,
  StyledForumPaginationPage,
} from "../styles/pagination/ForumPagination.styled";
import { Link, useSearchParams } from "react-router-dom";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

function ForumPagination({ data }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let currentPage = +searchParams.get("page");

  let arrayToRender = [...Array(data?.page_count)];

  const funcArrayToRender = (arr) => {
    let currentPage = searchParams.get("page");

    let resArr = arr?.map((item, i) => {
      return {
        pageNumber: i + 1,
        link: "?page=" + (i + 1),
        isShow: i + 1 >= +currentPage - 1 && i + 1 <= +currentPage + 1,
        isActive:
          parseInt(searchParams.get("page")) === i + 1 ||
          (searchParams.get("page") === null && i + 1 === 1) ||
          (searchParams.get("page") === "last" && data?.page_count - 1 === i),
      };
    });

    if (resArr.length >= 2) {
      resArr[0].isShow = true;
      resArr[1].isShow = true;
      resArr[Math.ceil((resArr.length - 1) / 2)].isShow = true;
      resArr[resArr.length - 2].isShow = true;
      resArr[resArr.length - 1].isShow = true;
    }

    if (currentPage === "last") {
      resArr[resArr.length - 1].isShow = true;
    }

    return resArr;
  };
  console.log("search params in pag", searchParams.toString());
  return (
    <StyledForumPagination>
      <ul>
        {data?.previous && (
          <StyledForumPaginationPage>
            <Link
              active={true}
              to={`?page=${currentPage - 1}${
                searchParams.has("author__user__username")
                  ? "&author__user__username=" +
                    searchParams.get("author__user__username")
                  : ""
              }`}
            >
              <GrLinkPrevious />
            </Link>
          </StyledForumPaginationPage>
        )}
        {funcArrayToRender(arrayToRender).map((item, i) => {
          if (item?.isShow) {
            return (
              <StyledForumPaginationPage active={item?.isActive} key={i}>
                <Link
                  active={true}
                  to={`?page=${i + 1}${
                    searchParams.has("author__user__username")
                      ? "&author__user__username=" +
                        searchParams.get("author__user__username")
                      : ""
                  }`}
                >
                  {i + 1}
                </Link>
              </StyledForumPaginationPage>
            );
          }
        })}
        {data?.next && (
          <StyledForumPaginationPage>
            <Link
              active={true}
              to={`?page=${currentPage + 1}${
                searchParams.has("author__user__username")
                  ? "&author__user__username=" +
                    searchParams.get("author__user__username")
                  : ""
              }`}
            >
              <GrLinkNext />
            </Link>
          </StyledForumPaginationPage>
        )}
      </ul>
    </StyledForumPagination>
  );
}

export default ForumPagination;
