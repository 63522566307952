import styled from "styled-components";

export const StyledRoomSuggestionContainer = styled.div``;

export const StyledRoomSuggestionInnerContainer = styled.div`
  margin-right: 4rem;

  @media (max-width: 1000px) {
    margin-right: 1rem;
    margin-bottom: 2rem;
  }
`;

export const StyledRoomSuggestion = styled.div`
  display: flex;
  background-color: #f2f4f6;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const StyledRoomSuggestionLogo = styled.img`
  width: 14.5rem;
  min-width: 14.5rem;
  height: 9.5625rem;
  margin-right: 2rem;
  background: #000;
  border-radius: 2rem;
  padding: 3rem;
  object-fit: contain;

  @media (max-width: 1000px) {
    margin-right: 1rem;
  }
`;

export const StyledRoomSuggestionName = styled.div`
  font-size: 2rem;
  font-weight: 500;
  white-space: nowrap;
`;

export const StyledRoomSuggestionButtonsContainer = styled.div`
  margin-left: auto;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;
