import React, { useState } from "react";
import {
  StyledArticleContainer,
  StyledArticle__Date,
  StyledArticle__Image,
  StyledArticle__InnerContainer,
  StyledArticle__Label,
  StyledArticle__RoomLabel,
  StyledArticle__Title,
  StyledBlock,
  StyledBlockContainer,
  StyledBlock__Title,
  StyledBlock__TitleContainer,
  StyledBlock__TitleLink,
} from "../../componets/styles/pages/main/Block.styled";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function Publications({ publications }) {
  const [pub, setPub] = useState(publications?.publications);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledBlockContainer>
      <StyledBlock
        style={{
          width: isMobile ? "100%" : "60%",
          marginRight: isMobile ? "0" : "1rem",
        }}
      >
        <StyledBlock__TitleContainer>
          <StyledBlock__Title>Новини</StyledBlock__Title>
          <StyledBlock__TitleLink to="/publications/">
            > Усі публікації
          </StyledBlock__TitleLink>
        </StyledBlock__TitleContainer>
        <div>
          {pub
            ?.sort((a, b) => {
              return new Date(b?.date_published) - new Date(a?.date_published);
            })
            ?.map((p, i) => {
              if (i < 3) {
                return (
                  <StyledArticleContainer>
                    <StyledArticle__Image src={p?.image} alt={p?.title} />
                    <div>
                      <StyledArticle__InnerContainer>
                        <StyledArticle__Label>
                          {p?.category?.name}
                        </StyledArticle__Label>
                        <StyledArticle__Date>
                          <ReactTimeAgo
                            date={p?.date_published}
                            locale="uk-UK"
                            timeStyle={{
                              labels: "twitter-minute-now",
                            }}
                          />
                        </StyledArticle__Date>
                      </StyledArticle__InnerContainer>
                      <StyledArticle__Title
                        to={`/publications/${p?.category?.slug}/${p?.slug}/`}
                      >
                        {p?.title}
                      </StyledArticle__Title>
                    </div>
                  </StyledArticleContainer>
                );
              } else if (i >= 3 && i < 8) {
                return (
                  <StyledArticleContainer>
                    <div>
                      <StyledArticle__InnerContainer>
                        <StyledArticle__Label
                          style={{
                            fontSize: "1rem",
                          }}
                        >
                          {p?.category?.name}
                        </StyledArticle__Label>
                        <StyledArticle__Date>
                          <ReactTimeAgo
                            date={p?.date_published}
                            locale="uk-UK"
                            timeStyle={{
                              labels: "twitter-minute-now",
                            }}
                          />
                        </StyledArticle__Date>
                      </StyledArticle__InnerContainer>
                      <StyledArticle__Title
                        to={`/publications/${p?.category?.slug}/${p?.slug}/`}
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        {p?.title}
                      </StyledArticle__Title>
                    </div>
                  </StyledArticleContainer>
                );
              }
            })}
        </div>
      </StyledBlock>

      <StyledBlock
        style={{
          width: isMobile ? "100%" : "40%",
          marginRight: "0",
        }}
      >
        <StyledBlock__TitleContainer>
          <StyledBlock__Title>Акції</StyledBlock__Title>
          <StyledBlock__TitleLink to="/publications/promotions/">
            > Усі акції
          </StyledBlock__TitleLink>
        </StyledBlock__TitleContainer>
        <div>
          {publications?.promotions?.map((p) => {
            return (
              <StyledArticleContainer>
                {!isMobile && (
                  <Link to={`/publications/${p?.category?.slug}/${p?.slug}/`}>
                    <StyledArticle__Image
                      style={{ width: "10rem", maxWidth: "10rem", minWidth: "10rem",  }}
                      src={p?.image}
                      alt={p?.title}
                    />
                  </Link>
                )}
                <div>
                  <StyledArticle__InnerContainer>
                    <StyledArticle__RoomLabel
                      to={`/rooms/${p?.poker_room?.[0]?.slug}/`}
                    >
                      <img
                        src={p?.poker_room?.[0]?.logo_small}
                        alt={p?.poker_room?.[0]?.name}
                      />
                      <div>{p?.poker_room?.[0]?.name}</div>
                    </StyledArticle__RoomLabel>
                    <StyledArticle__Date>
                      <ReactTimeAgo
                        date={p?.date_published}
                        locale="uk-UK"
                        timeStyle={{
                          labels: "twitter-minute-now",
                        }}
                      />
                    </StyledArticle__Date>
                  </StyledArticle__InnerContainer>
                  <StyledArticle__Title
                    to={`/publications/${p?.category?.slug}/${p?.slug}/`}
                    style={{
                      fontSize: "1.4rem",
                    }}
                  >
                    {p?.title}
                  </StyledArticle__Title>
                </div>
              </StyledArticleContainer>
            );
          })}
        </div>
      </StyledBlock>
    </StyledBlockContainer>
  );
}

export default Publications;
