import styled from "styled-components";

export const StyledUploadImage = styled.div`
  display: flex;
  flex-direction: column;

  & img {
    max-height: 20rem;
    width: fit-content;
    max-width: 100%;
    border-radius: 1rem;
  }

  & svg {
    font-size: 3rem;
  }

  & input[type="file"] {
    display: none;
  }
`;
