import React from "react";
import {
  StyledTheme,
  StyledThemeSubsection,
  StyledThemeName,
  StyledThemeAuthor,
} from "../../styles/forum/side/Theme.styled";
import { FaRegEye } from "react-icons/fa";
import { FaRegMessage } from "react-icons/fa6";
import { BiDislike, BiLike } from "react-icons/bi";
import formateDate from "../../../utils/DateFormater";
import ReactTimeAgo from "react-time-ago";

function Theme({ item }) {
  return (
    <StyledTheme>
      <StyledThemeSubsection>{item?.subsection?.name}</StyledThemeSubsection>
      <StyledThemeName to={`/forum/theme/${item?.id}/?page=last`}>
        {item?.name}
      </StyledThemeName>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <StyledThemeAuthor>{item?.author?.user?.username}</StyledThemeAuthor>
        <div
          style={{
            color: "grey",
          }}
        >
          <ReactTimeAgo
            date={item?.date_creation}
            locale="uk-UK"
            timeStyle={{
              labels: "short",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontWeight: "500",
          color: "grey",
        }}
      >
        <div style={{ marginRight: "1rem" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "0.5rem",
              alignItems: "center",
              marginRight: "1rem",
              fontSize: "1.2rem",
            }}
          >
            <FaRegEye style={{ marginRight: "0.5rem" }} />
            {item?.view_count}
          </div>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "0.5rem",
              alignItems: "center",
              marginRight: "1rem",
              fontSize: "1.2rem",
            }}
          >
            <FaRegMessage style={{ marginRight: "0.5rem" }} />
            {item?.post_amount}
          </div>
        </div>

        <div style={{ marginRight: "1rem" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "0.5rem",
              alignItems: "center",
              marginRight: "1rem",
              fontSize: "1.2rem",
            }}
          >
            <BiLike style={{ marginRight: "0.5rem" }} />
            {item?.likes}
          </div>
        </div>

        <div style={{ marginRight: "1rem" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "0.5rem",
              alignItems: "center",
              marginRight: "1rem",
              fontSize: "1.2rem",
            }}
          >
            <BiDislike style={{ marginRight: "0.5rem" }} />
            {item?.dislikes}
          </div>
        </div>
      </div>
    </StyledTheme>
  );
}

export default Theme;
