import React from "react";
import { StyledRoomCardInfo } from "../styles/roomDetails/RoomCardInfo.styled";

function RoomCardInfo({keyy, value}) {
  return (
    <StyledRoomCardInfo>
      <div className="room-card-info__key">{keyy}</div>
      <div className="room-card-info__value">{value}</div>
    </StyledRoomCardInfo>
  );
}

export default RoomCardInfo;
