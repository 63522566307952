import React from "react";
import styled from "styled-components";

export const StyledRoomCardLogo = styled.img`
    width: 18.5rem;
    min-width: 18.5rem;
    height: 12.5625rem;
    background: #000;
    border-radius: 2rem;
    padding: 3rem;
    object-fit: contain;
`;