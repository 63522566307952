import React from "react";
import { Left } from "../componets/styles/shared/Left";
import {
  BiTime,
  BiCommentDetail,
  BiLike,
  BiDislike,
  BiShare,
} from "react-icons/bi";
import {
  StyledPublicationDetailImg,
  StyledPublicationDetailTitle,
  StyledPublicationDetailStat,
  StyledPublicationDetailInfo,
  StyledPublicationDetailBody,
  StyledPublicationLikeShareContainer,
  StyledPublicationLike,
  StyledPublicationShare,
  StyledPublicationLikeBtn,
  StyledPublicationShareBtn,
} from "../componets/styles/publication/PublicationDetails.styled";
import {
  StyledPublicationAuthor,
  StyledPublicationAuthorImg,
  StyledPublicationAuthorName,
} from "../componets/styles/publication/PublicationAuthor.styled";
import { StyledCommentsTitle } from "../componets/styles/comments/Comments.styled";
import Button from "../componets/Button";
import { StyledLoadMoreBtn } from "../componets/styles/shared/LoadMoreBtn.styled";
import {
  FaFacebook,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import RichTxtEditor from "../componets/richTxtEditor/RichTxtEditor";
import { useContext } from "react";
import PublicationContext from "../componets/contexts/PublicationContext";
import useApi from "../services/useApi";
import {
  deleteComment,
  getComments,
  getPublication,
  postGrade,
} from "../services/publications";
import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../componets/shared/Spinner";
import CommentsList from "../componets/comments/CommentsList";
import { useState } from "react";
import formateDate from "../utils/DateFormater";
import { StyledRestorePasswordBtn } from "../componets/styles/Button.styled";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import AuthenticationContext from "../componets/contexts/AuthenticationContext";
import PleaseLogin from "../componets/shared/PleaseLogin";
import Error404 from "../componets/shared/Error404";
import SeoFields from "../componets/shared/SeoFields";
import * as DOMPurify from "dompurify";
import RoomSuggestion from "../componets/roomDetails/RoomSuggestion";
import { Right } from "../componets/styles/shared/Right";
import {
  StyledContainer,
  StyledTitle,
  StyledCardContainer,
  StyledCard,
  StyledLogo as StyledLogoSide,
  StyledInnerContainer,
  StyledName as StyledNameSide,
  StyledBonuses,
  StyledStickyContainer,
} from "../componets/styles/roomRecomend/RoomRecomend.styled";
import AdsSide from "../componets/shared/AdsSide";
import { getRooms } from "../services/rooms";

function PublicationDetails() {
  const { listComments, setListComments } = useContext(PublicationContext);
  const { state } = useContext(AuthenticationContext);
  let location = useLocation();
  const [page, setPage] = useState(1);
  const [fullGrade, setFullGrade] = useState(null);
  const [grade, setGrade] = useState(null);
  const [gradeStatus, setGradeStatus] = useState(null);
  const myRef = useRef(null);

  const { data, loading, error, request } = useApi(getPublication);

  const {
    data: dataComments,
    loading: loadingComments,
    error: errorComments,
    request: requestComments,
  } = useApi(getComments);

  const {
    data: dataGrade,
    loading: loadingGrade,
    error: errorGrade,
    request: requestGrade,
  } = useApi(postGrade);

  const {
    data: dataRooms,
    loading: loadingRooms,
    error: errorRooms,
    request: requestRooms,
  } = useApi(getRooms);

  useEffect(() => {
    if (dataRooms) {
      return;
    }
    requestRooms("/rooms/");
  }, [dataRooms]);

  useEffect(() => {
    request(location.pathname);
  }, []);

  useEffect(() => {
    if (page > 1) {
      // requestComments(`${location.pathname}/comments/`, {
      requestComments(
        `/publications/${data?.category?.slug}/${data?.slug}/comments/`,
        {
          params: { page: page },
        }
      );
    }
  }, [page]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setListComments({
      comments_count: data?.comments_count,
      comments: data?.publication_comments,
    });
    setGradeStatus(data?.likes_dislikes?.user_vote);
    setFullGrade(data?.likes_dislikes);
  }, [data]);

  useEffect(() => {
    setListComments(dataComments);
  }, [dataComments]);

  useEffect(() => {
    if (grade === null) return;

    if (grade) {
      requestGrade(
        `/publications/${data?.category?.slug}/${data?.slug}/grade/`,
        { params: { k: "like" } }
      );
      // requestGrade(`${location.pathname}/grade/`, { params: { k: "like" } });
    }

    if (grade === false) {
      requestGrade(
        `/publications/${data?.category?.slug}/${data?.slug}/grade/`,
        { params: { k: "dislike" } }
      );
      // requestGrade(`${location.pathname}/grade/`, { params: { k: "dislike" } });
    }
    setGrade(null);
    setGradeStatus(null);
  }, [grade]);

  useEffect(() => {
    if (!dataGrade) {
      return;
    }
    setGradeStatus(dataGrade?.user_vote);
    setFullGrade(dataGrade);
  }, [dataGrade]);

  const handleLoadMoreComments = (e) => {
    e.preventDefault();
    setPage((prev) => prev + 1);
  };

  const handleLikeClick = (e) => {
    e.preventDefault();
    setGrade(true);
  };

  const handleDisLikeClick = (e) => {
    e.preventDefault();
    setGrade(false);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error404 />;
  }

  return (
    data && (
      <>
        <SeoFields
          title={data?.seo_title}
          description={data?.seo_description}
          keywords={data?.seo_keywords}
          robots={data?.seo_robots}
          canonical={data?.seo_canonical}
          data={data}
        />
        <Left>
          <StyledPublicationDetailImg src={data.image} alt={data.title} />
          <StyledPublicationDetailTitle>
            {data.title}
          </StyledPublicationDetailTitle>
          <StyledPublicationDetailStat>
            <div
              style={{ width: "100%", display: "flex", alignItems: "flex-end" }}
            >
              <StyledPublicationAuthor>
                <StyledPublicationAuthorImg
                  src={data?.user?.photo}
                  alt={data?.user?.user?.username}
                />
                <StyledPublicationAuthorName>
                  <div>Автор</div>
                  <p>{data.user.user.username}</p>
                </StyledPublicationAuthorName>
              </StyledPublicationAuthor>
              <StyledPublicationDetailInfo>
                <BiTime />
                {formateDate(data.date_published)}
              </StyledPublicationDetailInfo>
            </div>
            <div
              style={{ width: "100%", display: "flex", alignItems: "flex-end" }}
            >
              <StyledPublicationDetailInfo>
                <BiCommentDetail />
                {listComments?.comments_count || 0}
              </StyledPublicationDetailInfo>
              <StyledPublicationDetailInfo>
                <BiShare />
                {data.share_counter}
              </StyledPublicationDetailInfo>
              <StyledPublicationDetailInfo>
                <BiLike />
                {fullGrade?.likes_count}
              </StyledPublicationDetailInfo>
              <StyledPublicationDetailInfo>
                <BiDislike />
                {fullGrade?.dislikes_count}
              </StyledPublicationDetailInfo>
            </div>
          </StyledPublicationDetailStat>

          <StyledPublicationDetailBody
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                data.body.replaceAll("<p>&nbsp;</p>", "")
              ),
            }}
          ></StyledPublicationDetailBody>

          <StyledPublicationLikeShareContainer>
            <StyledPublicationLike>
              <div style={{ marginRight: "2rem" }}>Вам сподобалась стаття?</div>
              <div style={{ display: "flex" }}>
                <Button
                  style={gradeStatus === true ? { color: "green" } : {}}
                  styled={StyledPublicationLikeBtn}
                  icon={<BiLike />}
                  // text={`${
                  //   dataGrade?.likes_count || data?.likes_dislikes?.likes_count
                  // }`}
                  text={fullGrade?.likes_count}
                  onClick={(e) => handleLikeClick(e)}
                />
                <Button
                  style={gradeStatus === false ? { color: "red" } : {}}
                  styled={StyledPublicationLikeBtn}
                  icon={<BiDislike />}
                  // text={`${
                  //   dataGrade?.dislikes_count ||
                  //   data?.likes_dislikes?.dislikes_count
                  // }`}
                  text={fullGrade?.dislikes_count}
                  onClick={(e) => handleDisLikeClick(e)}
                />
              </div>
            </StyledPublicationLike>
            <StyledPublicationShare>
              <div>Поділіться</div>
              <TelegramShareButton
                url={`https://betraisers.com/publications/${data?.category?.slug}/${data?.slug}/`}
              >
                <Button
                  icon={<FaTelegramPlane />}
                  styled={StyledPublicationShareBtn}
                  className="telegram"
                />
              </TelegramShareButton>

              <WhatsappShareButton
                url={`https://betraisers.com/publications/${data?.category?.slug}/${data?.slug}/`}
              >
                <Button
                  icon={<FaWhatsapp />}
                  styled={StyledPublicationShareBtn}
                  className="whatsup"
                />
              </WhatsappShareButton>
              <TwitterShareButton
                url={`https://betraisers.com/publications/${data?.category?.slug}/${data?.slug}/`}
              >
                <Button
                  icon={<FaTwitter />}
                  styled={StyledPublicationShareBtn}
                  className="twitter"
                />
              </TwitterShareButton>
              <FacebookShareButton
                url={`https://betraisers.com/publications/${data?.category?.slug}/${data?.slug}/`}
              >
                <Button
                  icon={<FaFacebook />}
                  styled={StyledPublicationShareBtn}
                  className="facebook"
                />
              </FacebookShareButton>
            </StyledPublicationShare>
          </StyledPublicationLikeShareContainer>

          <RoomSuggestion rooms={data?.poker_room} />

          <StyledCommentsTitle ref={myRef}>
            Коментарі ({listComments?.comments_count || 0})
          </StyledCommentsTitle>

          {state?.currentUser && state?.isLoggedIn ? (
            <RichTxtEditor
              slug={`/publications/${data?.category?.slug}/${data?.slug}/`}
              page={page}
              refToScrollTo={myRef}
            />
          ) : (
            <PleaseLogin />
          )}

          <CommentsList
            comments={listComments?.comments}
            page={page}
            refToScrollTo={myRef}
          />

          {listComments?.comments_count !== listComments?.comments?.length && (
            <Button
              styled={StyledLoadMoreBtn}
              disabled={loadingComments}
              text={!loadingComments && "Показати більше"}
              icon={
                loadingComments && (
                  <Spinner style={{ width: "2rem", height: "2rem" }} />
                )
              }
              onClick={handleLoadMoreComments}
            />
          )}
        </Left>
        <Right>
          {dataRooms && (
            <StyledContainer>
              <StyledTitle>⭐️ Рекомендовані покер-руми</StyledTitle>

              {dataRooms?.results.map((room, i) => {
                if (i >= 10) {
                  return;
                }
                return (
                  <Link key={i} to={`/rooms/${room.slug}/`}>
                    <StyledCardContainer>
                      <StyledCard>
                        <StyledLogoSide
                          src={room?.logo_small}
                          alt={room?.name}
                        />
                        <StyledInnerContainer>
                          <StyledNameSide>{room?.name}</StyledNameSide>
                          <StyledBonuses>{room?.first_bonus}</StyledBonuses>
                        </StyledInnerContainer>
                      </StyledCard>
                    </StyledCardContainer>
                  </Link>
                );
              })}
            </StyledContainer>
          )}
          <StyledStickyContainer>
            <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
            <AdsSide />
          </StyledStickyContainer>
        </Right>
      </>
    )
  );
}

export default PublicationDetails;
