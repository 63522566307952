import React, { useContext, useEffect, useRef, useState } from "react";
import { Left } from "../componets/styles/shared/Left";
import ForumRichTextEditor from "../componets/richTxtEditor/ForumRichTextEditor";
import Input from "../componets/form/Input";
import Button from "../componets/Button";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import Spinner from "../componets/shared/Spinner";
import { StyledPageTitle } from "../componets/styles/shared/PageTitle.styled";
import useApi from "../services/useApi";
import { postTheme } from "../services/forum";
import ForumContext from "../componets/contexts/ForumContext";
import { useNavigate, useParams } from "react-router-dom";

function ForumCreatePost() {
  const { data, loading, error, request } = useApi(postTheme);
  const { dataPost, setDataPost } = useContext(ForumContext);
  const [dataToRequest, setDataToRequest] = useState(null);
  const themeRef = useRef();
  const { subsection } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataToRequest) {
      return;
    }
    request("/forum/themes/create/", dataToRequest);
  }, [dataToRequest]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setDataToRequest(null);
    setDataPost("");
    themeRef.current.value = "";
    navigate(`/forum/theme/${data?.id}/?page=1`);
  }, [data]);

  return (
    <>
      <Left>
        <StyledPageTitle style={{ marginBottom: "2rem" }}>
          Створення нової теми
        </StyledPageTitle>
        <Input
          label="Тема"
          style={{ fontWeight: "700" }}
          styleForFieldset={{ marginBottom: "2rem" }}
          refc={themeRef}
          required
          maxLength="160"
        />
        <ForumRichTextEditor />
        <Button
          disabled={loading}
          styled={StyledRedBtn}
          text={!loading && "Створити тему"}
          icon={
            loading && (
              <Spinner style={{ width: "2rem", height: "2rem", margin: "0" }} />
            )
          }
          onClick={() => {
            if (themeRef.current.value && dataPost) {
              setDataToRequest({
                name: themeRef.current.value,
                text: dataPost,
                subsection: subsection,
              });
            }
          }}
        />
      </Left>
    </>
  );
}

export default ForumCreatePost;
