import React, { useEffect, useRef, useState } from "react";

function AutoHeightTextarea(props) {
  const { style, sendDataToParent } = props;
  const textareaRef = useRef(null);

  useEffect(() => {
    function handleTextareaInput() {
      const textarea = textareaRef.current;

      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }

    function initializeAutoHeightTextarea() {
      const textarea = textareaRef.current;

      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflowY = "hidden";
    }

    const textarea = textareaRef.current;

    if (textarea) {
      initializeAutoHeightTextarea();
      textarea.addEventListener("input", handleTextareaInput);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener("input", handleTextareaInput);
      }
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <textarea
      style={style}
      ref={textareaRef}
      onChange={(e) => {
        sendDataToParent(e.target.value);
      }}
    />
  );
}

export default AutoHeightTextarea;
