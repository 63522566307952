import React from "react";
import { Helmet } from "react-helmet";

function SeoFields({
  title,
  description,
  canonical,
  robots,
  keywords,
  googleMicroData,
  data,
}) {
  return (
    <Helmet>
      {robots && <meta name="robots" content={robots}></meta>}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {googleMicroData}
      {canonical && <meta property="og:url" content={canonical} />}
      {canonical && <meta property="og:type" content="website" />}
      {title && <meta property="og:title" content={title} />}
      {description && (
        <meta property="og:description" content={description} />
      )}
      {data?.image && <meta property="og:image" content={data?.image} />}
      {title && <meta property="og:site_name" content="betraisers" />}
      {data?.image && <meta property="image" content={data?.image} />}
      {data?.image && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {title && <meta name="twitter:site" content="@betraisers" />}
      {title && <meta name="twitter:creator" content="@betraisers" />}
      {title && <meta name="twitter:title" content={title} />}
      {description && (
        <meta name="twitter:description" content={description} />
      )}
      {data?.image && <meta name="twitter:image" content={data?.image} />}
    </Helmet>
  );
}

export default SeoFields;
