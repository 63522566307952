import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PostList from "../componets/forum/PostList";
import { Left } from "../componets/styles/shared/Left";
import useApi from "../services/useApi";
import {
  getThemePostList,
  postThemePost,
  addInBookmarks,
  rateTheme,
  getSide,
} from "../services/forum";
import Spinner from "../componets/shared/Spinner";
import ForumPagination from "../componets/pagination/ForumPagination";
import NotFound from "./NotFound";
import { StyledPostTitle } from "../componets/styles/forum/PostList.styled";
import ForumBreadcrumbs from "../componets/breadcrumbs/ForumBreadcrumbs";
import ForumRichTextEditor from "../componets/richTxtEditor/ForumRichTextEditor";
import Button from "../componets/Button";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import ForumContext from "../componets/contexts/ForumContext";
import AuthenticationContext from "../componets/contexts/AuthenticationContext";
import { toast } from "react-toastify";
import { BiDislike, BiLike } from "react-icons/bi";
import { StyledPublicationLikeBtn } from "../componets/styles/publication/PublicationDetails.styled";
import { StyledForumFilterBtn } from "../componets/styles/Button.styled";
import PleaseLogin from "../componets/shared/PleaseLogin";
import { Right } from "../componets/styles/shared/Right";
import SideBlock from "../componets/forum/SideBlock";
import List from "../componets/forum/side/List";
import Post from "../componets/forum/side/Post";
import Theme from "../componets/forum/side/Theme";
import AdsSide from "../componets/shared/AdsSide";
import {
  StyledStickyContainer,
  StyledTitle,
} from "../componets/styles/roomRecomend/RoomRecomend.styled";
import { useMediaQuery } from "react-responsive";
import { FaRegUser, FaUser, FaRegBookmark, FaBookmark } from "react-icons/fa6";

function ForumPostList() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { state } = useContext(AuthenticationContext);
  const { dataPost, setDataPost, dataEditPost } = useContext(ForumContext);

  const navigate = useNavigate();
  const { themeId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const [postsToRender, setPostsToRender] = useState(null);
  const [dataToRequest, setDataToRequest] = useState(null);
  const [isJustAuthor, setIsJustAuthor] = useState(false);

  const [userVote, setUserVote] = useState(null);

  const [likesDislikes, setLikesDislikes] = useState(null);

  const {
    data: rateData,
    loading: rateLoading,
    error: rateError,
    request: rateRequest,
  } = useApi(rateTheme);

  const {
    data: dataSide,
    loading: loadingSide,
    error: errorSide,
    request: requestSide,
  } = useApi(getSide);

  const [isInBookmarks, setIsInBookmarks] = useState(false);

  const [bookmarkState, setBookmarkState] = useState({
    inBookmarks: null,
    count: null,
    text: null,
    typeRequest: null,
  });

  const refEditor = useRef(null);

  const { data, loading, error, request } = useApi(getThemePostList);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    request: postRequest,
  } = useApi(postThemePost);

  const {
    data: bookmarksData,
    loading: bookmarksLoading,
    error: bookmarksError,
    request: bookmarksRequest,
  } = useApi(addInBookmarks);

  useEffect(() => {
    if (!userVote) {
      return;
    }
    rateRequest("/forum/themes/rate/", userVote);
    setUserVote(null);
  }, [userVote]);

  useEffect(() => {
    if (!rateData) {
      return;
    }
    setLikesDislikes({
      l: rateData?.likes,
      d: rateData?.dislikes,
      v: rateData?.is_voted,
    });
  }, [rateData]);

  useEffect(() => {
    if (!themeId) {
      return;
    }
    request(`/forum/posts/?theme__id=${themeId}&${searchParams.toString()}`);
    requestSide("/forum/side/");
  }, [themeId, searchParams]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setPostsToRender(data?.results);
    setIsInBookmarks(
      data?.results?.[0]?.theme?.is_in_bookmarks > 0 ? true : false
    );
    setBookmarkState({
      inBookmarks:
        data?.results?.[0]?.theme?.is_in_bookmarks > 0 ? true : false,
      count: data?.results?.[0]?.theme?.bookmarks_count,
      text: (data?.results?.[0]?.theme?.is_in_bookmarks > 0 ? true : false)
        ? "З закладок"
        : "У закладки",
    });
    setLikesDislikes({
      l: data?.results?.[0]?.theme?.likes,
      d: data?.results?.[0]?.theme?.dislikes,
      v: data?.results?.[0]?.theme?.is_voted,
    });
  }, [data]);

  useEffect(() => {
    if (bookmarkState?.typeRequest) {
      bookmarksRequest("/forum/themes/bookmark/", {
        id: themeId,
        bookmark: bookmarkState.typeRequest,
      });
    }
  }, [bookmarkState.typeRequest]);

  useEffect(() => {
    if (!bookmarksData) {
      return;
    }
    setBookmarkState({
      inBookmarks: bookmarksData?.is_in_bookmarks > 0 ? true : false,
      count: bookmarksData?.bookmarks_count,
      text: (bookmarksData?.is_in_bookmarks > 0 ? true : false)
        ? "З закладок"
        : "У закладки",
    });
  }, [bookmarksData]);

  useEffect(() => {
    if (!dataToRequest) {
      return;
    }
    postRequest("/forum/posts/create/", dataToRequest);
  }, [dataToRequest]);

  useEffect(() => {
    if (!postData) {
      return;
    }
    setDataToRequest(null);
    setDataPost("");
    request(`/forum/posts/?theme__id=${themeId}&page=last`);
    navigate(`/forum/theme/${themeId}/?page=last`);
    toast.success(<div>Пост додано!</div>);
  }, [postData]);

  useEffect(() => {
    if (isJustAuthor) {
      setSearchParams({
        ...searchParams,
        author__user__username:
          data?.results?.[0]?.theme?.author?.user?.username,
        // "anton"
      });
    } else {
      let tmp = Object.fromEntries(searchParams);
      delete tmp.author__user__username;
      setSearchParams(tmp);
    }
  }, [isJustAuthor]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <NotFound />;
  }

  const handleBookmarkClick = () => {
    if (bookmarkState?.inBookmarks) {
      setBookmarkState({ ...bookmarkState, typeRequest: "delete" });
    } else {
      setBookmarkState({ ...bookmarkState, typeRequest: "add" });
    }
  };

  const collectDataToRequest = () => {
    if (!dataPost || dataPost === "") {
      return;
    }
    return {
      author: state?.currentUser?.pk,
      theme: themeId,
      subsection: data?.results?.[0]?.subsection?.id,
      section: data?.results?.[0]?.section?.id,
      text: dataPost,
    };
  };

  return (
    <>
      <Left>
        {data && (
          <>
            <ForumBreadcrumbs
              data={[
                {
                  name: "Форум",
                  slug: "/forum/",
                },
                {
                  name: data?.results?.[0]?.subsection?.name,
                  slug: `/forum/${data?.results?.[0]?.subsection?.slug}/`,
                },
                data?.results?.[0]?.theme?.inner_subsection && {
                  name: data?.results?.[0]?.theme?.inner_subsection?.name,
                  slug: `/forum/${data?.results?.[0]?.subsection?.slug}/${data?.results?.[0]?.theme?.inner_subsection?.id}/`,
                },
                {
                  name: data?.results?.[0]?.theme?.name,
                  slug: `/forum/theme/${data?.results?.[0]?.theme?.id}/?page=1`,
                },
              ]}
            />
            <StyledPostTitle>{data?.results?.[0]?.theme?.name}</StyledPostTitle>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: isMobile ? "wrap" : "inherit",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                  color: "rgb(121, 122, 123)",
                  width: "100%",
                }}
              >
                {isMobile ? (
                  <Button
                    icon={
                      isJustAuthor ? (
                        <FaUser color="#ed4040" />
                      ) : (
                        <FaRegUser color="rgb(121, 122, 123)" />
                      )
                    }
                    styled={StyledPublicationLikeBtn}
                    style={{ margin: "0" }}
                    isActive={isJustAuthor}
                    onClick={() => {
                      setIsJustAuthor((prev) => !prev);
                    }}
                  />
                ) : (
                  <Button
                    text="Тільки автор"
                    styled={StyledForumFilterBtn}
                    isActive={isJustAuthor}
                    onClick={() => {
                      setIsJustAuthor((prev) => !prev);
                    }}
                  />
                )}

                {isMobile ? (
                  <Button
                    text={bookmarkState?.count}
                    icon={
                      bookmarkState?.inBookmarks ? (
                        <FaBookmark color="#ed4040" />
                      ) : (
                        <FaRegBookmark color="rgb(121, 122, 123)" />
                      )
                    }
                    styled={StyledPublicationLikeBtn}
                    style={{ color: "rgb(121, 122, 123)", margin: "0" }}
                    isActive={bookmarkState?.inBookmarks}
                    onClick={() => handleBookmarkClick()}
                  />
                ) : (
                  <Button
                    text={`${bookmarkState?.text} (${bookmarkState?.count})`}
                    styled={StyledForumFilterBtn}
                    isActive={bookmarkState?.inBookmarks}
                    onClick={() => handleBookmarkClick()}
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <Button
                    icon={
                      <BiLike
                        color={
                          +likesDislikes?.v > 0 ? "green" : "rgb(121, 122, 123)"
                        }
                      />
                    }
                    styled={StyledPublicationLikeBtn}
                    text={likesDislikes?.l}
                    style={{ color: "green", margin: "0" }}
                    onClick={() => {
                      setUserVote({
                        id: data?.results?.[0]?.theme?.id,
                        vote: "like",
                      });
                    }}
                  />
                  <Button
                    icon={
                      <BiDislike
                        color={
                          +likesDislikes?.v < 0 ? "red" : "rgb(121, 122, 123)"
                        }
                      />
                    }
                    styled={StyledPublicationLikeBtn}
                    text={likesDislikes?.d}
                    style={{ color: "red", margin: "0" }}
                    onClick={() => {
                      setUserVote({
                        id: data?.results?.[0]?.theme?.id,
                        vote: "dislike",
                      });
                    }}
                  />
                </div>
              </div>
              <ForumPagination data={data} />
            </div>
            <PostList data={postsToRender} refToScrollTo={refEditor} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: isMobile ? "wrap" : "inherit",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                  color: "rgb(121, 122, 123)",
                  width: "100%",
                }}
              >
                {isMobile ? (
                  <Button
                    icon={
                      isJustAuthor ? (
                        <FaUser color="#ed4040" />
                      ) : (
                        <FaRegUser color="rgb(121, 122, 123)" />
                      )
                    }
                    styled={StyledPublicationLikeBtn}
                    style={{ margin: "0" }}
                    isActive={isJustAuthor}
                    onClick={() => {
                      setIsJustAuthor((prev) => !prev);
                    }}
                  />
                ) : (
                  <Button
                    text="Тільки автор"
                    styled={StyledForumFilterBtn}
                    isActive={isJustAuthor}
                    onClick={() => {
                      setIsJustAuthor((prev) => !prev);
                    }}
                  />
                )}

                {isMobile ? (
                  <Button
                    text={bookmarkState?.count}
                    icon={
                      bookmarkState?.inBookmarks ? (
                        <FaBookmark color="#ed4040" />
                      ) : (
                        <FaRegBookmark color="rgb(121, 122, 123)" />
                      )
                    }
                    styled={StyledPublicationLikeBtn}
                    style={{ color: "rgb(121, 122, 123)", margin: "0" }}
                    isActive={bookmarkState?.inBookmarks}
                    onClick={() => handleBookmarkClick()}
                  />
                ) : (
                  <Button
                    text={`${bookmarkState?.text} (${bookmarkState?.count})`}
                    styled={StyledForumFilterBtn}
                    isActive={bookmarkState?.inBookmarks}
                    onClick={() => handleBookmarkClick()}
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <Button
                    icon={
                      <BiLike
                        color={
                          +likesDislikes?.v > 0 ? "green" : "rgb(121, 122, 123)"
                        }
                      />
                    }
                    styled={StyledPublicationLikeBtn}
                    text={likesDislikes?.l}
                    style={{ color: "green", margin: "0" }}
                    onClick={() => {
                      setUserVote({
                        id: data?.results?.[0]?.theme?.id,
                        vote: "like",
                      });
                    }}
                  />
                  <Button
                    icon={
                      <BiDislike
                        color={
                          +likesDislikes?.v < 0 ? "red" : "rgb(121, 122, 123)"
                        }
                      />
                    }
                    styled={StyledPublicationLikeBtn}
                    text={likesDislikes?.d}
                    style={{ color: "red", margin: "0" }}
                    onClick={() => {
                      setUserVote({
                        id: data?.results?.[0]?.theme?.id,
                        vote: "dislike",
                      });
                    }}
                  />
                </div>
              </div>
              <ForumPagination data={data} />
            </div>
            <div ref={refEditor}></div>
            {!dataEditPost &&
              (state?.currentUser && state?.isLoggedIn ? (
                <div style={{ marginTop: "2rem" }}>
                  <ForumRichTextEditor />
                  <Button
                    disabled={postLoading}
                    styled={StyledRedBtn}
                    text={!postLoading && "Відправити"}
                    icon={
                      postLoading && (
                        <Spinner
                          style={{ width: "2rem", height: "2rem", margin: "0" }}
                        />
                      )
                    }
                    onClick={() => setDataToRequest(collectDataToRequest())}
                  />
                </div>
              ) : (
                <PleaseLogin
                  style={{ marginTop: "2rem", width: "100%" }}
                  text="Будь ласка, зареєструйтесь або зайдіть, щоб мати можливість публікувати пости на форумі."
                />
              ))}
          </>
        )}
      </Left>
      <Right>
        {loadingSide && <Spinner />}
        {dataSide && (
          <>
            <SideBlock title="Останні пости">
              <List item={Post} data={dataSide?.last_posts} />
            </SideBlock>
            <SideBlock title="Нові теми">
              <List item={Theme} data={dataSide?.last_themes} />
            </SideBlock>
          </>
        )}
        <StyledStickyContainer>
          <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
          <AdsSide />
        </StyledStickyContainer>
      </Right>
    </>
  );
}

export default ForumPostList;
