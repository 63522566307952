import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledRoomsFilterList = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const StyledRoomsFilter = styled(NavLink)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: 0.25s;
  text-decoration: none;
  overflow: visible;
  position: relative;
  /* display: inline-block; */
  vertical-align: middle;
  font-weight: 400;
  padding: 1rem 2.5rem;
  margin-left: 0.1rem;
  font-size: 1.4rem;
  line-height: 1.2;
  color: #000000;
  background-color: rgba(0, 41, 91, 0.06);
  text-align: center;

  &:first-child {
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    margin-left: 0;
  }

  &:last-child {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }

  &.active {
    color: #0b5acd;
    background-color: rgba(49, 135, 238, 0.1);
  }

  @media (max-width: 767px) {
    padding: 0.5rem 1rem;
  }
`;

export const StyledSearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: 0.25s;
  position: relative;
  font-weight: 400;
  padding: 1rem 2.5rem;
  margin-left: 0.1rem;
  font-size: 2em;
  line-height: 1.28;
  color: #000000;
  background-color: rgba(0, 41, 91, 0.06);
  text-align: center;

  &:last-child {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }
`;

export const StyledSearchContainer = styled.div`
  position: relative;
  margin: 0 0 2rem 0;
  height: 5.68rem;

  @media (max-width: 767px) {
    position: relative;
    margin: 0 0 2rem 0;
    height: 4rem;
  }
`;

export const StyledSearchInput = styled.input`
  height: 5.68rem;
  width: 100%;
  background-color: #e9edf0;
  border: none;
  border-radius: 1rem;
  text-align: center;
  outline-width: 0px;
  font-size: 2.4rem;
  padding: 1rem 7rem;
  /* box-shadow: 0 0.5rem 0 0 white; */

  @media (max-width: 767px) {
    height: 4rem;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
  }
`;
