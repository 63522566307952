import React from "react";
import styled from "styled-components";

export const StyledRoomInstruction = styled.div`
  background: linear-gradient(
    270deg,
    rgb(98, 142, 207) 0.46%,
    rgb(126, 195, 217) 78.27%,
    rgb(148, 229, 234) 142%
  );
  border-radius: 1.4rem;
  padding: 2rem 3rem 3rem;
  counter-reset: numberedCounter;

  h1 {
    font-size: 2.2rem;
    line-height: 1.27;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  & .main_container {
    display: flex;
    position: relative;
    margin-bottom: 3rem;
  }

  & .main_container::before {
    font-size: 1.7rem;
    line-height: 1.41;
    font-weight: bold;
    letter-spacing: normal;
    word-wrap: break-word;
    counter-increment: numberedCounter;
    content: counter(numberedCounter);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.4rem;
    height: 3.4rem;
    margin-right: 1.2rem;
    padding-top: 0.1rem;
    color: #ffffff;
    background-color: #3a3a3c;
    border-radius: 50%;
  }

  & .main_container:last-child::before {
    background-color: #33B327;
  }

  & .main_container::after {
    content: '';
    position: absolute;
    top: 3.4rem;
    bottom: -3rem;
    left: 1.7rem;
    width: 0.1rem;
    background-color: #3a3a3c;
  }

  & .main_container:last-child::after {
    content: none;
  }

  h2 {
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 1.7rem;
    line-height: 1.41;
    font-weight: 700;
  }

  & .description {
    white-space: pre-line;
    font-size: 1.7rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }
`;
