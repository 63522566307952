import React, { useState } from "react";
import Button from "../../componets/Button";
import {
  StyledColorBlock,
  StyledListRoom,
  StyledRoomCardLogo,
  StyledColorBlockLink,
  StyledColorBlockLinkContainer,
} from "../../componets/styles/pages/main/MainPage.styled";
import { StyledRegistrationBtn } from "../../componets/styles/RegistrationBtn.styled";
import { StyledReviewBtn } from "../../componets/styles/Button.styled";
import { StyledRoomCard } from "../../componets/styles/roomDetails/RoomCard.styled";
import { StyledRoomCardLogo as StyledRoomCardLogo2 } from "../../componets/styles/roomDetails/RoomCardLogo.styled";
import { FaStar } from "react-icons/fa";
import {
  StyledCasinoHeaderBonusList,
  StyledCasinoHeaderBonus,
} from "../../componets/styles/casino/CasinoHeader.styled";
import * as DOMPurify from "dompurify";
import { useMediaQuery } from "react-responsive";

function OnlineCasinosBlock({ onlineCasinosList }) {
  const [room, setRoom] = useState(onlineCasinosList[0]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledColorBlock>
      <div style={{ display: "flex", flexDirection: isMobile ? "column" : "" }}>
        <h3>Онлайн казино</h3>
        <StyledColorBlockLinkContainer
          style={{ margin: isMobile ? "1.5rem 0 1.5rem" : "" }}
        >
          <StyledColorBlockLink
            to="/casinos/"
            colorStyles={{
              color: "#af0000",
              bgColor: "rgba(181, 66, 66, 0.15)",
              bgHover: "rgba(181, 66, 66, 0.2)",
              bgActive: "rgba(181, 66, 66, 0.25)",
            }}
          >
            Усі онлайн казино
          </StyledColorBlockLink>
        </StyledColorBlockLinkContainer>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "4rem",
          flexWrap: isMobile ? "wrap" : "",
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "45%",
            marginRight: isMobile ? "0" : "5rem",
            marginBottom: isMobile ? "2rem" : "",
          }}
        >
          <p style={{ marginBottom: "2rem" }}>
            Онлайн-казино, представлені в цьому списку, є перевіреними та
            надійними, мають ліцензії від Комісії з регулювання азартних ігор та
            іншіх відомих регуляторів.
          </p>
          <p>
            Наш відбір кращих ігрових платформ ґрунтується на ретельному аналізі
            важливих критеріїв, включаючи захист особистих даних, прозорість
            фінансових операцій, швидкість проходження верифікації,
            різноманітість слотів, вигідність бонусів та якість обслуговування
            користувачів. Наши рекомендації гарантують вашу безпеку та
            задоволення від гри.
          </p>
        </div>
        <div style={{ width: isMobile ? "100%" : "55%" }}>
          <StyledRoomCard
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                fontSize: "3rem",
                fontWeight: "700",
                position: "absolute",
                top: "10px",
                right: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaStar color="rgb(255, 188, 11)" />
              <span>{room?.get_average_rating}</span>
            </div>
            <StyledRoomCardLogo2 src={room?.logo_small} alt={room?.name} />
            <h1>{room?.name}</h1>
            <StyledCasinoHeaderBonusList
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {room?.bonus && (
                <StyledCasinoHeaderBonus
                  style={{ width: "fit-content" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(room?.bonus),
                  }}
                ></StyledCasinoHeaderBonus>
              )}
              {room?.bonus2 && (
                <StyledCasinoHeaderBonus
                  style={{ width: "fit-content" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(room?.bonus2),
                  }}
                ></StyledCasinoHeaderBonus>
              )}
              {room?.bonus3 && (
                <StyledCasinoHeaderBonus
                  style={{ width: "fit-content" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(room?.bonus3),
                  }}
                ></StyledCasinoHeaderBonus>
              )}
            </StyledCasinoHeaderBonusList>

            <div
              style={{
                display: "flex",
                justifyContent: isMobile ? "" : "center",
                alignItems: isMobile ? "center" : "",
                flexDirection: isMobile ? "column" : "",
              }}
            >
              <Button
                onClick={() => window.open(room?.registration_link, "_blank")}
                text="Зареєструватися"
                styled={StyledRegistrationBtn}
                style={{ margin: isMobile ? "2rem 0 1rem" : "" }}
              />
              <Button
                onClick={() => window.open(`/casinos/${room?.slug}/`, "_blank")}
                text="Огляд"
                styled={StyledReviewBtn}
                style={{ margin: isMobile ? "0" : "" }}
              />
            </div>
          </StyledRoomCard>
        </div>
      </div>
      <StyledListRoom>
        {onlineCasinosList?.map((room, id) => {
          return (
            <StyledRoomCardLogo
              src={room?.logo_small}
              alt={room?.name}
              onMouseOver={() => setRoom(onlineCasinosList[id])}
            />
          );
        })}
      </StyledListRoom>
    </StyledColorBlock>
  );
}

export default OnlineCasinosBlock;
