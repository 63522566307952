import styled from "styled-components";

export const StyledSubMenuContainer = styled.div`
    background-color: transparent;
    padding-top: 0.5rem;
`;

export const StyledSubMenu = styled.div`
  display: block;
  visibility: ${(props) => props.isShow ? "visible" : "hidden"};
  width: max-content;
  background-color: #ffffff;
  position: absolute;
  padding: 1rem 0;
  border-radius: 0.8rem;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
`;
