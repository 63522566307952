import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export const StyledCasinoList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-gap: 1.5rem;
  margin-top: 2rem;
`;

export const StyledCasinoPreview = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
  padding: 2rem 2.5rem 3rem;
  color: #000;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid lightgrey;
    /* transition: border .5s ease-out; */
  }
`;

const blink = keyframes`
    from {
      background-position: 70% 50%;
    }
    to {
      background-position: 0% 50%;
    }
`;

export const StyledHoverEffect = styled.div`
  position: relative;
  margin: 0 auto 1.9rem;
  transform: translateZ(0);
  transition: transform 0.6s;

  ${StyledCasinoPreview}:hover & {
    /* transform: rotate3d(1, 2, 0, 20deg) scale(1.02); */
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(
      80deg,
      rgba(243, 245, 246, 0) 35%,
      rgba(243, 245, 246, 0) 40%,
      rgba(243, 245, 246, 0.3) 50%,
      rgba(243, 245, 246, 0) 60%,
      rgba(243, 245, 246, 0) 65%
    );
    background-position: 150% 50%;
    background-size: 200% 200%;
  }

  ${StyledCasinoPreview}:hover &::before {
    animation: ${blink} 0.5s ease-out;
  }
`;

export const StyledCasinoPreviewLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background-color: #000;
  border-radius: 2rem;
  width: 10rem;
  height: 10rem;
`;

export const StyledCasinoPreviewLogo = styled.img`
  width: 100%;
`;

export const StyledCasinoPreviewName = styled.h2`
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 2.2rem;
  line-height: 1.27;
  letter-spacing: normal;
  word-wrap: break-word;
`;

export const StyledCasinoPreviewBonus = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.42;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 0.8rem;
  text-align: center;
  margin-bottom: 2.5rem;
`;

export const StyledCasinoPreviewLinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;

  svg {
    margin-right: 0.5rem;
  }

  a {
    padding: 1rem;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 1.4rem;
  }

  a:hover {
    color: #0b5acd;
  }
`;
