import React, { useEffect } from "react";
import {
  StyledStatistics,
  StyledStatisticsTitle,
  StyledStatisticsTable,
  StyledStatisticsTr,
  StyledStatisticsTd,
  StyledStatisticsTh,
} from "../../componets/styles/balance/Statistics.styled";
import useApi from "../../services/useApi";
import { getUserArticleList } from "../../services/users";
import { Link } from "react-router-dom";
import formateDate from "../../utils/DateFormater";
import { BiCommentDetail, BiLike, BiDislike, BiShare } from "react-icons/bi";
import Pagination from "../../componets/pagination/Pagination";
import setStatusColor from "../../utils/StatusColor";
import Spinner from "../../componets/shared/Spinner";

function AddedArticles() {
  const { data, loading, error, request } = useApi(getUserArticleList);

  useEffect(() => {
    request("pa/articles/");
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <StyledStatistics>
        <StyledStatisticsTitle>Додані статті</StyledStatisticsTitle>
        {loading && <Spinner />}
        {data && !loading && (
          <div style={{ overflow: "scroll" }}>
            <StyledStatisticsTable cellspacing="0" cellpadding="0">
              <StyledStatisticsTr>
                <StyledStatisticsTh>Дата/Час</StyledStatisticsTh>
                <StyledStatisticsTh>Назва</StyledStatisticsTh>
                <StyledStatisticsTh>Розділ</StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiCommentDetail />
                </StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiLike />
                </StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiDislike />
                </StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiShare />
                </StyledStatisticsTh>
                <StyledStatisticsTh>Статус</StyledStatisticsTh>
              </StyledStatisticsTr>

              {data &&
                data?.results?.map((a) => {
                  return (
                    <StyledStatisticsTr>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {formateDate(a?.date_published, true)}
                        </div>
                      </StyledStatisticsTd>
                      <Link to={a?.link}>
                        <StyledStatisticsTd>
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "1.4rem",
                              color: "#000",
                            }}
                          >
                            {a?.title}
                          </div>
                        </StyledStatisticsTd>
                      </Link>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgb(213, 75, 70)",
                          }}
                        >
                          {a?.category}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {a?.comments_count}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {a?.likes_count}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {a.dislikes_count}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {a?.share_counter}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            padding: "0.5rem",
                            borderRadius: "0.8rem",
                            color: "white",
                            backgroundColor: setStatusColor(a?.status),
                            fontWeight: "500",
                            fontSize: "1.4rem",
                            textAlign: "center",
                          }}
                        >
                          {a?.status}
                        </div>
                      </StyledStatisticsTd>
                    </StyledStatisticsTr>
                  );
                })}
            </StyledStatisticsTable>
          </div>
        )}
        {data && <Pagination data={data} request={request} />}
      </StyledStatistics>
    </div>
  );
}

export default AddedArticles;
