import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthenticationContext from "./contexts/AuthenticationContext";

function ProtectedRoute() {
  const { state } = useContext(AuthenticationContext);

  return state?.isLoggedIn &&
    state?.currentUser &&
    !state?.isLoading === true ? (
    <Outlet />
  ) : (
    <Navigate to="/login/" />
  );
}

export default ProtectedRoute;
