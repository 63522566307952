import styled from "styled-components";

export const StyledPleaseLogin = styled.div`
  background-color: #f2f4f6;
  display: flex;
  align-items: center;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  max-width: 68.8rem;
  border: 1px solid lightgray;

  p {
    font-size: 1.4rem;
    line-height: 1.28;
    letter-spacing: normal;
    overflow-wrap: break-word;
    margin-right: 3.5rem;
  }

  div {
    display: flex;
    flex-wrap: nowrap;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 2rem;
  }
`;
