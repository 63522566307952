import React from "react";
import { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../componets/Button";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import PublicationContext from "../componets/contexts/PublicationContext";
import Input from "../componets/form/Input";
import CreateArticleEditor from "../componets/richTxtEditor/CreateArticleEditor";
import {
  StyledRestorePasswordBtn,
  StyledReviewArticleBtn,
} from "../componets/styles/Button.styled";
import { Left } from "../componets/styles/shared/Left";
import { Right } from "../componets/styles/shared/Right";
import UploadImage from "../componets/upload/UploadImage";
import Modal from "react-modal";
import useApi from "../services/useApi";
import { postPublication, getCategories } from "../services/publications";
import CyrillicToTranslit, {
  cyrillicToTranslit,
} from "cyrillic-to-translit-js";
import Select from "../componets/form/Select";
import { StyledCloseModalBtn } from "../componets/styles/Button.styled";
import { GrClose } from "react-icons/gr";
import { StyledModalLink } from "../componets/styles/pages/AddArticle.styled";
import PreviewArticle from "./PreviewArticle";
import { BsCheck2Circle } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

function AddArticle() {
  const cyrillicToTranslitObj = new CyrillicToTranslit({ preset: "uk" });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const title = useRef();
  const desc = useRef();
  const {
    titleArticle,
    setTitleArticle,
    descArticle,
    setDescArticle,
    stats,
    dataEditor,
    setDataEditor,
    image,
    setImage,
    setImageArticle,
  } = useContext(PublicationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isPublish, setIsPublish] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [selectData, setSelectData] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [formState, setFormState] = useState({
    title: titleArticle || "",
    description: descArticle || "",
    selectValue: null,
  });

  const { data, loading, error, request } = useApi(postPublication);
  const {
    data: dataCategories,
    loading: loadingCategories,
    error: errorCategories,
    request: requestCategories,
  } = useApi(getCategories);

  const handleTitleChange = (e) => {
    e.preventDefault();
    setFormState((prevState) => ({ ...prevState, title: e.target.value }));
  };

  const handleDescChange = (e) => {
    e.preventDefault();
    setFormState((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOpenConfirmModal(false);
    document.querySelector("body").className = "";
  };

  const closeConfirmModal = () => {
    setIsOpen(false);
    setIsOpenConfirmModal(false);
    document.querySelector("body").className = "";
    title.current.value = "";
    desc.current.value = "";
    setImage();
    setImageArticle();
    setDataEditor("");
  };

  useEffect(() => {
    if (location?.state?.fromPreview) {
      setIsOpen(false);
    }
    requestCategories("categories/");
  }, []);

  useEffect(() => {
    if (!dataCategories) {
      return;
    }
    setSelectData(dataCategories?.results);
  }, [dataCategories]);

  useEffect(() => {
    if (!isPublish) {
      return;
    }

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const data = {
      title: title.current.value,
      description: desc.current.value,
      body: dataEditor,
      slug:
        cyrillicToTranslitObj
          .transform(
            title.current.value.replace(/[^a-zA-Z0-9а-яА-ЯіІїЇєЄґҐ ]/g, ""),
            "-"
          )
          .toLowerCase() +
        "-" +
        Math.floor(Math.random() * Date.now()).toString(36),
      category: selectValue,
    };

    const formData = new FormData();
    formData.append("image", image);
    formData.append("anotherData", JSON.stringify(data));

    request("publications/add/", formData, config);
    setIsPublish(null);
  }, [isPublish]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setIsOpenConfirmModal(true);
  }, [data]);

  const handlePublishClick = (e) => {
    e.preventDefault();
    setIsPublish(true);
  };

  return (
    <>
      <Modal
        isOpen={isPreview}
        onRequestClose={() => {
          document.querySelector("body").className = "";
          setIsPreview(false);
        }}
        contentLabel="Example Modal"
        className="modal-big"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}
        onAfterClose={() => {
          document.querySelector("body").className = "";
        }}
      >
        <PreviewArticle
          handlePublishClick={handlePublishClick}
          closeModal={() => {
            document.querySelector("body").className = "";
            setIsPreview(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={false}
        onAfterClose={closeModal}
        style={{ content: { height: "auto", minHeight: "0" } }}
      >
        <div style={{ padding: "5rem 3rem" }}>
          <h2 style={{ textAlign: "center", fontSize: "3rem" }}>Увага!</h2>
          <p
            style={{
              marginTop: "2rem",
              fontSize: "1.5rem",
              fontWeight: "500",
              color: "#ff3535",
              textAlign: "center",
              marginBottom: "4rem",
            }}
          >
            Перед тим як писати та публікувати статтю, наполегливо рекомендуємо
            ознайомитись із правилами публікації статей! У разі порушення
            правил, вам буде закрито доступ до цієї функції!
          </p>
          <StyledModalLink to="/rules/#article" target="_blank">
            Правила та умови публікації статей
          </StyledModalLink>

          <div style={{ margin: "4rem auto 0 auto" }}>
            <Button
              text="Я приймаю умови"
              styled={StyledRedBtn}
              onClick={closeModal}
              style={{ margin: "0 2rem 0 0" }}
            />
            <Button
              text="Назад"
              styled={StyledRestorePasswordBtn}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenConfirmModal}
        onRequestClose={closeConfirmModal}
        contentLabel="Example Modal"
        className="modal"
        overlayClassName="overlay"
        onAfterClose={closeConfirmModal}
        style={{
          content: { height: "auto", minHeight: "0", padding: "10rem 2rem" },
        }}
      >
        <Button
          icon={<GrClose />}
          styled={StyledCloseModalBtn}
          onClick={closeModal}
        />
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: "10rem", color: "#02980e" }}>
            <BsCheck2Circle />
          </div>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              marginBottom: "3rem",
            }}
          >
            Стаття додана успішно!
          </p>
          <p style={{ fontSize: "1.2rem", fontWeight: "500" }}>
            Вона з'явиться серед опублікованих статей коли пройде модерацію.
          </p>
        </div>
      </Modal>
      <Left>
        <div style={{ marginBottom: "2rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h2 style={{ marginRight: "2rem" }}>Назва статті</h2>
            {error && (
              <div style={{ fontSize: "1.5rem", color: "red" }}>
                {error?.response?.data?.title?.map((err) => {
                  return err + " ";
                })}
                {error?.response?.data?.slug?.map((err) => {
                  return err + " ";
                })}
              </div>
            )}
          </div>
          <Input
            value={formState.title}
            onChange={handleTitleChange}
            type="text"
            id="title"
            name="title"
            refc={title}
            style={
              (error?.response?.data?.title || error?.response?.data?.slug) && {
                border: "0.2rem solid #ed4040",
              }
            }
          />
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <h2 style={{ marginRight: "2rem", marginBottom: "1rem" }}>
            Категорія
          </h2>
          <Select options={selectData} setSelectValue={setSelectValue} />
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h2 style={{ marginRight: "2rem" }}>Краткое описание статьи</h2>
            {error && (
              <div style={{ fontSize: "1.5rem", color: "red" }}>
                {error?.response?.data?.description?.map((err) => {
                  return err + " ";
                })}
              </div>
            )}
          </div>
          <Input
            onChange={handleDescChange}
            value={formState.description}
            type="text"
            id="description"
            name="description"
            refc={desc}
            style={
              error?.response?.data?.slug && {
                border: "0.2rem solid #ed4040",
              }
            }
          />
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h2 style={{ marginRight: "2rem" }}>Зображення до статті</h2>
            {error && (
              <div style={{ fontSize: "1.5rem", color: "red" }}>
                {error?.response?.data?.image?.map((err) => {
                  return err + " ";
                })}
              </div>
            )}
          </div>
          <UploadImage />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <h2 style={{ marginBottom: "1rem", marginRight: "2rem" }}>
              Стаття
            </h2>
            {error && (
              <div style={{ fontSize: "1.5rem", color: "red" }}>
                {error?.response?.data?.body?.map((err) => {
                  return err + " ";
                })}
              </div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <h2 style={{ marginRight: "2rem" }}>Слів: {stats?.words || 0}</h2>
            <h2>Символів: {stats?.characters || 0}</h2>
          </div>
        </div>
        <CreateArticleEditor
          style={
            error?.response?.data?.body && {
              border: "0.2rem solid #ed4040",
              borderRadius: "0.8rem",
            }
          }
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          <Button
            styled={StyledRedBtn}
            // style={isMobile ? { margin: "0" } : {}}
            style={{ margin: "0" }}
            text="Опублікувати"
            onClick={handlePublishClick}
          />
          <Button
            text="Передогляд"
            // style={isMobile ? { margin: "0" } : {}}
            style={{ margin: "0" }}
            styled={StyledReviewArticleBtn}
            onClick={() => {
              // setTitleArticle(title.current.value);
              // setDescArticle(desc.current.value);
              // navigate("/publications/preview");
              setIsPreview(true);
            }}
          />
        </div>
      </Left>
      <Right>
        <div
          style={{
            padding: "1.5rem",
            borderRadius: "0.8rem",
            backgroundImage:
              "linear-gradient(270deg, rgb(214, 121, 83) 0.46%, rgb(240, 143, 107) 78.27%, rgb(251, 213, 126) 142.22%)",
          }}
        >
          <h2>Нагадування щодо публікації статті</h2>
          <div style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
            За порушення правил публікації статей адміністрація може обмежити
            доступ до цієї функції. За повторне порушення чи невиконання правил
            може бути БАН!
          </div>
          <h3 style={{ marginTop: "2rem" }}>Назва статті</h3>
          <ul>
            <li style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
              Назва статті може містити лише літери латинського або кириличного
              алфавіту, цифри, смайли чи символи "!"№:,.?#$%&".
            </li>
            <li style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
              Довжина назви не повинна перевищувати 255 символів.
            </li>
          </ul>
          <h3 style={{ marginTop: "2rem" }}>Короткий опис статті</h3>
          <div style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
            Короткий опис статті відображатиметься на сторінці зі списком
            статей. Опис не повинен перевищувати 3000 символів.
          </div>
          <div style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
            Опис не може бути порожнім. Якщо ви не знаєте, як коротко описати
            статтю, напишіть у опис перші 3 речення статті.
          </div>
          <h3 style={{ marginTop: "2rem" }}>Зображення для статті</h3>
          <div style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
            Зображення може бути лише у форматах .jpeg, .jpg, .png.
            <div style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
              Розмір зображення не повинен перевищувати 5 МБ.
            </div>
          </div>
          <h3 style={{ marginTop: "2rem" }}>Стаття</h3>
          <div style={{ marginTop: "1rem", fontSize: "1.4rem" }}>
            Стаття повинна бути не менше 1000 символів.
          </div>
        </div>
      </Right>
    </>
  );
}

export default AddArticle;
