import styled from "styled-components";
import { StyledCasinoHeaderLicense } from "../casino/CasinoHeader.styled";

export const StyledEstablishmentBlock = styled.div`
  background-color: #f2f4f6;
  border: 1px solid rgba(196, 205, 212, 0.5);
  border-radius: 1rem;
  padding: 3rem;
  margin-bottom: 1rem;

  a {
    color: rgb(49, 135, 238);
    text-decoration: none;
    transition: color 0.3s ease 0s;
  }

  a:hover {
    color: rgb(82, 160, 255);
  }

  ${StyledCasinoHeaderLicense} {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    padding: 1rem;

    ${StyledCasinoHeaderLicense} {
      margin-left: 0;
    }
  }
`;

export const StyledEstablishmentBlockImage = styled.img`
  width: 12rem;
  height: 12rem;
  border-radius: 0.8rem;
  object-fit: scale-down;
  margin-right: 2rem;

  @media (max-width: 767px) {
    width: 8rem;
    height: 8rem;
    margin-right: 1rem;
  }
`;
export const StyledEstablishmentBlockInfoTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  color: rgb(121, 122, 123);
  margin-bottom: 0.5rem;
`;
export const StyledEstablishmentBlockTitle = styled.h1`
  font-size: 2.5rem;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;
// export const StyledEstablishmentBlock = styled.div``;

export const StyledEstablishmentBlockAddReview = styled.div`
  background-color: #f2f4f6;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
`;
