import styled from "styled-components";

export const StyledPageTitle = styled.h1`
  text-align: center;
  font-size: 4.8rem;
  line-height: 1.25;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 3rem;
    line-height: 1.2;
  }
`;
