import styled from "styled-components";

export const StyledSuggestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  /* background: linear-gradient(
    270deg,
    rgb(214, 121, 83) 0.46%,
    rgb(240, 143, 107) 78.27%,
    rgb(251, 213, 126) 142.22%
  );
  background: linear-gradient(
    270deg,
    rgb(98, 142, 207) 0.46%,
    rgb(126, 195, 217) 78.27%,
    rgb(148, 229, 234) 142%
  ); */
  background-color: #f2f5f7;

  h3 {
    white-space: pre-line;
    font-size: 2rem;
    line-height: 1.27;
    letter-spacing: normal;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  div {
    white-space: pre-line;
    font-size: 1.4rem;
    line-height: 1.27;
    letter-spacing: normal;
    word-wrap: break-word;
  }
`;
