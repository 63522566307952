import React from 'react'
import Post from './Post'

function PostList({data, refToScrollTo}) {
  console.log(refToScrollTo)
  return (
    <div>{data?.map((item) => {
        return <Post data={item} refToScrollTo={refToScrollTo} />
    })}</div>
  )
}

export default PostList