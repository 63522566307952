import React, { useContext, useState, useEffect } from "react";
import Button from "../../Button";
import PublicationContext from "../../contexts/PublicationContext";
import { StyledIconBtn } from "../../styles/Button.styled";
import {
  StyledMobNavbar,
  StyledMobNavbarContainer,
} from "../../styles/layout/mobile/MobNavbar.styled";
import { GrClose } from "react-icons/gr";
import { ImExit } from "react-icons/im";
import {
  StyledUserAccount,
  StyledUserAccountInnerContainer,
  StyledUserAccountUsername,
  StyledUserAccountLogout,
  StyledUserAccountImage,
} from "../../styles/user/UserAccount.styled";
import { LoginBtn } from "../../styles/LoginBtn.styled";
import { SignUpBtn } from "../../styles/SignUpBtn.styled";
import { Link } from "react-router-dom";
import { StyledNavLink } from "../../styles/layout/NavLink.styled";

import Drawer from "react-modern-drawer";

import "react-modern-drawer/dist/index.css";
import { getProfile } from "../../../services/users";
import useApi from "../../../services/useApi";
import Accordion from "./Accordion";
import { RiArrowDownSLine } from "react-icons/ri";

function MobNavbar({
  currentUser,
  testFuncLogout,
  loginPressed,
  registerPressed,
}) {
  const { sidebar, setSidebar } = useContext(PublicationContext);

  const {
    data: dataProfile,
    loading: loadingProfile,
    error: errorProfile,
    request: requestProfile,
  } = useApi(getProfile);

  const [avatar, setAvatar] = useState(null);

  const closeSidebar = () => setSidebar(false);

  const toggleDrawer = () => {
    setSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    }
    if (!sidebar) {
      document.body.style.overflow = "";
    }
  }, [sidebar]);

  useEffect(() => {
    if (dataProfile) {
      return;
    }
    if (!currentUser) {
      return;
    }
    requestProfile("pa/editprofile/");
  }, [dataProfile, currentUser]);

  return (
    <Drawer
      open={sidebar}
      onClose={toggleDrawer}
      direction="right"
      className="drawer"
      style={{
        width: "32rem",
        zIndex: "9998",
        overflow: "scroll"
      }}
    >
      <StyledMobNavbar>
        <Button
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: "99999",
          }}
          icon={<GrClose />}
          styled={StyledIconBtn}
          onClick={closeSidebar}
        />
        <StyledMobNavbarContainer>
          <div style={{ margin: "2rem 1rem" }}>
            {currentUser ? (
              <StyledUserAccount>
                <Link to="/p/balance/" onClick={closeSidebar}>
                  <div style={{ display: "flex" }}>
                    <StyledUserAccountImage src={dataProfile?.photo} />

                    <StyledUserAccountInnerContainer>
                      <StyledUserAccountUsername>
                        {currentUser?.username}
                      </StyledUserAccountUsername>
                      <div style={{ textDecoration: "none", color: "#000" }}>
                        Особистий кабінет
                      </div>
                    </StyledUserAccountInnerContainer>
                  </div>
                </Link>
              </StyledUserAccount>
            ) : (
              <div>
                <Button
                  text={"Вхід"}
                  styled={LoginBtn}
                  onClick={() => loginPressed()}
                />
                <Button
                  text={"Реєстрація"}
                  styled={SignUpBtn}
                  onClick={() => registerPressed()}
                />
              </div>
            )}
          </div>
          <ul
            // onClick={closeSidebar}
            style={{ display: "block", marginTop: "4rem" }}
          >
            <li>
              <Accordion
                title={
                  <StyledNavLink>
                    <div style={{ fontSize: "2.5rem", marginRight: "1.5rem" }}>
                    📖
                    </div>
                    Публікації
                    <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
                  </StyledNavLink>
                }
                subMenu={[
                  {
                    name: "Усі публікації",
                    slug: "/publications/",
                  },
                  {
                    name: "Новини",
                    slug: "/publications/news/",
                  },
                  {
                    name: "Акції",
                    slug: "/publications/promotions/",
                  },
                  {
                    name: "Статті",
                    slug: "/publications/articles/",
                  },
                ]}
                closeSidebar={closeSidebar}
              />
            </li>
            <li>
              <Accordion
                closeSidebar={closeSidebar}
                title={
                  <StyledNavLink>
                    <div style={{ fontSize: "2.5rem", marginRight: "1.5rem" }}>
                    ❤️
                    </div>
                    Покер
                    <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
                  </StyledNavLink>
                }
                subMenu={[
                  {
                    name: "Онлайн покер руми",
                    slug: "/rooms/",
                  },
                  {
                    name: "Відгуки гравців",
                    slug: "/rooms/",
                  },
                  {
                    name: "Паролі на фріроли",
                    slug: "/freerolls/",
                  },
                  {
                    name: "Покер клуби України",
                    slug: "/pokerkluby/",
                  },
                ]}
              />
            </li>
            <li>
              <Accordion
                closeSidebar={closeSidebar}
                title={
                  <StyledNavLink>
                    <div style={{ fontSize: "2.5rem", marginRight: "1.5rem" }}>
                    🎰
                    </div>
                    Казино
                    <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
                  </StyledNavLink>
                }
                subMenu={[
                  {
                    name: "Огляди онлайн казино",
                    slug: "/casinos/",
                  },
                  {
                    name: "Відгуки гравців",
                    slug: "/casinos/",
                  },
                  {
                    name: "Казино України",
                    slug: "/kazyno/",
                  },
                ]}
              />
            </li>
            <li>
              <Accordion
                closeSidebar={closeSidebar}
                title={
                  <StyledNavLink>
                    <div style={{ fontSize: "2.5rem", marginRight: "1.5rem" }}>
                      🏨
                    </div>
                    Гральні заклади 🇺🇦
                    <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
                  </StyledNavLink>
                }
                subMenu={[
                  {
                    name: "Усі заклади України",
                    slug: "/hralni-zaklady-ukrainy/",
                  },
                  {
                    name: "Покер клуби України",
                    slug: "/pokerkluby/",
                  },
                  {
                    name: "Казино України",
                    slug: "/kazyno/",
                  },
                ]}
              />
            </li>
          </ul>
          {currentUser && (
            <StyledUserAccountLogout onClick={testFuncLogout}>
              <ImExit /> Вийти
            </StyledUserAccountLogout>
          )}
        </StyledMobNavbarContainer>
      </StyledMobNavbar>
    </Drawer>
  );
}

export default MobNavbar;
