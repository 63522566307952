import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledRegistrationBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.1rem;
  background-color: #ed4040;
  transition: all 0.3s ease;
  border-radius: 1rem;
  margin-right: 2rem;

  &:hover {
    background-color: #ff4d4d;
    outline-width: 0px;
  }

  &:active {
    background-color: #ba2020;
  }
`;

export const StyledRegistrationLink = styled(Link)`
  width: 24rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.1rem;
  background-color: #ed4040;
  transition: all 0.3s ease;
  border-radius: 1rem;

  &:hover {
    background-color: #ff4d4d;
    outline-width: 0px;
  }

  &:active {
    background-color: #ba2020;
  }
`;
