import React from "react";

function BackendErrors({ errors }) {
  const data = errors?.response?.data;
  const errorMessages = Object?.keys(data)?.map((key) => {
    if (key === "non_field_errors") {
      return data[key];
    }
    if (key === "queryset_freerolls") {
      return null;
    }
    if (key === "message" && data[key] === 'Схожий фрірол вже є у списку фріролів!') {
      return data[key];
    }
    return `${key} : ${data[key]}`;
  });
  return (
    <ul style={{ marginTop: "2rem" }}>
      {errorMessages.map((errorMessage) => {
        return (
          <li
            style={{
              color: "#ed4040",
              fontSize: "1.2rem",
              fontWeight: "500",
              lineHeight: "1.42",
              textTransform: "capitalize",
            }}
            key={errorMessage}
          >
            {errorMessage}
          </li>
        );
      })}
    </ul>
  );
}

export default BackendErrors;
