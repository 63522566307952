import React, { useEffect, useRef, useState } from "react";
import Button from "../../componets/Button";
import Input from "../../componets/form/Input";
import BackendErrors from "../../componets/modals/BackendErrors";
import { StyledRedBtn } from "../../componets/styles/buttons/RedBtn.styled";
import { changePswd } from "../../services/authentication";
import useApi from "../../services/useApi";
import Spinner from "../../componets/shared/Spinner";
import { useMediaQuery } from "react-responsive";


function ChangePassword() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const oldPassword = useRef();
  const newPassword1 = useRef();
  const newPassword2 = useRef();

  const [isHandleChangePswd, setIsHandleChangePswd] = useState(false);

  const { data, loading, error, request } = useApi(changePswd);

  useEffect(() => {
    if (!isHandleChangePswd) {
      return;
    }
    request("dj-rest-auth/password/change/", {
      new_password1: newPassword1.current.value,
      new_password2: newPassword2.current.value,
      old_password: oldPassword.current.value,
    });
    setIsHandleChangePswd(false);
  }, [isHandleChangePswd]);

  const handleChangePswd = (e) => {
    e.preventDefault();
    setIsHandleChangePswd(true);
  };

  return (
    <div style={{ width: isMobile ? "100%" : "50%", margin: "0 auto", marginTop: isMobile ? "0" : "3rem" }}>
      <div>
        <Input
          type="password"
          name="curr-password"
          id="curr-password"
          label="Поточний пароль"
          refc={oldPassword}
        />
      </div>
      <div style={{ marginTop: "3rem" }}>
        <Input
          type="password"
          name="new-password1"
          id="new-password1"
          label="Новий пароль"
          refc={newPassword1}
        />
        <Input
          type="password"
          name="new-password2"
          id="new-password2"
          label="Новий пароль ще раз"
          refc={newPassword2}
        />
      </div>
      <div>
        {data && <div>{data?.detail}</div>}
        {error && <BackendErrors errors={error} />}
        <Button
          text={
            loading ? (
              <Spinner style={{ margin: "0", width: "2rem", height: "2rem" }} />
            ) : (
              "Зберегти"
            )
          }
          styled={StyledRedBtn}
          style={{ marginLeft: "0", marginTop: "1rem", minWidth: "13rem" }}
          onClick={handleChangePswd}
        />
      </div>
    </div>
  );
}

export default ChangePassword;
