const authenticationReducer = (state, action) => {
  switch (action.type) {
    case "CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: true,
        isLoading: false,
      };
    case "CLEAR_CURRENT_USER":
      return {
        ...state,
        currentUser: null,
        isLoggedIn: false,
        isLoading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "UNSET_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
