import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "./Spinner";
import useApi from "../../services/useApi";
import { googleAuth } from "../../services/authentication";
import useLocalStorage from "../../hooks/useLocalStorage";

function GoogleAuth() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading, error, request } = useApi(googleAuth);
  const [token, setToken] = useLocalStorage("token");

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) {
      return;
    }

    request("dj-rest-auth/google/", {
      code: code,
    });
  }, [searchParams]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setToken(data?.key);
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (data?.key) {
    navigate(-3);
  }
}

export default GoogleAuth;
