import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";

export default class SpoilerPlugin extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add("SpoilerPlugin", () => {
      // The button will be an instance of ButtonView.
      const button = new ButtonView();

      button.set({
        label: "SpoilerPlugin",
        withText: true,
      });

      //Execute a callback function when the button is clicked
      button.on("execute", () => {
        const now = new Date();

        //Change the model using the model writer
        editor.model.change((writer) => {
          const div1 = writer.createElement("details");
          const docFrag = writer.createDocumentFragment();
          writer.append(div1, docFrag);
          writer.insertText("foo", div1);

          //Insert the text at the user's current position
          editor.model.insertContent(docFrag);
        //   editor.model.insertContent(writer.createText(now.toString()));
        });
      });

      return button;
    });
  }
}
