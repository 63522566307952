import React from "react";
import {
  StyledEstablishmentListBlock,
  StyledEstablishmentListBlockImage,
  StyledEstablishmentListBlockName,
} from "../styles/establishment/EstablishmentListBlock.styled";
import {
  StyledCasinoHeaderLicense,
  StyledCasinoHeaderLicenseFlag,
} from "../styles/casino/CasinoHeader.styled";
import { Rating } from "react-simple-star-rating";
import Button from "../Button";
import { StyledGoToReviewBtn } from "../styles/GoToReviewBtn.styled";
import { RiMegaphoneFill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

function EsteblishmentListBlock({ estab }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <StyledEstablishmentListBlock
      to={`/hralni-zaklady-ukrainy/${estab?.city?.slug}/${estab?.slug}/`}
    >
      <StyledEstablishmentListBlockImage src={estab?.image} alt={estab?.name} />
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: isMobile ? "1rem" : "2rem",
          }}
        >
          <StyledEstablishmentListBlockName>
            {estab?.name}
          </StyledEstablishmentListBlockName>
          {estab?.license_krail && (
            <StyledCasinoHeaderLicense>
              <StyledCasinoHeaderLicenseFlag>
                <div></div>
                <div></div>
              </StyledCasinoHeaderLicenseFlag>
              <div>Ліцензія КРАІЛ</div>
            </StyledCasinoHeaderLicense>
          )}
        </div>
        <div style={{ display: "flex", flexWrap: isMobile ? "wrap" : "nowrap" }}>
          <div
            style={{
              marginRight: isMobile ? "2rem" : "3rem",
            }}
          >
            {!isMobile && (
              <div
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "500",
                  color: "rgb(121, 122, 123)",
                }}
              >
                Рейтинг
              </div>
            )}
            <div style={{ display: "flex" }}>
              <Rating
                allowFraction={true}
                initialValue={estab?.rating}
                size={24}
                readonly
              />
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  marginLeft: "1rem",
                }}
              >
                {estab?.rating}
              </div>
            </div>
          </div>
          <div
            style={{
              marginRight: isMobile ? "2rem" : "3rem",
            }}
          >
            {!isMobile && (
              <div
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "500",
                  color: "rgb(121, 122, 123)",
                }}
              >
                Відгуки
              </div>
            )}
            <div
              style={{
                fontSize: "2rem",
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              <RiMegaphoneFill
                style={{ marginRight: "1rem" }}
                color="rgb(121, 122, 123)"
              />
              {estab?.feedback_count}
            </div>
          </div>
          <div>
            {!isMobile && (
              <div
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "500",
                  color: "rgb(121, 122, 123)",
                }}
              >
                Адреса
              </div>
            )}
            <div
              style={{
                fontSize: "2rem",
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
                marginTop: isMobile ? "1rem" : "0rem",
              }}
            >
              <FaLocationDot
                style={{ marginRight: "1rem" }}
                color="rgb(121, 122, 123)"
              />
              м. {estab?.city?.name}
            </div>
          </div>
          <Button
            text="Детальніше про заклад"
            styled={StyledGoToReviewBtn}
            style={{
              position: "inherit",
              width: isMobile ? "100%" : "max-content",
              flexShrink: "none",
              marginTop: isMobile ? "1rem" : "auto",
              marginLeft: "auto",
            }}
          />
        </div>
      </div>
    </StyledEstablishmentListBlock>
  );
}

export default EsteblishmentListBlock;
