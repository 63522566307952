import React from "react";
import styled from "styled-components";

export const StyledRoomReviews = styled.div``;

export const StyledRoomReviewsHeader = styled.div`
  background-color: #f2f4f6;
  border-radius: 1rem;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

export const StyledRoomReviewsHeaderTitle = styled.div`
  font-size: 2.2rem;
  line-height: 1.27;
  overflow-wrap: break-word;
  margin: 3rem 0px 1rem;
  text-align: center;
`;
