import styled from "styled-components";

export const StyledFreerollList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 1rem;
  margin-top: 2rem;
`;

export const StyledFreerollCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: ${(props) => props?.bgColor || "#f2f5f7"};
  border-radius: 1rem;
  font-size: 1.5rem;
`;

export const StyledFreerollRoomContainer = styled.div`
  display: flex;
`;

export const StyledFreerollRoomImg = styled.img`
  margin-right: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #000;
  padding: 0.5rem;
  object-fit: contain;
`;

export const StyledFreerollRoomName = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.3rem;
  margin-bottom: 0.5rem;
`;

export const StyledFreerollName = styled.div`
  margin: 1.5rem 0;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.1;
`;

export const StyledFreerollInfo = styled.div`
  display: flex;
  font-size: 1.5rem;
  line-height: 1.3rem;
  margin-bottom: 0.5rem;

  & .key {
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }

  & .value {
    font-weight: 700;
  }
`;

export const StyledFreerollPassword = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  padding: 3rem;
  border: 0.1rem dashed black;
  border-radius: 1rem;
  flex-grow: 1;
`;

export const StyledNewFreerollCard = styled.div`
  /* margin-right: 1rem; */
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
  padding: 1.5rem;
`;

export const StyledNewFreerollCard__RoomContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
`;

export const StyledNewFreerollCard__RoomImg = styled.img`
  width: 2rem;
  height: 2rem;
  background-color: black;
  border-radius: 50%;
  margin-right: 0.5rem;
  padding: 0.2rem;
  object-fit: contain;
`;

export const StyledNewFreerollCard__RoomName = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  white-space: nowrap;
`;

export const StyledNewFreerollCard__RoomRating = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StyledNewFreerollCard__RoomRatingValue = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
`;

export const StyledNewFreerollCard__DateTimeContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

export const StyledNewFreerollCard__TimeContainer = styled.div`
  display: flex;
  margin-right: 1rem;
`;

export const StyledNewFreerollCard__Time = styled.div`
  font-size: 2.5rem;
  font-weight: 500;
  margin-right: 0.5rem;
`;

export const StyledNewFreerollCard__Date = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const StyledNewFreerollCard__Name = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledNewFreerollCard__InfoKey = styled.div`
  color: rgb(127, 127, 127);
`;

export const StyledNewFreerollCard__InfoValue = styled.div`
  font-size: 1.5rem;
  white-space: nowrap;
`;
