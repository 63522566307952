import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledFooter = styled.footer`
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  margin-top: auto;
  padding-top: 2rem;
  margin-bottom: 3.4rem;

  @media (max-width: 767px) {
    padding: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5rem;

  &:last-child {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    &:last-child {
      margin-left: 0;
    }
  }
`;

export const StyledSectionTitle = styled.div`
  font-size: 1.3rem;
  line-height: 1.54;
  font-weight: bold;
  text-transform: uppercase;
  word-wrap: break-word;
  margin-bottom: 1.2rem;
  letter-spacing: 1px;
`;

export const StyledSectionLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  transition-duration: 0.25s;
  margin-bottom: 1.4rem;
  transition-property: opacity;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  & svg {
    margin-right: 0.5rem;
  }
`;
