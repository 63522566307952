import React from "react";
import styled from "styled-components";

export const StyledRoomCardFooter = styled.div`
  text-align: left;
  display: block;
  column-count: 2;
  column-gap: 6rem;
  margin-top: 3rem;
  padding: 2.5rem 3rem;
  border-top: 1px solid rgba(196, 205, 212, 0.5);

  @media (max-width: 767px) {
    padding: 2rem 0 0 0;
    column-count: 1;
    column-gap: 1;
  }
`;
