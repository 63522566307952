import React from "react";
import {
  StyledCasinoHeader,
  StyledCasinoHeaderLogo,
  StyledCasinoHeaderLogoContainer,
  StyledCasinoHeaderName,
  StyledCasinoHeaderRatingContainer,
  StyledCasinoHeaderRatingValue,
  StyledCasinoHeaderRatingAmountReviews,
  StyledCasinoHeaderRatingAmount,
  StyledCasinoHeaderBonusList,
  StyledCasinoHeaderBonus,
  StyledCasinoHeaderLicense,
  StyledCasinoHeaderLicenseFlag,
  StyledCasinoHeaderRegLink,
  StyledCasinoHeaderInstructionLink,
  StyledCasinoHeaderLinksContainer,
} from "../styles/casino/CasinoHeader.styled";
import { Rating } from "react-simple-star-rating";
import { GiPerspectiveDiceSixFacesOne } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import * as DOMPurify from "dompurify";

function CasinoHeader({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledCasinoHeader>
      <div
        style={{
          display: "flex",
          // flexWrap: isMobile ? "wrap" : "",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "",
          justifyContent: isMobile ? "" : "center",
        }}
      >
        <StyledCasinoHeaderLogoContainer>
          <StyledCasinoHeaderLogo src={data?.logo} alt={data?.name} />
        </StyledCasinoHeaderLogoContainer>
        <div
          style={{
            marginRight: isMobile ? "0" : "3rem",
            textAlign: isMobile ? "center" : "",
          }}
        >
          <StyledCasinoHeaderName>{data?.name}</StyledCasinoHeaderName>
          {data?.name2 && (
            <StyledCasinoHeaderName>{data?.name2}</StyledCasinoHeaderName>
          )}
          <Rating
            allowFraction={true}
            initialValue={data?.rating}
            size={30}
            readonly
          />
          <StyledCasinoHeaderRatingContainer>
            <StyledCasinoHeaderRatingValue>
              {data?.rating}
            </StyledCasinoHeaderRatingValue>
            <StyledCasinoHeaderRatingAmount>
              / 5.0
            </StyledCasinoHeaderRatingAmount>
            <StyledCasinoHeaderRatingAmountReviews>
              ({data?.feedback_count} відгуків)
            </StyledCasinoHeaderRatingAmountReviews>
          </StyledCasinoHeaderRatingContainer>
        </div>
        <StyledCasinoHeaderBonusList>
          {data?.bonus && (
            <StyledCasinoHeaderBonus
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data?.bonus),
              }}
            ></StyledCasinoHeaderBonus>
          )}
          {data?.bonus2 && (
            <StyledCasinoHeaderBonus
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data?.bonus2),
              }}
            ></StyledCasinoHeaderBonus>
          )}
          {data?.bonus3 && (
            <StyledCasinoHeaderBonus
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data?.bonus3),
              }}
            ></StyledCasinoHeaderBonus>
          )}
        </StyledCasinoHeaderBonusList>
        {data?.license_krail && (
          <StyledCasinoHeaderLicense>
            <StyledCasinoHeaderLicenseFlag>
              <div></div>
              <div></div>
            </StyledCasinoHeaderLicenseFlag>
            <div>Ліцензія КРАІЛ</div>
          </StyledCasinoHeaderLicense>
        )}
      </div>

      <StyledCasinoHeaderLinksContainer>
        <StyledCasinoHeaderRegLink to="aff/" target="_blank">
          <GiPerspectiveDiceSixFacesOne />
          Грати
        </StyledCasinoHeaderRegLink>
        <StyledCasinoHeaderInstructionLink
          to="how-start-to-play/"
          state={{ targetId: "instruction" }}
        >
          Інструкція з реєстрації
        </StyledCasinoHeaderInstructionLink>
      </StyledCasinoHeaderLinksContainer>
    </StyledCasinoHeader>
  );
}

export default CasinoHeader;
