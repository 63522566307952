import React, { useState, useRef, useEffect } from "react";
import Button from "../Button";
import { StyledCircleBtn } from "../styles/Button.styled";
import { AiOutlineCloudUpload, AiOutlineDelete } from "react-icons/ai";

function ProfileImage({ profileImg, setProfileImage }) {
  const hiddenImageInput = useRef();
  const allImages = ["png", "jpg", "image/jpeg"];
  const [image, setImage] = useState(profileImg);
  const [imageTmpURL, setImageTmpURL] = useState(null);
  const [error, setError] = useState(null);

  const handleChangeImage = (e) => {
    if (allImages.indexOf(e.target.files[0].type) === -1) {
      setError("Цей тип файлу не підтримуеться! Тільки png або jpg/jpeg");
    } else {
      setError(null);
      setImageTmpURL(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setProfileImage(e.target.files[0]);
    }
  };

  const handleLoadImage = () => {
    setError(null);
    hiddenImageInput.current.click();
  };

  const handleDeleteImage = (e) => {
    setImageTmpURL(null);
    setImage(profileImg);
    setProfileImage(null);
    setError(null);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        lineHeight: "1.4",
        fontSize: "1.5rem",
      }}
    >
      <div style={{ position: "relative", width: "fit-content" }}>
        <input
          type="file"
          ref={hiddenImageInput}
          onChange={handleChangeImage}
          style={{ display: "none" }}
        />
        <img
          src={imageTmpURL || image}
          alt=""
          style={{
            width: "20rem",
            height: "20rem",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
        {imageTmpURL ? (
          <Button
            type="button"
            icon={<AiOutlineDelete />}
            onClick={handleDeleteImage}
            styled={StyledCircleBtn}
          />
        ) : (
          <Button
            type="button"
            icon={<AiOutlineCloudUpload />}
            onClick={handleLoadImage}
            styled={StyledCircleBtn}
          />
        )}
      </div>
      <div style={{ marginTop: "2rem", color: "red" }}>{error}</div>
      {imageTmpURL && (
        <div>Натисніть "Зберегти", щоб зміни набули чинності</div>
      )}
    </div>
  );
}

export default ProfileImage;
