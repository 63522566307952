import React from "react";
import styled from "styled-components";

export const StyledRoomCardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;

  & .room-card-info__key {
    width: 50%;
    margin-right: 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #797a7b;
    /* white-space: nowrap; */
  }

  & .room-card-info__value {
    width: 50%;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #000;
  }
`;
