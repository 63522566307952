import styled from "styled-components";

export const StyledRedBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 13rem;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.1rem;
  background-color: #ed4040;
  transition: all 0.3s ease;
  border-radius: 1rem;
  margin: 0 auto;
  /* margin-right: 0;
  margin-left: auto; */
  white-space: nowrap;


  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #ff4d4d;
    outline-width: 0px;
  }

  &:active {
    background-color: #ba2020;
  }
`;
