import React from "react";
import Theme from "./Theme";
import {
  StyledThemeList,
  StyledThemeListsubsectionName,
} from "../styles/forum/ThemeList.styled";

function ThemeList({ data }) {
  return (
    <div>
      <StyledThemeListsubsectionName>
        {data?.[0]?.inner_subsection?.name
          ? data?.[0]?.inner_subsection?.name
          : data?.[0]?.subsection?.name}
      </StyledThemeListsubsectionName>
      <StyledThemeList>
        {data?.map((item) => {
          return <Theme data={item} />;
        })}
      </StyledThemeList>
    </div>
  );
}

export default ThemeList;
