import styled, { keyframes } from "styled-components";

export const StyledNavbar = styled.nav`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9997; //9999 in modal (className="overlay"), 9998 sidebar
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 120rem;
  padding: 1.5rem 0;
  font-size: 1.5rem;
  transition: padding 0.3s ease 0s;
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.75);

  div {
    /* display: flex; */
  }

  ul {
    display: flex;
    margin-bottom: -0.5rem;
  }

  li {
    margin-left: 0.6rem;
  }

  img {
    height: 4rem;
  }

  @media (max-width: 767px) {
    padding: 1.5rem 1.6rem;
  }
`;

const tiltShaking = keyframes`
0%, 22% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

2%, 20% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
}
4%, 8%, 12% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
}
6%, 10%, 14% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
}
16% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
}
`;

export const StyledNavGift = styled.div`
  display: flex;
  align-items: center;
  font-size: 3rem;
  margin-right: 2rem;
  animation-name: ${tiltShaking};
  animation-iteration-count: infinite;
  animation-duration: 3s;
`;
