import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledCasinoHeader = styled.div`
  /* display: flex; */
  width: 100%;
  border-radius: 1rem;
  background-color: #f2f5f7;
  border: 1px solid rgba(196, 205, 212, 0.5);
  padding: 3rem;
  margin-bottom: 1rem;
`;

export const StyledCasinoHeaderLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16rem;
  height: 10rem;
  min-width: 16rem;
  min-height: 10rem;
  padding: 3rem;
  background-color: #000;
  border-radius: 1rem;
  margin-right: 1.5rem;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const StyledCasinoHeaderLogo = styled.img`
  width: 100%;
`;

export const StyledCasinoHeaderName = styled.div`
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 700;
  white-space: nowrap;
`;

export const StyledCasinoHeaderRatingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: 700;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export const StyledCasinoHeaderRatingValue = styled.div`
  font-size: 2.5rem;
`;

export const StyledCasinoHeaderRatingAmount = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 1.5rem;
  white-space: nowrap;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const StyledCasinoHeaderRatingAmountReviews = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  white-space: nowrap;
`;

export const StyledCasinoHeaderBonusList = styled.div`
  font-weight: 500;
  /* width: fit-content; */
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

export const StyledCasinoHeaderBonus = styled.div`
  border-radius: 1rem;
  border: 1px solid #000;
  padding: 1rem;
  background-color: #fff;
  font-size: 1.6rem;
  margin-right: 2rem;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const StyledCasinoHeaderLicense = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  margin-left: auto;

  @media (max-width: 767px) {
    margin-top: 1rem;
    margin-left: 0;
  }
`;

export const StyledCasinoHeaderLicenseFlag = styled.div`
  width: 3rem;
  height: 2rem;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-right: 0.5rem;

  div:first-child {
    width: 100%;
    height: 1rem;
    background-color: #005bbb;
  }

  div:last-child {
    width: 100%;
    height: 1rem;
    background-color: #ffd500;
  }
`;

export const StyledCasinoHeaderLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledCasinoHeaderRegLink = styled(Link)`
  width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem 1.8rem;
  font-size: 2rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.1rem;
  background-color: #ed4040;
  transition: all 0.3s ease;
  border-radius: 1rem;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #ff4d4d;
    outline-width: 0px;
  }

  &:active {
    background-color: #ba2020;
  }
`;

export const StyledCasinoHeaderInstructionLink = styled(Link)`
  display: flex;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;

  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  &:active {
    color: rgba(0, 0, 0, 0.8);
  }
`;
