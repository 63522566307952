import React from "react";
import { StyledForumBreadcrumbs } from "../styles/breadcrumbs/ForumBreadcrumbs.styled";
import { Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";

function ForumBreadcrumbs({ data }) {
  return (
    <StyledForumBreadcrumbs>
      <ul>
        {data?.map((item, i) => {
          return (
            <>
              {item && (
                <>
                  <li>
                    <Link to={item?.slug}>{item?.name}</Link>
                  </li>
                  <MdNavigateNext />
                </>
              )}
            </>
          );
        })}
      </ul>
    </StyledForumBreadcrumbs>
  );
}

export default ForumBreadcrumbs;
