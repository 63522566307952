import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { StyledAdsSide } from "../styles/shared/AdsSide.styled";
import useApi from "../../services/useApi";
import { getBanners } from "../../services/pages";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AdsSide({ dataFromProps = null, casinoSlug = null, roomSlug = null }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dataToRender, setDataToRender] = useState(null);

  const { data, loading, error, request } = useApi(getBanners);

  useEffect(() => {
    if (dataFromProps) {
      setDataToRender(dataFromProps);
      return;
    }
    request("/banners/", { casino__slug: casinoSlug, room__slug: roomSlug });
  }, [dataFromProps]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setDataToRender(data);
  }, [data]);

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    appendDots: (dots) => (
      <div
        style={{
          padding: "0px",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "10px",
          backgroundColor: i === currentSlide ? "#ed4040" : "silver",
        }}
      ></div>
    ),
  };
  return (
    <StyledAdsSide>
      {dataToRender && (
        <Slider {...settings}>
          {dataToRender
            .filter((banner) => {
              if (roomSlug) {
                return banner?.room.some((room) => room?.slug === roomSlug);
              } else {
                return banner;
              }
            })
            .filter((banner) => {
              if (casinoSlug) {
                return banner?.casino.some((cas) => cas?.slug === casinoSlug);
              } else {
                return banner;
              }
            })
            .map((banner, i) => {
              return (
                <a key={i} href={banner?.link} target="_blank">
                  <img src={banner?.image} alt={banner?.image_alt} />
                </a>
              );
            })}
        </Slider>
      )}
    </StyledAdsSide>
  );
}

export default AdsSide;
