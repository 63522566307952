import styled from "styled-components";

export const StyledPublicationAuthor = styled.div`
  display: flex;
  margin-right: 4rem;
`;

export const StyledPublicationAuthorImg = styled.img`
  width: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: beige;
  object-fit: cover;
`;

export const StyledPublicationAuthorName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.3rem;
  font-weight: 700;

  & div:first-of-type {
    color: rgba(0, 0, 0, 0.5);
  }
`;
