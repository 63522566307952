import styled from "styled-components";

export const StyledCheckBox = styled.label`
  display: block;
  width: fit-content;
  position: relative;
  padding-left: 3.5rem;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 0.4rem;
    background-color: rgba(0, 41, 91, 0.06);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ span {
    background-color: rgba(0, 41, 91, 0.09);
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ span {
    background-color: rgba(49, 135, 238);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ span:after {
    display: block;
  }

  svg {
    width: 100%;
    height: 100%;
  }

`;
