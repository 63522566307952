import React from "react";
import {
  StyledWebsiteProposition,
  StyledWebsitePropositionDesc,
  StyledWebsitePropositionTitle,
  StyledWebsitePropositionIcon,
} from "../styles/suggestions/WebsiteProposition.styled";

function WebsiteProposition({ icon, title, desc, style, to }) {
  return (
    <StyledWebsiteProposition to={to} style={style}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
        <StyledWebsitePropositionIcon>{icon}</StyledWebsitePropositionIcon>
        <StyledWebsitePropositionTitle>{title}</StyledWebsitePropositionTitle>
      </div>

      <StyledWebsitePropositionDesc>{desc}</StyledWebsitePropositionDesc>
    </StyledWebsiteProposition>
  );
}

export default WebsiteProposition;
