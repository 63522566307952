import styled from "styled-components";
import { StyledRoomCard } from "../styles/roomPreview/RoomCard.styled";
import { StyledCasinoPreview } from "./casino/CasinoPreview.styled";
import { StyledEstablishmentListBlock } from "./establishment/EstablishmentListBlock.styled";

export const StyledGoToReviewBtn = styled.button`
  width: 100%;
  cursor: pointer;
  border: 0px;
  text-decoration: none;
  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: 0.25s;
  overflow: visible;
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  border-radius: 0.8rem;
  height: 4rem;
  padding: 0 2.5rem;
  font-size: 1.4rem;
  line-height: 1.28;
  color: #0b5acd;
  background-color: rgba(49, 135, 238, 0.1);
  position: static;
  flex-shrink: 0;
  margin-top: 2rem;

  ${StyledRoomCard}:hover & {
    background-color: #3187ee;
    color: #fff;
    outline-width: 0px;
  }

  ${StyledRoomCard}:active & {
    color: #fff;
    background-color: #126BD9;
  }

  ${StyledCasinoPreview}:hover & {
    background-color: #3187ee;
    color: #fff;
    outline-width: 0px;
  }

  ${StyledCasinoPreview}:active & {
    color: #fff;
    background-color: #126BD9;
  }

  ${StyledEstablishmentListBlock}:hover & {
    background-color: #3187ee;
    color: #fff;
    outline-width: 0px;
  }

  ${StyledEstablishmentListBlock}:active & {
    color: #fff;
    background-color: #126BD9;
  }
`;
