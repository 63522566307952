import styled from "styled-components";

export const OneBlockCenter = styled.div`
  width: 100%;
  max-width: 104rem;
  margin: 0px auto;
  padding: 0px 4rem;
  flex-grow: 1;

  @media (max-width: 767px) {
    max-width: none;
    margin: 0;
    padding: 0;
  }
`;
