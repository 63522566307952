import React, { useContext, useEffect, useState } from "react";
import {
  StyledComment,
  StyledCommentImg,
  StyledCommentContainer,
  StyledCommentBodyContainer,
  StyledCommentBody,
  StyledCommentBottomContainer,
  StyledCommentTime,
  StyledCommentButtons,
  StyledCommentAuthor,
} from "../styles/comments/Comments.styled";
import Button from "../Button";
import { StyledCommentButton } from "../styles/comments/Comments.styled";
import { BiCommentAdd, BiTrash, BiLike, BiDislike } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import formateDate from "../../utils/DateFormater";
import AuthenticationContext from "../contexts/AuthenticationContext";
import * as DOMPurify from "dompurify";


function ReviewAnswer({ data, answerData, refToScroll }) {
  const [isScroll, setIsScrool] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { state } = useContext(AuthenticationContext);

  const scroll = (ref) => {
    ref?.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  const handleAnswer = (e, id, feedback, data) => {
    e.preventDefault();
    answerData(data);
    setIsScrool(true);
  };

  useEffect(() => {
    if (!isScroll) {
      return;
    }
    setTimeout(() => {
      scroll(refToScroll);
    }, 50);
    setIsScrool(false);
  }, [isScroll]);

  return (
    <StyledComment isAnswer={true}>
      {!isMobile && (
        <StyledCommentImg
          src={data.user.photo}
          alt={data?.user?.user?.username}
        />
      )}
      <StyledCommentContainer>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "flex-end",
            // justifyContent: "space-between",
          }}
        >
          {isMobile && (
            <StyledCommentImg
              src={data.user.photo}
              alt={data?.user?.user?.username}
            />
          )}
          <StyledCommentAuthor>
            {data?.user?.user?.username}
          </StyledCommentAuthor>
        </div>

        <StyledCommentBodyContainer>
          <StyledCommentBody
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.text) }}
          ></StyledCommentBody>

          <StyledCommentBottomContainer>
            <StyledCommentTime>
              {formateDate(data?.date_published, true)}
            </StyledCommentTime>
            <StyledCommentButtons>
              {state?.currentUser && state?.isLoggedIn && (
                <Button
                  icon={<BiCommentAdd />}
                  styled={StyledCommentButton}
                  text="Відповісти"
                  onClick={(e) =>
                    handleAnswer(e, data?.id, data?.feedback, data)
                  }
                />
              )}
              {/* {state?.currentUser?.username === user?.user?.username && (
                <Button
                  icon={<BiTrash />}
                  styled={StyledCommentButton}
                  text="Удалить"
                  onClick={() => handleDelete(id)}
                />
              )}
              <Button
                icon={<BiLike />}
                styled={StyledCommentButton}
                text={data.likes}
                style={gradeStatus?.user_vote ? { color: "green" } : {}}
                onClick={(e) => handleLikeClick(e, id)}
              />
              <Button
                icon={<BiDislike />}
                styled={StyledCommentButton}
                text={data.dislikes}
                style={gradeStatus?.user_vote === false ? { color: "red" } : {}}
                onClick={(e) => handleDislikeClick(e, id)}
              /> */}
            </StyledCommentButtons>
          </StyledCommentBottomContainer>
        </StyledCommentBodyContainer>
      </StyledCommentContainer>
    </StyledComment>
  );
}

export default ReviewAnswer;
