import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "../Button";
import { StyledRegistrationBtn } from "../styles/RegistrationBtn.styled";
import {
  StyledForm,
  StyledNotice,
  StyledBottomContainer,
} from "../styles/form/Form.styled";
import Input from "../form/Input";
import useApi from "../../services/useApi";
import { register } from "../../services/authentication";
import BackendErrors from "./BackendErrors";
import Spinner from "../shared/Spinner";
import PublicationContext from "../contexts/PublicationContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import Modal from "react-modal";
import { RiMailSendLine } from "react-icons/ri";
import SeoFields from "../shared/SeoFields";

function RegistrationForm() {
  const emailRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const { data, loading, error, request } = useApi(register);
  const [dataToSignUp, setDataToSignUp] = useState(null);
  const { setEntranceModalOpen } = useContext(PublicationContext);
  const [isMailSent, setIsMailSent] = useState(true);

  const closeModal = () => {
    setIsMailSent(false);
    document.querySelector("body").className = "";
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    const data = {
      email: emailRef.current.value,
      username: usernameRef.current.value,
      password1: passwordRef.current.value,
      password2: confirmPasswordRef.current.value,
      ref: JSON.parse(window.localStorage.getItem("refer")),
    };
    setDataToSignUp(data);
  };

  useEffect(() => {
    if (!dataToSignUp) {
      return;
    }
    request("dj-rest-auth/registration/", dataToSignUp);
  }, [dataToSignUp]);

  if (data?.detail === "Verification e-mail sent.") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "10rem", color: "#02980e" }}>
          <RiMailSendLine />
        </div>
        <h3 style={{ marginBottom: "4rem" }}>
          Листа для підтвердження реєстрації було відправлено на вказаний email:
        </h3>
        <h2>{dataToSignUp?.email}</h2>
      </div>
    );
  }
  return (
    <>
      <SeoFields
        title="Реєстрація"
        description="Реєстрація на betraisers.com"
        keywords={null}
        robots="noindex, nofollow"
        canonical="https://betraisers.com/register/"
      />
      <StyledForm>
        <Input
          type="email"
          name="email"
          id="email"
          label="Email"
          refc={emailRef}
        />
        <Input
          type="text"
          name="nickname"
          id="nickname"
          label="Нікнейм"
          refc={usernameRef}
        />
        <div style={{ marginTop: "2rem" }}>
          <Input
            type="password"
            name="password"
            id="password"
            label="Пароль"
            refc={passwordRef}
          />
          <Input
            type="password"
            name="password2"
            id="password2"
            label="Пароль ще раз"
            refc={confirmPasswordRef}
          />
        </div>
        {error && <BackendErrors errors={error} />}
        <StyledBottomContainer>
          <StyledNotice>
            Реєструючись, Ви приймаєте <a href="">умови користування сайтом</a>
          </StyledNotice>
          <Button
            text={
              loading ? (
                <Spinner
                  style={{ margin: "0", width: "2rem", height: "2rem" }}
                />
              ) : (
                "Зареєструватися"
              )
            }
            styled={StyledRegistrationBtn}
            style={{
              marginTop: "1rem",
              marginRight: "1.5rem",
              minWidth: "22rem",
            }}
            onClick={handleRegistration}
          />
        </StyledBottomContainer>
      </StyledForm>
    </>
  );
}

export default RegistrationForm;
