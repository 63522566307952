import React, { useState, useRef, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import ReviewRichTextEditor from "../richTxtEditor/ReviewRichTextEditor";
import Button from "../Button";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import AutoHeightTextarea from "../form/AutoHeightTextArea";
import CheckBox from "../form/CheckBox";
import { BsCheck } from "react-icons/bs";
import useApi from "../../services/useApi";
import { addReview } from "../../services/rooms";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function AddReview(props) {
  const { setSuccessAddedModal, setAddReviewModal, pathToRequest } = props;
  const [rating, setRating] = useState(0);
  const [reviewBody, setReviewBody] = useState(null);
  const [minuses, setMinuses] = useState("");
  const [pluses, setPluses] = useState("");
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [errors, setErrors] = useState([]);
  const [doRequest, setDoRequest] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { data, loading, error, request } = useApi(addReview);

  const location = useLocation();

  const handleSendReview = (e) => {
    e.preventDefault();
    let errorsArr = [];

    if (rating <= 0 || rating > 5) {
      errorsArr.push("Поставте оцінку покер руму");
    }
    if (reviewBody === null || reviewBody === "") {
      errorsArr.push('Напишіть Ваш відгук у полі "Ваш відгук"');
    }
    if (isDisableBtn) {
      errorsArr.push(
        "Вам треба погодитись з правилами і умовами додавання відгуків"
      );
    }
    if (errorsArr.length !== 0) {
      setErrors(errorsArr);
      return;
    }
    setErrors([]);
    setDoRequest(true);
  };

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleGetDataReviewBody = (data) => {
    setReviewBody(data);
  };

  const handleGetDataMinuses = (data) => {
    setMinuses(data);
  };

  const handleGetDataPluses = (data) => {
    setPluses(data);
  };

  const isChecked = (data) => {
    setIsDisableBtn(!data);
  };

  useEffect(() => {
    if (!doRequest) {
      return;
    }
    request(pathToRequest || `${location.pathname}add/`, {
      rating,
      text: reviewBody,
      advantages: minuses,
      disadvantages: pluses,
    });
    setDoRequest(false);
    setAddReviewModal(false)
    setSuccessAddedModal(true);
  }, [doRequest]);

//   useEffect(() => {
//     if (!data) {
//       return;
//     }
//     refreshList(`${location.pathname}`);
//   }, [data]);

//   if (data) {
//     return <h1>Відгук додано!</h1>;
//   }

  return (
    <div>
      <h2 style={{ marginBottom: "1rem" }}>Ваша оцінка</h2>
      <Rating onClick={handleRating} />

      <h2 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Ваш відгук</h2>
      <ReviewRichTextEditor sendDataToParent={handleGetDataReviewBody} />

      <h2 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Плюси</h2>
      <AutoHeightTextarea
        sendDataToParent={handleGetDataPluses}
        style={{
          fontFamily: "Montserrat",
          display: "block",
          width: "100%",
          overflow: "hidden",
          resize: "vertical",
          minHeight: "5rem",
          height: "5rem",
          backgroundColor: "rgba(0,41,91,0.06)",
          borderRadius: "0.8rem",
          padding: "1.5rem",
          border: "0",
          caretColor: "#000",
          outline: "none",
        }}
      ></AutoHeightTextarea>

      <h2 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Мінуси</h2>
      <AutoHeightTextarea
        sendDataToParent={handleGetDataMinuses}
        style={{
          fontFamily: "Montserrat",
          display: "block",
          width: "100%",
          overflow: "hidden",
          resize: "vertical",
          minHeight: "5rem",
          height: "5rem",
          backgroundColor: "rgba(0,41,91,0.06)",
          borderRadius: "0.8rem",
          padding: "1.5rem",
          border: "0",
          caretColor: "#000",
          outline: "none",
        }}
      ></AutoHeightTextarea>

      <CheckBox
        isChecked={isChecked}
        icon={<BsCheck color="#fff" />}
        style={{ marginTop: "2rem" }}
        label={
          <div>
            "Я згоден з <a href="#">правилами та умовами</a> додавання відгуків"
          </div>
        }
      />
      <div style={{ fontSize: "1.2rem", color: "red", marginTop: "2rem" }}>
        {errors.map((i) => {
          return <div style={{ marginBottom: "1rem" }}>{i}</div>;
        })}
      </div>

      <Button
        text="Залишити відгук"
        styled={StyledRedBtn}
        style={{ marginTop: "2rem" }}
        onClick={handleSendReview}
        // disabled={isDisableBtn}
      />
    </div>
  );
}

export default AddReview;
