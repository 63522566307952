import { useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import apiClient from "./client";

const useApi = (apiFunc) => {
  const [token, setToken] = useLocalStorage("token");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    const token = window.localStorage.getItem("token");
    apiClient.defaults.headers.common["Authorization"] =
      token !== null &&
      token !== undefined &&
      token !== '""' &&
      apiFunc.name !== "login"
        ? `Token ${JSON.parse(token)}`
        : "";

    let optionRequest = {
      headers: {
        Authorization:
          token !== null &&
          token !== undefined &&
          token !== '""' &&
          apiFunc.name !== "login"
            ? `Token ${JSON.parse(token)}`
            : "",
      },
    };

    setLoading(true);

    try {
      const result = await apiFunc(...args, optionRequest);
      setData(result.data);
      setError(null);
    } catch (err) {
      setError(err);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request,
  };
};

export default useApi;
