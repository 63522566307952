import React from "react";
import RoomCard from "./RoomCard";
import { StyledRoomList } from "../styles/rooms/Rooms.styled";
import { useMediaQuery } from "react-responsive";
import MobileRoomCard from "./MobileRoomCard";
import Spinner from "../shared/Spinner";

function RoomList({ roomList, loading }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  if (loading) {
    return <Spinner />;
  }
  
  return (
    <StyledRoomList>
      {roomList?.map((room) => {
        return isNotMobile ? (
          <RoomCard room={room} />
        ) : (
          <MobileRoomCard room={room} />
        );
      })}
    </StyledRoomList>
  );
}

export default RoomList;
