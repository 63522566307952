import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function LoadOnTop({ children }) {
  const { pathname, state } = useLocation();

  useEffect(() => {
    if (state?.scrollto) {
      window.scrollTo(0, state?.scrollto);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return children;
}

export default LoadOnTop;
