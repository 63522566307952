import React from "react";
import {
  StyledCommentContainer,
  StyledCommentAuthor,
  StyledCommentBodyContainer,
  StyledComment,
  StyledCommentImg,
} from "../styles/comments/Comments.styled";
import Button from "../Button";
import { StyledRichTxtEditor } from "../styles/richTxtEditor/RichTxtEditor.styled";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import PublicationContext from "../contexts/PublicationContext";
import AuthenticationContext from "../contexts/AuthenticationContext";
import { useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import List from "@ckeditor/ckeditor5-list/src/list";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import {
  Emoji,
  EmojiActivity,
  EmojiFlags,
  EmojiFood,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiPlaces,
  EmojiSymbols,
} from "@phudak/ckeditor5-emoji/src";
import SpadeCardPlugin from "./SpadeCardPlugin";
import { useState } from "react";
import { useEffect } from "react";
import useApi from "../../services/useApi";
import { postComment } from "../../services/publications";
import { useLocation } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

function RichTxtEditor({ page, slug, refToScrollTo }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    listComments,
    setListComments,
    commentAnswer,
    setCommentAnswer,
    isAnswerClick,
    setIsAnswerClick,
    ed,
    setEd,
  } = useContext(PublicationContext);
  let { pathname } = useLocation();

  const { state } = useContext(AuthenticationContext);

  const [data, setData] = useState("");
  const [dataForRequest, setDataForRequest] = useState({
    text: data,
    parent: null,
  });
  const [isClicked, setIsClicked] = useState(false);
  const [ckeditor, setCkeditor] = useState(null);

  const { data: responseData, loading, error, request } = useApi(postComment);

  const addQuote = (comment) => {
    if (comment?.parent) {
      return (
        `<p><strong>${comment?.user?.user?.username}</strong></p><blockquote>` +
        comment?.text +
        `</blockquote><p><br data-cke-filler="true"></p>`
      );
    }
    return "";
  };

  // when we send comment to the server
  useEffect(() => {
    if (isClicked) {
      request(`${slug}comments/`, dataForRequest, {
        params: { page: page },
      });
      // setIsClicked(false);
      setDataForRequest({
        text: data,
        parent: null,
      });
    }
  }, [isClicked]);

  // when we recieved comments list
  useEffect(() => {
    if (isClicked && responseData.no_permission) {
      toast.error(responseData?.no_permission);
      setIsClicked(false);
      return;
    }
    if (isClicked) {
      toast.success("Коментар додано!");
      setIsClicked(false);
    }

    setListComments(responseData);
  }, [responseData]);

  // when user pressed answer and we have commentAnswer
  useEffect(() => {
    if (isAnswerClick && commentAnswer) {
      setData(addQuote(commentAnswer));

      if (!commentAnswer?.parent) {
        setDataForRequest((prev) => ({ ...prev, parent: commentAnswer?.id }));
      } else {
        setDataForRequest((prev) => ({
          ...prev,
          parent: commentAnswer?.parent,
        }));
      }
    }
  }, [isAnswerClick, commentAnswer]);

  const handleSendClick = (e) => {
    e.preventDefault();
    if (!state?.isLoggedIn && !state?.currentUser) {
      return;
    }
    setIsClicked(true);
    setData("");
  };

  const editorConfiguration = {
    plugins: [
      BlockQuote,
      Bold,
      Essentials,
      Image,
      ImageInsert,
      // ImageUpload,
      Italic,
      List,
      Paragraph,
      Emoji,
      EmojiPeople,
      EmojiNature,
      EmojiPlaces,
      EmojiFood,
      EmojiActivity,
      EmojiObjects,
      EmojiSymbols,
      EmojiFlags,
      SpadeCardPlugin,
    ],
    toolbar: {
      items: [
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        // "imageUpload",
        "insertImage",
        "|",
        "emoji",
        "|",
        "SpadeCardPlugin",
        "ClubCardPlugin",
        "DiamondCardPlugin",
        "HeartCardPlugin",
        "OffsuitCardPlugin",
      ],
      shouldNotGroupWhenFull: true,
    },
  };

  const clearParagraph = (data) => {
    return data.replaceAll("<p>&nbsp;</p>", "");
  };

  // here we init editor and work with onChange
  useEffect(() => {
    const ck = (
      <CKEditor
        editor={ClassicEditor}
        data={data}
        config={editorConfiguration}
        onReady={(editor) => {
          setEd(editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setData(data);

          if (data) {
            setDataForRequest((prev) => ({
              ...prev,
              text: clearParagraph(data),
            }));
          }

          if (isAnswerClick) {
            editor.model.change((writer) => {
              writer.setSelection(editor.model?.document?.getRoot(), "end");
            });
            editor.editing.view.focus();
          }
          setIsAnswerClick(false);
        }}
        onBlur={(event, editor) => {
        }}
        onFocus={(event, editor) => {
        }}
      />
    );

    setCkeditor(ck);
  }, [data, isAnswerClick]);

  return (
    <>
      <StyledComment>
        {!isMobile && (
          <StyledCommentImg
            src={state?.currentUser?.photo}
            alt={state?.currentUser?.username}
          />
        )}
        <StyledCommentContainer>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {isMobile && (
              <StyledCommentImg
                src={state?.currentUser?.photo}
                alt={state?.currentUser?.username}
              />
            )}
            <StyledCommentAuthor>
              {state?.currentUser?.username}
            </StyledCommentAuthor>
          </div>
          <StyledCommentBodyContainer>
            <StyledRichTxtEditor>{ckeditor}</StyledRichTxtEditor>
          </StyledCommentBodyContainer>
          <Button
            styled={StyledRedBtn}
            text="Залишити коментар"
            style={{ margin: "1rem auto 0" }}
            onClick={handleSendClick}
            disabled={loading}
          />
        </StyledCommentContainer>
      </StyledComment>
    </>
  );
}

export default RichTxtEditor;
