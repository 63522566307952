import React, { useEffect } from "react";
import useApi from "../services/useApi";
import { get404Page } from "../services/pages";
import { useLocation } from "react-router-dom";
import Error404 from "../componets/shared/Error404";

function NotFound() {
  const { data, loading, error, request } = useApi(get404Page);
  const location = useLocation();

  useEffect(() => {
    request(location.pathname);
  }, []);

  return <Error404 />;
}

export default NotFound;
