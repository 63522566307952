import React from "react";
import {
  StyledBlockContainer,
  StyledBlock__Title,
  StyledBlock__TitleContainer,
  StyledBlock__TitleLink,
  StyledRoomCard,
  StyledRoomCard__BonusContainer,
  StyledRoomCard__BonusKey,
  StyledRoomCard__BonusValue,
  StyledRoomCard__Logo,
  StyledRoomCard__Name,
  StyledRoomCard__RatingContainer,
  StyledRoomCard__RatingValue,
  StyledRoomCardMob,
  StyledRoomCard__LogoMob,
  StyledRoomCard__NameMob,
  StyledRoomCard__RatingContainerMob,
  StyledRoomCard__RatingValueMob,
  StyledRoomCard__BonusContainerMob,
  StyledRoomCard__BonusKeyMob,
  StyledRoomCard__BonusValueMob,
} from "../../componets/styles/pages/main/Block.styled";
import { Rating } from "react-simple-star-rating";
import * as DOMPurify from "dompurify";
import { useMediaQuery } from "react-responsive";
import { FaStar } from "react-icons/fa";

function Rooms({ rooms, casinos }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledBlockContainer>
      <div
        style={{
          width: isMobile ? "100%" : "50%",
          marginRight: isMobile ? "0" : "2rem",
          marginBottom: isMobile ? "1rem" : "0",
        }}
      >
        <StyledBlock__TitleContainer>
          <StyledBlock__Title>Покер руми</StyledBlock__Title>
          <StyledBlock__TitleLink to="/rooms/">
            > Усі руми
          </StyledBlock__TitleLink>
        </StyledBlock__TitleContainer>
        <div>
          {rooms?.map((r, i) => {
            if (isMobile) {
              return (
                <StyledRoomCardMob to={`/rooms/${r?.slug}/`}>
                  <StyledRoomCard__LogoMob src={r?.logo} alt={r?.name} />
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <StyledRoomCard__NameMob>
                        {r?.name}
                      </StyledRoomCard__NameMob>
                      <StyledRoomCard__RatingContainerMob>
                        <FaStar
                          style={{ fontSize: "1.7rem" }}
                          color="rgb(255, 188, 11)"
                        />

                        <StyledRoomCard__RatingValueMob
                          style={{
                            fontSize: "1.7rem",
                            fontWeight: "700",
                          }}
                        >
                          {r?.get_average_rating}
                        </StyledRoomCard__RatingValueMob>
                      </StyledRoomCard__RatingContainerMob>
                    </div>
                    <StyledRoomCard__BonusContainerMob>
                      <StyledRoomCard__BonusKeyMob>
                        🎁 Подарунок
                      </StyledRoomCard__BonusKeyMob>
                      <StyledRoomCard__BonusValueMob>
                        {r?.first_bonus}
                      </StyledRoomCard__BonusValueMob>
                    </StyledRoomCard__BonusContainerMob>
                  </div>
                </StyledRoomCardMob>
              );
            } else {
              return (
                <StyledRoomCard to={`/rooms/${r?.slug}/`}>
                  <StyledRoomCard__Logo src={r?.logo} alt={r?.name} />
                  <div style={{ marginRight: "2rem" }}>
                    <StyledRoomCard__Name>{r?.name}</StyledRoomCard__Name>
                    <Rating
                      allowFraction={true}
                      initialValue={r?.get_average_rating}
                      size={20}
                      readonly
                    />
                    <StyledRoomCard__RatingContainer>
                      <StyledRoomCard__RatingValue
                        style={{
                          fontSize: "2rem",
                          fontWeight: "700",
                        }}
                      >
                        {r?.get_average_rating}
                      </StyledRoomCard__RatingValue>
                      <StyledRoomCard__RatingValue>
                        /
                      </StyledRoomCard__RatingValue>
                      <StyledRoomCard__RatingValue
                        style={{
                          marginRight: "0",
                          color: "rgb(127, 127, 127)",
                          fontWeight: "500",
                        }}
                      >
                        5.0
                      </StyledRoomCard__RatingValue>
                    </StyledRoomCard__RatingContainer>
                  </div>
                  <StyledRoomCard__BonusContainer>
                    <StyledRoomCard__BonusKey>
                      🎁 Подарунок
                    </StyledRoomCard__BonusKey>
                    <StyledRoomCard__BonusValue>
                      {r?.first_bonus}
                    </StyledRoomCard__BonusValue>
                  </StyledRoomCard__BonusContainer>
                </StyledRoomCard>
              );
            }
          })}
        </div>
      </div>

      <div
        style={{
          width: isMobile ? "100%" : "50%",
        }}
      >
        <StyledBlock__TitleContainer>
          <StyledBlock__Title>Онлайн казино</StyledBlock__Title>
          <StyledBlock__TitleLink to="/casinos/">
            > Усі казино
          </StyledBlock__TitleLink>
        </StyledBlock__TitleContainer>
        <div>
          {casinos?.map((c, i) => {
            if (isMobile) {
              return (
                <StyledRoomCardMob to={`/casinos/${c?.slug}/`}>
                  <StyledRoomCard__LogoMob src={c?.logo} alt={c?.name} />
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <StyledRoomCard__NameMob>
                        {c?.name}
                      </StyledRoomCard__NameMob>
                      <StyledRoomCard__RatingContainerMob>
                        <FaStar
                          style={{ fontSize: "1.7rem" }}
                          color="rgb(255, 188, 11)"
                        />

                        <StyledRoomCard__RatingValueMob
                          style={{
                            fontSize: "1.7rem",
                            fontWeight: "700",
                          }}
                        >
                          {c?.get_average_rating}
                        </StyledRoomCard__RatingValueMob>
                      </StyledRoomCard__RatingContainerMob>
                    </div>
                    <StyledRoomCard__BonusContainerMob>
                      <StyledRoomCard__BonusKeyMob>
                        🎁 Подарунок
                      </StyledRoomCard__BonusKeyMob>
                      <StyledRoomCard__BonusValueMob
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            c?.bonus.replaceAll("<p>&nbsp;</p>", "")
                          ),
                        }}
                      ></StyledRoomCard__BonusValueMob>
                    </StyledRoomCard__BonusContainerMob>
                  </div>
                </StyledRoomCardMob>
              );
            } else {
              return (
                <StyledRoomCard to={`/casinos/${c?.slug}/`}>
                  <StyledRoomCard__Logo src={c?.logo} alt={c?.name} />
                  <div style={{ marginRight: "2rem" }}>
                    <StyledRoomCard__Name>{c?.name}</StyledRoomCard__Name>
                    <Rating
                      allowFraction={true}
                      initialValue={c?.get_average_rating}
                      size={20}
                      readonly
                    />
                    <StyledRoomCard__RatingContainer>
                      <StyledRoomCard__RatingValue
                        style={{
                          fontSize: "2rem",
                          fontWeight: "700",
                        }}
                      >
                        {c?.get_average_rating}
                      </StyledRoomCard__RatingValue>
                      <StyledRoomCard__RatingValue>
                        /
                      </StyledRoomCard__RatingValue>
                      <StyledRoomCard__RatingValue
                        style={{
                          marginRight: "0",
                          color: "rgb(127, 127, 127)",
                          fontWeight: "500",
                        }}
                      >
                        5.0
                      </StyledRoomCard__RatingValue>
                    </StyledRoomCard__RatingContainer>
                  </div>
                  <StyledRoomCard__BonusContainer>
                    <StyledRoomCard__BonusKey>
                      🎁 Подарунок
                    </StyledRoomCard__BonusKey>
                    <StyledRoomCard__BonusValue
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          c?.bonus.replaceAll("<p>&nbsp;</p>", "")
                        ),
                      }}
                    ></StyledRoomCard__BonusValue>
                  </StyledRoomCard__BonusContainer>
                </StyledRoomCard>
              );
            }
          })}
        </div>
      </div>
    </StyledBlockContainer>
  );
}

export default Rooms;
