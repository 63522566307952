import React from "react";
import {
  StyledColorBlock,
  StyledColorBlockLink,
  StyledColorBlockLinkContainer,
} from "../../componets/styles/pages/main/MainPage.styled";
import {
  StyledNewFreerollCard,
  StyledNewFreerollCard__RoomContainer,
  StyledNewFreerollCard__RoomImg,
  StyledNewFreerollCard__RoomName,
  StyledNewFreerollCard__RoomRating,
  StyledNewFreerollCard__RoomRatingValue,
  StyledNewFreerollCard__DateTimeContainer,
  StyledNewFreerollCard__TimeContainer,
  StyledNewFreerollCard__Time,
  StyledNewFreerollCard__Date,
  StyledNewFreerollCard__Name,
  StyledNewFreerollCard__InfoKey,
  StyledNewFreerollCard__InfoValue,
} from "../../componets/styles/freeroll/Freeroll";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { Rating } from "react-simple-star-rating";
import {
  StyledBlockContainer,
  StyledBlock__Title,
  StyledBlock__TitleLink,
  StyledBlock__TitleContainer,
} from "../../componets/styles/pages/main/Block.styled";
import { dateInObj } from "../../utils/DateFormater";
import { FaStar } from "react-icons/fa6";
import Button from "../../componets/Button";
import { StyledRegistrationLink } from "../../componets/styles/RegistrationBtn.styled";
import FreerollCard from "../../componets/freerolls/FreerollCard";

function FreerollsBlock({ freerollList }) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          bottom: "-3rem",
        }}
      >
        <ul style={{ display: "flex", justifyContent: "center" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "1rem",
          height: "1rem",
          backgroundColor: "rgba(0,0,0,0.5)",
          borderRadius: "1rem",
          marginTop: "3rem",
          marginBottom: "2rem",
          marginRight: "2rem",
        }}
      ></div>
    ),
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <StyledBlockContainer>
        <div style={{ width: "100%" }}>
          <StyledBlock__TitleContainer>
            <StyledBlock__Title>
              {isMobile ? "Фріроли" : "Фріроли та приватні турніри"}
            </StyledBlock__Title>
            <StyledBlock__TitleLink to="/freerolls/">
              > Усі фріроли
            </StyledBlock__TitleLink>
          </StyledBlock__TitleContainer>
          <div style={{ display: "flex", width: "100%", overflow: "scroll" }}>
            {freerollList?.map((freeroll) => {
              return (
                <FreerollCard
                  style={{ width: !isMobile && "20%", marginRight: "1rem" }}
                  key={freeroll.id}
                  freeroll={freeroll}
                />
              );
            })}
          </div>
        </div>
      </StyledBlockContainer>
    </>
  );
}

export default FreerollsBlock;
