import React from "react";
import {
  StyledNewFreerollCard,
  StyledNewFreerollCard__RoomContainer,
  StyledNewFreerollCard__RoomImg,
  StyledNewFreerollCard__RoomName,
  StyledNewFreerollCard__RoomRating,
  StyledNewFreerollCard__RoomRatingValue,
  StyledNewFreerollCard__DateTimeContainer,
  StyledNewFreerollCard__TimeContainer,
  StyledNewFreerollCard__Time,
  StyledNewFreerollCard__Date,
  StyledNewFreerollCard__Name,
  StyledNewFreerollCard__InfoKey,
  StyledNewFreerollCard__InfoValue,
} from "../styles/freeroll/Freeroll";
import formateDate, { dateInObj } from "../../utils/DateFormater";
import { Rating } from "react-simple-star-rating";
import { FaStar } from "react-icons/fa6";
import Button from "../Button";
import { StyledRegistrationLink } from "../styles/RegistrationBtn.styled";

function FreerollCard({ freeroll, style }) {
  return (
    <StyledNewFreerollCard style={style}>
      <StyledNewFreerollCard__RoomContainer>
        <StyledNewFreerollCard__RoomImg
          src={freeroll?.room?.logo_small}
          alt={freeroll?.room?.name}
        />
        <StyledNewFreerollCard__RoomName>
          {freeroll?.room?.name}
        </StyledNewFreerollCard__RoomName>
        <StyledNewFreerollCard__RoomRating>
          <FaStar style={{ fontSize: "1.6rem" }} color="rgb(255, 188, 11)" />
          <StyledNewFreerollCard__RoomRatingValue>
            {freeroll?.room?.get_average_rating}
          </StyledNewFreerollCard__RoomRatingValue>
        </StyledNewFreerollCard__RoomRating>
      </StyledNewFreerollCard__RoomContainer>
      <StyledNewFreerollCard__DateTimeContainer>
        <StyledNewFreerollCard__TimeContainer>
          <StyledNewFreerollCard__Time>
            {
              dateInObj(`${freeroll?.date_freeroll} ${freeroll?.time_freeroll}`)
                .hours
            }
            :
            {
              dateInObj(`${freeroll?.date_freeroll} ${freeroll?.time_freeroll}`)
                .minutes
            }
          </StyledNewFreerollCard__Time>
          <div style={{ marginRight: "0.5rem" }}>({freeroll?.timezone})</div>
        </StyledNewFreerollCard__TimeContainer>

        <div>
          <StyledNewFreerollCard__Date>
            {
              dateInObj(`${freeroll?.date_freeroll} ${freeroll?.time_freeroll}`)
                .date
            }
            .
            {
              dateInObj(`${freeroll?.date_freeroll} ${freeroll?.time_freeroll}`)
                .month
            }
            .
            {
              dateInObj(`${freeroll?.date_freeroll} ${freeroll?.time_freeroll}`)
                .year
            }
          </StyledNewFreerollCard__Date>
        </div>
      </StyledNewFreerollCard__DateTimeContainer>

      <StyledNewFreerollCard__Name>
        {freeroll?.name}
      </StyledNewFreerollCard__Name>
      {freeroll?.id_freeroll && (
        <div style={{ marginBottom: "1rem" }}>
          <StyledNewFreerollCard__InfoKey>
            ID фріролу
          </StyledNewFreerollCard__InfoKey>
          <StyledNewFreerollCard__InfoValue>
            {freeroll?.id_freeroll}
          </StyledNewFreerollCard__InfoValue>
        </div>
      )}

      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div>
          <StyledNewFreerollCard__InfoKey>
            Призові
          </StyledNewFreerollCard__InfoKey>
          <StyledNewFreerollCard__InfoValue
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              color: "#3186ee",
            }}
          >
            {freeroll?.prizepool}
          </StyledNewFreerollCard__InfoValue>
        </div>
        {freeroll?.buyin && (
          <div style={{ marginLeft: "auto" }}>
            <StyledNewFreerollCard__InfoKey>
              Байін
            </StyledNewFreerollCard__InfoKey>
            <StyledNewFreerollCard__InfoValue
              style={{
                fontSize: "1.6rem",
              }}
            >
              {freeroll?.buyin}
            </StyledNewFreerollCard__InfoValue>
          </div>
        )}
      </div>

      <div>
        <StyledNewFreerollCard__InfoKey>Пароль</StyledNewFreerollCard__InfoKey>
        <StyledNewFreerollCard__InfoValue
          style={{
            fontSize: "1.6rem",
            fontWeight: "500",
          }}
        >
          {freeroll?.pass_freeroll}
        </StyledNewFreerollCard__InfoValue>
      </div>

      <StyledRegistrationLink
        to={`/rooms/${freeroll?.room?.slug}/aff/`}
        style={{
          width: "100%",
          fontSize: "1.3rem",
          padding: "0.8rem",
        }}
      >
        Зареєструватися
      </StyledRegistrationLink>
    </StyledNewFreerollCard>
  );
}

export default FreerollCard;
