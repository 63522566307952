import React, { useState } from "react";
import {
  StyledCasinoInformation,
  StyledCasinoInformationContainer,
  StyledCasinoInformationKey,
  StyledCasinoInformationValue,
} from "../styles/casino/CasinoInformation.styled";
import { Link } from "react-router-dom";

function CasinoInformation({ data }) {
  return (
    <StyledCasinoInformation>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>ℹ️ Назва</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.name} {data?.name2 && data?.name2}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>🌐 Веб-сайт</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          <Link
            to={`/casinos/${data?.slug}/aff/`}
            style={{ color: "#000", textDecoration: "underline" }}
          >
            {data?.website}
          </Link>
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          🎂 Рік заснування
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.start_work}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>📝 Ліцензія</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.license}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          🎲 Доступні ігри
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.games}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          ∑ Кількість ігор
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.games_count}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          🔄 Виплата (RTP)
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>{data?.rtp}</StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>⚙️ Провайдери</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.providers}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          📱 Сумісні пристрої
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>{data?.os}</StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          💳 Методи депозиту
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.cashier_methods}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          🚀 Швидкість виводу
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.cashout_speed}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>
          🚧 Ліміт на вивід
        </StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.cashout_limit}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>💲 Валюта</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.currency}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>🌎 Мови</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.language}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>💬 Онлайн-чат</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.support_chat}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>📧 Email</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.support_email}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>📞 Телефон</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.support_phone}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
      <StyledCasinoInformationContainer>
        <StyledCasinoInformationKey>😎 Власник</StyledCasinoInformationKey>
        <StyledCasinoInformationValue>
          {data?.owner}
        </StyledCasinoInformationValue>
      </StyledCasinoInformationContainer>
    </StyledCasinoInformation>
  );
}

export default CasinoInformation;
