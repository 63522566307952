import React from "react";
import {
  StyledRoomSuggestionContainer,
  StyledRoomSuggestionInnerContainer,
  StyledRoomSuggestionButtonsContainer,
  StyledRoomSuggestion,
  StyledRoomSuggestionLogo,
  StyledRoomSuggestionName,
} from "../styles/roomDetails/RoomSuggection.styled";
import { Rating } from "react-simple-star-rating";
import { StyledRegistrationLink } from "../styles/RegistrationBtn.styled";
import { StyledTranparentLink } from "../styles/Button.styled";
import { BsSearchHeart } from "react-icons/bs";
import { VscFeedback } from "react-icons/vsc";
import * as DOMPurify from "dompurify";
import { Link } from "react-router-dom";

function RoomSuggestion({ rooms }) {
  return (
    <StyledRoomSuggestionContainer>
      {rooms?.map((r) => {
        return (
          <StyledRoomSuggestion>
            <StyledRoomSuggestionLogo src={r?.logo} alt={r?.name} />

            <StyledRoomSuggestionInnerContainer>
              <StyledRoomSuggestionName>{r?.name}</StyledRoomSuggestionName>
              <Rating
                allowFraction={true}
                initialValue={r?.get_average_rating}
                size={20}
                readonly
              />
              <div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <span style={{ fontSize: "2rem", fontWeight: "500" }}>
                    {r?.get_average_rating}
                  </span>
                  / 5.0
                </div>
                <Link
                  style={{ color: "#000", fontSize: "1.2rem" }}
                  to={`/rooms/${r?.slug}/reviews/`}
                >
                  <VscFeedback />
                  Відгуків ({r?.get_feedback_count})
                </Link>
              </div>
            </StyledRoomSuggestionInnerContainer>

            <StyledRoomSuggestionInnerContainer style={{ marginRight: "2rem" }}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  backgroundColor: "#fff",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                  marginBottom: "1rem",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(r?.description_preview),
                }}
              ></div>
              <div style={{ fontSize: "1.4rem" }}>
                <span style={{ color: "rgb(121, 122, 123)" }}>Бонус:</span>{" "}
                {r?.first_bonus}
              </div>
              <div style={{ fontSize: "1.4rem" }}>
                <span style={{ color: "rgb(121, 122, 123)" }}>Рейкбек:</span>{" "}
                {r?.rakeback}
              </div>
            </StyledRoomSuggestionInnerContainer>

            <StyledRoomSuggestionButtonsContainer>
              <StyledRegistrationLink
                style={{ margin: "0 0 1rem" }}
                to={`/rooms/${r?.slug}/aff/`}
                target="_blank"
              >
                Зареєструватися
              </StyledRegistrationLink>
              <StyledTranparentLink to={`/rooms/${r?.slug}/`}>
                <BsSearchHeart />
                Огляд
              </StyledTranparentLink>
            </StyledRoomSuggestionButtonsContainer>
          </StyledRoomSuggestion>
        );
      })}
    </StyledRoomSuggestionContainer>
  );
}

export default RoomSuggestion;
