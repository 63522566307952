import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledModalLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  text-decoration: underline;

  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  &:active {
    color: rgba(0, 0, 0, 0.8);
  }
`;
