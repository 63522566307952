import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { confirmEmail } from "../../services/authentication";
import useApi from "../../services/useApi";
import Spinner from "../shared/Spinner";
import { BsEnvelope, BsEnvelopeOpen } from "react-icons/bs";
import { VscError } from "react-icons/vsc";
import { GrClose } from "react-icons/gr";
import Button from "../Button";
import { StyledCloseModalBtn } from "../styles/Button.styled";

function SuccessConfirmEmailModal() {
  const params = useParams();
  const { data, loading, error, request } = useApi(confirmEmail);

  useEffect(() => {
    request("dj-rest-auth/registration/verify-email/", { key: params.key });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    if (error?.response?.data?.detail === "Not found.") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "55rem",
            textAlign: "center",
            margin: "0 4rem",
          }}
        >
          {/* <Button
            icon={<GrClose />}
            styled={StyledCloseModalBtn}
            onClick={(e) => {
              e.preventDefault();
            }}
          /> */}

          <div style={{ fontSize: "10rem", color: "red" }}>
            <BsEnvelopeOpen />
          </div>
          <h2>Email вже підтверджено або невірне посилання підтвердження</h2>
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "55rem",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "10rem", color: "red" }}>
          <VscError />
        </div>
        <h2>Щось пішло не так...</h2>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "55rem",
        textAlign: "center",
      }}
    >
      <div style={{ fontSize: "10rem", color: "#02980e" }}>
        <BsEnvelope />
      </div>
      <h2>Email підтверджено! Тепер Ви можете увійти в особистий кабінет.</h2>
    </div>
  );
}

export default SuccessConfirmEmailModal;
