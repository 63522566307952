import styled from "styled-components";

export const StyledForm = styled.form`
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
`;

export const StyledContainer = styled.fieldset`
  font-family: "Montserrat";
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.2;
  border: 0;
  height: 5rem;
  cursor: text;
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 41, 91, 0.06);
  margin-top: -1px;

  &:first-of-type {
    margin-top: 0;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  /* &:not(:last-of-type) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  } */

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
  }
`;

export const StyledLabel = styled.label`
  font-family: "Montserrat";
  font-size: 1.4rem;
  line-height: 1.42;
  letter-spacing: normal;
  overflow-wrap: break-word;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-width: 10rem;
  max-width: 15rem;
  padding-left: 2rem;
  color: inherit;
  word-break: break-word;
  cursor: inherit;
`;

export const StyledInput = styled.input`
  font-family: "Montserrat";
  width: 100%;
  flex-grow: 1;
  border: 0;
  border-radius: 0.8rem;
  height: 5rem;
  background-color: transparent;
  padding: 0px 1.9rem 0px 0.9rem;
  font-size: 1.4rem;
  line-height: 1.42;
  letter-spacing: normal;
  overflow-wrap: break-word;

  &:focus {
    outline: 0px;
  }

  /* &:-webkit-autofill {
    background-color: inherit;
  } */

  &:-webkit-autofill:focus {
    background-color: inherit;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  font-family: "Montserrat";
  flex-grow: 1;
  border: 0;
  border-radius: 0.8rem;
  height: 5rem;
  background-color: transparent;
  padding: 0px 1.9rem 0px 0.9rem;
  font-size: 1.4rem;
  line-height: 1.42;
  letter-spacing: normal;
  overflow-wrap: break-word;

  &:focus {
    outline: 0px;
  }
`;

export const StyledNotice = styled.p`
  white-space: pre-line;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  overflow-wrap: break-word;
  font-size: 1.5rem;
  line-height: 1.3333;
  margin-bottom: 0.4rem;
`;

export const StyledBottomContainer = styled.div`
  margin-top: auto;
  padding: 3rem 0;
`;
