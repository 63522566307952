export default function setStatusColor(status) {
  let color = null;
  switch (status) {
    case "Опубліковано":
    case "Успішно":
      color = "rgb(71 211 54)";
      break;
    case "Видалено":
      color = "#1d232a";
      break;
    case "Перевіряється":
      color = "rgb(255 214 0)";
      break;
    case "Помилка":
      color = "rgb(255 93 93)";
      break;
    case "Статус невідомий":
      color = "#9ca3af";
      break;
    case "Невдало":
      color = "#f43f5e";
      break;
    default:
      color = "#9ca3af";
  }
  return color;
}
