import client from "./client";

export const getMainPageData = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const get404Page = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const getBanners = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};