import React from "react";
import Spinner from "../shared/Spinner";
import { StyledPublicationList } from "../styles/publication/PublicationCard.styled";
import PublicationCard from "./PublicationCard";

function PublicationList({ publications: { data, loading, error } }) {
  if (Array.isArray(data) && !data.length) {
    return (
      <div style={{ textAlign: "center", fontSize: "3rem" }}>
        ⚠️ Публикаций не найдено
      </div>
    );
  }

  return (
    <StyledPublicationList>
      {data?.map((p, i) => {
        return <PublicationCard key={i} publication={p} />;
      })}
    </StyledPublicationList>
  );
}

export default PublicationList;
