import React from "react";
import styled from "styled-components";

export const StyledImgSlider = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 1rem;


  & .slick-list {
    border-radius: 1rem;
    height: 15rem;
  }

  & img {
    height: 15rem;
    border-radius: 1rem;
    cursor: pointer;
  }
`;
