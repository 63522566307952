import React from "react";
import { StyledFreerollList } from "../styles/freeroll/Freeroll";
import FreerollCard from "./FreerollCard";
import Spinner from "../shared/Spinner";
import { dateInObj } from "../../utils/DateFormater";
import { FaStar } from "react-icons/fa6";
import Button from "../../componets/Button";
import { StyledRegistrationLink } from "../../componets/styles/RegistrationBtn.styled";

function FreerollList({ freerollList }) {
  return (
    <StyledFreerollList>
      {freerollList.map((freeroll) => {
        return <FreerollCard key={freeroll.id} freeroll={freeroll} />;
      })}
    </StyledFreerollList>
  );
}

export default FreerollList;
