import React from "react";
import {
  StyledComment,
  StyledCommentImg,
  StyledCommentContainer,
  StyledCommentAuthor,
  StyledCommentBodyContainer,
  StyledCommentBody,
  StyledCommentTime,
  StyledCommentBottomContainer,
  StyledCommentButtons,
  StyledCommentButton,
} from "../styles/comments/Comments.styled";
import { BiLike, BiDislike, BiCommentAdd, BiTrash } from "react-icons/bi";
import Button from "../Button";
import useApi from "../../services/useApi";
import { deleteComment, gradeComment } from "../../services/publications";
import { useEffect, useState } from "react";
import { useContext } from "react";
import PublicationContext from "../contexts/PublicationContext";
import { useLocation } from "react-router-dom";
import AuthenticationContext from "../contexts/AuthenticationContext";
import { useMediaQuery } from "react-responsive";
import formateDate from "../../utils/DateFormater";
import * as DOMPurify from "dompurify";

function Comment({ data, page, refToScrollTo, style }) {
  const { parent, user, text, date_published, id, comment_likedislike } = data;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    listComments,
    setListComments,
    commentAnswer,
    setCommentAnswer,
    isAnswerClick,
    setIsAnswerClick,
    ed,
    setEd,
  } = useContext(PublicationContext);

  const { state } = useContext(AuthenticationContext);

  let location = useLocation();

  const [commentId, setCommentId] = useState(null);
  const [isScroll, setIsScroll] = useState(false);
  const [voteComment, setVoteComment] = useState(null);
  const [gradeStatus, setGradeStatus] = useState(null);

  const {
    data: dataAfterDeleted,
    loading: loadingAfterDeleted,
    error: errorAfterDeleted,
    request: requestDelete,
  } = useApi(deleteComment);

  const {
    data: dataVote,
    loading: loadingVote,
    error: errorVote,
    request: requestVote,
  } = useApi(gradeComment);

  useEffect(() => {
    setGradeStatus(comment_likedislike);
  }, []);

  // request to delete comment
  useEffect(() => {
    if (commentId) {
      requestDelete(`${location.pathname}comments/${commentId}`, {
        params: { page: page },
      });
    }
  }, [commentId]);

  // update list comments after deleted request
  useEffect(() => {
    if (commentId) {
      setListComments(dataAfterDeleted);
    }
  }, [dataAfterDeleted]);

  // set cursor and focus
  useEffect(() => {
    if (isAnswerClick) {
      ed?.model?.change((writer) => {
        writer.setSelection(ed?.model?.document?.getRoot(), "end");
      });
      ed?.editing?.view?.focus();
      setIsAnswerClick(false);
      scroll();
      setIsScroll(false);
    }
  }, [isAnswerClick]);

  // when isScroll = true and isAnswerClick = false we scrolling, because when isAnswerClick = false editor is reade
  useEffect(() => {
    if (isScroll && !isAnswerClick) {
      scroll();
      setIsScroll(false);
    }
  }, [isScroll, isAnswerClick]);

  // set id wich comment to delete
  const handleDelete = (id) => {
    setCommentId(id);
  };

  // find comment on wich answering
  // set this comment and set scrolling and isAnswer
  const handleAnswer = (e, id) => {
    e.preventDefault();
    const c = listComments.comments.find((item) => id === item.id);
    setCommentAnswer(c);
    setIsScroll(true);
    setIsAnswerClick(true);
  };

  // scroll to element ref
  const scroll = () => {
    refToScrollTo.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const handleLikeClick = (e, id) => {
    e.preventDefault();
    setVoteComment({ vote: "like", id: id });
  };

  const handleDislikeClick = (e, id) => {
    e.preventDefault();
    setVoteComment({ vote: "dislike", id: id });
  };

  useEffect(() => {
    if (voteComment === null) {
      return;
    }
    requestVote(`${location.pathname}comments/${id}/grade/`, voteComment);
    setVoteComment(null);
  }, [voteComment]);

  useEffect(() => {
    if (!dataVote) {
      return;
    }
    setGradeStatus(dataVote);
  }, [dataVote]);

  return (
    <StyledComment style={style} isAnswer={parent}>
      {!isMobile && (
        <StyledCommentImg src={user.photo} alt={user.user.username} />
      )}
      <StyledCommentContainer>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {isMobile && (
            <StyledCommentImg src={user.photo} alt={user.user.username} />
          )}
          <StyledCommentAuthor>{user.user.username}</StyledCommentAuthor>
        </div>
        <StyledCommentBodyContainer>
          <StyledCommentBody
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
          ></StyledCommentBody>
          <StyledCommentBottomContainer>
            <StyledCommentTime>
              {formateDate(date_published, true)}
            </StyledCommentTime>
            <StyledCommentButtons>
              <Button
                icon={<BiCommentAdd />}
                styled={StyledCommentButton}
                text="Відповісти"
                onClick={(e) => handleAnswer(e, id)}
              />
              {state?.currentUser?.username === user?.user?.username && (
                <Button
                  icon={<BiTrash />}
                  styled={StyledCommentButton}
                  text="Видалити"
                  onClick={() => handleDelete(id)}
                />
              )}
              <Button
                icon={<BiLike />}
                styled={StyledCommentButton}
                text={gradeStatus?.likes_count}
                style={gradeStatus?.user_vote ? { color: "green" } : {}}
                onClick={(e) => handleLikeClick(e, id)}
              />
              <Button
                icon={<BiDislike />}
                styled={StyledCommentButton}
                text={gradeStatus?.dislikes_count}
                style={gradeStatus?.user_vote === false ? { color: "red" } : {}}
                onClick={(e) => handleDislikeClick(e, id)}
              />
            </StyledCommentButtons>
          </StyledCommentBottomContainer>
        </StyledCommentBodyContainer>
      </StyledCommentContainer>
    </StyledComment>
  );
}

export default Comment;
