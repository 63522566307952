import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useParams,
  NavLink,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import RoomCard from "../componets/roomDetails/RoomCard.jsx";
import { Left } from "../componets/styles/shared/Left";
import { Right } from "../componets/styles/shared/Right";
import { RoomDetailsMenu } from "../componets/styles/roomDetails/RoomDetails.styled";
import RoomInstruction from "../componets/roomDetails/RoomInstruction";
import RoomReviews from "../componets/roomDetails/RoomReviews";
import RoomReview from "../componets/roomDetails/RoomReview";
import RoomBinding from "../componets/roomDetails/RoomBinding";
import useApi from "../services/useApi";
import { getRoom, getRoomPublications } from "../services/rooms";
import Spinner from "../componets/shared/Spinner";
import { useMediaQuery } from "react-responsive";
import ArticlesBlock from "../componets/sideblocks/ArticlesBlock.jsx";
import FreerollsBlock from "../componets/sideblocks/FreerollsBlock.jsx";
import { getFreerolls } from "../services/freerolls.js";
import Error404 from "../componets/shared/Error404.jsx";
import SeoFields from "../componets/shared/SeoFields.jsx";
import { getBanners } from "../services/pages.js";
import AdsSide from "../componets/shared/AdsSide";
import {
  StyledStickyContainer,
  StyledTitle,
} from "../../src/componets/styles/roomRecomend/RoomRecomend.styled";

function RoomDetails() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const { data, loading, error, request } = useApi(getRoom);
  const {
    data: dataArticles,
    loading: loadingArticles,
    error: errorArticles,
    request: requestArticles,
  } = useApi(getRoomPublications);
  const {
    data: dataFreerolls,
    loading: loadingFreerolls,
    error: errorFreerolls,
    request: requestFreerolls,
  } = useApi(getFreerolls);
  const {
    data: dataBanners,
    loading: loadingBanners,
    error: errorBanners,
    request: requestBanners,
  } = useApi(getBanners);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    request(location.pathname);
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    requestArticles(`/rooms/${data?.slug}/publications/`);
    requestFreerolls(`/freerolls/`, { params: { room__slug: data?.slug } });
  }, [data]);

  useEffect(() => {
    if (dataBanners) {
      return;
    }
    requestBanners("/banners/");
  }, [dataBanners]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error404 />;
  }

  const googleMicroData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        name: data?.name,
        url: data?.logo,
        applicationCategory: "GameApplication",
        // datePublished:
        //   "Укажите допустимую дату и время по стандарту ISO 8601. Например: 2015-07-27 или 2015-07-27T15:30",
        downloadUrl: `https://betraisers.com/rooms/${data?.slug}/aff/`,
        operatingSystem: data?.os_txt,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: data?.rating,
          ratingCount: data?.feedback_count,
        },
        offers: {
          "@type": "Offer",
          price: "0.00",
          priceCurrency: "USD",
        },
      })}
    </script>
  );

  return (
    <>
      {data && (
        <>
          <SeoFields
            title={data?.seo_title}
            description={data?.seo_description}
            keywords={data?.seo_keywords}
            robots={data?.seo_robots}
            canonical={data?.seo_canonical}
            googleMicroData={googleMicroData}
          />
          <Left>
            <RoomCard room={data} />
            <RoomDetailsMenu>
              <NavLink to="" end state={{ scrollto: scrollY }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: "3rem", marginRight: "1rem" }}>
                    📊
                  </div>
                  <div>Огляд {data?.name}</div>
                </div>
              </NavLink>
              <NavLink to="reviews/" state={{ scrollto: scrollY }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: "3rem", marginRight: "1rem" }}>
                    😍
                  </div>
                  <div>Відгуки про {data?.name}</div>
                </div>
              </NavLink>

              <NavLink to="how-start-to-play/" state={{ scrollto: scrollY }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: "3rem", marginRight: "1rem" }}>
                    💸
                  </div>
                  <div>Як почати грати на {data?.name}</div>
                </div>
              </NavLink>

              <NavLink to="binding/" state={{ scrollto: scrollY }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: "3rem", marginRight: "1rem" }}>
                    🪢
                  </div>
                  <div>Привязка {data?.name}</div>
                </div>
              </NavLink>
            </RoomDetailsMenu>

            <Routes>
              <Route
                path="/"
                element={
                  <RoomReview
                    roomContentList={data?.content_room}
                    roomReview={data?.review_room}
                    seoH1={data?.seo_h1}
                  />
                }
              />
              <Route
                path="reviews/"
                element={<RoomReviews rating={data?.rating} roomData={data} />}
              />
              <Route
                path="how-start-to-play/"
                element={
                  <RoomInstruction
                    roomInstruction={data?.reg_instruction}
                    roomData={data}
                  />
                }
              />
              <Route
                path="binding/"
                element={
                  <RoomBinding binding={data?.binding} roomData={data} />
                }
              />
            </Routes>
          </Left>

          <Right>
            {dataArticles && (
              <ArticlesBlock
                articles={{
                  data: dataArticles,
                  loading: loadingArticles,
                }}
              />
            )}
            {dataFreerolls && dataFreerolls?.results.length > 0 && (
              <FreerollsBlock data={dataFreerolls} />
            )}
            {dataBanners && (
              <StyledStickyContainer>
                <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
                <AdsSide dataFromProps={dataBanners} roomSlug={data?.slug} />
              </StyledStickyContainer>
            )}
          </Right>
        </>
      )}
    </>
  );
}

export default RoomDetails;
