import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledPost = styled.div`
  margin-bottom: 1rem;
  background-color: rgb(242, 245, 247);
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const StyledPostAvatar = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const StyledPostUsername = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledPostDate = styled.div`
  color: grey;
`;

export const StyledPostText = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
`;

export const StyledPostTheme = styled(Link)`
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgb(49, 135, 238);
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;
