import React, { useRef, useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import PublicationContext from "../contexts/PublicationContext";
import { StyledFreerollFilter } from "../styles/freeroll/FreerollFilter.styled";

function FreerollFilter({ request, limit, rooms, linkParam }) {
  const upcoming = useRef();
  const exclusive = useRef();
  const room = useRef();
  const date = useRef();
  const search = useRef();

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { params, setParams } = useContext(PublicationContext);

  const [isFilterRequest, setIsFilterRequest] = useState(false);

  const handleFilter = () => {
    let d = new Date();
    const t = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    setParams((prevState) => ({
      ...prevState,
      // date_freeroll__gte: upcoming.current.checked === true ? t : "",
      is_exclusive: exclusive.current.checked || "",
      room__slug: room.current.value,
      date_freeroll: date.current.value,
      name__icontains: search.current.value,
    }));
    setIsFilterRequest(true);
  };

  useEffect(() => {
    if (!isFilterRequest) {
      return;
    }
    request("freerolls/", { params: params });
    setIsFilterRequest(false);
    setParams((prevState) => ({ ...prevState, limit: limit }));
  }, [isFilterRequest]);

  return (
    <StyledFreerollFilter onChange={handleFilter}>
      {/* <fieldset style={{ display: "flex", alignItems: "center", border: "0" }}>
        <input
          ref={upcoming}
          id="upcoming"
          type="checkbox"
          style={{ marginRight: "1rem" }}
        />
        <label htmlFor="upcoming"> Только предстоящие</label>
      </fieldset> */}
      <fieldset
        id="test"
        style={{
          display: "flex",
          alignItems: "center",
          border: "0",
          marginRight: "1rem",
        }}
      >
        <input
          ref={exclusive}
          id="betraisers"
          type="checkbox"
          style={{ margin: "1rem" }}
        />
        <label htmlFor="betraisers"> Приватні betraisers</label>
      </fieldset>
      <fieldset
        style={{
          display: "flex",
          flexDirection: "column",
          border: "0",
          marginRight: "1rem",
        }}
      >
        <label htmlFor="rooms">Фільтр по покер-руму</label>
        <select ref={room} id="rooms" style={{ margin: "0" }}>
          <option value="">Вибиріть зі списку</option>
          {rooms &&
            rooms.map((room, i) => {
              return (
                <option key={i} value={room?.slug}>
                  {room?.name}
                </option>
              );
            })}
        </select>
      </fieldset>
      <fieldset
        style={{
          display: "flex",
          flexDirection: "column",
          border: "0",
          marginRight: "1rem",
        }}
      >
        <label htmlFor="date">Фільтр по даті</label>
        <input ref={date} type="date" name="date" id="date" />
      </fieldset>

      <fieldset
        style={{
          display: "flex",
          flexDirection: "column",
          border: "0",
          flexGrow: "1",
        }}
      >
        <label htmlFor="text">Пошук за назвою</label>
        <input ref={search} type="text" name="text" id="text" />
      </fieldset>
    </StyledFreerollFilter>
  );
}

export default FreerollFilter;
