import { Plugin } from '@ckeditor/ckeditor5-core';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
// import detailsIcon from '@ckeditor/ckeditor5-core/theme/icons/quote.svg'; // Використайте відповідну іконку

export default class DetailsSummaryUI extends Plugin {
    static get pluginName() {
        return 'DetailsSummaryUI';
    }

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('insertDetailsSummary', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Details/Summary',
                // icon: detailsIcon,
                tooltip: true
            });

            view.on('execute', () => {
                editor.execute('insertDetailsSummary');
                editor.editing.view.focus();
            });

            return view;
        });
    }
}
