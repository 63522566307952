import React, { useEffect, useState } from "react";
import { useContext, useRef } from "react";
import PublicationContext from "../../componets/contexts/PublicationContext";
import Button from "../Button";
import { AiOutlineCloudUpload, AiOutlineDelete } from "react-icons/ai";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import { StyledUploadImage } from "../styles/upload/UploadImage.styled";
import { StyledRestorePasswordBtn } from "../styles/Button.styled";

function UploadImage({imgStyle}) {
  const { imageArticle, setImageArticle, image, setImage } =
    useContext(PublicationContext);
  const [error, setError] = useState();
  const hiddenImageInput = useRef();
  const allImages = ["png", "jpg", "image/jpeg"];

  const handleClickImgBtn = (e) => {
    setError();
    hiddenImageInput.current.click();
  };

  const handleChangeImage = (e) => {
    if (allImages.indexOf(e.target.files[0].type) === -1) {
      setError(
        "Цей тип файлу не підтримається! Будь ласка, використовуйте jpeg/jpg або png!"
      );
    } else {
      setError();
      setImageArticle(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const handleDeleteImage = (e) => {
    setImageArticle();
    setImage();
    setError();
  };

  let show;

  useEffect(() => {
    show = error ? <p>{error}</p> : <img src={imageArticle} alt="" />;
  }, [error, imageArticle]);

  return (
    <StyledUploadImage>
      <div>
        {!imageArticle && (
          <Button
            styled={StyledRedBtn}
            text="Завантажити зображення"
            icon={<AiOutlineCloudUpload />}
            onClick={handleClickImgBtn}
            style={{ margin: "0 2rem 2rem 0", width: "fit-content" }}
          />
        )}
        {imageArticle && (
          <Button
            styled={StyledRestorePasswordBtn}
            text="Видалити зображення"
            icon={<AiOutlineDelete />}
            onClick={handleDeleteImage}
            style={{ margin: "0 0 2rem 0", width: "fit-content" }}
          />
        )}
      </div>
      <input type="file" ref={hiddenImageInput} onChange={handleChangeImage} />
      <img style={imgStyle} src={imageArticle} alt="Завантажене зображення" />
      <p>{error}</p>
    </StyledUploadImage>
  );
}

export default UploadImage;
