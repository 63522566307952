import client from "./client";

export const getForumPageData = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const getSubsectionThemeList = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const getThemePostList = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const postThemePost = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const postTheme = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const updateThemePost = (pathname, options, params = {}) => {
  return client.put(pathname, options, params);
};

export const ratePost = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const getAutorPostList = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const addInBookmarks = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const rateTheme = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const getSide = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};