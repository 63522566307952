import styled from "styled-components";

export const StyledBindingTab = styled.div`
  width: 100%;
  padding: 3.6rem 4rem;
  /* background-color: rgb(242, 245, 247);
  border-radius: 1rem; */

  @media (max-width: 767px) {
    padding: 0;
  }
`;
