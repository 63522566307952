import styled from "styled-components";

export const StyledAccordion = styled.div`
  svg {
    transform: ${(props) =>
      props.isActive ? "rotate(0deg)" : "rotate(-90deg)"};
    transition: transform 0.3s ease 0s;
  }
`;

export const StyledAccordionSubMenu = styled.div`
  display: block;
  margin-left: 5.5rem;

  a {
    display: block;
    color: #000000;
    text-decoration: none;
    padding: 1rem;
    font-size: 1.4rem;
  }
`;
