import client from "./client";

export const getPublications = (pathname = "", params = {}) => {
  //   return client.get(`/publications/${pathname}`, params);
  return client.get(pathname, params);
};

export const getPublication = (pathname) => {
  return client.get(pathname);
};

export const getCategories = (pathname) => {
  return client.get(pathname);
};

export const postPublication = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const postGrade = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const getComments = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const postComment = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const deleteComment = (pathname, options, params = {}) => {
  return client.delete(pathname, options, params);
};

export const gradeComment = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};
