import styled from "styled-components";
import { Link } from "react-router-dom";
import randomColor from "../../../utils/RandomColor";

export const StyledUserAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 21.5rem;
  height: 100%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 1rem;
    justify-content: start;
  }
`;

export const StyledUserAccountInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 2rem;
  height: 100%;

  @media (max-width: 767px) {
    margin-left: 2rem;
  }
`;

export const StyledUserAccountUsername = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
`;

export const StyledUserAccountLogout = styled(Link)`
  text-decoration: none;
  font-size: 1.1rem;
  color: #000;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    padding: 1.8rem 0.8rem 1.8rem 3.4rem;
    margin-top: auto;
    text-align: center;
    display: flex;
    align-items: center;

    svg {
      font-size: 2.4rem;
      margin-right: 2.4rem;
    }
  }
`;

export const StyledUserAccountImage = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  /* background-color: #${Math.floor(Math.random() * 16777215).toString(16)}; */
  /* background-color: ${randomColor(-0.5)}; */
`;
