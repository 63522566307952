import styled from "styled-components";

export const StyledThemeList = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(196, 205, 212, 0.5);
  border: 1px solid silver;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;

export const StyledThemeListsubsectionName = styled.h2`
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: #333;
  color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
