import React from "react";
import { StyledSocialAuth } from "./styles/SocialAuth.styled";
import { FaGoogle, FaFacebookF, FaXTwitter } from "react-icons/fa6";
import { StyledSocialAuthBtn } from "./styles/publication/PublicationDetails.styled";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";

function SocialAuth() {
  const navigate = useNavigate();
  const client_id =
    "722121418499-ouigqdf3dgv3c042q69cjl5gjipafbo9.apps.googleusercontent.com";
  const redirect_uri = "https://betraisers.com/dj-rest-auth/google/";
  // const redirect_uri = "http://localhost:8000/api/dj-rest-auth/google/";

  const requestUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect_uri}&prompt=consent&response_type=code&client_id=${client_id}&scope=openid%20email%20profile&access_type=offline`;

  const fb_requestUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=1850110238738889&redirect_uri=https://betraisers.com/dj-rest-auth/facebook/&response_type=token&scope=email`;

  const handleGoogleAuth = (e) => {
    e.preventDefault();
    window.location.replace(requestUrl);
  };

  const handleFacebookAuth = (e) => {
    e.preventDefault(e);
    window.location.replace(fb_requestUrl);
  };

  return (
    <StyledSocialAuth>
      <Button
        type="button"
        styled={StyledSocialAuthBtn}
        icon={<FaGoogle />}
        text='Google'
        onClick={(e) => handleGoogleAuth(e)}
      />
      <Button
        type="button"
        styled={StyledSocialAuthBtn}
        icon={<FaFacebookF />}
        text="Facebook"
        onClick={(e) => handleFacebookAuth(e)}
      />
    </StyledSocialAuth>
  );
}

export default SocialAuth;
