import { createContext, useReducer, useState } from "react";
import authenticationReducer from "./AuthenticationReduser";

const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
  // const [state, setState] = useState({
  //   currentUser: null,
  //   isLoggedIn: null,
  //   isLoading: false,
  // });

  const initialState = {
    currentUser: null,
    isLoggedIn: null,
    isLoading: false,
  };

  const [state, dispatch] = useReducer(authenticationReducer, initialState)

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
