import React from "react";
import { StyledEditArea } from "../styles/roomDetails/EditArea.styled";
import { RoomDetailsContent } from "../styles/roomDetails/RoomDetails.styled";
import * as DOMPurify from "dompurify";


function RoomReview({ roomReview, roomContentList, seoH1 }) {
  return (
    <>
      {roomContentList && (
        <>
        {seoH1 && <h1 style={{textAlign: "center"}}>{seoH1}</h1>}
          <h2
            style={{
              margin: "4rem 0 2rem",
              padding: "0",
              fontSize: "3.2rem",
              lineHeight: "4rem",
              fontWeight: "700",
            }}
          >
            Зміст
          </h2>
          <RoomDetailsContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(roomContentList),
            }}
          ></RoomDetailsContent>
        </>
      )}
      {roomReview && (
        <StyledEditArea
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(roomReview),
          }}
        ></StyledEditArea>
      )}
    </>
  );
}

export default RoomReview;
