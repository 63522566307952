import { Plugin } from '@ckeditor/ckeditor5-core';
import DetailsSummaryEditing from './detailsSummaryEditing';
import DetailsSummaryUI from './detailsSummaryUI';

export default class DetailsSummaryPlugin extends Plugin {
    static get requires() {
        return [DetailsSummaryEditing, DetailsSummaryUI];
    }

    static get pluginName() {
        return 'DetailsSummary';
    }
}
