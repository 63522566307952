import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GrNext, GrPrevious } from "react-icons/gr";
import Button from "../Button";
import { StyledPaginationBtn } from "../styles/Button.styled";

function Pagination({ data, request }) {
  const [pagState, setPagState] = useState(() => ({
    prev: data?.previous || null,
    next: data?.next || null,
    current: 1,
    amount: null,
  }));

  const [directionRequest, setDirectionRequest] = useState(null);

  useEffect(() => {
    if (data) {
      let urlStr = data?.next === null ? data?.previous : data?.next;
      let amount = getPageAmount(data?.count, urlStr);
      setPagState((prevState) => ({
        ...prevState,
        prev: data?.previous,
        next: data?.next,
        current: data?.previous ? prevState.current : 1,
        amount: amount,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!directionRequest) {
      return;
    }
    request(directionRequest);
    setDirectionRequest(null);
  }, [directionRequest]);

  const getLimit = (str) => {
    let limit = new URLSearchParams(new URL(str).search);
    return +limit.get("limit");
  };

  const getPageAmount = (count, urlStr) => {
    if(!urlStr) {
      return 1;
    }
    let limit = getLimit(urlStr);
    return Math.ceil(+count / limit);
  };

  const handleDoRequest = (direction, step) => {
    if (!direction) {
      return;
    }
    setPagState((prevState) => ({
      ...prevState,
      current: prevState.current + step,
    }));
    setDirectionRequest(direction);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "2rem",
        marginTop: "3rem",
      }}
    >
      <Button
        icon={<GrPrevious />}
        styled={StyledPaginationBtn}
        onClick={() => handleDoRequest(pagState.prev, -1)}
      />
      <div style={{ display: "flex", alignItems: "center", margin: "0 2rem" }}>
        {pagState.current} / {pagState.amount}
      </div>
      <Button
        icon={<GrNext />}
        styled={StyledPaginationBtn}
        onClick={() => handleDoRequest(pagState.next, 1)}
      />
    </div>
  );
}

export default Pagination;
