import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledNavLink = styled(NavLink)`
  font-weight: 500;
  margin-right: 3rem;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0.8rem 0 0.8rem;
  transition: opacity 0.3s, color 0.3s, border-bottom 0.3s;

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  &:active {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    margin-bottom: -2px;
    border-bottom: 2px solid #ed4040;
  }

  @media (max-width: 767px) {
    padding: 1.8rem 0.8rem 1.8rem 2.4rem;
    font-size: 1.5rem;

    svg {
      font-size: 2.4rem;
      margin-right: 2.4rem;
    }

    &.active {
      opacity: 1;
      /* border-left: 4px solid #ed4040; */
      border-bottom: 0;
    }
  }
`;
