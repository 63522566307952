import React from "react";
import CasinoPreview from "./CasinoPreview";
import { StyledCasinoList } from "../styles/casino/CasinoPreview.styled";
import Spinner from "../shared/Spinner";

function CasinoList({ casinoList, loading }) {
  if (loading) {
    return <Spinner />;
  }

  return (
    <StyledCasinoList>
      {casinoList?.map((casino) => {
        return <CasinoPreview casino={casino} />;
      })}
    </StyledCasinoList>
  );
}

export default CasinoList;
