import React, { useEffect } from "react";
import {
  StyledStatistics,
  StyledStatisticsTitle,
  StyledStatisticsTable,
  StyledStatisticsTr,
  StyledStatisticsTd,
  StyledStatisticsTh,
} from "../../componets/styles/balance/Statistics.styled";
import useApi from "../../services/useApi";
import { getUserFreerollList } from "../../services/users";
import formateDate from "../../utils/DateFormater";
import Pagination from "../../componets/pagination/Pagination";
import setStatusColor from "../../utils/StatusColor";
import Spinner from "../../componets/shared/Spinner";

function AddedFreerolls() {
  const { data, loading, request } = useApi(getUserFreerollList);

  useEffect(() => {
    request("pa/freerolls/");
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <StyledStatistics>
        <StyledStatisticsTitle>Додані фріроли</StyledStatisticsTitle>
        {loading && <Spinner />}
        {data && !loading && (
          <div style={{ overflow: "scroll" }}>
            <StyledStatisticsTable cellspacing="0" cellpadding="0">
              <StyledStatisticsTr>
                <StyledStatisticsTh>Дата/Час</StyledStatisticsTh>
                <StyledStatisticsTh>Назва</StyledStatisticsTh>
                <StyledStatisticsTh>Рум</StyledStatisticsTh>
                <StyledStatisticsTh>Дата фрірола</StyledStatisticsTh>
                <StyledStatisticsTh>Час фрірола</StyledStatisticsTh>
                <StyledStatisticsTh>Приз</StyledStatisticsTh>
                <StyledStatisticsTh>Байін</StyledStatisticsTh>
                <StyledStatisticsTh>Пароль</StyledStatisticsTh>
                <StyledStatisticsTh>Статус</StyledStatisticsTh>
              </StyledStatisticsTr>

              {data &&
                data?.results.map((i) => {
                  return (
                    <StyledStatisticsTr>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {formateDate(i?.date_published, true)}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.4rem",
                            color: "#000",
                          }}
                        >
                          {i?.name}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {i?.room?.name}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {i?.date_freeroll}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {i?.time_freeroll}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {i?.prizepool}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {i?.buyin}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.4rem",
                            color: "rgb(51, 179, 39)",
                          }}
                        >
                          {i?.pass_freeroll}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            padding: "0.5rem",
                            borderRadius: "0.8rem",
                            color: "white",
                            backgroundColor: setStatusColor(i?.status),
                            fontWeight: "500",
                            fontSize: "1.4rem",
                            textAlign: "center",
                          }}
                        >
                          {i?.status}
                        </div>
                      </StyledStatisticsTd>
                    </StyledStatisticsTr>
                  );
                })}
            </StyledStatisticsTable>
          </div>
        )}
        {data && <Pagination data={data} request={request} />}
      </StyledStatistics>
    </div>
  );
}

export default AddedFreerolls;
