import React from "react";
import { OneBlockCenter } from "../componets/styles/shared/OneBlockCenter";
import { StyledPublicationDetailBody } from "../componets/styles/publication/PublicationDetails.styled";

function Privacy() {
  return (
    <OneBlockCenter>
      <StyledPublicationDetailBody>
        <h1>Політика конфіденційності</h1>
        <p>Політика з обробки особистих даних</p>
        <h2>1. Загальні положення</h2>
        <p>
          Ця політика з обробки особистих даних визначає порядок обробки
          особистих даних та заходи забезпечення безпеки особистих даних, які
          приймає betraisers (далі - Оператор).
        </p>
        <p>
          1.1. Використання Користувачем сайту означає згоду з цією Політикою
          конфіденційності та умовами обробки особистих даних Користувача.
        </p>

        <p>
          1.2. Оператор ставить своєю найважливішою метою та умовою здійснення
          своєї діяльності дотримання прав і свобод людини і громадянина при
          обробці його особистих даних, зокрема захист прав на недоторканність
          особистого життя, особисту та сімейну таємницю.
        </p>

        <p>
          1.3. Ця політика Оператора щодо обробки особистих даних (далі -
          Політика) застосовується до всієї інформації, яку Оператор може
          отримати про відвідувачів веб-сайту https://betraisers.com/ .
        </p>

        <p>
          1.4. Ця Політика конфіденційності застосовується лише до сайту
          https://betraisers.com/ . Сайт не контролює і не несе відповідальності
          за сайти третіх осіб, на які Користувач може перейти за посиланнями,
          доступними на сайті.
        </p>

        <p>
          1.5. Адміністрація сайту не перевіряє достовірність особистих даних,
          наданих Користувачем сайту.
        </p>
        <h2>2. Основні поняття, що використовуються в Політиці</h2>
        <p>
          2.1. Автоматизована обробка персональних даних - обробка персональних
          даних за допомогою засобів обчислювальної техніки;
        </p>
        <p>
          2.2. Блокування персональних даних - тимчасове припинення обробки
          персональних даних (за винятком випадків, коли обробка необхідна для
          уточнення персональних даних);
        </p>

        <p>
          2.3. Веб-сайт - сукупність графічних і інформаційних матеріалів, а
          також програм для ЕОМ і баз даних, що забезпечують їх доступність в
          мережі Інтернет за мережевою адресою https://betraisers.com/ ;
        </p>

        <p>
          2.4. Інформаційна система персональних даних - сукупність вмісту баз
          даних персональних даних та забезпечуючих їх обробку інформаційних
          технологій і технічних засобів;
        </p>

        <p>
          2.5. Анонімізація персональних даних - дії, в результаті яких
          неможливо визначити без використання додаткової інформації належність
          персональних даних конкретному Користувачу чи іншому суб'єкту
          персональних даних;
        </p>

        <p>
          2.6. Обробка персональних даних - будь-яке дія (операція) чи
          сукупність дій (операцій), що виконуються з використанням засобів
          автоматизації або без використання таких засобів з персональними
          даними, включаючи збір, запис, систематизацію, накопичення,
          зберігання, уточнення (оновлення, зміна), вилучення, використання,
          передачу (розповсюдження, надання, доступ), анонімізацію, блокування,
          видалення, знищення персональних даних;
        </p>

        <p>
          2.7. Оператор - державний орган, муніципальний орган, юридична або
          фізична особа, які самостійно чи спільно з іншими особами
          організовують та (або) здійснюють обробку персональних даних, а також
          визначають цілі обробки персональних даних, склад персональних даних,
          які підлягають обробці, дії (операції), які виконуються з
          персональними даними;
        </p>

        <p>
          2.8. Особисті дані - будь-яка інформація, що стосується безпосередньо
          чи опосередковано визначеного Користувача веб-сайту
          https://betraisers.com/ ;
        </p>

        <p>
          2.9. Користувач - будь-який відвідувач веб-сайту
          https://betraisers.com/ ;
        </p>

        <p>
          2.10. Надання персональних даних - дії, спрямовані на викриття
          персональних даних визначеній особі чи визначеному колу осіб;
        </p>

        <p>
          2.11. Розповсюдження персональних даних - будь-які дії, спрямовані на
          викриття персональних даних неозначеному колу осіб (передача
          персональних даних) чи на ознайомлення з персональними даними
          необмеженого колу осіб, включаючи оприлюднення персональних даних у
          засобах масової інформації, розміщення в
          інформаційно-телекомунікаційних мережах або надання доступу до
          персональних даних будь-яким іншим способом;
        </p>

        <p>
          2.12. Транскордонна передача персональних даних - передача
          персональних даних на територію іноземної держави органу влади
          іноземної держави, іноземній фізичній або іноземній юридичній особі;
        </p>

        <p>
          2.13. Знищення персональних даних - будь-які дії, в результаті яких
          персональні дані безвозвратно знищуються з неможливістю подальшого
          відновлення змісту персональних даних в інформаційній системі
          персональних даних і (або) знищуються матеріальні носії персональних
          даних.
        </p>

        <h2>3. Оператор може обробляти наступні особисті дані Користувача:</h2>
        <p>3.1. Прізвище, ім'я, по батькові;</p>

        <p>3.2. Електронна адреса;</p>

        <p>3.3. Номери телефонів;</p>

        <p>3.4. Рік, місяць, дата та місце народження;</p>

        <p>
          3.5. Також на сайті відбувається збір та обробка анонімних даних від
          відвідувачів (включаючи файли "cookie") за допомогою сервісів
          інтернет-статистики (Гугл Аналітика та інших).
        </p>

        <p>
          3.6. Вищезазначені дані подальше в тексті Політики об'єднані загальним
          поняттям "Особисті дані".
        </p>

        <h2>4. Мети обробки особистих даних</h2>
        <p>
          4.1. Мета обробки особистих даних Користувача - інформування
          Користувача шляхом відправлення електронних листів; надання доступу
          Користувачеві до сервісів, інформації та/або матеріалів, що містяться
          на веб-сайті.
        </p>

        <p>
          4.2. Також Оператор має право надсилати Користувачеві сповіщення про
          нові продукти та послуги, спеціальні пропозиції та різноманітні події.
          Користувач завжди може відмовитися від отримання інформаційних
          повідомлень, надіславши Оператору листа на адресу електронної пошти
          support@betraisers.com із позначкою "Відмова від сповіщень про нові
          продукти та послуги та спеціальні пропозиції".
        </p>

        <p>
          4.3. Анонімні дані Користувачів, зібрані за допомогою сервісів
          інтернет-статистики, використовуються для отримання інформації про дії
          Користувачів на сайті, покращення якості сайту та його вмісту.
        </p>

        <p>
          4.4. Надання доступу Користувачеві на сайти або сервіси партнерів з
          метою отримання продуктів, оновлень та послуг.
        </p>

        <h2>5. Правові підстави обробки особистих даних</h2>
        <p>
          5.1. Оператор обробляє особисті дані Користувача лише у випадку їх
          заповнення та/або відправлення Користувачем самостійно через
          спеціальні форми, розташовані на сайті https://betraisers.com/ .
          Заповнюючи відповідні форми та/або відправляючи свої особисті дані
          Оператору, Користувач виражає свою згоду з цією Політикою.
        </p>
        <p>
          5.2. Оператор обробляє анонімні дані про Користувача в тому випадку,
          якщо це дозволено в налаштуваннях браузера Користувача (увімкнено
          збереження файлів "cookie" та використання технології JavaScript).
        </p>

        <h2>
          6. Порядок збору, зберігання, передачі та інших видів обробки
          особистих даних
        </h2>
        <p>
          Безпека особистих даних, які обробляються Оператором, забезпечується
          шляхом реалізації правових, організаційних та технічних заходів,
          необхідних для виконання в повному обсязі вимог чинного законодавства
          у галузі захисту особистих даних.
        </p>
        <p>
          6.1. Оператор забезпечує збереження особистих даних та приймає всі
          можливі заходи, що виключають доступ до особистих даних
          неуповноважених осіб.
        </p>

        <p>
          6.2. Користувач згоден з тим, що Адміністрація сайту може передавати
          особисті дані третім особам, зокрема, сервісам аналізу статистики
          (Google Analytics), рекламним сервісам (Facebook Ads, Google Ads та інших, а також сервісам розсилки пошти).
        </p>

        <p>
          6.3. У випадку виявлення неточностей у особистих даних, Користувач
          може актуалізувати їх самостійно, надсилавши Оператору повідомлення на
          адресу електронної пошти Оператора support@betraisers.com з позначкою
          "Актуалізація особистих даних".
        </p>

        <p>
          6.4. Адміністрація сайту приймає необхідні організаційні та технічні
          заходи для захисту особистої інформації Користувача від незаконного
          або випадкового доступу, знищення, зміни, блокування, копіювання,
          поширення, а також від інших незаконних дій третіх осіб.
        </p>

        <h2>7. Заключні положення</h2>
        <p>
          7.1. Користувач може отримати будь-які роз'яснення з питань, що
          цікавлять його, стосовно обробки його особистих даних, звернувшись до
          Оператора за допомогою електронної пошти support@betraisers.com.
        </p>
        <p>
          7.2. У цьому документі будуть відображені будь-які зміни політики
          обробки особистих даних Оператором. Політика діє безстроково до заміни
          нею новою версією.
        </p>

        <p>
          7.3. Актуальна версія Політики у вільному доступі розміщена в мережі
          Інтернет за адресою https://betraisers.com/privacy/ .
        </p>
      </StyledPublicationDetailBody>
    </OneBlockCenter>
  );
}

export default Privacy;
