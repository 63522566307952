import styled from "styled-components";

export const StyledMobileRoomCard = styled.div`
  border-radius: 1rem;
  width: 100%;
  background-color: ${(props) => props.bgColor || "rgba(98, 149, 110, 0.15)"};
  padding: 1.6rem;
  border: 1px solid lightgrey;
`;

export const StyledMobileRoomCardContainer = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
`;

export const StyledMobileRoomCardLogo = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 1.5rem;
  background-color: #000;
  padding: 1rem;
  object-fit: contain;
`;

export const StyledMobileRoomCardNetwork = styled.div`
  width: fit-content;
  margin-bottom: 0.5rem;
  padding: 0.1rem 0.8rem;
  color: inherit;
  background-color: rgba(0, 41, 91, 0.12);
  border-radius: 0.3rem;
  font-size: 1.2rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.076em;
  color: rgba(0, 0, 0, 0.5);
  overflow-wrap: break-word;
`;

export const StyledMobileRoomCardName = styled.h2`
  font-size: 1.7rem;
  line-height: 1.41;
  white-space: normal;
  overflow: hidden;
  letter-spacing: normal;
  overflow-wrap: break-word;
`;

export const StyledMobileRoomCardNDesc = styled.div`
  font-size: 1.2rem;
  line-height: 1.66;
  letter-spacing: normal;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const StyledMobileRoomCardBonus = styled.div`
  width: 50%;
  font-size: 1.2rem;
  line-height: 1.66;

  div:first-of-type {
    color: rgb(121, 122, 123);
  }
`;

export const StyledMobileRoomCardRake = styled.div`
  width: 50%;
  font-size: 1.2rem;
  line-height: 1.66;

  div:first-of-type {
    color: rgb(121, 122, 123);
  }
`;
