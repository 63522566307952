import client from "./client";

export const login = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const logout = (...args) => {
  return client.post(...args);
};

export const register = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const resend = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const confirmEmail = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const changePswd = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const resetPswd = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const resetPswdConfirm = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const googleAuth = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const facebookAuth = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const getSocialConnections = (pathname = "dj-rest-auth/socialaccounts/", params = {}) => {
  return client.get(pathname, params);
};

export const disconnectSocial = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};