import React, { useContext } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SideNav from "../componets/layout/SideNav";
import EditProfile from "./personalArea/EditProfile";
import ChangePassword from "./personalArea/ChangePassword";
import SocialConnection from "./personalArea/SocialConnection";
import BalanceTab from "./personalArea/BalanceTab";
import BindingTab from "./personalArea/BindingTab";
import { useEffect } from "react";
import AddedFreerolls from "./personalArea/AddedFreerolls";
import AddedArticles from "./personalArea/AddedArticles";
import AddedReviews from "./personalArea/AddedReviews";
import AddedComments from "./personalArea/AddedComments";
import Referals from "./personalArea/Referals";
import ProtectedRoute from "../componets/ProtectedRoute";
import NotFound from "./NotFound";
import AuthenticationContext from "../componets/contexts/AuthenticationContext";
import SeoFields from "../componets/shared/SeoFields";

function PersonalArea() {
  let location = useLocation();
  let navigate = useNavigate();
  const { state } = useContext(AuthenticationContext);

  useEffect(() => {
    location.pathname === "/p" && navigate("/p/balance/");
    location.pathname === "/p/" && navigate("/p/balance/");
  }, []);

  return (
    <>
      <SeoFields
        title="Особистий кабінет | betraisers.com"
        description="Особистий кабінет"
        keywords={null}
        robots="noindex, nofollow"
        canonical="https://betraisers.com/p/balance/"
      />
      <SideNav />
      <Routes>
        <Route path="*" element={<ProtectedRoute />}>
          <Route path="edit-profile/" element={<EditProfile />} />
          <Route path="change-password/" element={<ChangePassword />} />
          <Route path="social-connections/" element={<SocialConnection />} />
          <Route path="balance/" element={<BalanceTab />} />
          <Route path="my-bindings/" element={<BindingTab />} />
          <Route path="added-freerolls/" element={<AddedFreerolls />} />
          <Route path="added-articles/" element={<AddedArticles />} />
          <Route path="my-reviews/" element={<AddedReviews />} />
          <Route path="my-comments/" element={<AddedComments />} />
          <Route path="referals/" element={<Referals />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default PersonalArea;
