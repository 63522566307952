import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledEstablishmentListBlock = styled(Link)`
  display: flex;
  width: 100%;
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
  border: 1px solid rgb(242, 245, 247);
  padding: 2rem;
  cursor: pointer;
  transition-duration: 0.25s;
  margin-bottom: 1rem;
  color: #000;

  &:hover {
    border: 1px solid lightgrey;
  }

  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

export const StyledEstablishmentListBlockImage = styled.img`
  width: 10rem;
  max-width: 10rem;
  max-height: 10rem;
  border-radius: 1rem;
  margin-right: 1.375rem;
  object-fit: scale-down;

  @media (max-width: 767px) {
    width: 6rem;
    max-width: 6rem;
    max-height: 6rem;
  }
`;

export const StyledEstablishmentListBlockName = styled.div`
  font-size: 2rem;
  font-weight: 700;
`;
