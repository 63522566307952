import React, { useState } from "react";

import { StyledRichTxtEditor } from "../styles/richTxtEditor/RichTxtEditor.styled";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import List from "@ckeditor/ckeditor5-list/src/list";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import {
  Emoji,
  EmojiActivity,
  EmojiFlags,
  EmojiFood,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiPlaces,
  EmojiSymbols,
} from "@phudak/ckeditor5-emoji/src";
import SpadeCardPlugin from "./SpadeCardPlugin";

function JustRichTxtEditor({ dataReviewAnswer = null, dataBody }) {
  const editorConfiguration = {
    plugins: [
      BlockQuote,
      Bold,
      Essentials,
      Image,
      ImageInsert,
      // ImageUpload,
      Italic,
      List,
      Paragraph,
      Emoji,
      EmojiPeople,
      EmojiNature,
      EmojiPlaces,
      EmojiFood,
      EmojiActivity,
      EmojiObjects,
      EmojiSymbols,
      EmojiFlags,
      SpadeCardPlugin,
    ],
    toolbar: {
      items: [
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        // "imageUpload",
        "insertImage",
        "|",
        "emoji",
        "|",
        "SpadeCardPlugin",
        "ClubCardPlugin",
        "DiamondCardPlugin",
        "HeartCardPlugin",
        "OffsuitCardPlugin",
      ],
      shouldNotGroupWhenFull: true,
    },
  };

  const [data, setData] = useState("");

  const addQuote = (comment) => {
    if (comment) {
      return (
        `<p><strong>${comment?.user?.user?.username}</strong></p><blockquote>` +
        comment?.text +
        `</blockquote><p><br data-cke-filler="true"></p><p><br data-cke-filler="true"></p>`
      );
    }
    return "";
  };

  return (
    <StyledRichTxtEditor style={{ padding: "1.5rem" }}>
      <CKEditor
        editor={ClassicEditor}
        data={addQuote(dataReviewAnswer)}
        config={editorConfiguration}
        onReady={(editor) => {
          editor.model.change((writer) => {
            writer.setSelection(editor.model?.document?.getRoot(), "end");
          });
          editor.editing.view.focus();
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setData(data);
          dataBody(data)
        }}
        onBlur={(event, editor) => {

        }}
        onFocus={(event, editor) => {

        }}
      />
    </StyledRichTxtEditor>
  );
}

export default JustRichTxtEditor;
