import { useState, useRef, useEffect } from "react";
import { StyledSelect } from "../styles/form/Select.styled";

function Select({
  options,
  placeholder = "Виберіть категорію",
  width = "100%",
  setSelectValue,
  style,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(placeholder);
  const selectRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSelect = (option, value) => {
    setSelected(option);
    setSelectValue(value);
    setIsOpen(false);
  };

  return (
    <StyledSelect style={{ ...style, width }} ref={selectRef}>
      <div
        className={`select-selected ${isOpen ? "select-arrow-active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selected}
      </div>
      <div className={`select-items ${isOpen ? "" : "select-hide"}`}>
        {options.map((option) => (
          <div
            key={option.slug}
            className={`option ${
              selected === option.name ? "same-as-selected" : ""
            }`}
            onClick={() => handleSelect(option.name, option.slug)}
          >
            {option.name}
          </div>
        ))}
      </div>
      <select
        value={selected}
        className="select"
        onChange={(e) => setSelected(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.slug} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </StyledSelect>
  );
}

export default Select;
