import styled from "styled-components";

export const StyledSocialAuth = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem 4rem;
  margin-top: 4rem;

  button {
    margin: 0 1rem;
  }
`;
