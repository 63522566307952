import styled from "styled-components";

export const StyledFreerollBlock = styled.div`
  margin-bottom: 2rem;

  & svg {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const StyledFreerollBlockTitle = styled.div`
  color: #7f7f7f;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin-bottom: 1rem;
`;

export const StyledFreerollBlockList = styled.div``;

export const StyledFreerollBlockItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  cursor: pointer;

  &:last-of-type {
    border: none;
  }

  &:hover {
    background-color: #f2f5f7;
    transition: all 0.1s ease-in;
    border-radius: 1rem;
  }
`;

export const StyledFreerollBlockImg = styled.img`
  width: 3.8rem;
  height: 3.8rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.7rem;
  background-color: #000;
`;
export const StyledFreerollBlockRoom = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.42;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
`;

export const StyledFreerollBlockName = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
`;

export const StyledFreerollBlockPrize = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-left: 1rem;
  color: #ffbc37;
  font-weight: 700;
`;

export const StyledFreerollBlockDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
`;

export const StyledFreerollBlockPassword = styled.div`
  display: flex;
  font-size: 1.3rem;
`;
