import React, { useEffect, useState } from "react";
import Button from "../Button";
import { StyledRegistrationBtn } from "../styles/RegistrationBtn.styled";
import { StyledForm, StyledBottomContainer } from "../styles/form/Form.styled";
import Input from "../form/Input";
import { useContext } from "react";
import PublicationContext from "../contexts/PublicationContext";
import useApi from "../../services/useApi";
import { resend } from "../../services/authentication";
import Modal from "react-modal";


function ResendEmailModal() {
  const { emailResend } = useContext(PublicationContext);
  const { data, loading, error, request } = useApi(resend);
  const [isRequestSent, setIsRequestSent] = useState(false);

  function handleResendEmail(e) {
    e.preventDefault();
    setIsRequestSent(true);
  }

  useEffect(() => {
    if (!isRequestSent) {
      return;
    }
    request("dj-rest-auth/registration/resend-email/", { email: emailResend });
    setIsRequestSent(false);
  }, [isRequestSent]);

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  if (data?.detail === "ok") {
    return <h2>Email with confirmation link was sent. Please follow instruction in email.</h2>
    // return (
    //   <Modal
    //     isOpen={true}
    //     onRequestClose={() => {}}
    //     onAfterClose={() => {}}
    //     contentLabel="Confirm Modal"
    //     className="modal-confirm"
    //     overlayClassName="overlay"
    //   >
    //     <div>TEst</div>
    //   </Modal>
    // );
  }

  return (
    <StyledForm>
      <Input
        type="email"
        name="email"
        id="email"
        label="Email"
        value={emailResend}
        readOnly
      />
      <div style={{ marginTop: "2rem" }}>
        <p>Ваш email ще не підтверджено!</p>
        <p>
          Вам слід підтвердити електронну адресу натиснувши на кнопку
          "Підтвердити email"
        </p>
      </div>
      <StyledBottomContainer>
        <Button
          text="Підтвердити email"
          styled={StyledRegistrationBtn}
          style={{ marginTop: "1rem", marginRight: "1.5rem" }}
          onClick={handleResendEmail}
        />
      </StyledBottomContainer>
    </StyledForm>
  );
}

export default ResendEmailModal;
