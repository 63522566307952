import React, { useContext, useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Footer from "./componets/layout/Footer";
import Navbar from "./componets/layout/Navbar";
import GlobalStyle from "./componets/styles/Global";
import Freerolls from "./pages/Freerolls";
import Main from "./pages/Main";
import Publications from "./pages/Publications";
import Rooms from "./pages/Rooms";
import RoomDetails from "./pages/RoomDetails";
import { Container } from "./componets/styles/shared/Container";
import PublicationDetails from "./pages/PublicationDetails";
import { PublicationProvider } from "./componets/contexts/PublicationContext";
import LoadOnTop from "./componets/LoadOnTop";
import Modal from "react-modal";
import EntranceModal from "./componets/modals/EntranceModal";
import NotFound from "./pages/NotFound";
import PersonalArea from "./pages/PersonalArea";
import AddArticle from "./pages/AddArticle";
import AuthenticationContext from "./componets/contexts/AuthenticationContext";
import CurrentUserChecker from "./componets/shared/CurrentUserChecker";
import ProtectedRoute from "./componets/ProtectedRoute";
import SuccessConfirmEmailModal from "./componets/modals/SuccessConfirmEmailModal";
import Referer from "./componets/Referer";
import useLocalStorage from "./hooks/useLocalStorage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

import { useMediaQuery } from "react-responsive";
import MobNavbar from "./componets/layout/mobile/MobNavbar";
import Casinos from "./pages/Casinos";
import { ToastContainer } from "react-toastify";
import { GrClose } from "react-icons/gr";
import Button from "./componets/Button";
import { StyledCloseModalBtn } from "./componets/styles/Button.styled";
import GoogleAuth from "./componets/shared/GoogleAuth";
import FacebookAuth from "./componets/shared/FacebookAuth";
import CasinoDetails from "./pages/CasinoDetails";
import {
  StyledGamblerNotification,
  StyledNotAdvertisingNotification,
} from "./componets/styles/shared/GamblerNotification.styled";
import Establishments from "./pages/Establishments";
import EstablishmentDetail from "./pages/EstablishmentDetail";
import Forum from "./pages/Forum";
import ForumThemeList from "./pages/ForumThemeList";
import ForumPostList from "./pages/ForumPostList";
import { ForumProvider } from "./componets/contexts/ForumContext";
import ForumCreatePost from "./pages/ForumCreatePost";
import { FaCircleInfo } from "react-icons/fa6";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

export default function App() {
  Modal.setAppElement("#root");
  let location = useLocation();
  let navigate = useNavigate();
  const background = location.state && location.state.background;
  const { state } = useContext(AuthenticationContext);

  function closeModal() {
    document.querySelector("body").className = "";
    navigate("/");
  }

  const queryParams = new URLSearchParams(location.search);
  const refer = queryParams.get("ref");

  const [referer, setReferer] = useLocalStorage("refer");

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (refer) {
      setReferer(refer);
    }
  }, [refer]);

  const [isLogout, setIsLogout] = useState();
  const [isLogin, setIsLogin] = useState();
  const [isRegister, setIsRegister] = useState();

  const testFuncLogout = () => {
    setIsLogout(true);
  };

  const loginPressed = () => {
    setIsLogin(true);
  };

  const registerPressed = () => {
    setIsRegister(true);
  };

  return (
    <>
      {/* <AuthenticationProvider> */}
      <PublicationProvider>
        <CurrentUserChecker>
          <ForumProvider>
            <ToastContainer />
            <GlobalStyle />
            <LoadOnTop>
              {isMobile && (
                <MobNavbar
                  currentUser={state?.currentUser}
                  testFuncLogout={testFuncLogout}
                  loginPressed={loginPressed}
                  registerPressed={registerPressed}
                />
              )}
              <Navbar
                isLogoutProp={isLogout}
                isLoginProp={isLogin}
                isRegisterProp={isRegister}
                setIsLogoutProp={setIsLogout}
                setIsLoginProp={setIsLogin}
                setIsRegisterProp={setIsRegister}
              />

              <main>
                <Container>
                  <StyledNotAdvertisingNotification>
                    <FaCircleInfo />
                    <div>
                      Матеріал на цій сторінці не є рекламою азартних ігор та
                      організаторів букмекерської діяльності, а викладений
                      виключно з метою ознайомлення. Участь в азартних іграх
                      може викликати ігрову залежність. Дотримуйтеся правил
                      (принципів) відповідальної гри. Тільки для осіб 21+.
                    </div>
                  </StyledNotAdvertisingNotification>
                </Container>
                <Container>
                  <StyledGamblerNotification>
                    <p>
                      Участь в азартних іграх може викликати ігрову залежність.
                      Дотримуйтеся правил (принципів) відповідальної гри. Тільки
                      для повнолітніх.
                    </p>
                  </StyledGamblerNotification>
                </Container>

                <Container>
                  <Routes location={background || location}>
                    <Route path="/" element={<Main />} />
                    <Route
                      path="/login/"
                      element={
                        state.isLoggedIn ? (
                          <Navigate to={-1} />
                        ) : (
                          // <Outlet />
                          <Modal
                            isOpen={state?.isLoggedIn === false}
                            onRequestClose={closeModal}
                            contentLabel="Example Modal"
                            className="modal"
                            overlayClassName="overlay"
                            onAfterClose={closeModal}
                          >
                            <EntranceModal form="login" />
                          </Modal>
                        )
                      }
                    />
                    <Route
                      path="/register/"
                      element={
                        <Modal
                          isOpen={true}
                          onRequestClose={closeModal}
                          contentLabel="Example Modal"
                          className="modal"
                          overlayClassName="overlay"
                          onAfterClose={closeModal}
                        >
                          <EntranceModal form="register" />
                        </Modal>
                      }
                    />
                    <Route
                      path="/restore/"
                      element={
                        <Modal
                          isOpen={true}
                          onRequestClose={closeModal}
                          contentLabel="Example Modal"
                          className="modal"
                          overlayClassName="overlay"
                          onAfterClose={closeModal}
                        >
                          <EntranceModal form="restore" />
                        </Modal>
                      }
                    />
                    <Route
                      path="/restore/password/reset/confirm/:uid/:token/"
                      element={
                        <Modal
                          isOpen={true}
                          onRequestClose={closeModal}
                          contentLabel="Password Reset Confirm"
                          className="modal"
                          overlayClassName="overlay"
                          onAfterClose={closeModal}
                        >
                          <Button
                            icon={<GrClose />}
                            styled={StyledCloseModalBtn}
                            onClick={closeModal}
                          />
                          <EntranceModal form="restore-confirm" />
                        </Modal>
                      }
                    />

                    <Route
                      end
                      path="/publications/"
                      element={<Publications publications="" />}
                    />
                    <Route
                      path="/publications/news/"
                      element={<Publications publications="news" />}
                    />
                    <Route
                      path="/publications/articles/"
                      element={<Publications publications="articles" />}
                    />
                    <Route
                      path="/publications/promotions/"
                      element={<Publications publications="promotions" />}
                    />
                    <Route
                      path="/publications/create/"
                      element={<ProtectedRoute />}
                    >
                      <Route
                        path="/publications/create/"
                        element={<AddArticle />}
                      />
                    </Route>
                    <Route
                      path="/publications/:categorySlug/:slug/"
                      element={<PublicationDetails />}
                    />
                    <Route path="/rooms/" element={<Rooms />} />
                    <Route path="/rooms/:name/*" element={<RoomDetails />} />
                    <Route path="/freerolls/" element={<Freerolls />} />
                    <Route path="/freerolls/:room/" element={<Freerolls />} />
                    <Route path="/casinos/" element={<Casinos />} />
                    <Route
                      path="/casinos/:name/*"
                      element={<CasinoDetails />}
                    />

                    <Route
                      path="/hralni-zaklady-ukrainy/"
                      element={
                        <Establishments type="/hralni-zaklady-ukrainy/" />
                      }
                    />
                    <Route
                      path="/hralni-zaklady-ukrainy/:city/"
                      element={
                        <Establishments type="/hralni-zaklady-ukrainy/" />
                      }
                    />
                    <Route
                      path="/hralni-zaklady-ukrainy/:city/:slug/"
                      element={<EstablishmentDetail />}
                    />
                    <Route
                      path="/kazyno/"
                      element={<Establishments type="/kazyno/" />}
                    />
                    <Route
                      path="/kazyno/:city/"
                      element={<Establishments type="/kazyno/" />}
                    />
                    <Route
                      path="/pokerkluby/"
                      element={<Establishments type="/pokerkluby/" />}
                    />
                    <Route
                      path="/pokerkluby/:city/"
                      element={<Establishments type="/pokerkluby/" />}
                    />
                    <Route
                      path="/inshe/"
                      element={<Establishments type="/inshe/" />}
                    />
                    <Route
                      path="/inshe/:city/"
                      element={<Establishments type="/inshe/" />}
                    />

                    <Route
                      path="/p/confirm-email/:key/"
                      element={
                        <Modal
                          isOpen={true}
                          onRequestClose={closeModal}
                          contentLabel="Example Modal"
                          className="modal"
                          overlayClassName="overlay"
                          onAfterClose={closeModal}
                        >
                          <Button
                            icon={<GrClose />}
                            styled={StyledCloseModalBtn}
                            onClick={closeModal}
                          />
                          <SuccessConfirmEmailModal />
                        </Modal>
                      }
                    />

                    <Route path="/p/*" element={<PersonalArea />} />
                    <Route path="/r/:referer/" element={<Referer />} />
                    <Route path="/privacy/" element={<Privacy />} />
                    <Route path="/terms/" element={<Terms />} />
                    <Route path="/forum/" element={<Forum />} />
                    <Route
                      path="/forum/:subsection/"
                      element={<ForumThemeList />}
                    />
                    <Route
                      path="/forum/:subsection/:innersubsection/"
                      element={<ForumThemeList />}
                    />
                    <Route
                      path="/forum/:subsection/create-new-theme/"
                      element={<ProtectedRoute />}
                    >
                      <Route
                        path="/forum/:subsection/create-new-theme/"
                        element={<ForumCreatePost />}
                      />
                    </Route>
                    <Route
                      path="/forum/theme/:themeId/"
                      element={<ForumPostList />}
                    />
                    {/* <Route path="/forum/theme/:themeId/?page=" element={<ForumPostList />} /> */}
                    <Route
                      path="/dj-rest-auth/google/"
                      element={<GoogleAuth />}
                    />
                    <Route
                      path="/dj-rest-auth/facebook/"
                      element={<FacebookAuth />}
                    />
                    {/* <Route path="/*" element={<NotFound />} /> */}
                  </Routes>
                </Container>
              </main>
              <Footer />
            </LoadOnTop>
          </ForumProvider>
        </CurrentUserChecker>
      </PublicationProvider>
      {/* </AuthenticationProvider> */}
    </>
  );
}
