import React from "react";
import ReviewAnswer from "./ReviewAnswer";
import { StyledCommentList } from "../styles/comments/Comments.styled";

function ReviewAnswerList({ list, isShown, answerData, refToScroll }) {
  return (
    <StyledCommentList style={{marginTop: "0"}}>
      {list?.map((i) => {
        return <ReviewAnswer data={i} answerData={answerData} refToScroll={refToScroll} />;
      })}
    </StyledCommentList>
  );
}

export default ReviewAnswerList;
