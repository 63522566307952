import React from "react";
import {
  StyledPublicationCard,
  StyledPublicationImg,
  StyledPublicationInnerContainer,
  StyledPublicationLabel,
  StyledPublicationTitle,
  StyledPublicationDescription,
  StyledPublicationImgContainer,
  StyledPublicationInfoContainer,
  StyledPublicationInfo,
} from "../styles/publication/PublicationCard.styled";
import { BiTime, BiCommentDetail, BiLike } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import formateDate from "../../utils/DateFormater";
import { Link } from "react-router-dom";
import * as DOMPurify from "dompurify";

function PublicationCard({
  publication: {
    slug,
    image,
    title,
    description,
    category,
    date_published,
    comments_count,
    likes_count,
  },
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledPublicationCard key={slug}>
      <StyledPublicationImgContainer
        to={`/publications/${category.slug}/${slug}/`}
        // state={{ categorySlug: category.slug, slug: slug }}
      >
        <StyledPublicationImg src={image} alt={title} loading="lazy" />
      </StyledPublicationImgContainer>
      <StyledPublicationInnerContainer>
        <Link to={`/publications/${category.slug}/${slug}/`}>
          <StyledPublicationTitle>{title}</StyledPublicationTitle>
        </Link>
        <StyledPublicationDescription
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        ></StyledPublicationDescription>
        <StyledPublicationInfoContainer>
          <StyledPublicationInfo>
            <StyledPublicationLabel labelname={category.slug}>
              {category.name}
            </StyledPublicationLabel>
          </StyledPublicationInfo>
          <StyledPublicationInfo>
            <BiTime />
            {formateDate(date_published)}
          </StyledPublicationInfo>
          <StyledPublicationInfo>
            <BiCommentDetail />
            {comments_count}
          </StyledPublicationInfo>
          <StyledPublicationInfo>
            <BiLike />
            {likes_count}
          </StyledPublicationInfo>
        </StyledPublicationInfoContainer>
      </StyledPublicationInnerContainer>
    </StyledPublicationCard>
  );
}

export default PublicationCard;
