import React, { useEffect, useState } from "react";
import {
  StyledCommentContainer,
  StyledCommentBodyContainer,
  StyledComment,
} from "../styles/comments/Comments.styled";
import Button from "../Button";
import { StyledRichTxtEditor } from "../styles/richTxtEditor/RichTxtEditor.styled";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import PublicationContext from "../contexts/PublicationContext";
import { useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
// import ImageInsertViaUrl from "@ckeditor/ckeditor5-image/src/imageinsertviaurl";
// import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import List from "@ckeditor/ckeditor5-list/src/list";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import ParagraphButtonUI from "@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Undo from "@ckeditor/ckeditor5-undo/src/undo";
import SelectAll from "@ckeditor/ckeditor5-select-all/src/selectall";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import { Font } from "@ckeditor/ckeditor5-font";

import {
  Emoji,
  EmojiActivity,
  EmojiFlags,
  EmojiFood,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiPlaces,
  EmojiSymbols,
} from "@phudak/ckeditor5-emoji/src";
import SpadeCardPlugin from "./SpadeCardPlugin";
import ForumContext from "../contexts/ForumContext";
import SpoilerPlugin from "./SpoilerPlugin";
import Abbreviation from "./testPlugin/abbreviation";
import DetailsSummaryPlugin from "./details/DetailsSummaryPlugin";

function ForumRichTextEditor({ style, data: dataTxt }) {
  const {
    dataPost,
    setDataPost,
    dataEditPost,
    setDataEditPost,
    editPost,
    setEditPost,
  } = useContext(ForumContext);

  const editorConfiguration = {
    plugins: [
      Heading,
      ParagraphButtonUI,
      BlockQuote,
      Bold,
      Essentials,
      Image,
      //   ImageInsertViaUrl,
      ImageInsert,
      Font,
      Italic,
      List,
      Paragraph,
      Emoji,
      EmojiPeople,
      EmojiNature,
      EmojiPlaces,
      EmojiFood,
      EmojiActivity,
      EmojiObjects,
      EmojiSymbols,
      EmojiFlags,
      SpadeCardPlugin,
      SpoilerPlugin,
      Table,
      TableToolbar,
      Undo,
      SelectAll,
      WordCount,
      Abbreviation,
      DetailsSummaryPlugin,
    ],
    toolbar: {
      items: [
        "undo",
        `redo`,
        "selectAll",
        "|",
        "heading",
        "paragraph",
        "bold",
        "italic",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "insertImage",
        "insertTable",
        "|",
        "emoji",
        "-",
        "SpadeCardPlugin",
        "ClubCardPlugin",
        "DiamondCardPlugin",
        "HeartCardPlugin",
        "OffsuitCardPlugin",
        "SpoilerPlugin",
        "abbreviation",
        "insertDetailsSummary",
      ],
      shouldNotGroupWhenFull: true,
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        // "tableProperties",
        // "tableCellProperties",
      ],
    },
    heading: {
      options: [
        {
          model: "heading2",
          view: "h2",
          title: "Heading 1",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 2",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 3",
          class: "ck-heading_heading4",
        },
      ],
    },
    allowedContent: "details",
  };

  return (
    <div>
      <StyledRichTxtEditor
        style={{
          borderRadius: "0.5rem",
          padding: "0.5rem 0 0",
          marginBottom: "1rem",
        }}
      >
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={dataEditPost ? editPost : dataPost}
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "min-height",
                "20rem",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            let data = editor.getData();

            if (dataEditPost && dataTxt) {
              setEditPost(data);
            } else {
              setDataPost(data);
            }
          }}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </StyledRichTxtEditor>
    </div>
  );
}

export default ForumRichTextEditor;
