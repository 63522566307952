import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthenticationProvider } from "./componets/contexts/AuthenticationContext";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import uk from "javascript-time-ago/locale/uk";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(uk);
const customLabels = {
  year: {
    one: "{0} рік",
    many: "{0} років",
    other: "{0} роки",
  },
  month: {
    one: "{0} місяць",
    many: "{0} місяців",
    other: "{0} місяця",
  },
  week: {
    one: "{0} тиждень",
    many: "{0} тижні",
    other: "{0} тижня",
  },
  day: {
    one: "{0} день",
    few: "{0} днів",
    other: "{0} дня",
  },
  hour: {
    one: "{0} година",
    many: "{0} годин",
    other: "{0} години",
  },
  minute: {
    one: "{0} хвилина",
    many: "{0} хвилин",
    other: "{0} хвилини",
  },
  second: {
    one: "{0} секунда",
    many: "{0} секунд",
    other: "{0} секунди",
  },
};
TimeAgo.addLabels("uk", "custom", customLabels);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Router>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </Router>
  // </React.StrictMode>
);
