import React, { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Button from "../../componets/Button";
import AuthenticationContext from "../../componets/contexts/AuthenticationContext";
import Input from "../../componets/form/Input";
import { StyledRedBtn } from "../../componets/styles/buttons/RedBtn.styled";
import UploadImage from "../../componets/upload/UploadImage";
import useApi from "../../services/useApi";
import { editProfile, getProfile } from "../../services/users";
import Spinner from "../../componets/shared/Spinner";
import PublicationContext from "../../componets/contexts/PublicationContext";
import ProfileImage from "../../componets/upload/ProfileImage";
import { toast } from "react-toastify";

function EditProfile() {
  const { state } = useContext(AuthenticationContext);
  const [image, setImage] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [dataToEdit, setDataToEdit] = useState(null);
  const [isSaveData, setIsSaveData] = useState(false);

  const { data, loading, error, request } = useApi(getProfile);
  const {
    data: dataEditProfile,
    loading: loadingEditProfile,
    error: errorEditProfile,
    request: requestEditProfile,
  } = useApi(editProfile);

  useEffect(() => {
    request("pa/editprofile/");
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    setDataToEdit({
      telegramId: data?.telegramId,
      skypeId: data?.skypeId,
      phone: data?.phone,
    });
  }, [data]);

  useEffect(() => {
    if (!dataToEdit) {
      return;
    }
    if (image) {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const formData = new FormData();
      formData.append("photo", image);
      for (const [key, value] of Object.entries(dataToEdit)) {
        formData.append(key, value);
      }
      // formData.append("anotherData", JSON.stringify(dataToEdit));
      requestEditProfile("pa/editprofile/", formData, config);
      setIsSaveData(false);
    } else {
      requestEditProfile("pa/editprofile/", dataToEdit);
      setIsSaveData(false);
    }
  }, [isSaveData]);

  useEffect(() => {
    if (!dataEditProfile) {
      return;
    }
    if (!image) {
      toast.success("Збережено!", {
        toastId: 'customId',
      });
      return;
    }
    setImage(null);
    window.location.reload();
  }, [dataEditProfile]);

  const handleSave = () => {
    setIsSaveData(true);
  };

  //----------------------------
  //----------------------------

  // const config = {
  //   headers: { "Content-Type": "multipart/form-data" },
  // };

  // const formData = new FormData();
  // formData.append("image", image);
  // formData.append("anotherData", JSON.stringify(dataToEdit));

  //----------------------------
  //----------------------------
  if(loading) {
    return <Spinner />
  }

  return (
    <>
      {data && (
        <div
          style={{
            width: isMobile ? "100%" : "50%",
            margin: "0 auto",
            marginTop: isMobile ? "0" : "3rem",
          }}
        >
          <div style={{ marginBottom: "3rem" }}>
            <ProfileImage setProfileImage={setImage} profileImg={data?.photo} />
          </div>

          <div>
            <Input
              type="email"
              name="email"
              id="email"
              label="Email"
              value={state.currentUser.email}
              readOnly
            />
            <Input
              type="text"
              name="username"
              id="username"
              label="Username"
              value={state.currentUser.username}
              readOnly
            />
          </div>
          <div style={{ marginTop: "3rem" }}>
            <Input
              value={dataToEdit?.telegramId}
              onChange={(e) =>
                setDataToEdit((prevState) => ({
                  ...prevState,
                  telegramId: e.target.value,
                }))
              }
              type="text"
              name="telegram"
              id="telegram"
              label="Telegram"
            />
            <Input
              value={dataToEdit?.skypeId}
              onChange={(e) =>
                setDataToEdit((prevState) => ({
                  ...prevState,
                  skypeId: e.target.value,
                }))
              }
              type="text"
              name="skype"
              id="skype"
              label="Skype"
            />
            <Input
              value={dataToEdit?.phone}
              onChange={(e) =>
                setDataToEdit((prevState) => ({
                  ...prevState,
                  phone: e.target.value,
                }))
              }
              type="text"
              name="phone"
              id="phone"
              label="Телефон"
            />
          </div>
          <Button
            text={loadingEditProfile ? "" : "Зберегти"}
            styled={StyledRedBtn}
            style={{ marginLeft: "0", marginTop: "1rem" }}
            onClick={handleSave}
            icon={
              loadingEditProfile && (
                <Spinner
                  style={{ width: "2rem", height: "2rem", margin: "0" }}
                />
              )
            }
          />
        </div>
      )}
    </>
  );
}

export default EditProfile;
