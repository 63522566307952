import React from "react";
import { OneBlockCenter } from "../componets/styles/shared/OneBlockCenter";
import { StyledPublicationDetailBody } from "../componets/styles/publication/PublicationDetails.styled";

function Terms() {
  return (
    <OneBlockCenter>
      <StyledPublicationDetailBody>
        <h1>Умови та положення</h1>

        <h2>1. Загальні положення Користувацької угоди</h2>
        <p>
          1.1. Відвідувачем сайту є будь-яка приватна особа, яка зайшла і
          переглянула хоча б одну сторінку сайту без попередньої реєстрації та
          авторизації на сайті чи форумі.
        </p>
        <p>
          1.2. Відвідувач, який зареєструвався та авторизувався за допомогою
          унікальних індивідуальних даних, стає користувачем сайту.
        </p>

        <p>
          1.3. Приймаючи умови цієї угоди при реєстрації, ви підтверджуєте свою
          згоду з правилами betraisers.com.
        </p>

        <p>
          1.4. Сайт залишає за собою право в будь-який момент змінити правила
          використання ресурсу betraisers.com.
        </p>

        <h2>2. Правила використання облікового запису</h2>
        <p>
          2.1. Реєстрація на сайті дозволяється користувачам, які досягли віку,
          встановленого законодавством країни їхнього проживання.
        </p>
        <p>
          2.2. Заборонено створювати обліковий запис повторно у випадку
          блокування адміністрацією за порушення правил Користувацької угоди та
          мати більше одного облікового запису.
        </p>

        <p>
          2.3. На сайті заборонено розміщувати посилання на сторонні ресурси.
        </p>

        <p>
          2.4. Заборонено образливо ставитися до користувачів та порушувати теми
          расового і політичного характеру.
        </p>

        <p>
          2.5. Адміністрація має право блокувати обліковий запис без пояснення
          причин.
        </p>

        <h2>3. Використання матеріалів сайту</h2>
        <p>
          3.1. Авторські права на матеріали сайту, ресурси та сервіси, якщо не
          зазначено інше, належать адміністрації сайту.
        </p>
        <p>
          3.2. Розміщення матеріалів сайту у соціальних мережах дозволяється при
          використанні кнопки "Поділитися".
        </p>

        <p>
          3.3. Публікація матеріалів сайту допускається за умови вказання
          посилання на джерело (betraisers.com).
        </p>

        <h2>4. Відповідальність</h2>
        <p>
          4.1. betraisers несе відповідальність тільки за своїх співробітників.
        </p>
        <p>
          4.2. Не приймаємо претензії в разі завданого шкоди внаслідок помилки в
          публікації чи некоректної роботи сайту.
        </p>

        <p>
          4.3. Відповідальність за достовірність інформації, розміщеної в
          загальнодоступних розділах сайту, несе виключно її автор.
        </p>

        <h2>5. Додаткові питання</h2>
        <p>
          5.1. Якщо у вас виникли додаткові питання чи побажання, звертайтеся за
          адресою support@betraisers.com
        </p>
      </StyledPublicationDetailBody>
    </OneBlockCenter>
  );
}

export default Terms;
