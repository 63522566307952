import styled from "styled-components";

export const LoginBtn = styled.button`
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: 0.25s;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 4rem;
  font-size: 1.4rem;
  line-height: 1.28;
  color: #000000;
  background-color: rgba(0, 41, 91, 0.06);
  padding-left: 2rem;
  padding-right: 2rem;
  border-width: 0px;
  text-decoration: none;
  overflow: visible;
  border-radius: 0.8rem 0 0 0.8rem;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }
`;
