import styled from "styled-components";

export const StyledRichTxtEditor = styled.div`
    display: block;
    width: 100%;
    border-radius: 1rem;
    background-color: rgb(242,245,247);
    font-size: 1.5rem;

    & li {
        /* color: red; */
    }
`;