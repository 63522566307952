import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

const GlobalStyle = createGlobalStyle`

    *,
    *:before,
    *:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    p, span {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    /* input {
        all: unset;
    } */

    input:-webkit-autofill {
        background: transparent;
    }

    input:autofill {
        background-color: transparent;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus {
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: none;
    }

    ul, ol {
        list-style: none;
    }

    html {
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
    }

    button {
        font-family: 'Montserrat';
    }

    #root {
        /* overflow: hidden; */
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    main {
        margin-top: 9.35rem;
        margin-bottom: 4rem;
    }

    .navbar-blur {
        padding: .5rem 0;
        box-shadow: 0 1px 0 0 var(--color-ut-border-10, rgba(0, 0, 0, 0.1));
    }

    .ck.ck-editor__main {
        border: 1px solid lightgray;
        border-radius: 1rem;
    }

    .ck.ck-toolbar {
        border: 0;
        background: transparent;
    }

    .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
        border: 0;
        background: transparent;
    }

    .ck.ck-editor__main>.ck-editor__editable.ck-focused {
        border: 0;
        box-shadow: none;
        background: transparent;
    }

    .ck-cards-grid {
        width: max-content;
    }

    .ck-cards-grid__tiles {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column: auto;
    }

    .ck.ck-button.grid-btn {
        font-size: 2rem;
    }

    .ck.ck-button.grid-btn svg:first-child {
        min-width: 2rem;
    }

    .svg-card-icon {
        width: auto;
    }

    .ck.ck-button.dropdown-icon svg:first-child {
        width: auto;
    }

    .ck.ck-character-grid {
        width: max-content;
        height: 20rem;
        overflow: scroll;
    }

    .ck.ck-character-grid__tiles {
        display: flex;
        flex-wrap: wrap;
    }

    .ck-content p,
    .ck-content table,
    .ck-content h2,
    .ck-content h3,
    .ck-content h4,
    .ck-content h5,
    .ck-content h6,
    .ck-content img,
    .ck-content figure,
    .ck-content ol,
    .ck-content ul,
    .ck-content blockquote {
        margin-bottom: 2rem;
    }

    .ck-content ul {
    padding-left: 4rem;
    list-style: disc;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.5rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  .ck-content ol {
    padding-left: 4rem;
    list-style: auto;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.5rem;
    line-height: 1.41;
    letter-spacing: normal;
    word-wrap: break-word;
  }

  .ck-content details {
    width: 100%;
    border: 1px solid silver;
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 0 2rem;
    margin-bottom: 2rem;
  }

  .ck-content summary {
    /* background-color: green; */

    /* width: 100%; */
    /* margin: 0 -2rem; */
    padding: 2rem 0;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    /* margin-bottom: 2rem; */
  }

    .ReactModal__Body--open {
        overflow: hidden;
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 0.3s;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Content {
        transform: translateY(-100%);
        transition: transform 0.6s;
    }

    .ReactModal__Content--after-open {
        transform: translateY(0px);
    }

    .modal {
        outline: 0px;
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        margin: 2rem auto;
        max-width: 43rem;
        width: 100%;
        min-height: 55rem;
        text-align: left;
        border-radius: 0.8rem;
        box-shadow:
            0 0.4rem 1.4rem rgba(0, 0, 0, 0.1),
            0 1.1rem 6rem rgba(0, 0, 0, 0.1);
        background-color: white;
        /* padding: 4.8rem 5rem 2rem; */
    }

    .modal-big {
        outline: 0px;
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        margin: 2rem auto;
        width: 75%;
        min-height: 55rem;
        text-align: left;
        border-radius: 0.8rem;
        box-shadow:
            0 0.4rem 1.4rem rgba(0, 0, 0, 0.1),
            0 1.1rem 6rem rgba(0, 0, 0, 0.1);
        background-color: white;

        @media (max-width: 767px) {
        width: 100%;
            
        }
    }

    .modal-confirm {
        outline: 0px;
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        margin: 2rem auto;
        padding: 2rem 4rem;
        max-width: fit-content;
        width: 100%;
        text-align: left;
        border-radius: 0.8rem;
        box-shadow:
            0 0.4rem 1.4rem rgba(0, 0, 0, 0.1),
            0 1.1rem 6rem rgba(0, 0, 0, 0.1);
        background-color: white;
    }

    .modal-create-freeroll,
    .modal-add-review {
        outline: 0px;
        display: flex;
        flex-direction: column;
        vertical-align: middle;
        margin: 2rem auto;
        padding: 4rem 6rem;
        max-width: fit-content;
        width: 100%;
        text-align: left;
        border-radius: 0.8rem;
        box-shadow:
            0 0.4rem 1.4rem rgba(0, 0, 0, 0.1),
            0 1.1rem 6rem rgba(0, 0, 0, 0.1);
        background-color: white;

        @media (max-width: 767px) {
            padding: 2rem;
        }
    }

    .overlay {
        overflow: auto;
        /* display: flex; */
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        inset: 0px;
        padding: 0px 2rem;
        text-align: center;
        background-color: rgba(0, 0, 20, 0.4);
        backdrop-filter: blur(2rem);
    }

    //Slick slider
    .slick-track
    {
        display: flex !important;
    }

    .slick-slide
    {
        height: inherit !important;
    }

    .slick-slide > div {
        margin: 0 0.5rem;
        height: 100%;
    }

    .slick-slide > div > div {
        height: 100%;
    }

    .slick-slide {
        z-index: 998;
    }

    .slick-active.slick-current {
        z-index: 999;
    }

    .EZDrawer .EZDrawer__container {
        background: rgba(255, 255, 255, 0.75);
    }

    .drawer {
        width: 32rem;
        backdrop-filter: saturate(180%) blur(20px);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        /* background: rgba(255, 255, 255, 0.75); */
    }

    .PhotoView-Portal {
        z-index: 99999;
    }

    @media (max-width: 767px) {
        .navbar-blur {
            padding: .15rem 1.6rem;
            box-shadow: 0 1px 0 0 var(--color-ut-border-10, rgba(0, 0, 0, 0.1));
        }

    }

    .Toastify__toast {
        background-color: rgb(242, 245, 247);
        /* border: 1px solid grey; */
        border-radius: 1rem;
        box-shadow: none;
    }

    .Toastify__toast-body {
        font-size: 1.5rem;
    }

    /* @media screen and (-webkit-min-device-pixel-ratio:0) {
        select,
        textarea,
        input {
            font-size: 16px;
        }
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
        select:focus,
        textarea:focus,
        input:focus {
            font-size: 16px;
        }
    } */


`;

export default GlobalStyle;
