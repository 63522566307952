import React from "react";
import {
  StyledTheme,
  StyledThemeTitleContainer,
  StyledThemeTitle,
  StyledThemeStatContainer,
  StyledThemeLastPostContainer,
  StyledThemeLastPostDate,
  StyledThemeLastPostAuthor,
  StyledThemeAuthor,
  StyledTheme_Mob,
  StyledThemeTitleContainer_Mob,
  StyledThemeTitle_Mob,
  StyledThemeStatContainer_Mob,
  StyledThemeLastPostContainer_Mob,
  StyledThemeLastPostDate_Mob,
  StyledThemeLastPostAuthor_Mob,
  StyledThemeAuthor_Mob,
} from "../styles/forum/Theme.styled";
import formateDate from "../../utils/DateFormater";
import { FaRegEye } from "react-icons/fa";
import { FaRegMessage } from "react-icons/fa6";
import { BiDislike, BiLike } from "react-icons/bi";
import { FaMessage } from "react-icons/fa6";
import isPostDateLessThan30Days from "../../utils/DateCompare";
import { useMediaQuery } from "react-responsive";

function Theme({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  if (isMobile) {
    return (
      <StyledTheme_Mob>
        <div style={{ display: "flex", marginBottom: "1.5rem" }}>
          <div
            style={{
              color: isPostDateLessThan30Days(data?.last_post?.date_creation)
                ? "#ed4040"
                : "grey",
              fontSize: "1.7rem",
              marginRight: "0.5rem",
            }}
          >
            <FaMessage />
          </div>
          <StyledThemeTitleContainer_Mob>
            <StyledThemeTitle_Mob to={`/forum/theme/${data?.id}/?page=last`}>
              {data?.name}
            </StyledThemeTitle_Mob>
            <StyledThemeAuthor_Mob>
              {data?.author?.user?.username}
            </StyledThemeAuthor_Mob>
          </StyledThemeTitleContainer_Mob>
        </div>

        <StyledThemeStatContainer_Mob>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
              color: "grey",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              {/* <div style={{ fontSize: "0.8rem" }}>Переглядів</div> */}
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaRegEye style={{ marginRight: "0.5rem" }} />
                {data?.view_count}
              </div>
            </div>
            <div style={{ marginRight: "1rem" }}>
              {/* <div style={{ fontSize: "0.8rem" }}>Постів</div> */}
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaRegMessage style={{ marginRight: "0.5rem" }} />
                {data?.post_amount}
              </div>
            </div>

            <div style={{ marginRight: "1rem" }}>
              {/* <div style={{ fontSize: "0.8rem" }}>Лайків</div> */}
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <BiLike style={{ marginRight: "0.5rem" }} />
                {data?.likes}
              </div>
            </div>

            <div style={{ marginRight: "1rem" }}>
              {/* <div style={{ fontSize: "0.8rem" }}>Дизлайків</div> */}
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <BiDislike style={{ marginRight: "0.5rem" }} />
                {data?.dislikes}
              </div>
            </div>
          </div>
        </StyledThemeStatContainer_Mob>

        <StyledThemeLastPostContainer_Mob>
          {/* <StyledThemeLastPost>{data?.last_post?.theme}</StyledThemeLastPost> */}
          <div style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}>
            Останній пост:
          </div>
          <div style={{ display: "flex", lineHeight: "2rem" }}>
            <StyledThemeLastPostDate_Mob
              style={{ color: "grey", fontWeight: "500" }}
            >
              {formateDate(data?.last_post?.date_creation)}
            </StyledThemeLastPostDate_Mob>
            <StyledThemeLastPostAuthor_Mob
              style={{ color: "grey", fontSize: "1rem", fontWeight: "700" }}
            >
              {data?.last_post?.author}
            </StyledThemeLastPostAuthor_Mob>
          </div>
        </StyledThemeLastPostContainer_Mob>
      </StyledTheme_Mob>
    );
  } else {
    return (
      <StyledTheme>
        <div
          style={{
            color: isPostDateLessThan30Days(data?.last_post?.date_creation)
              ? "#ed4040"
              : "grey",
            fontSize: "2rem",
            marginRight: "2rem",
          }}
        >
          <FaMessage />
        </div>
        <StyledThemeTitleContainer>
          <StyledThemeTitle to={`/forum/theme/${data?.id}/?page=last`}>
            {data?.name}
          </StyledThemeTitle>
          <StyledThemeAuthor>{data?.author?.user?.username}</StyledThemeAuthor>
        </StyledThemeTitleContainer>

        <StyledThemeStatContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
              color: "grey",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <div style={{ fontSize: "0.8rem" }}>Переглядів</div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaRegEye style={{ marginRight: "0.5rem" }} />
                {data?.view_count}
              </div>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <div style={{ fontSize: "0.8rem" }}>Постів</div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaRegMessage style={{ marginRight: "0.5rem" }} />
                {data?.post_amount}
              </div>
            </div>

            <div style={{ marginRight: "1rem" }}>
              <div style={{ fontSize: "0.8rem" }}>Лайків</div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <BiLike style={{ marginRight: "0.5rem" }} />
                {data?.likes}
              </div>
            </div>

            <div style={{ marginRight: "1rem" }}>
              <div style={{ fontSize: "0.8rem" }}>Дизлайків</div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <BiDislike style={{ marginRight: "0.5rem" }} />
                {data?.dislikes}
              </div>
            </div>
          </div>
        </StyledThemeStatContainer>

        <StyledThemeLastPostContainer>
          {/* <StyledThemeLastPost>{data?.last_post?.theme}</StyledThemeLastPost> */}
          <div style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}>
            Останній пост:
          </div>
          <div style={{ display: "flex", lineHeight: "2rem" }}>
            <StyledThemeLastPostDate
              style={{ color: "grey", fontWeight: "500" }}
            >
              {formateDate(data?.last_post?.date_creation)}
            </StyledThemeLastPostDate>
            <StyledThemeLastPostAuthor
              style={{ color: "grey", fontSize: "1rem", fontWeight: "700" }}
            >
              {data?.last_post?.author}
            </StyledThemeLastPostAuthor>
          </div>
        </StyledThemeLastPostContainer>
      </StyledTheme>
    );
  }
}
export default Theme;
