import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  StyledBonuses,
  StyledCardContainer,
  StyledCard,
  StyledLogo as StyledLogoSide,
  StyledName as StyledNameSide,
  StyledContainer,
  StyledInnerContainer,
  StyledTitle,
  StyledStickyContainer,
} from "../../src/componets/styles/roomRecomend/RoomRecomend.styled";

import { Left } from "../componets/styles/shared/Left";
import { StyledPageTitle } from "../componets/styles/shared/PageTitle.styled";
import { Right } from "../componets/styles/shared/Right";
import useApi from "../services/useApi";
import { getFreerolls } from "../services/freerolls";
import { Link, useLocation } from "react-router-dom";
import FreerollList from "../componets/freerolls/FreerollList";
import Spinner from "../componets/shared/Spinner";
import Button from "../componets/Button";
import { StyledLoadMoreBtn } from "../componets/styles/shared/LoadMoreBtn.styled";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import Modal from "react-modal";
import CreateFreerollModal from "../componets/modals/CreateFreerollModal";
import { StyledCloseModalBtn } from "../componets/styles/Button.styled";
import { GrClose } from "react-icons/gr";
import FreerollFilter from "../componets/freerolls/FreerollFilter";
import PublicationContext from "../componets/contexts/PublicationContext";
import AuthenticationContext from "../componets/contexts/AuthenticationContext";
import Suggestion from "../componets/suggestions/Suggestion";
import { getRooms } from "../services/rooms";
import Error404 from "../componets/shared/Error404";
import SeoFields from "../componets/shared/SeoFields";
import AdsSide from "../componets/shared/AdsSide";

function Freerolls() {
  const { params, setParams } = useContext(PublicationContext);
  let { room } = useParams();
  const { state } = useContext(AuthenticationContext);
  const { data, loading, error, request } = useApi(getFreerolls);
  const {
    data: dataRooms,
    loading: loadingRooms,
    error: errorRooms,
    request: requestRooms,
  } = useApi(getRooms);
  const location = useLocation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(0);
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [isLoadMoreFreerolls, setIsLoadMoreFreerolls] = useState(false);
  const [openCreateFreeroll, setOpenCreateFreeroll] = useState(false);

  useEffect(() => {
    request("freerolls/", { params: { room__slug: room } });
  }, []);

  useEffect(() => {
    if (dataRooms) {
      return;
    }
    requestRooms("/rooms/");
  }, [dataRooms]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isFirstRequest) {
      if (data.next) {
        const params = new URLSearchParams(new URL(data?.next).search);
        setLimit((prevState) => prevState + +params.get("limit"));
        setParams((prevState) => ({
          ...prevState,
          limit: +params.get("limit") * 2,
        }));
      }

      setIsFirstRequest(false);
      setOpenCreateFreeroll(location?.state?.isModalAddFreerollOpen || false);
      window.history.replaceState(location.state, "");
    }
    setIsLoadMoreFreerolls(false);
  }, [data]);

  useEffect(() => {
    if (!isLoadMoreFreerolls) {
      return;
    }
    request(`freerolls/`, { params: params });
    setParams((prevState) => ({
      ...prevState,
      limit: prevState.limit + limit,
    }));
    setIsLoadMoreFreerolls(false);
  }, [isLoadMoreFreerolls]);

  const handleLoadMoreFreerolls = (e) => {
    // e.preventDefault();
    setIsLoadMoreFreerolls(true);
  };

  const handleCreateFreeroll = (e) => {
    e.preventDefault();
    if (!state?.isLoggedIn) {
      navigate("/login/");
    } else {
      setOpenCreateFreeroll(true);
    }
  };

  const closeModal = () => {
    document.querySelector("body").className = "";
    setOpenCreateFreeroll(false);
    setIsLoadMoreFreerolls(true);
    // window.location.reload(false);
  };

  if (error) {
    return <Error404 />;
  }

  return (
    <>
      <SeoFields
        title="Паролі на фріроли: безкоштовні турніри сьогодні"
        description="Дійсні паролі на приватні турніри, фріроли та їх розклад. Паролі на Pokerstars, 888poker, ACR Poker, PokerBet та інших покер румів"
        keywords={null}
        robots="index, follow"
        canonical="https://betraisers.com/freerolls/"
      />
      <Left>
        <Modal
          isOpen={openCreateFreeroll}
          onRequestClose={closeModal}
          onAfterClose={closeModal}
          contentLabel="Create Freeroll"
          className="modal-create-freeroll"
          overlayClassName="overlay"
        >
          <Button
            icon={<GrClose />}
            styled={StyledCloseModalBtn}
            onClick={closeModal}
          />
          <CreateFreerollModal rooms={dataRooms?.results} />
        </Modal>

        <StyledPageTitle>Паролі на фріроли</StyledPageTitle>

        <FreerollFilter
          request={request}
          limit={limit}
          rooms={dataRooms?.results}
          linkParam={room}
        />
        {isFirstRequest && loading && <Spinner />}
        {data && <FreerollList freerollList={data?.results} />}
        {data?.next && (
          <Button
            styled={StyledLoadMoreBtn}
            onClick={handleLoadMoreFreerolls}
            style={{ marginTop: "2rem" }}
            disabled={loading}
            text={!loading && "Показати більше"}
            icon={
              loading && <Spinner style={{ width: "2rem", height: "2rem" }} />
            }
          />
        )}
      </Left>

      <Right>
        {/* <Suggestion
          title="10 BR Points за фрірол"
          text="Знаєш пароль на фрірол якого нема в списку? Додавай його і отримуй 10 BR Points ($0.1 USD) на свій рахунок."
          buttons={
            <Button
              styled={StyledRedBtn}
              style={{ margin: "1rem 0" }}
              text="Додати фрірол"
              onClick={handleCreateFreeroll}
            />
          }
        /> */}
        {dataRooms && (
          <StyledContainer>
            <StyledTitle>⭐️ Рекомендовані покер-руми</StyledTitle>

            {dataRooms?.results.map((room, i) => {
              if (i >= 10) {
                return;
              }
              return (
                <Link key={i} to={`/rooms/${room.slug}/`}>
                  <StyledCardContainer>
                    <StyledCard>
                      <StyledLogoSide src={room?.logo_small} alt={room?.name} />
                      <StyledInnerContainer>
                        <StyledNameSide>{room?.name}</StyledNameSide>
                        <StyledBonuses>{room?.first_bonus}</StyledBonuses>
                      </StyledInnerContainer>
                    </StyledCard>
                  </StyledCardContainer>
                </Link>
              );
            })}
          </StyledContainer>
        )}
        <StyledStickyContainer>
          <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
          <AdsSide />
        </StyledStickyContainer>
      </Right>
    </>
  );
}

export default Freerolls;
