import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "./Spinner";
import useApi from "../../services/useApi";
import { facebookAuth } from "../../services/authentication";
import useLocalStorage from "../../hooks/useLocalStorage";

function FacebookAuth({ isConnect = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading, error, request } = useApi(facebookAuth);
  const [token, setToken] = useLocalStorage("token");

  useEffect(() => {
    // console.log("searchParams", searchParams); //???? if empty
    let params = new URLSearchParams(location.hash.replace("#", ""));
    let fb_token = params.get("access_token");
    const requestUrl = isConnect
      ? "dj-rest-auth/facebook/connect/"
      : "dj-rest-auth/facebook/";

    if (!fb_token) {
      return;
    }

    request(requestUrl, {
      access_token: fb_token,
    });
  }, [searchParams]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setToken(data?.key);
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (data?.key) {
    if (isConnect) {
      navigate("/p/social-connections");
    } else {
      navigate(-2);
    }
  }
}

export default FacebookAuth;
