import styled from "styled-components";

export const Left = styled.div`
    width: 75%;
    margin-right: 1%;

    @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
    }
`;