import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ThemeList from "../componets/forum/ThemeList";
import { Left } from "../componets/styles/shared/Left";
import useApi from "../services/useApi";
import { getSide, getSubsectionThemeList } from "../services/forum";
import Spinner from "../componets/shared/Spinner";
import ForumBreadcrumbs from "../componets/breadcrumbs/ForumBreadcrumbs";
import Button from "../componets/Button";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import { FaPlus } from "react-icons/fa6";
import { Right } from "../componets/styles/shared/Right";
import SideBlock from "../componets/forum/SideBlock";
import List from "../componets/forum/side/List";
import Post from "../componets/forum/side/Post";
import Theme from "../componets/forum/side/Theme";
import ForumPagination from "../componets/pagination/ForumPagination";
import InnerSubsectionList from "../componets/forum/InnerSubsectionList";
import { useMediaQuery } from "react-responsive";

function ForumThemeList() {
  const { subsection, innersubsection } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data, loading, error, request } = useApi(getSubsectionThemeList);
  const {
    data: dataSide,
    loading: loadingSide,
    error: errorSide,
    request: requestSide,
  } = useApi(getSide);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (!subsection) {
      return;
    }
    if (innersubsection) {
      request(
        `/forum/themes/?inner_subsection__id=${innersubsection}${
          searchParams.get("page") ? "&page=" + searchParams.get("page") : ""
        }`
      );
    } else {
      request(
        `/forum/themes/?subsection__slug=${subsection}${
          searchParams.get("page") ? "&page=" + searchParams.get("page") : ""
        }`
      );
    }
    requestSide("/forum/side/");
  }, [subsection, innersubsection, searchParams]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Left>
        <div
          style={{
            display: "flex",
            marginBottom: "1rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <ForumBreadcrumbs
            data={[
              {
                name: "Форум",
                slug: "/forum/",
              },
              {
                name: data?.results?.[0]?.subsection?.name,
                slug: `/forum/${data?.results?.[0]?.subsection?.slug}/`,
              },
              data?.results?.[0]?.inner_subsection?.name &&
                innersubsection && {
                  name: data?.results?.[0]?.inner_subsection?.name,
                  slug: `/forum/${data?.results?.[0]?.subsection?.slug}/${data?.results?.[0]?.inner_subsection?.id}/`,
                },
            ]}
          />
          <Button
            icon={<FaPlus />}
            styled={StyledRedBtn}
            text="Нова тема"
            style={{
              margin: isMobile ? "0" : "0 0 0 auto",
              fontSize: isMobile ? "1.4rem" : "1.2rem",
              width: isMobile && "fit-content",
              borderRadius: "0.5rem",
            }}
            onClick={() => navigate(`/forum/${subsection}/create-new-theme/`)}
          />
        </div>

        {data?.results?.[0]?.subsection?.inner_subsections &&
        data?.results?.[0]?.subsection?.inner_subsections.length > 0 &&
        !innersubsection ? (
          <>
            <InnerSubsectionList data={data?.results?.[0]?.subsection} />
            <ThemeList data={data?.results} />
            <ForumPagination data={data} />
          </>
        ) : (
          data && (
            <>
              <ThemeList data={data?.results} />
              <ForumPagination data={data} />
            </>
          )
        )}
        {/* 
        {data && (
          <>
            <ThemeList data={data?.results} />
            <ForumPagination data={data} />
          </>
        )} */}
      </Left>
      <Right>
        {loadingSide && <Spinner />}
        {dataSide && (
          <>
            <SideBlock title="Останні пости">
              <List item={Post} data={dataSide?.last_posts} />
            </SideBlock>
            <SideBlock title="Нові теми">
              <List item={Theme} data={dataSide?.last_themes} />
            </SideBlock>
          </>
        )}
      </Right>
    </>
  );
}

export default ForumThemeList;
