import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 0 1rem;
    flex-wrap: wrap;
    gap: 4rem;
  }

`;
