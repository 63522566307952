import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledForumPagination = styled.div`
  width: 100%;
  margin: 2rem 0;
  
  ul {
    display: flex;
    width: fit-content;
    margin-left: auto;
  }
`;

export const StyledForumPaginationPage = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.1rem;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 0.3rem;
  background-color: ${(props) => (props.active ? "#ed4040" : "")};
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 1rem;

  a {
    color: ${(props) => (props.active ? "#fff" : "#000")};
  }
`;
