import React from "react";
import {
  StyledBlock__Title,
  StyledBlock__TitleContainer,
  StyledBlock__TitleLink,
  StyledForum__StatsBlock,
  StyledForum__StatsBlockContainer,
  StyledForum__StatsBlockIcon,
  StyledForum__StatsBlockValue,
  StyledForum__StatsBlockValueText,
  StyledForum__StatsContainer,
  StyledForum__Block,
  StyledForum__BlockTitle,
  StyledForum__BlockTheme,
  StyledForum__BlockThemeLink,
  StyledForum__BlockThemeInnerContainer,
  StyledForum__BlockThemeSection,
  StyledForum__BlockThemeTime,
  StyledForum__BlockLink,
  StyledForum__BlockPost,
  StyledForum__BlockPostTopContainer,
  StyledForum__BlockPostUserContainer,
  StyledForum__BlockPostUserImage,
  StyledForum__BlockPostUserName,
  StyledForum__BlockPostText,
  StyledForum__BlockPostBottomContainer,
  StyledForum__BlockPostTheme,
  StyledForum__BlockPostThemeLink,
} from "../../componets/styles/pages/main/Block.styled";
import * as DOMPurify from "dompurify";
import ReactTimeAgo from "react-time-ago";
import { useMediaQuery } from "react-responsive";

function Forum({ forum }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div>
      <StyledBlock__TitleContainer>
        <StyledBlock__Title>Форум</StyledBlock__Title>
        <StyledBlock__TitleLink to="/forum/">
          > Перейти на форум
        </StyledBlock__TitleLink>
      </StyledBlock__TitleContainer>
      <StyledForum__StatsContainer>
        <StyledForum__StatsBlock>
          <StyledForum__StatsBlockContainer>
            <StyledForum__StatsBlockIcon>🤠</StyledForum__StatsBlockIcon>
            <div>
              <StyledForum__StatsBlockValue>
                {forum?.forum_stats?.forum_users_count}
              </StyledForum__StatsBlockValue>
              <StyledForum__StatsBlockValueText>
                форумчан
              </StyledForum__StatsBlockValueText>
            </div>
          </StyledForum__StatsBlockContainer>
        </StyledForum__StatsBlock>

        <StyledForum__StatsBlock>
          <StyledForum__StatsBlockContainer>
            <StyledForum__StatsBlockIcon>✍️</StyledForum__StatsBlockIcon>
            <div>
              <StyledForum__StatsBlockValue>
                {forum?.forum_stats?.forum_themes_count}
              </StyledForum__StatsBlockValue>
              <StyledForum__StatsBlockValueText>
                тем
              </StyledForum__StatsBlockValueText>
            </div>
          </StyledForum__StatsBlockContainer>
        </StyledForum__StatsBlock>

        <StyledForum__StatsBlock>
          <StyledForum__StatsBlockContainer>
            <StyledForum__StatsBlockIcon>✉️</StyledForum__StatsBlockIcon>
            <div>
              <StyledForum__StatsBlockValue>
                {forum?.forum_stats?.forum_posts_count}
              </StyledForum__StatsBlockValue>
              <StyledForum__StatsBlockValueText>
                постів
              </StyledForum__StatsBlockValueText>
            </div>
          </StyledForum__StatsBlockContainer>
        </StyledForum__StatsBlock>

        <StyledForum__StatsBlock>
          <StyledForum__StatsBlockContainer>
            <StyledForum__StatsBlockIcon>👁️</StyledForum__StatsBlockIcon>
            <div>
              <StyledForum__StatsBlockValue>
                {forum?.forum_stats?.forum_views_count}
              </StyledForum__StatsBlockValue>
              <StyledForum__StatsBlockValueText>
                переглядів
              </StyledForum__StatsBlockValueText>
            </div>
          </StyledForum__StatsBlockContainer>
        </StyledForum__StatsBlock>
      </StyledForum__StatsContainer>

      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <StyledForum__Block>
          <StyledForum__BlockTitle>Гарячі теми</StyledForum__BlockTitle>
          <div>
            {forum?.hot_themes?.map((t) => {
              return (
                <StyledForum__BlockTheme>
                  <StyledForum__BlockThemeLink
                    to={`/forum/theme/${t?.id}/?page=last`}
                    target="_blank"
                  >
                    {t?.name}
                  </StyledForum__BlockThemeLink>
                  <StyledForum__BlockThemeInnerContainer>
                    <StyledForum__BlockThemeSection>
                      {t?.subsection?.name}
                    </StyledForum__BlockThemeSection>
                    <StyledForum__BlockThemeTime>
                      <ReactTimeAgo
                        date={t?.last_post?.date_creation}
                        locale="uk-UK"
                        timeStyle={{
                          labels: "short",
                        }}
                      />
                    </StyledForum__BlockThemeTime>
                  </StyledForum__BlockThemeInnerContainer>
                </StyledForum__BlockTheme>
              );
            })}
          </div>
          <StyledForum__BlockLink to="/forum/">
            Більше гарячих тем >
          </StyledForum__BlockLink>
        </StyledForum__Block>

        <StyledForum__Block>
          <StyledForum__BlockTitle>Нові теми</StyledForum__BlockTitle>
          <div>
            {forum?.new_themes?.map((t) => {
              return (
                <StyledForum__BlockTheme>
                  <StyledForum__BlockThemeLink
                    to={`/forum/theme/${t?.id}/?page=last`}
                    target="_blank"
                  >
                    {t?.name}
                  </StyledForum__BlockThemeLink>
                  <StyledForum__BlockThemeInnerContainer>
                    <StyledForum__BlockThemeSection>
                      {t?.subsection?.name}
                    </StyledForum__BlockThemeSection>
                    <StyledForum__BlockThemeTime>
                      <ReactTimeAgo
                        date={t?.date_creation}
                        locale="uk-UK"
                        timeStyle={{
                          labels: "short",
                        }}
                      />
                    </StyledForum__BlockThemeTime>
                  </StyledForum__BlockThemeInnerContainer>
                </StyledForum__BlockTheme>
              );
            })}
          </div>
          <StyledForum__BlockLink to="/forum/">
            Більше нових тем >
          </StyledForum__BlockLink>
        </StyledForum__Block>

        <StyledForum__Block>
          <StyledForum__BlockTitle>Останні пости</StyledForum__BlockTitle>
          <div>
            {forum?.last_posts?.map((p) => {
              return (
                <StyledForum__BlockPost>
                  <StyledForum__BlockPostTopContainer>
                    <StyledForum__BlockPostUserContainer>
                      <StyledForum__BlockPostUserImage
                        src={p?.author?.photo}
                        alt={p?.author?.user?.username}
                      />
                      <StyledForum__BlockPostUserName>
                        {p?.author?.user?.username}
                      </StyledForum__BlockPostUserName>
                    </StyledForum__BlockPostUserContainer>
                    <StyledForum__BlockThemeTime>
                      <ReactTimeAgo
                        date={p?.date_creation}
                        locale="uk-UK"
                        timeStyle={{
                          labels: "short",
                        }}
                      />
                    </StyledForum__BlockThemeTime>
                  </StyledForum__BlockPostTopContainer>

                  <StyledForum__BlockPostText
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        p?.text.replaceAll("<p>&nbsp;</p>", ""),
                        { FORBID_TAGS: ["figure", "img"] }
                      ),
                    }}
                  ></StyledForum__BlockPostText>

                  <StyledForum__BlockPostBottomContainer>
                    <StyledForum__BlockPostTheme>
                      у темі
                    </StyledForum__BlockPostTheme>
                    <StyledForum__BlockPostThemeLink
                      to={`/forum/theme/${p?.theme?.id}/?page=last`}
                      target="_blank"
                    >
                      {p?.theme?.name}
                    </StyledForum__BlockPostThemeLink>
                  </StyledForum__BlockPostBottomContainer>
                </StyledForum__BlockPost>
              );
            })}
          </div>
          <StyledForum__BlockLink to="/forum/">
            Перейти на форум >
          </StyledForum__BlockLink>
        </StyledForum__Block>
      </div>
    </div>
  );
}

export default Forum;
