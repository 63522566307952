import React, { useState, useEffect } from "react";
import {
  StyledSocialConnections,
  StyledSocialConnectionList,
  StyledSocialConnection,
  StyledSocialConnectionInnerContainer,
} from "../../componets/styles/personalArea/SocialConnection.style";
import { FaGoogle, FaFacebookF } from "react-icons/fa6";
import Button from "../../componets/Button";
import { StyledRedBtn } from "../../componets/styles/buttons/RedBtn.styled";
import { StyledRestorePasswordBtn } from "../../componets/styles/Button.styled";
import useApi from "../../services/useApi";
import {
  disconnectSocial,
  getSocialConnections,
} from "../../services/authentication";
import Spinner from "../../componets/shared/Spinner";

function SocialConnection() {
  const { data, loading, error, request } = useApi(getSocialConnections);
  const {
    data: dataDisconnect,
    loading: loadingDisconnect,
    error: errorDisconnect,
    request: requestDisconnect,
  } = useApi(disconnectSocial);
  const [google, setGoogle] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [disconnectSocialID, setDisconnectSocialID] = useState(null);

  const fb_requestUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=1850110238738889&redirect_uri=https://betraisers.com/dj-rest-auth/facebook/&response_type=token&scope=email`;
  const client_id =
    "722121418499-ouigqdf3dgv3c042q69cjl5gjipafbo9.apps.googleusercontent.com";
  const redirect_uri = "https://betraisers.com/dj-rest-auth/google/";
  const requestUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect_uri}&prompt=consent&response_type=code&client_id=${client_id}&scope=openid%20email%20profile&access_type=offline`;

  useEffect(() => {
    request("dj-rest-auth/socialaccounts/");
  }, []);

  useEffect(() => {
    if (!disconnectSocialID) {
      return;
    }
    requestDisconnect(
      `dj-rest-auth/socialaccounts/${disconnectSocialID?.id}/disconnect/`
    );
    if (disconnectSocialID?.provider === "google") {
      setGoogle(null);
    }
    if (disconnectSocialID?.provider === "facebook") {
      setFacebook(null);
    }
    setDisconnectSocialID(null);
  }, [disconnectSocialID]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setGoogle(null);
    setFacebook(null);

    data?.results.forEach((i) => {
      if (i?.provider === "google") {
        setGoogle(i?.id);
      }
      if (i?.provider === "facebook") {
        setFacebook(i?.id);
      }
    });
  }, [data]);

  const handleConnectSocial = (e, provider) => {
    e.preventDefault();
    const req = provider === "facebook" ? fb_requestUrl : requestUrl;
    window.location.replace(req);
  };

  const handleDisconnectSocial = (e, id, provider) => {
    e.preventDefault();
    setDisconnectSocialID({ id: id, provider: provider });
  };

  if (loading) {
    return <Spinner />;
  }


  return (
    <StyledSocialConnections>
      <StyledSocialConnectionList>
        <StyledSocialConnection>
          <StyledSocialConnectionInnerContainer>
            <FaGoogle color={google ? "#ed4040" : "#000"} />
            <div>Google</div>
            <Button
              styled={google ? StyledRestorePasswordBtn : StyledRedBtn}
              style={{ marginTop: "0" }}
              text={google ? "Відєднати" : "Приєднати"}
              onClick={(e) =>
                google
                  ? handleDisconnectSocial(e, google, "google")
                  : handleConnectSocial(e, 'google')
              }
            />
          </StyledSocialConnectionInnerContainer>
        </StyledSocialConnection>

        <StyledSocialConnection>
          <StyledSocialConnectionInnerContainer>
            <FaFacebookF color={facebook ? "#ed4040" : "#000"} />
            <div>Facebook</div>
            <Button
              styled={facebook ? StyledRestorePasswordBtn : StyledRedBtn}
              style={{ marginTop: "0" }}
              text={facebook ? "Відєднати" : "Приєднати"}
              onClick={(e) =>
                facebook
                  ? handleDisconnectSocial(e, facebook, "facebook")
                  : handleConnectSocial(e, 'facebook')
              }
            />
          </StyledSocialConnectionInnerContainer>
        </StyledSocialConnection>
      </StyledSocialConnectionList>
    </StyledSocialConnections>
  );
}

export default SocialConnection;
