import React from "react";
import {
  StyledRoomCard,
  StyledNetwork,
  StyledHoverEffect,
  StyledLogo,
  StyledName,
  StyledDescription,
  StyledDevider,
  StyledAdvantages,
  StyledAdvantage,
  StyledAdvantageKey,
  StyledAdvantageValue,
} from "../styles/roomPreview/RoomCard.styled";
import Button from "../Button";
import { StyledGoToReviewBtn } from "../styles/GoToReviewBtn.styled";
import * as DOMPurify from "dompurify";


function RoomCard({ room }) {
  return (
    <StyledRoomCard to={`${room.slug}/`}>
      <StyledNetwork>{room.network}</StyledNetwork>
      <StyledHoverEffect>
        <StyledLogo src={room.logo_small} alt={room.name} />
      </StyledHoverEffect>
      <StyledName>{room.name}</StyledName>
      <StyledDescription
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(room.description_preview),
        }}
      ></StyledDescription>
      <StyledDevider />
      <StyledAdvantages>
        <StyledAdvantage>
          <StyledAdvantageKey>Бонус</StyledAdvantageKey>
          <StyledAdvantageValue>{room.first_bonus}</StyledAdvantageValue>
        </StyledAdvantage>
        <StyledAdvantage>
          <StyledAdvantageKey>Рейкбек</StyledAdvantageKey>
          <StyledAdvantageValue>{room.rakeback}</StyledAdvantageValue>
        </StyledAdvantage>
      </StyledAdvantages>
      <Button text="Перейти до огляду" styled={StyledGoToReviewBtn} />
    </StyledRoomCard>
  );
}

export default RoomCard;
