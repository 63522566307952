import React, { useEffect, useState } from "react";
import {
  StyledCommentContainer,
  StyledCommentBodyContainer,
  StyledComment,
} from "../styles/comments/Comments.styled";
import Button from "../Button";
import { StyledRichTxtEditor } from "../styles/richTxtEditor/RichTxtEditor.styled";
import { StyledRedBtn } from "../styles/buttons/RedBtn.styled";
import PublicationContext from "../contexts/PublicationContext";
import { useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import List from "@ckeditor/ckeditor5-list/src/list";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import ParagraphButtonUI from "@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Undo from "@ckeditor/ckeditor5-undo/src/undo";
import SelectAll from "@ckeditor/ckeditor5-select-all/src/selectall";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";

import {
  Emoji,
  EmojiActivity,
  EmojiFlags,
  EmojiFood,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiPlaces,
  EmojiSymbols,
} from "@phudak/ckeditor5-emoji/src";
import SpadeCardPlugin from "./SpadeCardPlugin";
import { StyledStatistics } from "../styles/balance/Statistics.styled";

function CreateArticleEditor({ style }) {
  // const { setInputData, setEditor } = useContext(PublicationContext);
  const { dataEditor, setDataEditor, setStats } = useContext(PublicationContext);

  const editorConfiguration = {
    plugins: [
      Heading,
      ParagraphButtonUI,
      BlockQuote,
      Bold,
      Essentials,
      Image,
      ImageInsert,
      // ImageUpload,
      Italic,
      List,
      Paragraph,
      Emoji,
      EmojiPeople,
      EmojiNature,
      EmojiPlaces,
      EmojiFood,
      EmojiActivity,
      EmojiObjects,
      EmojiSymbols,
      EmojiFlags,
      SpadeCardPlugin,
      Table,
      TableToolbar,
      Undo,
      SelectAll,
      WordCount,
    ],
    toolbar: {
      items: [
        "undo",
        `redo`,
        "selectAll",
        "|",
        "heading",
        "paragraph",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        // "imageUpload",
        "insertImage",
        "insertTable",
        "|",
        "emoji",
        "-",
        "SpadeCardPlugin",
        "ClubCardPlugin",
        "DiamondCardPlugin",
        "HeartCardPlugin",
        "OffsuitCardPlugin",
      ],
      shouldNotGroupWhenFull: true,
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        // "tableProperties",
        // "tableCellProperties",
      ],
    },
    heading: {
      options: [
        {
          model: "heading2",
          view: "h2",
          title: "Heading 1",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 2",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 3",
          class: "ck-heading_heading4",
        },
      ],
    },
  };

  return (
    <div >
      <StyledComment style={style}>
        <StyledCommentContainer>
          <StyledCommentBodyContainer>
            <StyledRichTxtEditor>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={dataEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "min-height",
                      "30rem",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let data = editor.getData();
                  setDataEditor(data);

                  editor.plugins.get("WordCount").on("update", (evt, stats) => {
                    setStats(stats);
                  });
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </StyledRichTxtEditor>
          </StyledCommentBodyContainer>
        </StyledCommentContainer>
      </StyledComment>
      {/* <Button styled={StyledRedBtn} text="Опубликовать статью" /> */}
      {/* <Button styled={StyledRedBtn} text="Предосмотр" onClick={() => } /> */}
    </div>
  );
}

export default CreateArticleEditor;
