import styled from "styled-components";

export const StyledSelect = styled.div`
  position: relative;
  font-family: "Montserrat";
  font-size: 1.5rem;

  & select {
    display: none;
  }

  .select-selected {
    background-color: #f0f2f5;
    border-radius: 0.8rem;
    height: 5rem;
    display: flex;
    align-items: center;
  }

  /* Style the arrow inside the select element: */
  .select-selected:after {
    position: absolute;
    content: "";
    top: 45%;
    right: 1rem;
    width: 0;
    height: 0;
    border: 0.6rem solid transparent;
    border-color: #000 transparent transparent transparent;
  }

  .select-selected.select-arrow-active {
    border-radius: 0.8rem 0.8rem 0rem 0rem;
  }

  /* Point the arrow upwards when the select box is open (active): */
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #000 transparent;
    top: calc(45% - 0.6rem) ;
  }

  /* style the items (options), including the selected item: */
  .select-items div,
  .select-selected {
    display: flex;
    align-items: center;
    color: #000000;
    height: 5rem;
    padding: 0.8rem 1.6rem;
    border: 0px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
  }

  /* Style items (options): */
  .select-items {
    position: absolute;
    background-color: #f0f2f5;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 0rem 0rem 0.8rem 0.8rem;
  }

  .select-items div:last-child {
    border-radius: 0rem 0rem 0.8rem 0.8rem;
  }

  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }

  .select-items div:hover,
  .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
