import React, { useEffect } from "react";
import {
  StyledStatistics,
  StyledStatisticsTitle,
  StyledStatisticsTable,
  StyledStatisticsTr,
  StyledStatisticsTd,
  StyledStatisticsTh,
} from "../../componets/styles/balance/Statistics.styled";
import useApi from "../../services/useApi";
import { getUserCommentList } from "../../services/users";
import formateDate from "../../utils/DateFormater";
import { BiDislike, BiLike, BiCommentAdd } from "react-icons/bi";
import { Link } from "react-router-dom";
import Pagination from "../../componets/pagination/Pagination";
import setStatusColor from "../../utils/StatusColor";
import Spinner from "../../componets/shared/Spinner";
import * as DOMPurify from "dompurify";


function AddedComments() {
  const { data, loading, request } = useApi(getUserCommentList);

  useEffect(() => {
    request("pa/comments/");
  }, []);

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <StyledStatistics>
        <StyledStatisticsTitle>Додані коментарі</StyledStatisticsTitle>
        {loading && <Spinner />}
        {data && !loading && (
          <div style={{ overflow: "scroll", height: "100%" }}>
            <StyledStatisticsTable cellspacing="0" cellpadding="0">
              <StyledStatisticsTr>
                <StyledStatisticsTh>Дата/Час</StyledStatisticsTh>
                <StyledStatisticsTh>Коментар</StyledStatisticsTh>
                <StyledStatisticsTh>Розділ</StyledStatisticsTh>
                <StyledStatisticsTh>Стаття</StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiCommentAdd />
                </StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiLike />
                </StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiDislike />
                </StyledStatisticsTh>
                <StyledStatisticsTh>Статус</StyledStatisticsTh>
              </StyledStatisticsTr>

              {data &&
                data?.results.map((c) => {
                  return (
                    <StyledStatisticsTr>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {formateDate(c?.date_published, true)}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd
                        style={{
                          fontWeight: "500",
                          fontSize: "1.4rem",
                          color: "#000",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(`<div>${c?.text}</div>`),
                        }}
                      ></StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgb(88, 82, 246)",
                          }}
                        >
                          {c?.category}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <Link to={c?.link}>
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "1.2rem",
                              color: "rgba(0,0,0,0.5)",
                            }}
                          >
                            {c?.publication}
                          </div>
                        </Link>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {c?.answers_count}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {c?.likes_count}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "1.2rem",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          {c?.dislikes_count}
                        </div>
                      </StyledStatisticsTd>
                      <StyledStatisticsTd>
                        <div
                          style={{
                            padding: "0.5rem",
                            borderRadius: "0.8rem",
                            color: "white",
                            backgroundColor: setStatusColor(c?.status),
                            fontWeight: "500",
                            fontSize: "1.4rem",
                            textAlign: "center",
                          }}
                        >
                          {c?.status}
                        </div>
                      </StyledStatisticsTd>
                    </StyledStatisticsTr>
                  );
                })}
            </StyledStatisticsTable>
          </div>
        )}
        {data && <Pagination data={data} request={request} />}
      </StyledStatistics>
    </div>
  );
}

export default AddedComments;
