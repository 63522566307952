import React from "react";
import { useContext } from "react";
import { StyledEntranceModal } from "../styles/modals/EntranceModal.styled";
import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import PublicationContext from "../contexts/PublicationContext";
import Button from "../Button";
import { StyledCloseModalBtn } from "../styles/Button.styled";
import { GrClose } from "react-icons/gr";
import {
  StyledEntranceTab,
  StyledEntranceTabItem,
  StyledEntranceTitle,
} from "../styles/modals/EntranceModal.styled";
import { useLocation, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import RestorePswdForm from "./RestorePswdForm";
import ResendEmailModal from "./ResendEmailModal";
import RestorePswdConfirm from "./RestorePswdConfirm";
import AuthenticationContext from "../contexts/AuthenticationContext";

function EntranceModal({ form }) {
  const { entranceForm, setEntranceModalOpen, setEntranceForm } =
    useContext(PublicationContext);
  const { state } = useContext(AuthenticationContext);
  let navigate = useNavigate();
  const location = useLocation();
  const background = location.state && location.state.background;

  useEffect(() => {
    setEntranceForm(form);

    const onPopState = (e) => {
      setEntranceModalOpen(false);
      setEntranceForm(null);
    };

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
      setEntranceForm(null);
    };
  }, [entranceForm]);

  return (
    <StyledEntranceModal>
      <Button
        icon={<GrClose />}
        styled={StyledCloseModalBtn}
        onClick={(e) => {
          e.preventDefault();

          if (background) {
            navigate(background, {
              state: { scrollto: location.state?.scrollto },
            });
          } else {
            navigate("/");
          }
          setEntranceModalOpen(false);
        }}
      />
      {location.pathname !== "/restore/" &&
        !location.pathname.includes("/restore/password/reset/confirm/") && (
          <StyledEntranceTab>
            <StyledEntranceTabItem
              to="/login/"
              state={location.state}
              onClick={() => setEntranceForm("login")}
            >
              Вхід
            </StyledEntranceTabItem>
            <StyledEntranceTabItem
              to="/register/"
              state={location.state}
              onClick={() => setEntranceForm("register")}
            >
              Реєстрація
            </StyledEntranceTabItem>
          </StyledEntranceTab>
        )}
      {location.pathname === "/login/" && entranceForm === "login" && (
        <LoginForm />
      )}
      {location.pathname === "/register/" && entranceForm === "register" && (
        <RegistrationForm />
      )}
      {location.pathname === "/restore/" && entranceForm === "restore" && (
        <>
          <StyledEntranceTitle>Відновлення пароля</StyledEntranceTitle>
          <RestorePswdForm />
        </>
      )}
      {location.pathname.includes("/restore/password/reset/confirm/") &&
        entranceForm === "restore-confirm" &&
        !state?.isLoggedIn && (
          <>
            <StyledEntranceTitle>Відновлення пароля</StyledEntranceTitle>
            <RestorePswdConfirm />
          </>
        )}
      {location.pathname === "/login/" && entranceForm === "resend" && (
        <ResendEmailModal email="test@gmail.com" />
      )}
      <Outlet />
    </StyledEntranceModal>
  );
}

export default EntranceModal;
