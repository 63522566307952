import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { StyledNavbar } from "../styles/layout/Navbar.styled";
import { StyledNavLink } from "../styles/layout/NavLink.styled";
import { LoginBtn } from "../styles/LoginBtn.styled";
import Button from "../Button";
import { SignUpBtn } from "../styles/SignUpBtn.styled";
import { useState, useContext } from "react";
import Modal from "react-modal";
import EntranceModal from "../modals/EntranceModal";
import PublicationContext from "../contexts/PublicationContext";
import AuthenticationContext from "../contexts/AuthenticationContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
  StyledUserAccount,
  StyledUserAccountInnerContainer,
  StyledUserAccountUsername,
  StyledUserAccountLogout,
  StyledUserAccountImage,
} from "../styles/user/UserAccount.styled";
import useApi from "../../services/useApi";
import { logout } from "../../services/authentication";
import ConfirmLogoutModal from "../modals/ConfirmLogoutModal";
import { GiHamburgerMenu } from "react-icons/gi";

import { useMediaQuery } from "react-responsive";
import { StyledIconBtn } from "../styles/Button.styled";
import MobNavbar from "./mobile/MobNavbar";
import { getProfile } from "../../services/users";
import { StyledNavGift } from "../styles/layout/Navbar.styled";
import SubMenu from "./SubMenu";
import { RiArrowDownSLine } from "react-icons/ri";

function Navbar({
  isLogoutProp,
  isLoginProp,
  isRegisterProp,
  setIsLogoutProp,
  setIsRegisterProp,
  setIsLoginProp,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const [subMenuShow, setSubMenuShow] = useState("");

  const {
    setEntranceForm,
    isEntranceModalOpen,
    setEntranceModalOpen,
    entranceForm,
    sidebar,
    setSidebar,
  } = useContext(PublicationContext);
  const { state, dispatch } = useContext(AuthenticationContext);
  let location = useLocation();
  let navigate = useNavigate();

  const { data, loading, error, request } = useApi(logout);
  const {
    data: dataProfile,
    loading: loadingProfile,
    error: errorProfile,
    request: requestProfile,
  } = useApi(getProfile);

  const [isLogoutConfirm, setIsLogoutConfirm] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const [token, setToken] = useLocalStorage("token");

  const [skrollY, setScrollY] = useState(0);
  const [blur, setBlur] = useState("");

  Modal.setAppElement("#root");

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }

    watchScroll();

    if (+skrollY > 30) {
      setBlur("navbar-blur");
    } else {
      setBlur("");
    }

    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  function openModal(form) {
    setEntranceModalOpen(true);
    setEntranceForm(form);
    navigate(`/${form}/`, {
      state: { background: location, scrollto: window.scrollY },
    });
  }

  function closeModal() {
    document.querySelector("body").className = "";
    navigate(location?.state?.background, {
      state: { scrollto: location.state?.scrollto },
    });
    setEntranceModalOpen(false);
  }

  function handleLogout() {
    setIsLogoutConfirm(true);
  }

  function closeConfirmModal() {
    setIsLogoutConfirm(false);
    document.querySelector("body").className = "";
    navigate(location?.state?.background, {
      state: { scrollto: location.state?.scrollto },
    });
  }

  function yesClick() {
    setIsLogout(true);
  }

  function noClick() {
    setIsLogoutConfirm(false);
  }

  useEffect(() => {
    if (!isLogout) {
      return;
    }
    request("/dj-rest-auth/logout/");
  }, [isLogout]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.detail === "Successfully logged out.") {
      setToken("");
      dispatch({ type: "CLEAR_CURRENT_USER" });
      setIsLogoutConfirm(false);
      setIsLogout(false);
    }
  }, [data]);

  useEffect(() => {
    if (dataProfile) {
      setAvatar(dataProfile?.photo);
      return;
    }
    if (!state?.currentUser) {
      return;
    }
    requestProfile("pa/editprofile/");
  }, [dataProfile, state]);

  useEffect(() => {
    if (isLogoutProp) {
      handleLogout();
      setIsLogoutProp(false);
    }
    if (isLoginProp) {
      openModal("login");
      setIsLoginProp(false);
    }
    if (isRegisterProp) {
      openModal("register");
      setIsRegisterProp(false);
    }
  }, [isLogoutProp, isLoginProp, isRegisterProp]);

  return (
    <StyledNavbar className={blur}>
      {/* delete style when will be logo */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link
          style={{
            // marginLeft: "0.5rem",
            // marginTop: "1rem",
            // marginBottom: "1rem",
            color: "#000",
            fontSize: !blur ? "2.5rem" : "2rem",
            fontWeight: "700",
            transition: "all 0.4s",
          }}
          to="/"
        >
          {/* <span>betraisers</span> */}
          <img
            style={{
              color: "#000",
              height: !blur ? "4rem" : "3rem",
              transition: "all 0.4s",
            }}
            src="https://betraisers.com/media/betraisers-logo.png"
          />
        </Link>
      </div>
      {isNotMobile && (
        <>
          <ul>
            <li onMouseLeave={() => setSubMenuShow("")}>
              <StyledNavLink
                onMouseEnter={(e) => {
                  setSubMenuShow("publications");
                }}
                to="/publications/"
              >
                Публікації
                <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
              </StyledNavLink>
              <SubMenu
                isShow={subMenuShow === "publications"}
                items={[
                  {
                    name: "Акції",
                    slug: "/publications/promotions/",
                  },
                  {
                    name: "Новини",
                    slug: "/publications/news/",
                  },
                  {
                    name: "Статті",
                    slug: "/publications/articles/",
                  },
                ]}
              />
            </li>
            <li onMouseLeave={() => setSubMenuShow("")}>
              <StyledNavLink
                to="/rooms/"
                onMouseEnter={(e) => {
                  setSubMenuShow("poker");
                }}
              >
                Покер
                <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
              </StyledNavLink>
              <SubMenu
                isShow={subMenuShow === "poker"}
                items={[
                  {
                    name: "Онлайн покер руми",
                    slug: "/rooms/",
                  },
                  {
                    name: "Відгуки гравців",
                    slug: "/rooms/",
                  },
                  {
                    name: "Паролі на фріроли",
                    slug: "/freerolls/",
                  },
                  {
                    name: "Покер клуби України",
                    slug: "/pokerkluby/",
                  },
                ]}
              />
            </li>
            {/* <li>
              <StyledNavLink to="/freerolls/">Фріроли</StyledNavLink>
            </li> */}
            <li onMouseLeave={() => setSubMenuShow("")}>
              <StyledNavLink
                onMouseEnter={(e) => {
                  setSubMenuShow("casino");
                }}
                to="/casinos/"
              >
                Казино
                <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
              </StyledNavLink>
              <SubMenu
                isShow={subMenuShow === "casino"}
                items={[
                  {
                    name: "Огляди онлайн казино",
                    slug: "/casinos/",
                  },
                  {
                    name: "Відгуки гравців",
                    slug: "/casinos/",
                  },
                  {
                    name: "Казино України",
                    slug: "/kazyno/",
                  },
                ]}
              />
            </li>
            <li onMouseLeave={() => setSubMenuShow("")}>
              <StyledNavLink
                onMouseEnter={(e) => {
                  setSubMenuShow("hralni-zaklady-ukrainy");
                }}
                to="/hralni-zaklady-ukrainy/"
              >
                Гральні заклади 🇺🇦
                <RiArrowDownSLine style={{ marginLeft: "0.5rem" }} />
              </StyledNavLink>
              <SubMenu
                isShow={subMenuShow === "hralni-zaklady-ukrainy"}
                items={[
                  {
                    name: "Усі гральні заклади України",
                    slug: "/hralni-zaklady-ukrainy/",
                  },
                  {
                    name: "Покер клуби України",
                    slug: "/pokerkluby/",
                  },
                  {
                    name: "Казино України",
                    slug: "/kazyno/",
                  },
                ]}
              />
            </li>
            {/* <li>
              <StyledNavLink to="/forum/">Форум</StyledNavLink>
            </li> */}
          </ul>

          {state?.currentUser ? (
            <StyledUserAccount>
              <StyledUserAccountInnerContainer>
                <StyledUserAccountUsername>
                  {state?.currentUser?.username}
                </StyledUserAccountUsername>
                <StyledUserAccountLogout onClick={handleLogout}>
                  Вийти
                </StyledUserAccountLogout>
              </StyledUserAccountInnerContainer>
              <Link to="/p/balance/">
                <StyledUserAccountImage src={avatar}></StyledUserAccountImage>
              </Link>
            </StyledUserAccount>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledNavGift>🎁</StyledNavGift>
              <Button
                text={"Вхід"}
                styled={LoginBtn}
                onClick={() => openModal("login")}
              />
              <Button
                text={"Реєстрація"}
                styled={SignUpBtn}
                onClick={() => openModal("register")}
              />
            </div>
          )}
        </>
      )}

      {isMobile && (
        <Button
          ariaLabel="Menu"
          icon={<GiHamburgerMenu color="#000" />}
          styled={StyledIconBtn}
          onClick={() => {
            setSidebar(true);
          }}
        />
      )}

      <Modal
        isOpen={isEntranceModalOpen}
        onRequestClose={closeModal}
        onAfterClose={closeModal}
        contentLabel="Example Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <EntranceModal form={entranceForm} />
      </Modal>
      <Modal
        isOpen={isLogoutConfirm}
        onRequestClose={closeConfirmModal}
        onAfterClose={closeConfirmModal}
        contentLabel="Confirm Modal"
        className="modal-confirm"
        overlayClassName="overlay"
      >
        <ConfirmLogoutModal yesClick={yesClick} noClick={noClick} />
      </Modal>
    </StyledNavbar>
  );
}

Navbar.defaultProps = {
  title: "betraisers",
};

Navbar.propTypes = {
  title: PropTypes.string,
};

export default Navbar;
