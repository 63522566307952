import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledRestorePasswordBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #000;
  letter-spacing: 0.1rem;
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 1rem;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
    outline-width: 0px;
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }

  svg {
    margin-right: 1rem;
  }
`;

export const StyledCloseModalBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  border: 0;
  text-decoration: none;
  border-radius: 50%;
  font-size: 1.4rem;
  line-height: 1.28;
  background-color: transparent;
  transition: 0.25s all;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }
`;

export const StyledBlueBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
  line-height: 1.24rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.1rem;
  background-color: #3187ee;
  transition: all 0.3s ease;
  border-radius: 0.8rem;

  &:hover {
    background-color: #52a0ff;
    outline-width: 0px;
  }

  &:active {
    background-color: #126bd9;
  }
`;

export const StyledSuccessBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
  line-height: 1.24rem;
  text-decoration: none;
  border: none;
  color: #02980e;
  letter-spacing: 0.1rem;
  background-color: rgba(51, 179, 39, 0.1);
  border-radius: 0.8rem;
`;

export const StyledCheckingBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
  line-height: 1.24rem;
  text-decoration: none;
  border: none;
  color: #ea9900;
  letter-spacing: 0.1rem;
  background-color: rgba(234, 153, 0, 0.1);
  border-radius: 0.8rem;
`;

export const StyledErrorBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
  line-height: 1.24rem;
  text-decoration: none;
  border: none;
  color: #ed4040;
  letter-spacing: 0.1rem;
  background-color: #ed404066;
  border-radius: 0.8rem;
`;

export const StyledReviewArticleBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #000000;
  letter-spacing: 0.1rem;
  background-color: rgba(0, 41, 91, 0.06);
  transition: all 0.3s ease;
  border-radius: 1rem;
  margin: 0 auto;
  margin-left: 2rem;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }
`;

export const StyledTransparentBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3rem;
  padding: 0;
  font-size: 3rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
`;

export const StyledReviewBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  color: #000;
  letter-spacing: 0.1rem;
  background-color: #fff;
  transition: all 0.3s ease;
  border-radius: 1rem;

  &:hover {
  }

  &:active {
  }
`;

export const StyledIconBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border: 0;
  text-decoration: none;
  border-radius: 50%;
  font-size: 2.4rem;
  line-height: 1.28;
  background-color: transparent;
  transition: 0.25s all;
`;

export const StyledPaginationBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border: 0;
  text-decoration: none;
  border-radius: 50%;
  font-size: 2.4rem;
  line-height: 1.28;
  background-color: transparent;
  transition: 0.25s all;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
    outline-width: 0px;
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }
`;

export const StyledCircleBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 5rem;
  height: 5rem;

  font-size: 2.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.1rem;
  background-color: #ed4040;
  transition: all 0.3s ease;
  white-space: nowrap;

  svg {
  }

  &:hover {
    background-color: #ff4d4d;
    outline-width: 0px;
  }

  &:active {
    background-color: #ba2020;
  }
`;

export const StyledTranparentLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.8rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #000;
  letter-spacing: 0.1rem;
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 1rem;

  &:hover {
    background-color: rgba(0, 41, 91, 0.09);
    outline-width: 0px;
  }

  &:active {
    background-color: rgba(0, 41, 91, 0.12);
  }

  svg {
    margin-right: 1rem;
  }
`;

export const StyledForumFilterBtn = styled.div`
  display: flex;
  margin-right: 1rem;
  padding: 1.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
  height: 2.1rem;
  border: 0;
  text-decoration: none;
  border-radius: 0.5rem;
  color: ${props => props.isActive ? "#FFF" : "#000"};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.28;
  white-space: nowrap;
  background-color: ${props => props.isActive ? "#ed4040" : "transparent"};
  transition: 0.25s all;

  &:hover {
    background-color: ${props => props.isActive ? "#ed4040" : "rgba(0, 41, 91, 0.09)"};
    outline-width: 0px;
  }

  &:active {
    background-color: ${props => props.isActive ? "#ed4040" : "rgba(0, 41, 91, 0.12)"};
  }
`;
