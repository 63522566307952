import React, { useEffect, useRef, useState } from "react";
import Input from "../form/Input";
import Button from "../Button";
import {
  StyledBlueBtn,
  StyledCheckingBtn,
  StyledErrorBtn,
  StyledSuccessBtn,
} from "../styles/Button.styled";
import useApi from "../../services/useApi";
import { checkBinding } from "../../services/rooms";
import { useLocation } from "react-router-dom";
import BackendErrors from "../modals/BackendErrors";

function RoomBinding({ binding }) {
  const emailRef = useRef();
  const nicknameRef = useRef();
  const phoneRef = useRef();
  const location = useLocation();
  const [isCheckRequest, setIsCheckRequest] = useState(false);
  const { data, loading, error, request } = useApi(checkBinding);

  const [bindingState, setBindingState] = useState({
    text: "Перевірити прив'язку",
    button: StyledBlueBtn,
  });

  const [rndr] = useState({
    unknown: { text: "Прив'язка невідома", button: StyledBlueBtn },
    checking: { text: "Прив'язка перевіряється", button: StyledCheckingBtn },
    error: { text: "Помилка прив'язки", button: StyledErrorBtn },
    success: { text: "Успішно прив'язано!", button: StyledSuccessBtn },
  });

  useEffect(() => {
    if (rndr[binding?.[0]?.status]) {
      setBindingState(rndr[binding?.[0]?.status]);
    }
  }, [binding]);

  const handleCheckBinding = (e) => {
    e.preventDefault();
    setIsCheckRequest(true);
  };

  useEffect(() => {
    if (!isCheckRequest) {
      return;
    }
    request(`${location.pathname}check/`, {
      email: emailRef.current.value,
      nickname: nicknameRef.current.value,
      phone: phoneRef.current.value,
    });
    setIsCheckRequest(false);
  }, [isCheckRequest]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setBindingState(rndr[data?.status]);
  }, [data]);

  return (
    <div>
      <p style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
        У цьому розділі ви можете ввести свою електронну пошту та нікнейм у
        покер-румі (онлайн казино), щоб перевірити, чи прив'язаний ваш обліковий запис в
        покер-румі з нашим веб-сайтом.
      </p>
      <p style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
        Після реєстрації в покер-румі (онлайн казино) через наш веб-сайт рекомендуємо перевірити
        прив'язку та переконатися, що вона пройшла успішно, тоді ви почнете
        отримувати бонуси від нас!
      </p>

      <p
        style={{
          fontSize: "1.5rem",
          fontWeight: "700",
          marginBottom: "2rem",
        }}
      >
        Електронну пошту та нікнейм слід вводити ті, які ви використовували при
        реєстрації в покер-румі (онлайн казино)!
      </p>

      <div style={{ maxWidth: "50rem", marginBottom: "2rem" }}>
        <Input
          type="email"
          name="email"
          id="email"
          label="Email"
          value={binding?.[0]?.email}
          readOnly={true && binding?.[0]?.email}
          refc={emailRef}
        />
        <Input
          type="text"
          name="username"
          id="username"
          label="Username"
          value={binding?.[0]?.nickname}
          readOnly={true && binding?.[0]?.nickname}
          refc={nicknameRef}
        />
        <Input
          type="text"
          name="text"
          id="phone"
          label="Phone"
          value={binding?.[0]?.phone}
          readOnly={true && binding?.[0]?.phone}
          refc={phoneRef}
        />
        <Button
          // text={rndrTxt}
          text={bindingState?.text}
          styled={bindingState?.button}
          // styled={rndr.checking.button}
          style={{ marginTop: "1rem" }}
          onClick={handleCheckBinding}
          disabled={binding?.[0]?.email}
        />
        {error && <BackendErrors errors={error} />}
      </div>
      <p style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
        Перевірка може займати від 2 до 72 годин, в залежності від покер-руму (онлайн казино).
      </p>
      {/* <p style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
        Якщо ви зареєструвалися в покер-румі (онлайн казино) не через нас, ви також можете
        переприв'язати свій обліковий запис в покер-румі (онлайн казино) до нашого веб-сайту.
        Для цього введіть свою електронну пошту та нікнейм в покер-румі,
        натисніть кнопку "перевірити прив'язку" і зв'яжіться з нами через
        електронну пошту або Telegram.
      </p> */}
    </div>
  );
}

export default RoomBinding;
