import React from "react";
import { StyledDevider } from "../styles/roomPreview/RoomCard.styled";
import {
  StyledMobileRoomCard,
  StyledMobileRoomCardNetwork,
  StyledMobileRoomCardName,
  StyledMobileRoomCardNDesc,
  StyledMobileRoomCardBonus,
  StyledMobileRoomCardRake,
  StyledMobileRoomCardLogo,
  StyledMobileRoomCardContainer,
} from "../styles/rooms/MobileRoomCard.styled";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as DOMPurify from "dompurify";


function MobileRoomCard({ room, bgColor = "rgb(242, 245, 247)" }) {
  return (
    <Link
      to={`/rooms/${room?.slug}/`}
      style={{ textDecoration: "none", color: "initial" }}
    >
      <StyledMobileRoomCard bgColor={bgColor}>
        <StyledMobileRoomCardContainer>
          <div style={{ marginRight: "2rem" }}>
            <StyledMobileRoomCardNetwork>
              {room?.network}
            </StyledMobileRoomCardNetwork>
            <StyledMobileRoomCardName>{room?.name}</StyledMobileRoomCardName>
            <StyledMobileRoomCardNDesc
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(room.description_preview),
              }}
            ></StyledMobileRoomCardNDesc>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <StyledMobileRoomCardLogo src={room?.logo_small} alt="" />
            <div
              style={{
                fontSize: "1.8rem",
                fontWeight: "700",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FaStar color="rgb(255, 188, 11)" />
              <span>{room?.get_average_rating}</span>
            </div>
          </div>
        </StyledMobileRoomCardContainer>
        <StyledDevider style={{ marginBottom: "1rem" }} />
        <StyledMobileRoomCardContainer style={{ marginBottom: "0" }}>
          <StyledMobileRoomCardBonus>
            <div>Бонус</div>
            <div>{room?.first_bonus}</div>
          </StyledMobileRoomCardBonus>
          <StyledMobileRoomCardRake>
            <div>Рейкбек</div>
            <div>{room?.rakeback}</div>
          </StyledMobileRoomCardRake>
        </StyledMobileRoomCardContainer>
      </StyledMobileRoomCard>
    </Link>
  );
}

export default MobileRoomCard;
