import React, { useEffect } from "react";
import { StyledPageTitle } from "../componets/styles/shared/PageTitle.styled";
import RoomList from "../componets/rooms/RoomList";
import useApi from "../services/useApi";
import { getRooms } from "../services/rooms";
import { OneBlockCenter } from "../componets/styles/shared/OneBlockCenter";
import SeoFields from "../componets/shared/SeoFields";

function Rooms() {
  const { data, loading, error, request } = useApi(getRooms);

  useEffect(() => {
    request("rooms/");
  }, []);

  return (
    <>
      <SeoFields
        title="Кращі покер руми на реальні гроші в Україні"
        description="Топ покер руми для гри в Україні. Реальні відгуки та огляди poker румів: акції, бездепозитні бонуси, бонус за депозит."
        keywords={null}
        robots="index, follow"
        canonical="https://betraisers.com/rooms/"
      />
      <OneBlockCenter>
        <StyledPageTitle>Список кращих покер румів</StyledPageTitle>
        {/* <StyledRoomsFilterList>
          <StyledRoomsFilter to="/rooms">Лучшие покер-румы</StyledRoomsFilter>
          <StyledRoomsFilter to="/rooms-for-ukraine">
            Покер-румы для Украины
          </StyledRoomsFilter>
          <StyledRoomsFilter to="/rooms-for-russia">
            Покер-румы для россии
          </StyledRoomsFilter>
          <StyledRoomsFilter to="/rooms-with-free-bonus">
            Покер-румы с бездепозитным бонусом
          </StyledRoomsFilter>
          <StyledRoomsFilter to="/rooms-with-freerolls">
            Покер-румы с фрироллами
          </StyledRoomsFilter>
        </StyledRoomsFilterList> */}

        <RoomList roomList={data?.results} loading={loading} />
      </OneBlockCenter>
    </>
  );
}

export default Rooms;
