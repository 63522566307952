import React from "react";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import {
  StyledFooter,
  StyledSection,
  StyledSectionTitle,
  StyledSectionLink,
} from "../styles/layout/Footer.styled";
import { Link } from "react-router-dom";

function Footer() {
  const footerYear = new Date().getFullYear();
  return (
    <StyledFooter>
      <StyledSection>
        <StyledSectionTitle>Соц. сети</StyledSectionTitle>
        <StyledSectionLink>
          <FaTelegramPlane />
          Telegram
        </StyledSectionLink>
        <StyledSectionLink>
          <FaTwitter />X (Twitter)
        </StyledSectionLink>
        <StyledSectionLink>
          <FaInstagram />
          Instagram
        </StyledSectionLink>
      </StyledSection>
      <StyledSection>
        <StyledSectionTitle>Розділи</StyledSectionTitle>
        <StyledSectionLink to="/publications/">
          Акції покер румів
        </StyledSectionLink>
        <StyledSectionLink to="/rooms/">
          Кращі покер руми 2024
        </StyledSectionLink>
        <StyledSectionLink to="/freerolls/">
          Паролі на фріроли
        </StyledSectionLink>
      </StyledSection>
      <StyledSection>
        <StyledSectionTitle>Поміч</StyledSectionTitle>
        <StyledSectionLink>Telegram: @betraisers_support</StyledSectionLink>
        <StyledSectionLink>Email: help@betraisers.com</StyledSectionLink>
      </StyledSection>
      <StyledSection style={{ justifyContent: "flex-end" }}>
        {/* <h3 style={{ color: "red", marginBottom: "2rem" }}>
          GPWA Verification
        </h3> */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <StyledSectionLink style={{marginRight: "2rem"}} to="/privacy/">Конфіденційність</StyledSectionLink>
          <StyledSectionLink to="/terms/">Умови та положення</StyledSectionLink>
        </div>

        <div style={{ opacity: "0.6" }}>
          Сайт betraisers.com не є організатором ігор.
        </div>
        <div style={{ opacity: "0.6", marginBottom: "1rem" }}>
          Сайт має виключно інформаційний характер.
        </div>
        <div style={{ opacity: "0.6" }}>
          © betraisers.com, 2023-{new Date().getFullYear()}
        </div>
      </StyledSection>
    </StyledFooter>
  );
}

export default Footer;
