export default function formateDate(date_published, withSeconds = false) {
  const d = new Date(date_published);
  const date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const month =
    +d.getMonth() + 1 < 10 ? "0" + (+d.getMonth() + 1) : +d.getMonth() + 1;
  const year = d.getFullYear();
  const hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  const seconds = withSeconds
    ? d.getSeconds() < 10
      ? ":0" + d.getSeconds()
      : ":" + d.getSeconds()
    : "";
  return `${date}.${month}.${year} ${hours}:${minutes}${seconds}`;
}

export function dateInObj(ddate) {
  const d = new Date(ddate);
  const date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const month =
    +d.getMonth() + 1 < 10 ? "0" + (+d.getMonth() + 1) : +d.getMonth() + 1;
  const year = d.getFullYear();
  const hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  const seconds =
    d.getSeconds() < 10 ? ":0" + d.getSeconds() : ":" + d.getSeconds();
  return {
    date,
    month,
    year,
    hours,
    minutes,
    seconds,
  };
}
