import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const StyledRoomCard = styled(Link)`
  color: #000;
  text-decoration: none;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
  /* width: calc(33.33% - 1.5rem); */
  /* margin-left: 1.5rem; */
  margin-top: 3rem;
  padding: 2rem 2.5rem 3rem;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid lightgrey;
    /* transition: border .5s ease-out; */
  }
`;

export const StyledNetwork = styled.div`
  margin-bottom: 2.4rem;
  font-size: 1.2rem;
  line-height: 1.66;
  text-transform: uppercase;
  letter-spacing: 0.076em;
  color: rgba(0, 0, 0, 0.5);
  overflow-wrap: break-word;
`;

export const StyledName = styled.h2`
  overflow: hidden;
  white-space: nowrap;
  font-size: 2.2rem;
  line-height: 1.27;
  letter-spacing: normal;
  word-wrap: break-word;
`;

const blink = keyframes`
    from {
      background-position: 70% 50%;
    }
    to {
      background-position: 0% 50%;
    }
`;

export const StyledHoverEffect = styled.div`
  position: relative;
  margin: 0 auto 1.9rem;
  transform: translateZ(0);
  transition: transform 0.6s;

  ${StyledRoomCard}:hover & {
    /* transform: rotate3d(1, 2, 0, 20deg) scale(1.02); */
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(
      80deg,
      rgba(243, 245, 246, 0) 35%,
      rgba(243, 245, 246, 0) 40%,
      rgba(243, 245, 246, 0.3) 50%,
      rgba(243, 245, 246, 0) 60%,
      rgba(243, 245, 246, 0) 65%
    );
    background-position: 150% 50%;
    background-size: 200% 200%;
  }

  ${StyledRoomCard}:hover &::before {
    animation: ${blink} 0.5s ease-out;
  }
`;

export const StyledLogo = styled.img`
  display: block;
  object-fit: contain;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  background-color: #000;
  border-radius: 2rem;
  padding: 1rem;
  object-fit: contain;
`;

export const StyledDescription = styled.div`
  display: block;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.42;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 0.8rem;
  margin-bottom: 2.5rem;
`;

export const StyledDevider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const StyledAdvantages = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
`;

export const StyledAdvantage = styled.div`
  flex-grow: 1;
  margin-left: 0.8rem;
  margin-top: 1.8rem;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.42;
  letter-spacing: normal;
  overflow-wrap: break-word;
`;

export const StyledAdvantageKey = styled.div`
  color: rgb(121, 122, 123);
`;

export const StyledAdvantageValue = styled.div``;
