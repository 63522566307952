import styled from "styled-components";

export const StyledBinding = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  border-top: 1px solid rgb(235, 238, 243);

  div {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.22;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.42;
    color: rgba(0, 0, 0, 0.5);
    /* margin-left: 2rem; */
  }

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 1rem;
    margin-right: 1.5rem;
  }

  button {
    margin-left: auto;
    min-width: 20rem;
  }

  label {
    min-width: 0;
    max-width: none;
  }

  input {
    min-width: 0;
    max-width: none;
  }

  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 767px) {
    align-items: start;

    button {
      margin-top: 1rem;
    }
  }
`;
