import React, { useEffect, useState, useContext } from "react";
import useApi from "../services/useApi";
import {
  getEstablishment,
  getEstablishmentReviews,
} from "../services/establishments";
import { Link, useLocation, useParams } from "react-router-dom";
import { Left } from "../componets/styles/shared/Left";
import {
  StyledCasinoHeaderLicense,
  StyledCasinoHeaderLicenseFlag,
} from "../componets/styles/casino/CasinoHeader.styled";
import { Rating } from "react-simple-star-rating";
import { RiMegaphoneFill } from "react-icons/ri";
import { StyledDevider } from "../componets/styles/roomPreview/RoomCard.styled";
import {
  FaPhone,
  FaLocationDot,
  FaAt,
  FaGlobe,
  FaViber,
  FaWhatsapp,
  FaFacebook,
} from "react-icons/fa6";
import { FaTelegramPlane, FaInstagram } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StyledEditArea } from "../componets/styles/roomDetails/EditArea.styled";
import * as DOMPurify from "dompurify";
import { StyledImgSlider } from "../componets/styles/establishment/StyledImgSlider.styled";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import {
  StyledEstablishmentBlock,
  StyledEstablishmentBlockAddReview,
  StyledEstablishmentBlockImage,
  StyledEstablishmentBlockInfoTitle,
  StyledEstablishmentBlockTitle,
} from "../componets/styles/establishment/EstablishmentBlock.styled";
import Button from "../componets/Button";
import AuthenticationContext from "../componets/contexts/AuthenticationContext";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import PleaseLogin from "../componets/shared/PleaseLogin";
import { getRooms } from "../services/rooms";
import { Right } from "../componets/styles/shared/Right";
import {
  StyledBonuses,
  StyledCardContainer,
  StyledCard,
  StyledLogo as StyledLogoSide,
  StyledName as StyledNameSide,
  StyledContainer,
  StyledInnerContainer,
  StyledTitle,
  StyledStickyContainer,
} from "../../src/componets/styles/roomRecomend/RoomRecomend.styled";
import Spinner from "../componets/shared/Spinner";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { StyledCloseModalBtn } from "../componets/styles/Button.styled";
import AddReview from "../componets/modals/AddReview";
import { StyledLoadMoreBtn } from "../componets/styles/shared/LoadMoreBtn.styled";
import ReviewList from "../componets/reviews/ReviewList";
import { BsCheck2Circle } from "react-icons/bs";
import { getCasinos } from "../services/casinos";
import AdsSide from "../componets/shared/AdsSide";
import { useMediaQuery } from "react-responsive";
import SeoFields from "../componets/shared/SeoFields";

function EstablishmentDetail() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { state } = useContext(AuthenticationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [doRequestRefresjList, setDoRequestRefresjList] = useState(false);
  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);
  const [limit, setLimit] = useState(0);
  const [limitParams, setLimitParams] = useState(0);
  const location = useLocation();
  const { data, loading, error, request } = useApi(getEstablishment);
  const {
    data: dataReviews,
    loading: loadingReviews,
    error: errorReviews,
    request: requestReviews,
  } = useApi(getEstablishmentReviews);

  const params = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const {
    data: dataRooms,
    loading: loadingRooms,
    error: errorRooms,
    request: requestRooms,
  } = useApi(getRooms);

  const {
    data: dataCasinos,
    loading: loadingCasinos,
    error: errorCasinos,
    request: requestCasinos,
  } = useApi(getCasinos);

  useEffect(() => {
    request(`/establishments/${params?.slug}/`);
  }, []);

  useEffect(() => {
    requestReviews(`/establishments/${params?.slug}/reviews/list/`);
  }, []);

  useEffect(() => {
    if (dataRooms) {
      return;
    }
    requestRooms("/rooms/");
  }, [dataRooms]);

  useEffect(() => {
    if (dataCasinos) {
      return;
    }
    requestCasinos("/casinos/");
  }, [dataCasinos]);

  useEffect(() => {
    if (!dataReviews) {
      return;
    }

    if (isFirstLoadPage) {
      let params = null;
      try {
        params = new URLSearchParams(new URL(data?.next).search);
      } catch (e) {
        params = null;
      }
      setLimit((prevState) => prevState + +params?.get("limit"));
      setLimitParams((prevState) => prevState + +params?.get("limit"));
      setIsFirstLoadPage(false);
    }
    setDoRequestRefresjList(false);
  }, [dataReviews]);

  useEffect(() => {
    if (!doRequestRefresjList) {
      return;
    }
    requestReviews(`/establishments/${params?.slug}/reviews/list/`, {
      params: { limit: limitParams },
    });
    setDoRequestRefresjList(false);
  }, [doRequestRefresjList]);

  const handleAddReview = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setDoRequestRefresjList(false);
  };

  const handleLoadMoreReviews = () => {
    setLimitParams((prevState) => prevState + limit);
    setDoRequestRefresjList(true);
  };

  const closeModal = () => {
    document.querySelector("body").className = "";
    setIsModalOpen(false);
  };

  const closeSuccessModal = () => {
    document.querySelector("body").className = "";
    setIsSuccessModalOpen(false);
    setDoRequestRefresjList(true);
  };

  if (loading) {
    return <Spinner />;
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    beforeChange: (current, next) => setCurrentSlide(next),
    appendDots: (dots) => (
      <div
        style={{
          padding: "0px",
        }}
      >
        <ul style={{ margin: "-10px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "10px",
          backgroundColor: i === currentSlide ? "#ed4040" : "silver",
        }}
      ></div>
    ),
  };

  const googleMicroData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        name: data?.name,
        image: data?.image,
        address: data?.location,
        telephone: data?.phone,
        description: data?.seo_description,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: data?.rating,
          ratingCount: data?.feedback_count,
        },
      })}
    </script>
  );

  return (
    <>
      <SeoFields
        title={data?.seo_title}
        description={data?.seo_description}
        keywords={data?.seo_keywords}
        robots={data?.seo_robots}
        canonical={data?.seo_canonical}
        googleMicroData={googleMicroData}
      />
      <Left>
        <StyledEstablishmentBlock>
          <div style={{ display: "flex" }}>
            <StyledEstablishmentBlockImage src={data?.image} alt={data?.name} />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <StyledEstablishmentBlockTitle>
                  {data?.name}
                </StyledEstablishmentBlockTitle>
                {data?.license_krail && (
                  <StyledCasinoHeaderLicense>
                    <StyledCasinoHeaderLicenseFlag>
                      <div></div>
                      <div></div>
                    </StyledCasinoHeaderLicenseFlag>
                    <div>Ліцензія КРАІЛ</div>
                  </StyledCasinoHeaderLicense>
                )}
              </div>
              <div
                style={{ width: "100%", display: "flex", marginBottom: "1rem" }}
              >
                <div style={{ marginRight: "3rem" }}>
                  <StyledEstablishmentBlockInfoTitle>
                    Рейтинг
                  </StyledEstablishmentBlockInfoTitle>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Rating
                      allowFraction={true}
                      initialValue={data?.rating}
                      size={24}
                      readonly
                    />
                    <div style={{ fontSize: "2rem", fontWeight: "500" }}>
                      {data?.rating}
                    </div>
                  </div>
                </div>

                <div style={{ marginRight: "3rem" }}>
                  <StyledEstablishmentBlockInfoTitle>
                    Відгуки
                  </StyledEstablishmentBlockInfoTitle>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "2rem",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                      }}
                    >
                      <RiMegaphoneFill
                        style={{ marginRight: "1rem" }}
                        color="rgb(121, 122, 123)"
                      />
                      {dataReviews?.count}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: isMobile ? "column" : "row",
                  marginLeft: isMobile ? "-9rem" : "0",
                }}
              >
                <div
                  style={{
                    marginRight: "3rem",
                    marginBottom: isMobile ? "2rem" : "0",
                  }}
                >
                  <StyledEstablishmentBlockInfoTitle>
                    Контакти
                  </StyledEstablishmentBlockInfoTitle>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "1.5rem",
                        // display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                      }}
                    >
                      {data?.phone && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaPhone
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          {data?.phone}
                        </div>
                      )}

                      {data?.phone2 && (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaPhone
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          {data?.phone2}
                        </div>
                      )}

                      {data?.twitter && (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaAt
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          {data?.twitter}
                        </div>
                      )}

                      {data?.website && (
                        <a
                          href={data?.website}
                          rel="nofollow"
                          target="_blank"
                          style={{
                            display: "flex",
                          }}
                        >
                          <FaGlobe
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          {data?.website}
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginRight: "3rem",
                    marginBottom: isMobile ? "2rem" : "0",
                  }}
                >
                  <StyledEstablishmentBlockInfoTitle>
                    Адреса
                  </StyledEstablishmentBlockInfoTitle>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    {data?.location && (
                      <div
                        style={{
                          fontSize: "1.5rem",
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "500",
                        }}
                      >
                        <FaLocationDot
                          style={{
                            marginRight: "0.5rem",
                            color: "rgb(121, 122, 123)",
                            alignSelf: "flex-start",
                          }}
                        />
                        {data?.location}
                      </div>
                    )}
                  </div>
                  <a
                    href={`https://www.google.com/maps/search/${data?.location}`}
                    rel="nofollow"
                    target="_blank"
                    style={{
                      fontSize: "1.3rem",
                    }}
                  >
                    Дивитись на карті
                  </a>
                </div>

                <div>
                  <StyledEstablishmentBlockInfoTitle>
                    Соц. мережі/Меседжери
                  </StyledEstablishmentBlockInfoTitle>

                  <div>
                    <div
                      style={{
                        fontSize: "1.5rem",
                        // display: "flex",
                        // alignItems: "center",
                        fontWeight: "500",
                      }}
                    >
                      {data?.telegram && (
                        <a
                          href={data?.telegram}
                          target="_blank"
                          rel="nofollow"
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaTelegramPlane
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          Telegram
                        </a>
                      )}
                      {data?.instagram && (
                        <a
                          href={data?.instagram}
                          target="_blank"
                          rel="nofollow"
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaInstagram
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          Instagram
                        </a>
                      )}
                      {data?.viber && (
                        <a
                          href={data?.viber}
                          target="_blank"
                          rel="nofollow"
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaViber
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          Viber
                        </a>
                      )}
                      {data?.whatsup && (
                        <a
                          href={data?.whatsup}
                          target="_blank"
                          rel="nofollow"
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaWhatsapp
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          Whatsapp
                        </a>
                      )}
                      {data?.facebook && (
                        <a
                          href={data?.facebook}
                          target="_blank"
                          rel="nofollow"
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <FaFacebook
                            style={{
                              marginRight: "0.5rem",
                              color: "rgb(121, 122, 123)",
                              alignSelf: "flex-start",
                            }}
                          />
                          Facebook
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StyledDevider style={{ margin: "3rem 0 2rem" }} />
          {data?.review && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  fontSize: "1.6rem",
                  fontWeight: "700",
                  marginBottom: "1.5rem",
                }}
              >
                Про заклад
              </div>
              <StyledEditArea
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data?.review),
                }}
              ></StyledEditArea>
            </div>
          )}
          {data?.establishment_photos.length > 0 && (
            <StyledImgSlider>
              <PhotoProvider maskOpacity={0.8} bannerVisible={true}>
                <Slider {...settings}>
                  {data?.establishment_photos.map((photo, index) => {
                    return (
                      <div>
                        <PhotoView key={index} src={photo?.image}>
                          <img src={photo?.image} alt={photo?.seo_image_alt} />
                        </PhotoView>
                      </div>
                    );
                  })}
                </Slider>
              </PhotoProvider>
            </StyledImgSlider>
          )}
        </StyledEstablishmentBlock>
        <StyledEstablishmentBlockAddReview>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: isMobile ? "wrap" : "nowrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "2.5rem",
                fontWeight: "700",
                marginBottom: isMobile ? "2rem" : "0",
                marginRight: "2rem",
              }}
            >
              Відгуків ({dataReviews?.count})
            </div>
            <div>
              {state?.currentUser && state?.isLoggedIn ? (
                <Button
                  text="Додати відгук"
                  styled={StyledRedBtn}
                  onClick={handleAddReview}
                />
              ) : (
                <PleaseLogin />
              )}
            </div>
          </div>
        </StyledEstablishmentBlockAddReview>
        <div>
          <Modal
            isOpen={isSuccessModalOpen}
            onRequestClose={closeSuccessModal}
            contentLabel="Add Review"
            className="modal-add-review"
            overlayClassName="overlay"
            onAfterClose={closeSuccessModal}
          >
            <Button
              icon={<GrClose />}
              styled={StyledCloseModalBtn}
              onClick={closeSuccessModal}
            />
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: "10rem", color: "#02980e" }}>
                <BsCheck2Circle />
              </div>
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                  marginBottom: "3rem",
                }}
              >
                Відгук додано успішно!
              </p>
            </div>
          </Modal>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Add Review"
            className="modal-add-review"
            overlayClassName="overlay"
            onAfterClose={closeModal}
          >
            <Button
              icon={<GrClose />}
              styled={StyledCloseModalBtn}
              onClick={closeModal}
            />
            <AddReview
              setSuccessAddedModal={setIsSuccessModalOpen}
              setAddReviewModal={setIsModalOpen}
              pathToRequest={`/establishments/${params?.slug}/reviews/add/`}
              // refreshList={request}
            />
          </Modal>

          {data && (
            <ReviewList
              reviewList={dataReviews?.results}
              refreshList={setDoRequestRefresjList}
              pathToRequest={`/establishments/${params?.slug}/reviews/`}
            />
          )}
          {data?.next && (
            <Button
              styled={StyledLoadMoreBtn}
              onClick={handleLoadMoreReviews}
              style={{ marginTop: "2rem" }}
              disabled={loadingReviews}
              text={
                !loadingReviews &&
                `Показати ще ${limit} з ${dataReviews?.count}`
              }
              icon={
                loadingReviews && (
                  <Spinner style={{ width: "2rem", height: "2rem" }} />
                )
              }
            />
          )}
        </div>
      </Left>

      <Right>
        {dataRooms && (
          <StyledContainer>
            <StyledTitle>♠️ Покер онлайн</StyledTitle>

            {dataRooms?.results.map((room, i) => {
              if (i >= 5) {
                return;
              }
              return (
                <Link key={i} to={`/rooms/${room.slug}/`}>
                  <StyledCardContainer>
                    <StyledCard>
                      <StyledLogoSide src={room?.logo_small} alt={room?.name} />
                      <StyledInnerContainer>
                        <StyledNameSide>{room?.name}</StyledNameSide>
                        <StyledBonuses>{room?.first_bonus}</StyledBonuses>
                      </StyledInnerContainer>
                    </StyledCard>
                  </StyledCardContainer>
                </Link>
              );
            })}
          </StyledContainer>
        )}
        {dataCasinos && (
          <StyledContainer>
            <StyledTitle>🎰 Казино онлайн</StyledTitle>

            {dataCasinos?.results.map((room, i) => {
              if (i >= 5) {
                return;
              }
              return (
                <Link key={i} to={`/casinos/${room.slug}/`}>
                  <StyledCardContainer>
                    <StyledCard>
                      <StyledLogoSide src={room?.logo_small} alt={room?.name} />
                      <StyledInnerContainer>
                        <StyledNameSide>{room?.name}</StyledNameSide>
                        <StyledBonuses
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(room?.bonus),
                          }}
                        ></StyledBonuses>
                      </StyledInnerContainer>
                    </StyledCard>
                  </StyledCardContainer>
                </Link>
              );
            })}
          </StyledContainer>
        )}
        <StyledStickyContainer>
          <StyledTitle>🔥 Гарячі пропозіції</StyledTitle>
          <AdsSide />
        </StyledStickyContainer>
      </Right>
    </>
  );
}

export default EstablishmentDetail;
