import React, { useEffect } from "react";
import { useContext } from "react";
import PublicationContext from "../componets/contexts/PublicationContext";
import AuthenticationContext from "../componets/contexts/AuthenticationContext";
import Button from "../componets/Button";
import {
  StyledCloseModalBtn,
  StyledReviewArticleBtn,
} from "../componets/styles/Button.styled";
import { GrClose } from "react-icons/gr";
import {
  BiTime,
  BiCommentDetail,
  BiLike,
  BiDislike,
  BiShare,
} from "react-icons/bi";
import {
  StyledPublicationDetailImg,
  StyledPublicationDetailTitle,
  StyledPublicationDetailStat,
  StyledPublicationDetailInfo,
  StyledPublicationDetailBody,
} from "../componets/styles/publication/PublicationDetails.styled";
import {
  StyledPublicationAuthor,
  StyledPublicationAuthorImg,
  StyledPublicationAuthorName,
} from "../componets/styles/publication/PublicationAuthor.styled";
import { useNavigate } from "react-router-dom";
import { StyledRedBtn } from "../componets/styles/buttons/RedBtn.styled";
import { useMediaQuery } from "react-responsive";
import * as DOMPurify from "dompurify";


function PreviewArticle({ closeModal, handlePublishClick }) {
  const { dataEditor, titleArticle, setDataEditor, imageArticle } =
    useContext(PublicationContext);
  const { state } = useContext(AuthenticationContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    let data = dataEditor?.replaceAll(
      `src="https://www.pekarstas.com/static/img/cards`,
      `class="card-icon" src="https://www.pekarstas.com/static/img/cards`
    );
    setDataEditor(data);
  }, []);

  return (
    <div style={{ padding: isMobile ? "5rem 1rem" : "5rem" }}>
      <Button
        icon={<GrClose />}
        styled={StyledCloseModalBtn}
        onClick={closeModal}
      />
      <StyledPublicationDetailImg src={imageArticle} alt="" />
      <StyledPublicationDetailTitle>
        {titleArticle}
      </StyledPublicationDetailTitle>
      <StyledPublicationDetailStat>
        <StyledPublicationAuthor>
          <StyledPublicationAuthorImg
            src={state?.currentUser?.photo}
            alt={state?.currentUser?.username}
          />
          <StyledPublicationAuthorName>
            <div>Автор</div>
            <p>{state?.currentUser?.username}</p>
          </StyledPublicationAuthorName>
        </StyledPublicationAuthor>
        <StyledPublicationDetailInfo>
          <BiTime />
          25.03.2023 19:30
        </StyledPublicationDetailInfo>
        <StyledPublicationDetailInfo>
          <BiCommentDetail />36
        </StyledPublicationDetailInfo>
        <StyledPublicationDetailInfo>
          <BiShare />17
        </StyledPublicationDetailInfo>
        <StyledPublicationDetailInfo>
          <BiLike />25
        </StyledPublicationDetailInfo>
        <StyledPublicationDetailInfo>
          <BiDislike />2
        </StyledPublicationDetailInfo>
      </StyledPublicationDetailStat>
      <StyledPublicationDetailBody>
        {dataEditor && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(dataEditor.replaceAll("<p>&nbsp;</p>", "")),
            }}
          />
        )}
      </StyledPublicationDetailBody>
      <div
        style={{
          margin: "3rem 0 0 0",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "2rem",
        }}
      >
        <Button
          text="Опублікувати статтю"
          styled={StyledRedBtn}
          style={{ margin: "0 3rem 0 0" }}
          onClick={(e) => {
            handlePublishClick(e);
            closeModal();
          }}
        />
        <Button
          text="Повернутися до редагування"
          styled={StyledReviewArticleBtn}
          style={{ margin: "0" }}
          onClick={closeModal}
        />
      </div>
    </div>
  );
}

export default PreviewArticle;
