import styled from "styled-components";

export const StyledChangeInputContainer = styled.div`
  font-family: "Montserrat";
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const StyledChangeInput = styled.fieldset`
  font-family: "Montserrat";
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 41, 91, 0.07);
  border-radius: 1rem;
  margin-right: 1rem;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const StyledChangeLabel = styled.div`
  width: 9rem;
  min-width: 9rem;
  height: 6rem;
  display: flex;
  align-items: center;
  padding: 2rem 0 2rem 2rem;
  font-size: 1.5rem;

  color: #999;
  vertical-align: baseline;
  cursor: text;
  flex-shrink: 0;
  min-width: 11.5rem;
  max-width: 15rem;
  word-break: break-word;
  overflow: hidden;
  line-height: normal;
  z-index: 2;
`;

export const StyledInput = styled.input`
  font-family: "Montserrat";
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;

  font-size: 3.2rem;
  padding: 1.6rem;
  outline: none;
  max-width: 26rem;
  height: 6rem;
  font-feature-settings: "tnum";

  &:focus {
    outline: 0px;
  }
`;
