import xsIcon from "./spades/xs.svg";
import twoSIcon from "./spades/2s.svg";
import threeSIcon from "./spades/3s.svg";
import fourSIcon from "./spades/4s.svg";
import fiveSIcon from "./spades/5s.svg";
import sixSIcon from "./spades/6s.svg";
import sevenSIcon from "./spades/7s.svg";
import eightSIcon from "./spades/8s.svg";
import nineSIcon from "./spades/9s.svg";
import tenSIcon from "./spades/ts.svg";
import jackSIcon from "./spades/js.svg";
import queenSIcon from "./spades/qs.svg";
import kingSIcon from "./spades/ks.svg";
import aceSIcon from "./spades/as.svg";

import xcIcon from "./clubs/xc.svg";
import twoCIcon from "./clubs/2c.svg";
import threeCIcon from "./clubs/3c.svg";
import fourCIcon from "./clubs/4c.svg";
import fiveCIcon from "./clubs/5c.svg";
import sixCIcon from "./clubs/6c.svg";
import sevenCIcon from "./clubs/7c.svg";
import eightCIcon from "./clubs/8c.svg";
import nineCIcon from "./clubs/9c.svg";
import tenCIcon from "./clubs/tc.svg";
import jackCIcon from "./clubs/jc.svg";
import queenCIcon from "./clubs/qc.svg";
import kingCIcon from "./clubs/kc.svg";
import aceCIcon from "./clubs/ac.svg";

import xhIcon from "./hearts/xh.svg";
import twoHIcon from "./hearts/2h.svg";
import threeHIcon from "./hearts/3h.svg";
import fourHIcon from "./hearts/4h.svg";
import fiveHIcon from "./hearts/5h.svg";
import sixHIcon from "./hearts/6h.svg";
import sevenHIcon from "./hearts/7h.svg";
import eightHIcon from "./hearts/8h.svg";
import nineHIcon from "./hearts/9h.svg";
import tenHIcon from "./hearts/th.svg";
import jackHIcon from "./hearts/jh.svg";
import queenHIcon from "./hearts/qh.svg";
import kingHIcon from "./hearts/kh.svg";
import aceHIcon from "./hearts/ah.svg";

import xdIcon from "./diamonds/xd.svg";
import twoDIcon from "./diamonds/2d.svg";
import threeDIcon from "./diamonds/3d.svg";
import fourDIcon from "./diamonds/4d.svg";
import fiveDIcon from "./diamonds/5d.svg";
import sixDIcon from "./diamonds/6d.svg";
import sevenDIcon from "./diamonds/7d.svg";
import eightDIcon from "./diamonds/8d.svg";
import nineDIcon from "./diamonds/9d.svg";
import tenDIcon from "./diamonds/td.svg";
import jackDIcon from "./diamonds/jd.svg";
import queenDIcon from "./diamonds/qd.svg";
import kingDIcon from "./diamonds/kd.svg";
import aceDIcon from "./diamonds/ad.svg";

import xoIcon from "./offsuit/xo.svg";
import twoOIcon from "./offsuit/2o.svg";
import threeOIcon from "./offsuit/3o.svg";
import fourOIcon from "./offsuit/4o.svg";
import fiveOIcon from "./offsuit/5o.svg";
import sixOIcon from "./offsuit/6o.svg";
import sevenOIcon from "./offsuit/7o.svg";
import eightOIcon from "./offsuit/8o.svg";
import nineOIcon from "./offsuit/9o.svg";
import tenOIcon from "./offsuit/to.svg";
import jackOIcon from "./offsuit/jo.svg";
import queenOIcon from "./offsuit/qo.svg";
import kingOIcon from "./offsuit/ko.svg";
import aceOIcon from "./offsuit/ao.svg";

export let Cards = {
  spades: [
    {
      label: "X spade",
      icon: xsIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/xs.svg"></img>',
    },
    {
      label: "2 spade",
      icon: twoSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/2s.svg"></img>',
    },
    {
      label: "3 spade",
      icon: threeSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/3s.svg"></img>',
    },
    {
      label: "4 spade",
      icon: fourSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/4s.svg"></img>',
    },
    {
      label: "5 spade",
      icon: fiveSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/5s.svg"></img>',
    },
    {
      label: "6 spade",
      icon: sixSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/6s.svg"></img>',
    },
    {
      label: "7 spade",
      icon: sevenSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/7s.svg"></img>',
    },
    {
      label: "8 spade",
      icon: eightSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/8s.svg"></img>',
    },
    {
      label: "9 spade",
      icon: nineSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/9s.svg"></img>',
    },
    {
      label: "T spade",
      icon: tenSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ts.svg"></img>',
    },
    {
      label: "J spade",
      icon: jackSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/js.svg"></img>',
    },
    {
      label: "Q spade",
      icon: queenSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/qs.svg"></img>',
    },
    {
      label: "K spade",
      icon: kingSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ks.svg"></img>',
    },
    {
      label: "A spade",
      icon: aceSIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/as.svg"></img>',
    },
  ],
  clubs: [
    {
      label: "X clubs",
      icon: xcIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/xc.svg"></img>',
    },
    {
      label: "2 clubs",
      icon: twoCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/2c.svg"></img>',
    },
    {
      label: "3 clubs",
      icon: threeCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/3c.svg"></img>',
    },
    {
      label: "4 clubs",
      icon: fourCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/4c.svg"></img>',
    },
    {
      label: "5 clubs",
      icon: fiveCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/5c.svg"></img>',
    },
    {
      label: "6 clubs",
      icon: sixCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/6c.svg"></img>',
    },
    {
      label: "7 clubs",
      icon: sevenCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/7c.svg"></img>',
    },
    {
      label: "8 clubs",
      icon: eightCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/8c.svg"></img>',
    },
    {
      label: "9 clubs",
      icon: nineCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/9c.svg"></img>',
    },
    {
      label: "T clubs",
      icon: tenCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/tc.svg"></img>',
    },
    {
      label: "J clubs",
      icon: jackCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/jc.svg"></img>',
    },
    {
      label: "Q clubs",
      icon: queenCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/qc.svg"></img>',
    },
    {
      label: "K clubs",
      icon: kingCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/kc.svg"></img>',
    },
    {
      label: "A clubs",
      icon: aceCIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ac.svg"></img>',
    },
  ],
  hearts: [
    {
      label: "X hearts",
      icon: xhIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/xh.svg"></img>',
    },
    {
      label: "2 hearts",
      icon: twoHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/2h.svg"></img>',
    },
    {
      label: "3 hearts",
      icon: threeHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/3h.svg"></img>',
    },
    {
      label: "4 hearts",
      icon: fourHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/4h.svg"></img>',
    },
    {
      label: "5 hearts",
      icon: fiveHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/5h.svg"></img>',
    },
    {
      label: "6 hearts",
      icon: sixHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/6h.svg"></img>',
    },
    {
      label: "7 hearts",
      icon: sevenHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/7h.svg"></img>',
    },
    {
      label: "8 hearts",
      icon: eightHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/8h.svg"></img>',
    },
    {
      label: "9 hearts",
      icon: nineHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/9h.svg"></img>',
    },
    {
      label: "T hearts",
      icon: tenHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/th.svg"></img>',
    },
    {
      label: "J hearts",
      icon: jackHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/jh.svg"></img>',
    },
    {
      label: "Q hearts",
      icon: queenHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/qh.svg"></img>',
    },
    {
      label: "K hearts",
      icon: kingHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/kh.svg"></img>',
    },
    {
      label: "A hearts",
      icon: aceHIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ah.svg"></img>',
    },
  ],
  diamonds: [
    {
      label: "X diamonds",
      icon: xdIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/xd.svg"></img>',
    },
    {
      label: "2 diamonds",
      icon: twoDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/2d.svg"></img>',
    },
    {
      label: "3 diamonds",
      icon: threeDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/3d.svg"></img>',
    },
    {
      label: "4 diamonds",
      icon: fourDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/4d.svg"></img>',
    },
    {
      label: "5 diamonds",
      icon: fiveDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/5d.svg"></img>',
    },
    {
      label: "6 diamonds",
      icon: sixDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/6d.svg"></img>',
    },
    {
      label: "7 diamonds",
      icon: sevenDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/7d.svg"></img>',
    },
    {
      label: "8 diamonds",
      icon: eightDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/8d.svg"></img>',
    },
    {
      label: "9 diamonds",
      icon: nineDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/9d.svg"></img>',
    },
    {
      label: "T diamonds",
      icon: tenDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/td.svg"></img>',
    },
    {
      label: "J diamonds",
      icon: jackDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/jd.svg"></img>',
    },
    {
      label: "Q diamonds",
      icon: queenDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/qd.svg"></img>',
    },
    {
      label: "K diamonds",
      icon: kingDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/kd.svg"></img>',
    },
    {
      label: "A diamonds",
      icon: aceDIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ad.svg"></img>',
    },
  ],
  offsuit: [
    {
      label: "X",
      icon: xoIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/xo.svg"></img>',
    },
    {
      label: "2",
      icon: twoOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/2o.svg"></img>',
    },
    {
      label: "3",
      icon: threeOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/3o.svg"></img>',
    },
    {
      label: "4",
      icon: fourOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/4o.svg"></img>',
    },
    {
      label: "5",
      icon: fiveOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/5o.svg"></img>',
    },
    {
      label: "6",
      icon: sixOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/6o.svg"></img>',
    },
    {
      label: "7",
      icon: sevenOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/7o.svg"></img>',
    },
    {
      label: "8",
      icon: eightOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/8d.svg"></img>',
    },
    {
      label: "9",
      icon: nineOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/9o.svg"></img>',
    },
    {
      label: "T",
      icon: tenOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/to.svg"></img>',
    },
    {
      label: "J",
      icon: jackOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/jo.svg"></img>',
    },
    {
      label: "Q",
      icon: queenOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/qo.svg"></img>',
    },
    {
      label: "K",
      icon: kingOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ko.svg"></img>',
    },
    {
      label: "A",
      icon: aceOIcon,
      img: '<img src="https://www.pekarstas.com/static/img/cards/ao.svg"></img>',
    },
  ],

};
