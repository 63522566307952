import React from "react";
import {
  StyledCasinoPreview,
  StyledCasinoPreviewLogo,
  StyledCasinoPreviewName,
  StyledCasinoPreviewLogoContainer,
  StyledCasinoPreviewBonus,
  StyledHoverEffect,
  StyledCasinoPreviewLinksContainer,
} from "../styles/casino/CasinoPreview.styled";
import Button from "../Button";
import { StyledGoToReviewBtn } from "../styles/GoToReviewBtn.styled";
import { StyledDevider } from "../styles/roomPreview/RoomCard.styled";
import { Link } from "react-router-dom";
import { LuCherry } from "react-icons/lu";
import { BiCommentDetail } from "react-icons/bi";
import * as DOMPurify from "dompurify";

function CasinoPreview({ casino }) {
  return (
    <div>
      <StyledCasinoPreview to={`${casino?.slug}/`}>
        <StyledHoverEffect>
          <StyledCasinoPreviewLogoContainer>
            <StyledCasinoPreviewLogo
              src={casino?.logo_small}
              alt={casino?.name}
            />
          </StyledCasinoPreviewLogoContainer>
        </StyledHoverEffect>
        <StyledCasinoPreviewName>{casino?.name}</StyledCasinoPreviewName>
        <StyledCasinoPreviewBonus
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(casino?.bonus),
          }}
        ></StyledCasinoPreviewBonus>
        {casino?.bonus2 && (
          <StyledCasinoPreviewBonus
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(casino?.bonus2),
            }}
          ></StyledCasinoPreviewBonus>
        )}
        {casino?.bonus3 && (
          <StyledCasinoPreviewBonus
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(casino?.bonus3),
            }}
          ></StyledCasinoPreviewBonus>
        )}
        <StyledDevider />
        <StyledCasinoPreviewLinksContainer>
          <Link to={`${casino?.slug}/aff/`}>
            <LuCherry />
            Грати
          </Link>
          <Link to={`${casino?.slug}/reviews/`}>
            <BiCommentDetail />
            Відгуки
          </Link>
        </StyledCasinoPreviewLinksContainer>
        <Button text="Перейти до огляду" styled={StyledGoToReviewBtn} />
      </StyledCasinoPreview>
    </div>
  );
}

export default CasinoPreview;
