import React from "react";
import Button from "../Button";
import {
  StyledContainer,
  StyledInput,
  StyledLabel,
} from "../styles/form/Form.styled";
import { RiEyeCloseLine } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import { StyledCloseModalBtn } from "../styles/Button.styled";
import { useState } from "react";

function Input({
  type,
  name,
  id,
  label,
  readOnly,
  value,
  refc,
  style,
  required,
  styleForFieldset,
  onChange,
  maxLength,
}) {
  const [isEyeClose, setEyeClose] = useState(true);
  const [toggledType, setToggeledType] = useState(type);

  const onHandleEye = (e) => {
    e.preventDefault();
    setEyeClose((prevState) => !prevState);
    setToggeledType(toggledType === "password" ? "text" : "password");
  };

  const icon = isEyeClose ? <RiEyeCloseLine color="#000000" /> : <ImEye color="#000000" />;

  return (
    <StyledContainer style={styleForFieldset}>
      {label && (
        <StyledLabel htmlFor={id}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </StyledLabel>
      )}
      <StyledInput
        type={toggledType}
        maxlength={maxLength}
        name={name}
        id={id}
        readOnly={readOnly}
        value={value}
        ref={refc}
        style={style}
        onChange={onChange}
        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
      />
      {type === "password" && (
        <Button
          typy="button"
          icon={icon}
          styled={StyledCloseModalBtn}
          onClick={onHandleEye}
          style={{ top: "auto", right: "0.5rem" }}
        />
      )}
    </StyledContainer>
  );
}

export default Input;
