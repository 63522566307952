import styled from "styled-components";

export const StyledCreateFreerollModal = styled.div`

`;

export const StyledFieldsContainer = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const StyledFieldsInnerContainer = styled.div`
  width: 100%;
`;
