import React, { useContext, useState, useEffect } from "react";
import {
  StyledPost,
  StyledPostAuthor,
  StyledPostAuthorImg,
  StyledPostAuthorUsername,
  StyledPostBody,
  StyledPostInnerContainer,
  StyledPostControl,
  StyledPostIsAuthor,
  StyledPost_Mob,
  StyledPostAuthor_Mob,
  StyledPostAuthorImg_Mob,
  StyledPostAuthorUsername_Mob,
  StyledPostBody_Mob,
  StyledPostInnerContainer_Mob,
  StyledPostControl_Mob,
  StyledPostIsAuthor_Mob,
} from "../styles/forum/Post.styled";
import {
  StyledCommentTime,
  StyledCommentBottomContainer,
  StyledCommentButtons,
  StyledCommentButton,
} from "../styles/comments/Comments.styled";
import {
  BiLike,
  BiDislike,
  BiCommentAdd,
  BiEditAlt,
  BiCheck,
  BiX,
} from "react-icons/bi";
import { FaQuoteLeft } from "react-icons/fa6";
import { PiWarningBold } from "react-icons/pi";
import Button from "../Button";
import formateDate from "../../utils/DateFormater";
import * as DOMPurify from "dompurify";
import ForumContext from "../contexts/ForumContext";
import ForumRichTextEditor from "../richTxtEditor/ForumRichTextEditor";
import useApi from "../../services/useApi";
import { ratePost, updateThemePost } from "../../services/forum";
import { toast } from "react-toastify";
import AuthenticationContext from "../contexts/AuthenticationContext";
import ReactTimeAgo from "react-time-ago";
import { round } from "javascript-time-ago/steps";
import { useMediaQuery } from "react-responsive";

function Post({ data, refToScrollTo }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    dataPost,
    setDataPost,
    dataEditPost,
    setDataEditPost,
    editPost,
    setEditPost,
    setIsSaveEditPost,
    isSaveEditPost,
  } = useContext(ForumContext);
  const { state } = useContext(AuthenticationContext);

  const [isAnswerClick, setIsAnswerClick] = useState(false);
  const [userVote, setUserVote] = useState(null);
  const [likesDislikes, setLikesDislikes] = useState(() => {
    return { l: data?.likes, d: data?.dislikes, v: data?.is_voted };
  });

  const {
    data: updateData,
    loading: updateLoading,
    error: updateError,
    request: updateRequest,
  } = useApi(updateThemePost);

  const {
    data: rateData,
    loading: rateLoading,
    error: rateError,
    request: rateRequest,
  } = useApi(ratePost);

  useEffect(() => {
    if (!isSaveEditPost) {
      return;
    }
    updateRequest(`/forum/posts/update/${dataEditPost?.id}/`, {
      text: editPost,
    });
    setIsSaveEditPost(false);
  }, [isSaveEditPost]);

  useEffect(() => {
    if (!updateData) {
      return;
    }
    if (updateData?.id === data?.id) {
      toast.success(<div>Пост відредаговано!</div>);
    }

    setDataEditPost(null);
  }, [updateData]);

  useEffect(() => {
    if (!userVote) {
      return;
    }
    rateRequest("/forum/posts/rate/", userVote);
    setUserVote(null);
  }, [userVote]);

  useEffect(() => {
    if (!rateData) {
      return;
    }
    setLikesDislikes({
      l: rateData?.likes,
      d: rateData?.dislikes,
      v: rateData?.is_voted,
    });
  }, [rateData]);

  useEffect(() => {
    if (!isAnswerClick) {
      return;
    }
    scroll();
    setIsAnswerClick(false);
  }, [isAnswerClick]);

  const answerData = (data) => {
    // return (
    //   `<p><strong>${data?.author?.user?.username}</strong></p><blockquote>` +
    //   data?.text +
    //   `</blockquote><p><br data-cke-filler="true"></p>`
    // );
    return (
      `<blockquote><i>Цитата <strong>${data?.author?.user?.username}</strong></i>` +
      data?.text +
      `</blockquote><p><br data-cke-filler="true"></p>`
    );
  };

  const scroll = () => {
    console.log(refToScrollTo);
    refToScrollTo.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  if (isMobile) {
    return (
      <StyledPost_Mob>
        <StyledPostInnerContainer_Mob>
          <StyledPostAuthor_Mob>
            <StyledPostAuthorImg_Mob
              src={data?.author?.photo}
              alt={data?.author?.user?.username}
            />
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <StyledPostAuthorUsername_Mob>
                  {data?.author?.user?.username}
                </StyledPostAuthorUsername_Mob>
                {data?.author?.user?.username ===
                  data?.theme?.author?.user?.username && (
                  <StyledPostIsAuthor_Mob>Автор теми</StyledPostIsAuthor_Mob>
                )}
              </div>

              <div>
                <div style={{ color: "rgb(121, 122, 123)", fontWeight: "500" }}>
                  На сайті:{" "}
                  <ReactTimeAgo
                    date={data?.author?.date_joined}
                    locale="uk-UK"
                    timeStyle={{
                      steps: round,
                      labels: "custom",
                    }}
                  />
                </div>
                <div style={{ color: "rgb(121, 122, 123)", fontWeight: "500" }}>
                  Повідомлень: {data?.author?.posts_amount}
                </div>
              </div>
            </div>
          </StyledPostAuthor_Mob>

          {dataEditPost && dataEditPost?.id === data?.id ? (
            <ForumRichTextEditor data={data?.text} />
          ) : (
            <StyledPostBody_Mob
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  (data?.id === updateData?.id && updateData?.text) ||
                    data?.text.replaceAll("<p>&nbsp;</p>", "")
                ),
              }}
            ></StyledPostBody_Mob>
          )}
        </StyledPostInnerContainer_Mob>
        <StyledPostInnerContainer_Mob>
          <StyledPostControl_Mob>
            <StyledCommentBottomContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <StyledCommentTime>
                  {formateDate(data?.date_creation, true)}
                </StyledCommentTime>
                <div style={{ display: "flex" }}>
                  <Button
                    icon={
                      <BiLike
                        color={
                          +likesDislikes?.v > 0 ? "green" : "rgb(121, 122, 123)"
                        }
                      />
                    }
                    styled={StyledCommentButton}
                    text={likesDislikes?.l}
                    style={{ color: "green" }}
                    onClick={() => {
                      setUserVote({ id: data?.id, vote: "like" });
                    }}
                  />
                  <Button
                    icon={
                      <BiDislike
                        color={
                          +likesDislikes?.v < 0 ? "red" : "rgb(121, 122, 123)"
                        }
                      />
                    }
                    styled={StyledCommentButton}
                    text={likesDislikes?.d}
                    style={{ color: "red", marginRight: "0" }}
                    onClick={() => {
                      setUserVote({ id: data?.id, vote: "dislike" });
                    }}
                  />
                </div>
              </div>

              <StyledCommentButtons>
                {dataEditPost && dataEditPost?.id === data?.id ? (
                  <>
                    <Button
                      icon={<BiCheck />}
                      style={{ color: "green" }}
                      styled={StyledCommentButton}
                      text="Зберегти"
                      onClick={() => {
                        if (!editPost || editPost === "") {
                          return;
                        }
                        setIsSaveEditPost(true);
                      }}
                    />
                    <Button
                      icon={<BiX />}
                      style={{ color: "red" }}
                      styled={StyledCommentButton}
                      text="Скасувати"
                      onClick={() => {
                        setDataEditPost(null);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      icon={<BiCommentAdd />}
                      styled={StyledCommentButton}
                      text="Відповісти"
                      onClick={() => {
                        setDataPost(
                          answerData(
                            (data?.id === updateData?.id && updateData) || data
                          )
                        );
                        setIsAnswerClick(true);
                      }}
                    />
                    {state?.currentUser?.username ===
                      data?.author?.user?.username && (
                      <Button
                        icon={<BiEditAlt />}
                        styled={StyledCommentButton}
                        text="Редагувати"
                        onClick={() => {
                          setDataEditPost(
                            (data?.id === updateData?.id && updateData) || data
                          );
                          setEditPost(
                            (data?.id === updateData?.id && updateData?.text) ||
                              data?.text
                          );
                        }}
                      />
                    )}

                    <Button
                      icon={<PiWarningBold />}
                      styled={StyledCommentButton}
                      // text="Скарга"
                      // style={{ fontSize: "1.7rem" }}
                      onClick={() => {}}
                    />
                  </>
                )}
              </StyledCommentButtons>
            </StyledCommentBottomContainer>
          </StyledPostControl_Mob>
        </StyledPostInnerContainer_Mob>
      </StyledPost_Mob>
    );
  } else {
    return (
      <StyledPost>
        <StyledPostInnerContainer>
          <StyledPostAuthor>
            {data?.author?.user?.username ===
              data?.theme?.author?.user?.username && (
              <StyledPostIsAuthor>Автор теми</StyledPostIsAuthor>
            )}
            <StyledPostAuthorUsername>
              {data?.author?.user?.username}
            </StyledPostAuthorUsername>
            <StyledPostAuthorImg
              src={data?.author?.photo}
              alt={data?.author?.user?.username}
            />
            <div
              style={{
                color: "rgb(121, 122, 123)",
                fontWeight: "500",
                whiteSpace: "nowrap",
              }}
            >
              На сайті:{" "}
              <ReactTimeAgo
                date={data?.author?.date_joined}
                locale="uk-UK"
                timeStyle={{
                  steps: round,
                  labels: "custom",
                }}
              />
            </div>
            <div
              style={{
                color: "rgb(121, 122, 123)",
                fontWeight: "500",
                whiteSpace: "nowrap",
              }}
            >
              Повідомлень: {data?.author?.posts_amount}
            </div>
          </StyledPostAuthor>

          {dataEditPost && dataEditPost?.id === data?.id ? (
            <ForumRichTextEditor data={data?.text} />
          ) : (
            <StyledPostBody
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  (data?.id === updateData?.id && updateData?.text) ||
                    data?.text.replaceAll("<p>&nbsp;</p>", "")
                ),
              }}
            ></StyledPostBody>
          )}
        </StyledPostInnerContainer>
        <StyledPostInnerContainer>
          <StyledPostControl>
            <StyledCommentBottomContainer>
              <StyledCommentTime style={{ marginLeft: "14.5rem" }}>
                {formateDate(data?.date_creation, true)}
              </StyledCommentTime>
              <StyledCommentButtons>
                {dataEditPost && dataEditPost?.id === data?.id ? (
                  <>
                    <Button
                      icon={<BiCheck />}
                      style={{ color: "green" }}
                      styled={StyledCommentButton}
                      text="Зберегти"
                      onClick={() => {
                        if (!editPost || editPost === "") {
                          return;
                        }
                        setIsSaveEditPost(true);
                      }}
                    />
                    <Button
                      icon={<BiX />}
                      style={{ color: "red" }}
                      styled={StyledCommentButton}
                      text="Скасувати"
                      onClick={() => {
                        setDataEditPost(null);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      icon={<BiCommentAdd />}
                      styled={StyledCommentButton}
                      text="Відповісти"
                      onClick={() => {
                        setDataPost(
                          answerData(
                            (data?.id === updateData?.id && updateData) || data
                          )
                        );
                        setIsAnswerClick(true);
                      }}
                    />
                    {state?.currentUser?.username ===
                      data?.author?.user?.username && (
                      <Button
                        icon={<BiEditAlt />}
                        styled={StyledCommentButton}
                        text="Редагувати"
                        onClick={() => {
                          setDataEditPost(
                            (data?.id === updateData?.id && updateData) || data
                          );
                          setEditPost(
                            (data?.id === updateData?.id && updateData?.text) ||
                              data?.text
                          );
                        }}
                      />
                    )}
                    <Button
                      icon={
                        <BiLike
                          color={
                            +likesDislikes?.v > 0
                              ? "green"
                              : "rgb(121, 122, 123)"
                          }
                        />
                      }
                      styled={StyledCommentButton}
                      text={likesDislikes?.l}
                      style={{ color: "green" }}
                      onClick={() => {
                        setUserVote({ id: data?.id, vote: "like" });
                      }}
                    />
                    <Button
                      icon={
                        <BiDislike
                          color={
                            +likesDislikes?.v < 0 ? "red" : "rgb(121, 122, 123)"
                          }
                        />
                      }
                      styled={StyledCommentButton}
                      text={likesDislikes?.d}
                      style={{ color: "red" }}
                      onClick={() => {
                        setUserVote({ id: data?.id, vote: "dislike" });
                      }}
                    />
                    <Button
                      icon={<PiWarningBold />}
                      styled={StyledCommentButton}
                      // text="Скарга"
                      // style={{ fontSize: "1.7rem" }}
                      onClick={() => {}}
                    />
                  </>
                )}
              </StyledCommentButtons>
            </StyledCommentBottomContainer>
          </StyledPostControl>
        </StyledPostInnerContainer>
      </StyledPost>
    );
  }
}

export default Post;
