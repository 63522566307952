import client from "./client";

export const getRooms = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const getRoom = (pathname, options, params = {}) => {
  return client.get(pathname, options, params);
};

export const getRoomReviews = (pathname, options, params = {}) => {
  return client.get(pathname, options, params);
};

export const getRoomPublications = (pathname, params = {}) => {
  return client.get(pathname, params);
};

export const checkBinding = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const addReview = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};

export const addReviewAnswer = (pathname = "/rooms/<slug:room>/reviews/answer/add/", options, params = {}) => {
  return client.post(pathname, options, params);
};

export const gradeReview = (pathname, options, params = {}) => {
  return client.post(pathname, options, params);
};