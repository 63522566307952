import React from "react";
import { StyledArticlesBlock } from "../styles/sideblocks/ArticlesBlock.styled";
import { Link } from "react-router-dom";
import {
  StyledInfo,
  StyledInfoContainer,
} from "../styles/pages/main/MainPage.styled";
import { BiCommentDetail, BiLike, BiTime } from "react-icons/bi";
import convertDate from "../../utils/DateFormater";

function ArticlesBlock({ articles: { data, loading } }) {
  return (
    <>
      <div
        style={{
          color: "#7f7f7f",
          fontSize: "1.7rem",
          fontWeight: "500",
          lineHeight: "2.2rem",
          marginBottom: "1rem",
        }}
      >
        🔥 Останні публікації
      </div>
      {data && (
        <StyledArticlesBlock>
          <div>
            <ul>
              {data?.results?.map((article) => {
                return (
                  <li style={{ fontSize: "1.5rem" }}>
                    <Link
                      to={`/publications/${article?.category?.slug}/${article?.slug}/`}
                    >
                      {article?.title}
                    </Link>

                    <StyledInfoContainer>
                      <StyledInfo>
                        <BiTime />
                        {convertDate(article?.date_published)}
                      </StyledInfo>

                      <StyledInfo>
                        <BiCommentDetail /> {article?.comments_count}
                      </StyledInfo>

                      <StyledInfo>
                        <BiLike />
                        {article?.likes_count}
                      </StyledInfo>
                    </StyledInfoContainer>
                  </li>
                );
              })}
            </ul>
          </div>
        </StyledArticlesBlock>
      )}
    </>
  );
}

export default ArticlesBlock;
