import { View, ButtonView } from "@ckeditor/ckeditor5-ui";
import HtmlDataProcessor from "@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor";

export default class DropdownView extends View {
  constructor(locale, editor, cards) {
    super(locale, editor, cards);

    let cardsArr = [];

    function _initButtons(locale) {
      let btnArr = [];
      for (let i = 0; i <= 14; i++) {
        btnArr.push(new ButtonView(locale));
      }
      return btnArr;
    }

    function _setButtonsParams(btnArr, cards) {
      btnArr.forEach((btn, i) => {
        btn.set({
          label: cards[i]?.label,
          tooltip: true,
          icon: cards[i]?.icon,
          class: ["grid-btn"],
        });
        cardsArr.push(btn);
      });
    }

    function _onExecute(buttons, cards) {
      buttons.forEach((btn, i) => {
        btn.on("execute", () => {
          const htmlString = cards[i]?.img;

          editor.model.change((writer) => {
            const htmlDP = new HtmlDataProcessor(writer);
            let viewFragment = htmlDP.toView(htmlString);
            const modelFragment = editor.data.toModel(viewFragment);
            editor.model.insertContent(modelFragment);
          });
        });
      });
    }

    const cardButtons = _initButtons(locale);
    _setButtonsParams(cardButtons, cards);
    _onExecute(cardButtons, cards);

    this.tiles = this.createCollection(cardsArr);

    this.setTemplate({
      tag: "div",
      children: [
        {
          tag: "div",
          attributes: {
            class: ["ck-cards-grid__tiles"],
          },
          children: this.tiles,
        },
      ],
      attributes: {
        class: ["ck-cards-grid"],
      },
    });
  }
}
