import React from "react";
import { StyledSideBlock } from "../styles/forum/SideBlock.styled";

function SideBlock({ title, children }) {
  return (
    <StyledSideBlock>
      <h2>{title}</h2>
      {children}
    </StyledSideBlock>
  );
}

export default SideBlock;
