import React from "react";
import styled from "styled-components";

export const StyledCommentsTitle = styled.div`
  scroll-margin: 6.5rem;
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: 700;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const StyledCommentList = styled.div`
  margin-top: 2rem;
`;

export const StyledComment = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  padding-left: ${(props) => (props.isAnswer ? "4rem;" : "0;")};
`;

export const StyledCommentImg = styled.img`
  width: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  object-fit: cover;
`;

export const StyledCommentContainer = styled.div`
  width: 100%;
`;

export const StyledCommentAuthor = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const StyledCommentBodyContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  background-color: rgb(242, 245, 247);
  border-radius: 1rem;
`;

export const StyledCommentBody = styled.div`
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;

  & blockquote {
    position: relative;
    white-space: pre-line;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: normal;
    letter-spacing: normal;
    overflow-wrap: break-word;
    margin-top: 0px;
    margin-bottom: 1rem;
    padding-left: 1.3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  & blockquote::before {
    content: "";
    position: absolute;
    background-color: var(--color-ut-alert, #ed4040);
    width: 0.3rem;
    height: 100%;
    left: 0px;
    top: 0rem;
    bottom: 0rem;
  }

  & img {
    max-height: 25rem;
  }
`;

export const StyledCommentBottomContainer = styled.div`
  display: flex;
  margin-top: 2rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 1.5rem;
  }
`;

export const StyledCommentTime = styled.div`
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 4rem;
`;

export const StyledCommentButtons = styled.div`
  display: flex;
`;

export const StyledCommentButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.5);
  transition: 0.25s all;
  cursor: pointer;
  margin-right: 2rem;
  background: transparent;

  &:hover {
    color: rgba(0, 0, 0, 1);
    transform: scale(1.1);
  }

  & svg {
    margin-right: 0.5rem;
  }
`;
