import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledSubMenuItem = styled(Link)`
  color: inherit;
  text-decoration: none;
  border: 0px;
  font-size: 1.4rem;
  line-height: 1.42;
  letter-spacing: normal;
  overflow-wrap: break-word;
  display: block;
  padding: 0.6rem 2rem;
  width: 100%;
  text-align: left;
  transition: background-color 0.2s ease-in 0s;
  cursor: pointer;

  &:hover {
    outline: 0px;
    background-color: rgba(0, 41, 91, 0.06);
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
  }
`;
