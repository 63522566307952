import React, { useEffect } from "react";
import {
  StyledStatistics,
  StyledStatisticsTitle,
  StyledStatisticsTable,
  StyledStatisticsTr,
  StyledStatisticsTd,
  StyledStatisticsTh,
} from "../../componets/styles/balance/Statistics.styled";
import { BiDislike, BiLike } from "react-icons/bi";
import useApi from "../../services/useApi";
import { getUserReviews } from "../../services/users";
import Pagination from "../../componets/pagination/Pagination";
import Spinner from "../../componets/shared/Spinner";
import formateDate from "../../utils/DateFormater";
import setStatusColor from "../../utils/StatusColor";
import * as DOMPurify from "dompurify";


function AddedReviews() {
  const { data, loading, error, request } = useApi(getUserReviews);

  useEffect(() => {
    request("pa/reviews/");
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <StyledStatistics>
        <StyledStatisticsTitle>Додані відгуки</StyledStatisticsTitle>
        {loading && <Spinner />}
        {data && !loading && (
          <div style={{ overflow: "scroll" }}>
            <StyledStatisticsTable cellspacing="0" cellpadding="0">
              <StyledStatisticsTr>
                <StyledStatisticsTh>Дата/Час</StyledStatisticsTh>
                <StyledStatisticsTh>Покеррум</StyledStatisticsTh>
                <StyledStatisticsTh>Текст відгука</StyledStatisticsTh>
                <StyledStatisticsTh>Оцінка</StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiLike />
                </StyledStatisticsTh>
                <StyledStatisticsTh>
                  <BiDislike />
                </StyledStatisticsTh>
                <StyledStatisticsTh>Статус</StyledStatisticsTh>
              </StyledStatisticsTr>
              {data?.results?.map((r) => {
                return (
                  <StyledStatisticsTr>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {formateDate(r?.date_published)}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {r?.room?.name}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.4rem",
                          color: "#000",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(`<div>${r?.text}</div>`),
                        }}
                      ></div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {r?.rating}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {r?.likes}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.2rem",
                          color: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {r?.dislikes}
                      </div>
                    </StyledStatisticsTd>
                    <StyledStatisticsTd>
                      <div
                        style={{
                          padding: "0.5rem",
                          borderRadius: "0.8rem",
                          color: "white",
                          backgroundColor: setStatusColor(r?.status),
                          fontWeight: "500",
                          fontSize: "1.4rem",
                          textAlign: "center",
                        }}
                      >
                        {r?.status}
                      </div>
                    </StyledStatisticsTd>
                  </StyledStatisticsTr>
                );
              })}
            </StyledStatisticsTable>
          </div>
        )}
        {data && <Pagination data={data} request={request} />}
      </StyledStatistics>
    </div>
  );
}

export default AddedReviews;
