import styled from "styled-components";

export const StyledStatistics = styled.div`
  margin-top: 4rem;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

export const StyledStatisticsTable = styled.table`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.42;
  border: none;
  border-collapse: collapse;

  tr td:first-child {
    border-top-left-radius: 5px;
  }
  tr td:last-child {
    border-top-right-radius: 5px;
  }

  tr td:first-child {
    border-bottom-left-radius: 5px;
  }
  tr td:last-child {
    border-bottom-right-radius: 5px;
  }
`;

export const StyledStatisticsTr = styled.tr`
  &:not(:first-of-type):hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
  }
`;

export const StyledStatisticsTh = styled.th`
  text-align: left;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  overflow-wrap: break-word;
  font-weight: 400;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const StyledStatisticsTd = styled.td`
  border: none;
  /* word-break: break-all; */
  div {
  }

  padding: 0.5rem 1rem;
  color: ${(props) => {
    switch (props.operationType) {
      case "income":
        return "#33B327";
      case "cashout":
        return "#ed4040";
      case "change":
        return "#ea9900";
    }
  }};
`;

export const StyledStatisticsTitle = styled.div`
  white-space: pre-line;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: normal;
  overflow-wrap: break-word;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

export const StyledStatisticsHeader = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & div {
    width: calc(20% - 2rem);
    margin-left: 2rem;
    font-size: 1.4rem;
    line-height: 1.42;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.5);
    overflow-wrap: break-word;
    flex-shrink: 0;
  }
  & div:first-of-type {
    margin-left: 0;
    width: calc(15% - 2rem);
  }
`;

export const StyledStatisticsOperation = styled.div`
  display: flex;

  & div {
    width: calc(20% - 2rem);
    margin-left: 2rem;
    font-size: 1.4rem;
    line-height: 1.42;
    letter-spacing: normal;
    color: #000000;
    overflow-wrap: break-word;
    flex-shrink: 0;
  }

  & div:first-of-type {
    margin-left: 0;
    width: calc(15% - 2rem);
  }
`;
