import styled from "styled-components";

export const StyledAdsSide = styled.div`
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 2rem;

  & img {
    width: 100%;
    border-radius: 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  }
`;
