import React from "react";
import {
  StyledSubsection,
  StyledSubsectionTitleContainer,
  StyledSubsectionTitle,
  StyledSubsectionDescription,
  StyledSubsectionStatContainer,
  StyledSubsectionLastPostContainer,
  StyledSubsectionLastPostAuthor,
  StyledSubsectionLastPostDate,
  StyledSubsectionLastPost,
  StyledSubsection_Mob,
  StyledSubsectionTitleContainer_Mob,
  StyledSubsectionTitle_Mob,
  StyledSubsectionDescription_Mob,
  StyledSubsectionStatContainer_Mob,
} from "../styles/forum/Subsection.styled";
import formateDate from "../../utils/DateFormater";
import { FaRegMessage, FaFeatherPointed } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

function InnerSubsection({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  if (isMobile) {
    return (
      <StyledSubsection_Mob>
        <div style={{marginBottom: "1rem"}}>
          <div style={{ fontSize: "2.5rem", marginRight: "0.5rem" }}>
            {data?.icon}
          </div>
          <StyledSubsectionTitleContainer_Mob>
            <StyledSubsectionTitle_Mob to={`${data?.id}/`}>
              {data?.name}
            </StyledSubsectionTitle_Mob>
            <StyledSubsectionDescription_Mob>
              {data?.description}
            </StyledSubsectionDescription_Mob>
          </StyledSubsectionTitleContainer_Mob>
        </div>

        <StyledSubsectionStatContainer_Mob>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
              color: "grey",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaFeatherPointed style={{ marginRight: "0.5rem" }} />
                <div style={{ fontSize: "1.2rem", marginRight: "0.5rem" }}>
                  Тем
                </div>
                {data?.theme_amount}
              </div>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaRegMessage style={{ marginRight: "0.5rem" }} />
                <div style={{ fontSize: "1.2rem", marginRight: "0.5rem" }}>
                  Постів
                </div>
                {data?.post_amount}
              </div>
            </div>
          </div>
        </StyledSubsectionStatContainer_Mob>

        {/* <StyledSubsectionLastPostContainer_Mob>
        <StyledSubsectionLastPost_Mob
          to={`/forum/theme/${data?.last_post?.theme_id}/?page=last`}
        >
          {data?.last_post?.theme}
        </StyledSubsectionLastPost_Mob>
        <div style={{ display: "flex" }}>
          <StyledSubsectionLastPostDate_Mob>
            {formateDate(data?.last_post?.date_creation)}
          </StyledSubsectionLastPostDate_Mob>
          <StyledSubsectionLastPostAuthor_Mob>
            {data?.last_post?.author}
          </StyledSubsectionLastPostAuthor_Mob>
        </div>
      </StyledSubsectionLastPostContainer_Mob> */}
      </StyledSubsection_Mob>
    );
  } else {
    return (
      <StyledSubsection>
        <div style={{ fontSize: "2.5rem", marginRight: "2rem" }}>
          {data?.icon}
        </div>
        <StyledSubsectionTitleContainer>
          <StyledSubsectionTitle to={`${data?.id}/`}>
            {data?.name}
          </StyledSubsectionTitle>
          <StyledSubsectionDescription>
            {data?.description}
          </StyledSubsectionDescription>
        </StyledSubsectionTitleContainer>

        <StyledSubsectionStatContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
              color: "grey",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <div style={{ fontSize: "1rem" }}>Тем</div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaFeatherPointed style={{ marginRight: "0.5rem" }} />
                {data?.theme_amount}
              </div>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <div style={{ fontSize: "1rem" }}>Постів</div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "0.5rem",
                  alignItems: "center",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                <FaRegMessage style={{ marginRight: "0.5rem" }} />
                {data?.post_amount}
              </div>
            </div>
          </div>
        </StyledSubsectionStatContainer>

        <StyledSubsectionLastPostContainer>
          <StyledSubsectionLastPost
            to={`/forum/theme/${data?.last_post?.theme_id}/?page=last`}
          >
            {data?.last_post?.theme}
          </StyledSubsectionLastPost>
          <div style={{ display: "flex" }}>
            <StyledSubsectionLastPostDate>
              {formateDate(data?.last_post?.date_creation)}
            </StyledSubsectionLastPostDate>
            <StyledSubsectionLastPostAuthor>
              {data?.last_post?.author}
            </StyledSubsectionLastPostAuthor>
          </div>
        </StyledSubsectionLastPostContainer>
      </StyledSubsection>
    );
  }
}

export default InnerSubsection;
