import { Plugin } from '@ckeditor/ckeditor5-core';
import DetailsSummaryCommand from './detailsSummaryCommand';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget';

export default class DetailsSummaryEditing extends Plugin {
    static get pluginName() {
        return 'DetailsSummaryEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        // Register the details and summary elements in the schema.
        schema.register('details', {
            isObject: true,
            allowWhere: '$block',
            allowContentOf: '$block'
        });

        schema.register('summary', {
            isLimit: true,
            allowIn: 'details',
            allowContentOf: '$block'
        });

        // Define converters.
        editor.conversion.for('upcast').elementToElement({
            model: 'details',
            view: 'details'
        });

        editor.conversion.for('upcast').elementToElement({
            model: 'summary',
            view: 'summary'
        });

        editor.conversion.for('dataDowncast').elementToElement({
            model: 'details',
            view: 'details'
        });

        editor.conversion.for('dataDowncast').elementToElement({
            model: 'summary',
            view: 'summary'
        });

        editor.conversion.for('editingDowncast').elementToElement({
            model: 'details',
            view: (modelElement, { writer: viewWriter }) => {
                const detailsElement = viewWriter.createContainerElement('details');
                return toWidget(detailsElement, viewWriter);
            }
        });

        editor.conversion.for('editingDowncast').elementToElement({
            model: 'summary',
            view: (modelElement, { writer: viewWriter }) => {
                const summaryElement = viewWriter.createEditableElement('summary');
                return toWidgetEditable(summaryElement, viewWriter);
            }
        });

        // Add the command.
        editor.commands.add('insertDetailsSummary', new DetailsSummaryCommand(editor));
    }
}
