import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledSubsection = styled.div`
  display: flex;
  border-bottom: 1px solid silver;
  padding: 1rem 0;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StyledSubsectionTitleContainer = styled.div`
  width: 50%;
  margin-right: 2rem;
`;

export const StyledSubsectionTitle = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledSubsectionDescription = styled.div`
  font-size: 1.3rem;
`;

export const StyledSubsectionStatContainer = styled.div`
  width: 25%;
  font-size: 1.3rem;
  margin-right: 2rem;
`;

export const StyledSubsectionLastPostContainer = styled.div`
  width: 25%;
`;

export const StyledSubsectionLastPost = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledSubsectionLastPostDate = styled.div`
  margin-right: 2rem;
  color: grey;
`;

export const StyledSubsectionLastPostAuthor = styled.div`
  color: grey;
`;

// ========== MOBILE ==========

export const StyledSubsection_Mob = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid silver;
  padding: 1rem 0;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StyledSubsectionTitleContainer_Mob = styled.div`
  width: 100%;
  margin-right: 2rem;
`;

export const StyledSubsectionTitle_Mob = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledSubsectionDescription_Mob = styled.div`
  font-size: 1.3rem;
`;

export const StyledSubsectionStatContainer_Mob = styled.div`
  width: 100%;
  font-size: 1.3rem;
  margin-right: 2rem;
`;

export const StyledSubsectionLastPostContainer_Mob = styled.div`
  width: 100%;
`;

export const StyledSubsectionLastPost_Mob = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgb(49, 135, 238);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  &:hover {
    color: rgb(82, 160, 255);
  }

  &:active {
    color: rgb(49, 135, 238);
  }
`;

export const StyledSubsectionLastPostDate_Mob = styled.div`
  margin-right: 2rem;
`;

export const StyledSubsectionLastPostAuthor_Mob = styled.div``;
