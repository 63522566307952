import client from "./client";

export const getEstablishments = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const getEstablishmentsFilters = (pathname = "", params = {}) => {
  return client.get(pathname, params);
};

export const getEstablishment = (pathname, options, params = {}) => {
    return client.get(pathname, options, params);
  };

  export const getEstablishmentReviews = (pathname, options, params = {}) => {
    return client.get(pathname, options, params);
  };