import React, { useContext } from "react";
import { StyledPleaseLogin } from "../styles/shared/PleaseLogin.styled";
import Button from "../Button";
import { LoginBtn } from "../styles/LoginBtn.styled";
import { SignUpBtn } from "../styles/SignUpBtn.styled";
import { useLocation, useNavigate } from "react-router-dom";
import PublicationContext from "../contexts/PublicationContext";

function PleaseLogin({ text, style }) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setEntranceForm,
    isEntranceModalOpen,
    setEntranceModalOpen,
    entranceForm,
  } = useContext(PublicationContext);

  function openModal(form) {
    setEntranceModalOpen(true);
    setEntranceForm(form);
    navigate(`/${form}/`, {
      state: { background: location, scrollto: window.scrollY },
    });
  }

  return (
    <StyledPleaseLogin style={style}>
      <p>
        {text ||
          "Будь ласка, зареєструйтесь або зайдіть, щоб мати можливість залишати відгук."}
      </p>
      <div>
        <Button
          text={"Увійти"}
          styled={LoginBtn}
          onClick={() => navigate("/login/")}
        />
        <Button
          text={"Зареєструватися"}
          styled={SignUpBtn}
          onClick={() => navigate("/register/")}
        />
      </div>
    </StyledPleaseLogin>
  );
}

export default PleaseLogin;
