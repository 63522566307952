import React, { useState } from "react";
import formateDate from "../../utils/DateFormater";
import {
  StyledArticle__Date,
  StyledArticle__RoomLabel,
  StyledBlockContainer,
  StyledBlock__Title,
  StyledBlock__TitleContainer,
  StyledFeedbackCard,
  StyledFeedbackCard__BottomContainer,
  StyledFeedbackCard__Feedback,
  StyledFeedbackCard__UserContainer,
  StyledFeedbackCard__UserImage,
  StyledFeedbackCard__UserName,
} from "../../componets/styles/pages/main/Block.styled";
import { Rating } from "react-simple-star-rating";
import * as DOMPurify from "dompurify";
import ReactTimeAgo from "react-time-ago";
import { useMediaQuery } from "react-responsive";

function Feedbacks({ feedbacks }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [f, setF] = useState([
    ...feedbacks?.casinos_feedbacks,
    ...feedbacks?.rooms_feedbacks,
  ]);
  return (
    <StyledBlockContainer>
      <div style={{ width: "100%" }}>
        <StyledBlock__TitleContainer>
          <StyledBlock__Title>Останні відгуки</StyledBlock__Title>
        </StyledBlock__TitleContainer>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {f
            ?.sort((a, b) => {
              return new Date(b?.date_published) - new Date(a?.date_published);
            })
            .map((f, i) => {
              while (i < 4) {
                return (
                  <StyledFeedbackCard>
                    <StyledFeedbackCard__UserContainer>
                      <StyledFeedbackCard__UserImage
                        src={f?.user?.photo}
                        alt={f?.user?.user?.username}
                      />
                      <div>
                        <StyledFeedbackCard__UserName>
                          {f?.user?.user?.username}
                        </StyledFeedbackCard__UserName>
                        <Rating
                          allowFraction={true}
                          initialValue={f?.rating}
                          size={20}
                          readonly
                        />
                      </div>
                    </StyledFeedbackCard__UserContainer>
                    <StyledFeedbackCard__Feedback
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          f?.text.replaceAll("<p>&nbsp;</p>", "")
                        ),
                      }}
                    ></StyledFeedbackCard__Feedback>
                    <StyledFeedbackCard__BottomContainer>
                      <StyledArticle__Date>
                        <ReactTimeAgo
                          date={f?.date_published}
                          locale="uk-UK"
                          timeStyle={{
                            labels: "twitter-minute-now",
                          }}
                        />
                      </StyledArticle__Date>
                      <StyledArticle__RoomLabel
                        to={`/${f?.room ? "rooms" : "casinos"}/${
                          f?.room?.slug || f?.casino?.slug
                        }/`}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#000000",
                          fontWeight: "700",
                        }}
                      >
                        {/* <img
                          src={f?.room?.logo_small || f?.casino?.logo_small}
                          alt={f?.room?.name || f?.casino?.name}
                        /> */}
                        <div
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          {f?.room?.name || f?.casino?.name}
                        </div>
                      </StyledArticle__RoomLabel>
                    </StyledFeedbackCard__BottomContainer>
                  </StyledFeedbackCard>
                );
              }
            })}
        </div>
      </div>
    </StyledBlockContainer>
  );
}

export default Feedbacks;
