import React from "react";

function Button({
  styled: Button,
  text,
  icon,
  className,
  onClick,
  style,
  children,
  disabled,
  type,
  ariaLabel,
  ...otherProps
}) {
  return (
    <Button
      type={type}
      className={className}
      onClick={onClick}
      style={style}
      disabled={disabled}
      aria-label={text || ariaLabel}
      {...otherProps}
    >
      {icon}
      {text}
      {children}
    </Button>
  );
}

export default Button;
